import React from 'react';
import { Breadcrumbs } from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import { PurchaseOrder } from './screens';

export const TelematicsPurchaseOrderReconciliation = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  return (
    <div
      className={`grid-col margin-bottom-2${isDesktop ? ' slim-width' : ''}`}
    >
      <Breadcrumbs
        items={[
          { path: '/home', text: 'Home' },
          { text: 'Telematics PO Reconciliation' },
        ]}
      />
      <PurchaseOrder isDesktop={isDesktop} />
    </div>
  );
};
export default TelematicsPurchaseOrderReconciliation;
