import React, { useState, useCallback, useEffect } from 'react';
import {
  PageTitle,
  RadioButton,
  Button,
  Alert,
  Icon,
} from '@gsa/afp-component-library';
import { Composition } from 'atomic-layout';
import { useHistory } from 'react-router-dom';
import { AreYouSureModal } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { setEvafData } from 'reducers/marshalling';
import '../screens.css';
import { scrollToTop } from 'utils';

export const VehicleTypeSelection = ({
  setScreen,
  eVafFormCompleted = false,
  setEvafFormCompleted,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const evafData = useSelector((state) => state?.marshalling?.evaf || {});
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [vehicleType, setVehicleType] = useState('');

  const submitHandler = useCallback(() => {
    setFormErrors({});
    if (vehicleType.trim() === '') {
      setFormErrors({
        ...formErrors,
        vehicleType: 'Must select an option to continue.',
      });
      return;
    }
    let evafType = '';
    if (vehicleType === 'exchange') {
      evafType = 'exchange-vehicle';
      setScreen('exchange-vehicle');
    }
    if (vehicleType === 'pickup') {
      evafType = 'new-vehicle';
      setScreen('new-vehicle');
    }
    if (vehicleType === 'turnin') {
      evafType = 'old-vehicle';
      setScreen('old-vehicle');
    }
    dispatch(
      setEvafData({
        ...evafData,
        evafType,
      }),
    );
    if (vehicleType !== '') {
      setEvafFormCompleted(false);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formErrors, vehicleType, setEvafFormCompleted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [vehicleType]);

  return (
    <div className="grid-col">
      <PageTitle
        title="Electronic Vehicle Accountability Form (eVAF)"
        className={`${isDesktop ? 'margin-top-0' : ''} evaf-title`}
      />
      <Composition
        areas={`
          ${eVafFormCompleted ? `AlertMessages` : ``}
          SelectionForm
          ContinueButton
          CancelButton
        `}
        areasMd={`
          ${eVafFormCompleted ? `AlertMessages AlertMessages` : ``}
          SelectionForm SelectionForm
          ContinueButton ContinueButton 
          CancelButton CancelButton
        `}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({ AlertMessages, SelectionForm, ContinueButton, CancelButton }) => (
          <>
            <AlertMessages>
              {eVafFormCompleted && (
                <>
                  <Alert
                    className="margin-bottom-3"
                    type="success"
                    heading=""
                    noIcon={false}
                    validation={false}
                    focused={false}
                    showClose={true}
                    onClose={() => setEvafFormCompleted(false)}
                  >
                    The Electronic Vehicle Accountability form (eVAF) has been
                    signed and completed.
                  </Alert>
                </>
              )}
            </AlertMessages>
            <SelectionForm>
              <div className={formErrors?.vehicleType ? 'form-error' : ''}>
                {formErrors?.vehicleType && (
                  <div className="form-error-msg">
                    <strong>{formErrors.vehicleType}</strong>
                  </div>
                )}
                <RadioButton
                  data-testid="vehicle-exchange-radio"
                  id="vehicle-exchange"
                  name="vehicle-type"
                  label="Vehicle Exchange"
                  checked={vehicleType === 'exchange'}
                  onChange={() => {
                    setFormErrors({});
                    setVehicleType('exchange');
                    setEvafFormCompleted(false);
                  }}
                />
                <RadioButton
                  data-testid="pickup-only-radio"
                  id="pickup-only"
                  name="vehicle-type"
                  label="Pick up ONLY"
                  checked={vehicleType === 'pickup'}
                  className="margin-top-3"
                  onChange={() => {
                    setFormErrors({});
                    setVehicleType('pickup');
                    setEvafFormCompleted(false);
                  }}
                />
                <RadioButton
                  data-testid="turnin-only-radio"
                  id="turnin-only"
                  name="vehicle-type"
                  label="Turn in ONLY"
                  checked={vehicleType === 'turnin'}
                  className="margin-top-3 margin-bottom-3"
                  onChange={() => {
                    setFormErrors({});
                    setVehicleType('turnin');
                    setEvafFormCompleted(false);
                  }}
                />
              </div>
            </SelectionForm>
            <ContinueButton>
              <Button
                className={`${
                  isDesktop
                    ? 'continue-button-evaf-desk'
                    : 'continue-button-evaf-mob'
                } icon-buttowidth-full`}
                data-testid="continue-button"
                size={isDesktop ? 'medium' : 'large'}
                label="Continue"
                onClick={submitHandler}
              />
              <Icon
                className={`${
                  isDesktop ? 'right-icon-evaf-desk' : 'right-icon-evaf-mob'
                } usa-icon--size-3 right-icon-evaf`}
                iconName="arrow_forward"
              />
            </ContinueButton>
            <CancelButton>
              <Button
                className="width-full"
                data-testid="cancel-button"
                variant="outline"
                size={isDesktop ? 'medium' : 'large'}
                label="Cancel"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </CancelButton>
          </>
        )}
      </Composition>
      <AreYouSureModal
        title="Cancel eVAF."
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Cancel eVAF"
        cancelLabel="Return eVAF"
        onConfirm={() => {
          // CLEAR EVAF SESSION HERE
          setShowModal(false);
          history.push('/home');
          scrollToTop();
        }}
        onCancel={() => setShowModal(false)}
      >
        Are you sure you want to cancel eVAF?
      </AreYouSureModal>
    </div>
  );
};

export default VehicleTypeSelection;
