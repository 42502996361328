import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, Label, Textbox, Button } from '@gsa/afp-component-library';
import { scrollToElement } from 'utils';
import { Composition } from 'atomic-layout';
import {
  PhotoCapture,
  AreYouSureModal,
  ManagedAccordion,
  DamageCodesModal,
} from 'components';
import { damageCodeMap, damageTypes } from 'constants/damageCodeMap';
import './DamageInTransit.css';

export const DamageInTransit = ({ vin, errors = {}, validator, onChange }) => {
  const [damage, setDamage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showDamageModal, setShowDamageModal] = useState(false);
  const [removalItem, setRemovalItem] = useState(null);
  const [openItems, setOpenItems] = useState([]);
  const [damageCodes, setDamageCodes] = useState([]);
  const [damageModalData, setDamageModalData] = useState({});
  const damageTypeOptions = damageTypes.map((type) => {
    return {
      code: type.code,
      text: `${type.code} - ${type.text}`,
    };
  });

  useEffect(() => {
    damageCodeMap.every((area, i) => {
      if (errors[area.slug] && Object.keys(errors[area.slug]).length > 0) {
        setOpenItems([i]);
        window.setTimeout(() => {
          scrollToElement(`.container-${i}`);
        }, 250);
        return false;
      }
      return true;
    });
  }, [errors]);

  const handleRemovalComplete = () => {
    const { area, code } = removalItem;
    const codes = damage[area] || {};
    const newCodes = {};
    for (const [key, value] of Object.entries(codes)) {
      if (key !== code) {
        newCodes[key] = value;
      }
    }
    let newDamage = {};
    if (Object.keys(newCodes).length < 1) {
      for (const [damageArea, damageData] of Object.entries(damage)) {
        if (damageArea !== area) {
          newDamage[damageArea] = damageData;
        }
      }
    } else {
      newDamage = {
        ...damage,
        [area]: newCodes,
      };
    }
    setDamage(newDamage);
    setShowModal(false);
    setRemovalItem(null);
  };

  const formatTitle = useCallback(
    (name, slug) => {
      const areaCount = damage[slug] ? Object.keys(damage[slug]).length : 0;
      return (
        <span className="accordion-title">
          <span className="text-container">
            {name}
            {areaCount > 0 && <span className="count-bubble">{areaCount}</span>}
          </span>
        </span>
      );
    },
    [damage],
  );

  const formatContent = (area, damage, vin) => {
    return (
      <div className="area-content">
        {area.codes.map((code, i) => {
          const areaSlug = area.slug;
          return (
            <Composition
              key={i}
              areas={`
                Selection
                Details
              `}
              gap={16}
              templateRows="auto"
              templateCols="1fr"
            >
              {({ Selection, Details }) => (
                <>
                  <Selection>
                    <Checkbox
                      className="damage-select"
                      id={`${areaSlug}-${i}-code-${code.code}`}
                      name={`${areaSlug}-${i}-code-${code.code}`}
                      label={`${code.code} - ${code.text}`}
                      value={code.code}
                      checked={
                        damage[areaSlug] && damage[areaSlug][code.code]
                          ? 'checked'
                          : ''
                      }
                      onChange={(e) => {
                        if (
                          damage[areaSlug] &&
                          Object.keys(damage[areaSlug]).includes(code.code)
                        ) {
                          e.preventDefault();
                          setRemovalItem({
                            area: areaSlug,
                            code: code.code,
                          });
                          setShowModal(true);
                        } else {
                          setDamage({
                            ...damage,
                            [areaSlug]: {
                              ...(damage[areaSlug] || {}),
                              [code.code]: {},
                            },
                          });
                        }
                      }}
                    />
                  </Selection>
                  <Details>
                    {damage[areaSlug] && damage[areaSlug][code.code] && (
                      <div
                        data-testid={`damage-details-${areaSlug}-${i}-code-${code.code}`}
                        className="damage-details"
                      >
                        <Composition
                          areas={`
                            CodeSelect
                            Photos
                            AdditionalDetails
                          `}
                          gap={32}
                          templateRows="auto"
                          templateCols="1fr"
                        >
                          {({ CodeSelect, Photos, AdditionalDetails }) => (
                            <>
                              <CodeSelect>
                                <div
                                  className={
                                    errors[areaSlug] &&
                                    errors[areaSlug][code.code]?.type
                                      ? 'form-error'
                                      : ''
                                  }
                                >
                                  <Label required>Damage code</Label>
                                  <p className="damage-code-info">
                                    Select all applicable damage codes for the
                                    damage location.
                                  </p>
                                  {damage[areaSlug][code.code]?.type &&
                                    damage[areaSlug][code.code]?.type.length >
                                      0 &&
                                    !showDamageModal && (
                                      <div className="damage-code-chip">
                                        {damage[areaSlug][code.code]?.type.map(
                                          (data, i) => {
                                            return (
                                              <span
                                                className="afp-chip"
                                                key={i}
                                              >
                                                {data}
                                                <div
                                                  className="damage-code-chipclose primary"
                                                  onClick={() => {
                                                    const selectedCodes =
                                                      JSON.parse(
                                                        JSON.stringify(
                                                          damage[areaSlug][
                                                            code.code
                                                          ]?.type,
                                                        ),
                                                      );
                                                    const filtered =
                                                      selectedCodes.filter(
                                                        (it) => it !== data,
                                                      );
                                                    setDamage({
                                                      ...damage,
                                                      [areaSlug]: {
                                                        ...(damage[areaSlug] ||
                                                          {}),
                                                        [code.code]: {
                                                          ...(damage[areaSlug][
                                                            code.code
                                                          ] || {}),
                                                          type: filtered,
                                                        },
                                                      },
                                                    });
                                                  }}
                                                >
                                                  x
                                                </div>
                                              </span>
                                            );
                                          },
                                        )}
                                      </div>
                                    )}
                                  {errors[areaSlug] &&
                                    errors[areaSlug][code.code]?.type && (
                                      <div
                                        data-testid="form-error-msg"
                                        className="form-error-msg"
                                      >
                                        <strong>
                                          This is a required field
                                        </strong>
                                      </div>
                                    )}
                                  <Button
                                    data-testid="damage-code"
                                    className="width-full"
                                    variant="outline"
                                    size="medium"
                                    label="Add Damage Codes"
                                    leftIcon={{
                                      name: 'add',
                                      iconName: 'add',
                                    }}
                                    onClick={() => {
                                      if (
                                        damage[areaSlug][code.code]?.type &&
                                        damage[areaSlug][code.code]?.type
                                          .length > 0
                                      ) {
                                        setDamageCodes(
                                          damage[areaSlug][code.code]?.type,
                                        );
                                      }
                                      setDamageModalData({
                                        areaSlug,
                                        code: code.code,
                                      });
                                      setShowDamageModal(true);
                                    }}
                                  />
                                </div>
                              </CodeSelect>
                              <Photos>
                                <PhotoCapture
                                  vin={vin}
                                  area={areaSlug}
                                  code={code.code}
                                  id={`${areaSlug}-${i}-code-${code.code}-photo`}
                                  name={`${areaSlug}-${i}-code-${code.code}-photo`}
                                  onCapture={(photos, photoData) => {
                                    setDamage({
                                      ...damage,
                                      [areaSlug]: {
                                        ...(damage[areaSlug] || {}),
                                        [code.code]: {
                                          ...(damage[areaSlug][code.code] ||
                                            {}),
                                          photos,
                                          photoData,
                                        },
                                      },
                                    });
                                  }}
                                />
                              </Photos>
                              <AdditionalDetails>
                                <div
                                  className={
                                    errors[areaSlug] &&
                                    errors[areaSlug][code.code]?.details
                                      ? 'form-error'
                                      : ''
                                  }
                                >
                                  <Label>Additional details</Label>
                                  {errors[areaSlug] &&
                                    errors[areaSlug][code.code]?.details && (
                                      <div
                                        data-testid="form-error-msg"
                                        className="form-error-msg"
                                      >
                                        <strong>
                                          Text limited to 1000 characters
                                        </strong>
                                      </div>
                                    )}
                                  <Textbox
                                    id={`${areaSlug}-${i}-code-${code.code}-details`}
                                    name={`${areaSlug}-${i}-code-${code.code}-details`}
                                    type="textarea"
                                    size="medium"
                                    onChange={(e) => {
                                      setDamage({
                                        ...damage,
                                        [areaSlug]: {
                                          ...(damage[areaSlug] || {}),
                                          [code.code]: {
                                            ...(damage[areaSlug][code.code] ||
                                              {}),
                                            details: e.target.value || '',
                                          },
                                        },
                                      });
                                      const textLeft =
                                        1000 - Number(e.target.value.length);
                                      const charBox = document.getElementById(
                                        `${areaSlug}-${i}-code-${code.code}-chars`,
                                      );
                                      const allChars = charBox.parentNode;
                                      if (charBox) {
                                        if (textLeft < 1000) {
                                          charBox.innerHTML =
                                            (textLeft >= 0 ? textLeft : 0) +
                                            '/';
                                          if (textLeft < 0) {
                                            if (
                                              allChars.className.indexOf(
                                                'error',
                                              ) === -1
                                            ) {
                                              allChars.className += ' error';
                                            }
                                          } else {
                                            allChars.className =
                                              allChars.className
                                                .split(' ')
                                                .filter(
                                                  (classItem) =>
                                                    classItem !== 'error',
                                                )
                                                .join(' ');
                                          }
                                        } else {
                                          charBox.innerHTML = '';
                                          allChars.className =
                                            allChars.className
                                              .split(' ')
                                              .filter(
                                                (classItem) =>
                                                  classItem !== 'error',
                                              )
                                              .join(' ');
                                        }
                                      }
                                    }}
                                  />
                                  <div className="textbox-note">
                                    <span
                                      id={`${areaSlug}-${i}-code-${code.code}-chars`}
                                    ></span>
                                    1000 characters allowed
                                  </div>
                                </div>
                              </AdditionalDetails>
                            </>
                          )}
                        </Composition>
                      </div>
                    )}
                  </Details>
                </>
              )}
            </Composition>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(damage);
    }
  }, [damage, onChange]);

  const items = damageCodeMap.map((area, i) => {
    return {
      id: i,
      title: formatTitle(area.name, area.slug),
      content: formatContent(area, damage, vin),
      expanded: false,
    };
  });

  if (!vin) {
    return null;
  }

  return (
    <div
      className="damage-in-transit border-bottom border-base-light padding-bottom-3 padding-top-1"
      data-testid="damage-in-transit"
    >
      <h2 className="heading">
        BOL Damage Code <span className="nowrap">Assistance Tool</span>
      </h2>
      <p>Select all applicable areas:</p>
      <ManagedAccordion
        items={items}
        openItems={openItems}
        onOpen={(itemId) => {
          if (typeof validator === 'function' && !openItems.includes(itemId)) {
            validator(damage, true);
          }
          setOpenItems([itemId]);
        }}
        onClose={(itemId) => {
          if (typeof validator === 'function' && openItems.includes(itemId)) {
            validator(damage, true);
          }
          setOpenItems([]);
        }}
      />
      <AreYouSureModal
        title="Are you sure you want to remove this selection?"
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, remove"
        cancelLabel="Cancel"
        onConfirm={() => handleRemovalComplete()}
        onCancel={() => {
          setRemovalItem(null);
          setShowModal(false);
        }}
      >
        Unchecking this box will remove any details and photos you may have
        added.
      </AreYouSureModal>
      <DamageCodesModal
        title="Damage Codes"
        show={showDamageModal}
        confirmLabel="Add"
        cancelLabel="Cancel"
        onConfirm={() => {
          setDamage({
            ...damage,
            [damageModalData?.areaSlug]: {
              ...(damage[damageModalData?.areaSlug] || {}),
              [damageModalData?.code]: {
                ...(damage[damageModalData?.areaSlug][damageModalData?.code] ||
                  {}),
                type: damageCodes,
              },
            },
          });
          setDamageCodes([]);
          setShowDamageModal(false);
        }}
        onCancel={() => {
          setDamageCodes([]);
          setShowDamageModal(false);
        }}
      >
        {damageTypeOptions.map((damageCode, i) => {
          return (
            <Checkbox
              key={i}
              className="damage-code-checks"
              id={damageCode.code}
              name={damageCode.code}
              label={damageCode.text}
              value={damageCode.code}
              checked={damageCodes.includes(damageCode.text) ? 'checked' : ''}
              onChange={() => {
                if (damageCodes.includes(damageCode.text)) {
                  const filtered = JSON.parse(
                    JSON.stringify(damageCodes),
                  ).filter((i) => i !== damageCode.text);
                  setDamageCodes(filtered);
                } else {
                  const selected = [...damageCodes, damageCode.text];
                  setDamageCodes(selected);
                }
              }}
            />
          );
        })}
      </DamageCodesModal>
    </div>
  );
};

export default DamageInTransit;
