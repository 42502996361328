import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from 'atomic-layout';
import {
  Breadcrumbs as AfpBreadcrumbs,
  Chip,
  Icon,
} from '@gsa/afp-component-library';
import { AreYouSureModal } from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSync } from 'hooks';
import 'styled-components/macro';
import './Breadcrumbs.css';

const FsrView = ({ locationName, onClick }) => {
  const label = (
    <span>
      <Icon
        iconName="edit"
        css={`
          vertical-align: text-top;
        `}
      />
      <span
        css={`
          display: inline-block;
          margin-left: 4px;
        `}
      >
        {locationName}
      </span>
    </span>
  );
  return (
    <div
      css={`
        cursor: pointer;
        display: inline-block;
      `}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
    >
      <Chip label={label} readonly={true} nowrap={true} />
    </div>
  );
};

export const Breadcrumbs = ({ items }) => {
  let history = useHistory();
  const { isFsr } = useSync();
  const userState = useSelector((state) => state?.user || {});
  const [showModal, setShowModal] = useState(false);
  const currentZone = userState?.fsrZone || null;
  const currentLocation = userState?.fsrLocation || null;
  const locations = userState?.locations || [];
  const locationName = locations.find((loc) => {
    return loc.locationId === Number(currentLocation);
  });

  const handleLocationSwitch = () => {
    setShowModal(true);
    history.push('/');
  };

  const isDesktop = useResponsiveQuery({ from: 'md' });

  const generateLink = (path, text) => {
    return <NavLink to={path}>{text}</NavLink>;
  };

  const crumbs = items.filter((item) => item?.path);
  const current = items.filter((item) => !item?.path);

  return (
    <Box
      className="pwa-breadcrumbs"
      marginTop={16}
      marginTopMd={0}
      marginBottom="-1rem"
      marginBottomMd={0}
      data-testid="bread-crumbs"
      css={`
        & > nav {
          margin-bottom: ${isDesktop ? '0' : 'inherit'};
        }
      `}
    >
      <AfpBreadcrumbs
        trail={crumbs.map((item) => generateLink(item.path, item.text))}
        current={current.length ? current[0].text : ''}
        className={isDesktop ? 'margin-bottom-0' : ''}
      />
      {isFsr && currentZone && currentLocation && (
        <>
          <div
            data-testid="fsr-view-container"
            css={`
              margin-top: ${isDesktop ? '0' : '24px'};
              margin-bottom: 16px;
            `}
          >
            <FsrView
              locationName={locationName.name}
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
          <AreYouSureModal
            title="Switch the Marshalling location?"
            show={showModal}
            showConfirm={true}
            showCancel={true}
            confirmLabel="Switch Location"
            cancelLabel="Cancel"
            onConfirm={() => handleLocationSwitch()}
            onCancel={() => {
              setShowModal(false);
            }}
          >
            <p>You are currently operating as this Marshalling location:</p>
            <p>
              <strong>
                {locationName.name}
                <br />
                {locationName.countryId}
                <br />
                {locationName.address1} {locationName.address2}{' '}
                {locationName.address3}
                <br />
                {locationName.city} {locationName.stateId}
                <br />
                {locationName.postalCode}
              </strong>
            </p>
            <p>
              If you would like to switch the Marshalling location, please make
              sure you complete your current task before switching.
            </p>
          </AreYouSureModal>
        </>
      )}
    </Box>
  );
};

export default Breadcrumbs;
