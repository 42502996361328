import React from 'react';
import { Composition } from 'atomic-layout';
import { PageTitle, Button, Alert } from '@gsa/afp-component-library';
import { formatVehicleTitle } from 'utils';
import '../screens.css';

export const TelematicsNotRequired = ({
  setScreen,
  vehicle,
  errorMessage,
  isDesktop,
}) => {
  const parseError = (errorCode) => {
    let errorMessage = '';
    switch (errorCode) {
      case 'TELEMATICS_NOT_REQUIRED':
        errorMessage =
          'This vehicle does not required Telematics device installation.';
        break;
      case 'TELEMATICS_ALREADY_INSTALLED':
        errorMessage = 'This vehicle already has telematics installed.';
        break;
      default:
        errorMessage = (
          <>
            An unknown error occurred during telematics device check. If the
            issue persists, please report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
    }
    return errorMessage;
  };

  return (
    <div className="grid-col">
      <Alert
        className={`${isDesktop ? 'margin-bottom-3' : 'margin-top-3'}`}
        type="error"
        heading=""
        noIcon={false}
        validation={false}
        focused={false}
        showClose={false}
      >
        {parseError(errorMessage)}
      </Alert>
      <PageTitle
        title="Telematics Installation"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <Composition
        areas={`VehicleDetails`}
        gap={8}
        templateCols="auto"
        templateRows="auto"
        alignItems="center"
      >
        {({ VehicleDetails }) => (
          <>
            <VehicleDetails>
              {vehicle && (
                <>
                  <div>
                    <strong>{formatVehicleTitle(vehicle)}</strong>
                  </div>
                  <div>
                    <strong>VIN: {vehicle.vin}</strong>
                  </div>
                  <div>
                    <strong>RPN/Order Number: {vehicle.orderNumber}</strong>
                  </div>
                </>
              )}
            </VehicleDetails>
          </>
        )}
      </Composition>
      <div className="padding-bottom-3 padding-top-5">
        <Button
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Return to scan"
          leftIcon={{
            name: 'photo_camera',
            iconName: 'photo_camera',
          }}
          onClick={() => {
            setScreen('find-vehicle');
          }}
        />
      </div>
    </div>
  );
};

export default TelematicsNotRequired;
