import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, connectModal, Button } from '@gsa/afp-component-library';

const Title = () => {
  return <h2>Update Available</h2>;
};

const Content = () => {
  return (
    <article className="padding-bottom-5 padding-top-5">
      A new version of this application is available. Please update to the
      latest version.
    </article>
  );
};

const Actions = ({ onConfirm }) => (
  <footer className="display-flex">
    <Button
      label="Update"
      variant="primary"
      data-testid="update-confirm-btn"
      onClick={onConfirm}
    />
  </footer>
);

Actions.propTypes = {
  onConfirm: PropTypes.func.isRequired,
};

export const UpdateModal = ({ showReload = false, reload }) => {
  const [isOpen, setIsOpen] = useState(showReload);

  useEffect(() => {
    if (showReload) {
      setIsOpen(true);
    }
  }, [showReload]);

  const handleConfirm = () => {
    setIsOpen(false);
    reload();
  };

  const ConfirmUpdateModal = connectModal(() => (
    <Modal
      className="usa-prose"
      hideClose
      title={<Title />}
      actions={<Actions onConfirm={handleConfirm} />}
    >
      <Content />
    </Modal>
  ));

  return <ConfirmUpdateModal isOpen={isOpen} />;
};

export default UpdateModal;
