import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import { useResponsiveQuery } from 'atomic-layout';
import { PlateListing } from 'components';
import 'styled-components/macro';
import './PlateReconcileAckModal.css';

export const PlateReconcileAckModal = ({
  show = false,
  issuePlates,
  onConfirm,
  modalTitle,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  if (!show) {
    return null;
  }

  return (
    <div
      data-testid="plate-reconcile-ack-modal"
      className={`afp-modal-wrapper${isDesktop ? '' : ' mobile'}`}
      role="dialog"
      aria-labelledby="modal-heading"
    >
      <div className="afp-modal-overlay">
        <Modal
          title={`Not all plates were ${modalTitle}`}
          actions={
            <Button
              data-testid="close-button"
              className="width-full"
              variant="primary"
              size={isDesktop ? 'medium' : 'large'}
              label="Acknowledge"
              onClick={() => {
                if (typeof onConfirm === 'function') {
                  onConfirm();
                }
              }}
              css={`
                text-align: center !important;
              `}
            />
          }
          className="plate-reconcile-ack-modal"
          hideClose={false}
          onClose={() => {
            if (typeof onConfirm === 'function') {
              onConfirm();
            }
          }}
          variant="large"
        >
          <div className="margin-bottom-3">
            The plates listed below were not successfully {modalTitle} for pending destruction. Please set them aside and contact your FSR.
          </div>
          <PlateListing
            isResponseList={true}
            plates={issuePlates}
            defaultOpen={true}
          />
        </Modal>
      </div>
    </div>
  );
};

export default PlateReconcileAckModal;
