import React, { useState } from 'react';
import { DeliveryListing } from 'components';
import { PageTitle, Alert, Button } from '@gsa/afp-component-library';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeAcceptedVehicle,
  removeAllVehicles,
  undoAllVehiclesRemoval,
} from 'reducers/marshalling';
import { Composition } from 'atomic-layout';
import { SessionSummary, AreYouSureModal } from 'components';
import { scrollToTop } from 'utils';
import { useMutation } from '@apollo/client';
import {
  CREATE_DAMAGE_IN_TRANSIT,
  COMPLETE_DELIVERY_SESSION,
} from 'services/sync-gql';

export const Review = ({
  setScreen,
  continueSession,
  setContinueSession,
  setSessionComplete,
  setSessionCompleteCount,
  setVehicleSuccess,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const deliveryData = marshallingData?.delivery || [];
  const removedVehicleData = marshallingData?.removedVehicle || [];
  const itemsWithDit = deliveryData.filter((vehicle) => vehicle.dit === 'yes');
  const itemsWithoutDit = deliveryData.filter(
    (vehicle) => vehicle.dit === 'no',
  );
  const itemsCannotDit = deliveryData.filter(
    (vehicle) => vehicle.dit === 'cannot',
  );
  const [showRemoval, setShowRemoval] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDamageConfirmModal, setShowDamageConfirmModal] = useState(false);
  const [error, setError] = useState(null);
  const userData = useSelector((state) => state?.user || {});
  const vendorId = userData?.vendorId || '0';

  const [uploadCompleteDelivery] = useMutation(COMPLETE_DELIVERY_SESSION, {
    onError: (error) => {
      console.log(error);
    },
  });

  const [createDamageInTransit] = useMutation(CREATE_DAMAGE_IN_TRANSIT, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: async () => {
      return true;
    },
  });

  const handleVehicleRemoval = (vehicle) => {
    setContinueSession(false);
    dispatch(removeAcceptedVehicle(vehicle.id));
    const listOfWhatIsLeft = deliveryData.filter(
      (item) => item.id !== vehicle.id,
    );
    if (listOfWhatIsLeft.length < 1) {
      setVehicleSuccess(false);
      setScreen('find-vehicle');
    }
  };

  const handleUndoAllVehiclesRemoval = () => {
    dispatch(undoAllVehiclesRemoval());
    setShowRemoval(!showRemoval);
  };

  const handleRemoveAllVehicles = () => {
    dispatch(removeAllVehicles());
  };

  const constructImagesFormat = (vin, category, photos, photoData, type) => {
    const result = [];
    if (photos.length > 0) {
      if (photoData.length > 0) {
        //for multiple images
        photoData.forEach((photo, i) => {
          const final = {
            file: photo,
            fileName: `${category}-${type}${
              type === 'cannotInspect' ? `-${i + 1}` : ''
            }`,
            thumbnailSizes: [
              { thumbnailWidth: 100, thumbnailHeight: 100 },
              { thumbnailWidth: 50, thumbnailHeight: 50 },
              { thumbnailWidth: 10, thumbnailHeight: 10 },
            ],
            originalFileFormat: photos[i]?.type,
            thumbnailFileFormat: [photos[i]?.type],
            VIN: vin,
          };
          result.push(final);
        });
      }
    }
    return result;
  };

  const constructSessionImageData = () => {
    let result = [];
    deliveryData.forEach((data) => {
      const { damage, vin, cannot } = data;
      //Check data has damage codes selected
      if (Object.keys(damage).length > 0) {
        for (const category in damage) {
          const nested = damage[category];
          for (const part in nested) {
            const { photos, photoData, type } = nested[part];
            //Create sessionImages structure if it has atleast one image
            result.push(
              ...constructImagesFormat(vin, category, photos, photoData, type),
            );
          }
        }
      } else if (Object.keys(cannot).length > 0) {
        const { photos, photoData } = cannot;
        result.push(
          ...constructImagesFormat(
            vin,
            vin.toString(),
            photos,
            photoData,
            'cannotInspect',
          ),
        );
      }
    });
    return result;
  };

  const constructDamageAreaCodesData = (ditId) => {
    let result = [];
    itemsWithDit.forEach((item) => {
      const { damage } = item;
      //Check data has damage codes selected
      if (Object.keys(damage).length > 0) {
        for (const category in damage) {
          const nested = damage[category];
          for (const part in nested) {
            const { type, details } = nested[part];
            const typeCodes = [];
            type.forEach((it) => {
              typeCodes.push(it.split('-')[0].trim());
            });
            typeCodes.forEach((element) => {
              //Create DIT Area Codes structure
              const final = {
                damageAreaCodeId: Number(part),
                damageTypeCodeId: Number(element),
                addlnDetails: details,
                ditId: Number(ditId),
              };
              result.push(final);
            });
          }
        }
      }
    });
    return result;
  };

  const constructCannotInspect = () => {
    let result = [];
    itemsCannotDit.forEach((item) => {
      if (item.dit === 'cannot') {
        const constructed = {
          addln_documentation: item.cannot.details,
          vin: item.vin,
        };
        result.push(constructed);
      }
    });
    return result;
  };

  const createDamageInTransitRecord = async () => {
    var { data } = await createDamageInTransit({
      variables: {
        damageInTransit: {
          noticedAt: new Date(),
          submittedAt: new Date(),
          vendorId: vendorId,
        },
      },
    });
    if (data && data.addDamageInTransit) {
      return data.addDamageInTransit.id;
    }
  };

  const completeDeliverySession = async () => {
    const id = await createDamageInTransitRecord();
    if (id) {
      const { data, errors } = await uploadCompleteDelivery({
        variables: {
          data: {
            sessionImages: constructSessionImageData(),
            damageAreaCodes: constructDamageAreaCodesData(id),
            cannotInspectDIT: constructCannotInspect(),
          },
        },
      });

      if (data && data?.completeDelivery) {
        if (deliveryData.length > 0) {
          setSessionComplete(true);
          setSessionCompleteCount(deliveryData.length);
          handleRemoveAllVehicles();
          setScreen('start');
        }
      } else {
        console.error('api error', errors);
        setError(
          <>
            <div>
              <strong>Cannot complete vehicle delivery.</strong> There are no
              vehicles added to this delivery session. Add vehicles by scanning
              or manual searching.
            </div>
          </>,
        );
        scrollToTop();
      }
    }
  };

  const deliveryListingItems = [];
  if (itemsWithDit.length > 0 || itemsCannotDit.length > 0) {
    const vehicleList = itemsWithDit.concat(itemsCannotDit);
    deliveryListingItems.push({
      id: 1,
      title: {
        text: 'Vehicles with Damage in Transit',
        icon: 'warning',
        iconColor: 'red',
      },
      vehicles: vehicleList,
    });
  }

  if (itemsWithoutDit.length > 0) {
    deliveryListingItems.push({
      id: 2,
      title: {
        text: 'Vehicles without Damage in Transit',
        icon: 'check_circle',
        iconColor: 'green',
      },
      vehicles: itemsWithoutDit,
    });
  }

  return (
    <div className="grid-col">
      <PageTitle
        title={
          showRemoval ? 'Remove vehicle' : 'Review vehicle delivery session'
        }
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      {error && (
        <Alert
          type="error"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          {error}
        </Alert>
      )}
      {continueSession && (
        <Alert
          type="warning closable"
          heading="Complete existing session"
          noIcon={false}
          validation={false}
          focused={false}
          showClose={true}
          onClose={() => setContinueSession(false)}
        >
          You must complete the existing vehicle delivery session before
          creating a new one.
        </Alert>
      )}
      <SessionSummary
        totalVehicles={deliveryData.length}
        totalDamagedVehicles={
          deliveryData.filter(
            (vehicle) => vehicle.dit === 'yes' || vehicle.dit === 'cannot',
          ).length
        }
      />
      <div className="grid-row padding-top-3">
        <DeliveryListing
          items={deliveryListingItems}
          showRemoval={showRemoval}
          onRemoval={handleVehicleRemoval}
        />
      </div>
      <div className="grid-row padding-top-3">
        <Composition
          areas={`
            CompleteDelivery
            RemoveVehicles
            ReturnToScan
            CancelSession
            UndoVehicleRemoval
          `}
          areasMd={`
            ${
              showRemoval
                ? `UndoVehicleRemoval RemoveVehicles`
                : `ReturnToScan RemoveVehicles`
            }
            ${
              showRemoval
                ? `ReturnToScan CompleteDelivery`
                : `CancelSession CompleteDelivery`
            }
          `}
          gap={8}
          templateCols="auto"
          templateColsMd="50% 50%"
          templateRows="auto"
          width="100%"
        >
          {({
            CompleteDelivery,
            RemoveVehicles,
            ReturnToScan,
            CancelSession,
            UndoVehicleRemoval,
          }) => (
            <>
              <CompleteDelivery>
                {!showRemoval && (
                  <Button
                    className="width-full"
                    variant="primary"
                    size={isDesktop ? 'medium' : 'large'}
                    label="Complete vehicle delivery"
                    onClick={() => {
                      if (
                        itemsWithDit.length > 0 ||
                        itemsCannotDit.length > 0
                      ) {
                        setShowDamageConfirmModal(true);
                      } else {
                        completeDeliverySession();
                      }
                    }}
                  />
                )}
              </CompleteDelivery>
              <RemoveVehicles>
                <Button
                  className="width-full"
                  variant={showRemoval ? 'primary' : 'outline'}
                  size={isDesktop ? 'medium' : 'large'}
                  label={
                    showRemoval
                      ? 'Return to review session'
                      : 'Remove a vehicle'
                  }
                  onClick={() => {
                    setShowRemoval(!showRemoval);
                  }}
                />
              </RemoveVehicles>
              <UndoVehicleRemoval>
                {showRemoval && removedVehicleData.length > 0 && (
                  <Button
                    className="width-full"
                    variant={
                      showRemoval && removedVehicleData.length > 0 && 'outline'
                    }
                    size={isDesktop ? 'medium' : 'large'}
                    label="Restore Vehicles to Session"
                    onClick={() => {
                      handleUndoAllVehiclesRemoval();
                    }}
                  />
                )}
              </UndoVehicleRemoval>
              <ReturnToScan>
                {!showRemoval && (
                  <Button
                    className="width-full"
                    variant="outline"
                    size={isDesktop ? 'medium' : 'large'}
                    label="Add a vehicle"
                    onClick={() => {
                      setContinueSession(false);
                      setScreen('find-vehicle');
                    }}
                  />
                )}
              </ReturnToScan>
              <CancelSession>
                {!showRemoval && (
                  <Button
                    className="width-full"
                    variant="outline"
                    size={isDesktop ? 'medium' : 'large'}
                    label="Cancel this delivery session"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                )}
              </CancelSession>
            </>
          )}
        </Composition>
        <AreYouSureModal
          title="Make sure you annotate Damage in Transit on the Bill of Lading."
          show={showDamageConfirmModal}
          showConfirm={true}
          showCancel={false}
          confirmLabel="Okay"
          variant="blue-btn"
          onConfirm={() => {
            setShowDamageConfirmModal(false);
            completeDeliverySession();
          }}
          onCancel={() => setShowDamageConfirmModal(false)}
        >
          Damage in Transit must be reported on the Bill of Lading at the time
          of Vehicle Delivery.
        </AreYouSureModal>
        <AreYouSureModal
          title="All vehicles will be deleted."
          show={showModal}
          showConfirm={true}
          showCancel={true}
          confirmLabel="Cancel this delivery session"
          cancelLabel="Return to delivery session"
          onConfirm={() => {
            handleRemoveAllVehicles();
            setShowModal(false);
            setScreen('start');
          }}
          onCancel={() => setShowModal(false)}
        >
          Are you sure you want to cancel this delivery session? All vehicles
          added during this session will be deleted.
        </AreYouSureModal>
      </div>
    </div>
  );
};

export default Review;
