import React, { useState, useEffect, useCallback } from 'react';
import { AccordionListing } from 'components';
import {
  PageTitle,
  Label,
  RadioButton,
  Textbox,
  Button,
  Alert,
} from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Composition } from 'atomic-layout';
import { useSelector, useDispatch } from 'react-redux';
import { removeAllVehicles, setMarshallingData } from 'reducers/marshalling';
import {
  Scanner,
  SessionSummary,
  OrderSearchResults,
  AreYouSureModal,
} from 'components';
import { formatVehicleTitle, scrollToTop, VINValidation } from 'utils';
import { useSync } from 'hooks';
import '../screens.css';

export const FindVehicle = ({
  setScreen,
  setVehicle,
  vehicle,
  vehicleSuccess,
  setVehicleSuccess,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { getVehicleData } = useSync();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLookupData = useSelector(
    (state) => state?.marshalling?.lookup || [],
  );
  const vehicleLookupTime = useSelector(
    (state) => state?.marshalling?.lookupTs || 0,
  );
  const prevLookupTime = usePrevious(vehicleLookupTime);
  const deliveryData = marshallingData?.delivery || [];
  const [searchBy, setSearchBy] = useState('order-number');
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [scanFrom, setScanFrom] = useState('search');
  const [scannerResult, setScannerResult] = useState(null);
  const [orderResults, setOrderResults] = useState([]);
  const [deliveredInOrder, setDeliveredInOrder] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (
      search.trim() !== '' &&
      isSearching &&
      prevLookupTime < vehicleLookupTime
    ) {
      if (searchBy === 'vin' && search.trim() === vehicleLookupData[0]?.vin) {
        if (vehicleLookupData.length > 0) {
          setVehicle(vehicleLookupData[0]);
          setIsScanned(false);
          setIsSearching(false);
          setScreen('vehicle-delivery');
        } else {
          if (isScanned) {
            setError(
              <>
                <div>
                  <strong>VIN not recognized.</strong>
                </div>
                <div>
                  The scanned VIN could not be found in our system. Please
                  manually enter the VIN or order number to see available
                  vehicles.
                </div>
              </>,
            );
            setIsScanned(false);
            return;
          } else {
            setFormErrors({
              ...formErrors,
              search: 'VIN not found',
            });
            return;
          }
        }
      } else if (
        searchBy === 'order-number' &&
        search.trim() === vehicleLookupData[0]?.orderNumber
      ) {
        const orderDeliveredItems = deliveryData.filter(
          (vehicle) => search === vehicle.orderNumber,
        );
        if (vehicleLookupData.length > 0) {
          if (orderDeliveredItems.length < vehicleLookupData.length) {
            setIsSearching(false);
            setFormErrors({
              ...formErrors,
              search: '',
            });
            setOrderResults(vehicleLookupData);
            setDeliveredInOrder([...orderDeliveredItems]);
          } else {
            setIsSearching(false);
            setOrderResults([]);
            setFormErrors({
              ...formErrors,
              search: 'All vehicles added for this order',
            });
            return;
          }
        } else {
          setIsSearching(false);
          setOrderResults([]);
          setFormErrors({
            ...formErrors,
            search: 'Order number not found',
          });
          return;
        }
      }
    }
  }, [
    vehicleLookupData,
    vehicle,
    isScanned,
    formErrors,
    setScreen,
    setVehicle,
    searchBy,
    search,
    isSearching,
    deliveryData,
    prevLookupTime,
    vehicleLookupTime,
  ]);

  const vehicleLookup = async ({ vin, orderNumber, id }) => {
    dispatch(
      await setMarshallingData({
        ...marshallingData,
        lookup: [],
      }),
    );
    getVehicleData({
      variables: {
        vin,
        orderNumber,
        id,
      },
    });
  };

  const handleRemoveAllVehicles = () => {
    dispatch(removeAllVehicles());
  };

  const clearFormError = (errorKey) => {
    const newErrors = {};
    for (const [key, value] of Object.entries(formErrors)) {
      if (key !== errorKey) {
        newErrors[key] = value;
      }
    }
    setFormErrors(newErrors);
  };

  const submitHandler = useCallback(() => {
    setError(null);
    setIsScanned(false);
    setVehicleSuccess(false);
    setFormErrors({});
    setOrderResults([]);
    setDeliveredInOrder([]);
    if (search.trim() === '') {
      if (searchBy === 'vin') {
        setFormErrors({
          ...formErrors,
          search: 'VIN is required',
        });
      } else {
        setFormErrors({
          ...formErrors,
          search: 'Order number is required',
        });
      }
      return;
    }

    if (searchBy === 'vin') {
      const VINError = VINValidation(search);
      if (VINError) {
        setFormErrors({
          ...formErrors,
          search: VINError,
        });
        return;
      }
      const vehicleDelivered = deliveryData.filter(
        (vehicle) => vehicle.vin === search,
      );
      if (vehicleDelivered.length > 0) {
        setError(
          <>
            <div data-testid="alert-scan-find-vehicle">
              This vehicle has already been scanned and added.
            </div>
            <div>
              <strong>{formatVehicleTitle(vehicleDelivered[0])}</strong>
            </div>
            <div>
              <strong>VIN: {vehicleDelivered[0].vin}</strong>
            </div>
          </>,
        );
        return;
      }
      if (deliveryData.length === 20) {
        setError(
          <>
            <div>
              <strong>
                Maximum number of vehicles per session has been reached.
              </strong>{' '}
              Only 20 vehicles are allowed per vehicle delivery session. To
              complete this session, select the complete delivery session
              button.
            </div>
          </>,
        );
        return;
      }
      setIsSearching(true);
      vehicleLookup({
        vin: search,
      });
    } else if (searchBy === 'order-number') {
      setIsSearching(true);
      vehicleLookup({
        orderNumber: search,
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [deliveryData, formErrors, search, searchBy, setVehicleSuccess]);

  useEffect(() => {
    if (search) {
      if (searchBy === 'vin' && isScanned && !error) {
        submitHandler();
      }
    }
  }, [isScanned, search, searchBy, submitHandler, error]);

  return (
    <div className="grid-col">
      <PageTitle
        title="Find vehicle"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <Composition
        areas={`
          ${error || vehicleSuccess ? `AlertMessages` : ``}
          ExplainText
          DeliverySummary
          SearchForm
          ${orderResults && orderResults.length > 0 ? `OrderResults` : `.`}
          CompleteButton
          CancelButton
        `}
        areasMd={`
          ${error || vehicleSuccess ? `AlertMessages AlertMessages` : ``}
          ExplainText ExplainText
          DeliverySummary DeliverySummary
          SearchForm SearchForm
          ${
            orderResults && orderResults.length > 0
              ? `OrderResults OrderResults`
              : `. .`
          }
          CancelButton CompleteButton
        `}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({
          AlertMessages,
          ExplainText,
          DeliverySummary,
          SearchForm,
          OrderResults,
          CompleteButton,
          CancelButton,
        }) => (
          <>
            <AlertMessages>
              {error && (
                <Alert
                  data-testid="find-vehicle-error"
                  type="error"
                  heading=""
                  noIcon={false}
                  validation={false}
                  focused={false}
                  showClose={false}
                >
                  {error}
                </Alert>
              )}
              {vehicleSuccess && (
                <Alert
                  type="success"
                  heading=""
                  noIcon={false}
                  validation={false}
                  focused={false}
                  showClose={true}
                  onClose={() => setVehicleSuccess(false)}
                >
                  <div>
                    <strong>{formatVehicleTitle(vehicle)}</strong>
                  </div>
                  <div>
                    <strong>VIN: {vehicle.vin}</strong>
                  </div>
                  <div>was successfully added to this delivery session.</div>
                </Alert>
              )}
            </AlertMessages>
            <ExplainText>
              <p className="margin-top-0">
                Scan or enter the VIN or order number to see available vehicles.
                Complete delivery session when you have added all vehicles.
              </p>
            </ExplainText>
            <DeliverySummary>
              <div className="border-base-light padding-bottom-3">
                <SessionSummary
                  totalVehicles={deliveryData.length}
                  totalDamagedVehicles={
                    deliveryData.filter(
                      (vehicle) =>
                        vehicle.dit === 'yes' || vehicle.dit === 'cannot',
                    ).length
                  }
                />
              </div>
              {deliveryData.length > 0 && (
                <AccordionListing
                  items={[
                    {
                      id: 1,
                      title: {
                        text: 'Vehicles scanned in this session',
                      },
                      vehicles: deliveryData,
                    },
                  ]}
                />
              )}
            </DeliverySummary>
            <SearchForm>
              <div>
                <Button
                  className="width-full"
                  variant="primary"
                  size={isDesktop ? 'medium' : 'large'}
                  label="Scan VIN"
                  leftIcon={{
                    name: 'photo_camera',
                    iconName: 'photo_camera',
                  }}
                  onClick={() => {
                    setScanFrom('search');
                    setSearchBy('vin');
                    setSearch('');
                    setScannerOpen(true);
                  }}
                />
                <div className="border-bottom border-base-light padding-bottom-3">
                  <Label className="text-bold">Search by</Label>
                  <Composition
                    areas={`
                      OrderNumber VIN .
                      SearchText SearchText SearchText
                    `}
                    gap={8}
                    templateCols="150px auto auto"
                  >
                    {({ VIN, OrderNumber, SearchText }) => (
                      <>
                        <VIN>
                          <RadioButton
                            data-testid="vin-search-radio"
                            id="vin-search"
                            name="search-by"
                            label="VIN"
                            checked={searchBy === 'vin'}
                            onChange={() => {
                              setIsSearching(false);
                              setSearchBy('vin');
                              setSearch('');
                              clearFormError('search');
                              setError(null);
                            }}
                          />
                        </VIN>
                        <OrderNumber>
                          <RadioButton
                            data-testid="order-number-search-radio"
                            id="order-number-search"
                            name="search-by"
                            label="Order number"
                            checked={searchBy === 'order-number'}
                            onChange={() => {
                              setIsSearching(false);
                              setSearchBy('order-number');
                              setSearch('');
                              clearFormError('search');
                              setError(null);
                            }}
                          />
                        </OrderNumber>
                        <SearchText marginBottom={-40}>
                          <div
                            className={formErrors?.search ? 'form-error' : ''}
                          >
                            {formErrors?.search && (
                              <div className="form-error-msg">
                                <strong>{formErrors.search}</strong>
                              </div>
                            )}
                            <Textbox
                              type="text"
                              id="search"
                              name="search"
                              value={search}
                              variant={formErrors?.search ? 'error' : ''}
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              className="mobile-textbox icon-input"
                            />
                          </div>
                          <Button
                            className="icon-button"
                            data-testid="search-button"
                            leftIcon={{
                              name: 'search',
                              iconName: 'search',
                            }}
                            onClick={submitHandler}
                          />
                        </SearchText>
                      </>
                    )}
                  </Composition>
                </div>
              </div>
            </SearchForm>
            <OrderResults>
              {orderResults && orderResults.length > 0 && (
                <OrderSearchResults
                  orderVehicles={orderResults}
                  deliveredVehicles={deliveredInOrder}
                  setVehicle={setVehicle}
                  setScreen={setScreen}
                  setScannerOpen={setScannerOpen}
                  setScanFrom={setScanFrom}
                  scanFrom={scanFrom}
                  scannerResult={scannerResult}
                  setError={setError}
                />
              )}
            </OrderResults>
            <CompleteButton>
              <Button
                className="width-full"
                variant="primary"
                size={isDesktop ? 'medium' : 'large'}
                label="Complete delivery session"
                onClick={() => {
                  if (deliveryData.length > 0) {
                    setScreen('review');
                  } else {
                    setError(
                      <>
                        <div>
                          <strong>Cannot complete vehicle delivery.</strong>{' '}
                          There are no vehicles added to this delivery session.{' '}
                          Add vehicles by scanning or manual searching.
                        </div>
                      </>,
                    );
                    scrollToTop();
                  }
                }}
              />
            </CompleteButton>
            <CancelButton>
              <Button
                className="width-full"
                variant="outline"
                size={isDesktop ? 'medium' : 'large'}
                label="Cancel this delivery session"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </CancelButton>
          </>
        )}
      </Composition>
      <AreYouSureModal
        title="All vehicles will be deleted."
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Cancel this delivery session"
        cancelLabel="Return to delivery session"
        onConfirm={() => {
          handleRemoveAllVehicles();
          setShowModal(false);
          setScreen('start');
        }}
        onCancel={() => setShowModal(false)}
      >
        Are you sure you want to cancel this delivery session? All vehicles
        added during this session will be deleted.
      </AreYouSureModal>
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            if (scanFrom === 'search') {
              setSearch(result);
              setSearchBy('vin');
              setIsScanned(true);
            } else {
              setScannerResult(result);
            }
          }}
          onClose={() => {
            setScannerOpen(false);
            setIsScanned(false);
          }}
        />
      )}
    </div>
  );
};

export default FindVehicle;
