import React from 'react';

export const currencyFormat = (num = 0, decimalPlace = 2) => {
  return `$${num
    .toFixed(decimalPlace)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatDataValue = (value, currency = false) => {
  if (typeof value === 'string' && value?.length) return value;

  if (typeof value === 'number') {
    return currency ? currencyFormat(value) : value;
  }

  return <>&ndash;</>;
};

export const formatVehicleTitle = (vehicle) => {
  return (
    <>
      {vehicle.year} {vehicle.make} {vehicle.model} ({vehicle.color})
    </>
  );
};

export const ucfirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
