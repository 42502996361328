import React from 'react';
import { Alert } from '@gsa/afp-component-library';

export const SessionSummary = ({
  totalVehicles = 0,
  totalDamagedVehicles = 0,
}) => {
  return (
    <Alert
      data-testid="session-summary"
      type="info"
      heading="Delivery session summary"
      noIcon={true}
      validation={false}
      focused={false}
      showClose={false}
    >
      <div>
        <span data-testid="total-vehicles" className="total">
          {totalVehicles}
        </span>{' '}
        Total vehicles
      </div>
      {totalDamagedVehicles > 0 && (
        <div>
          <span data-testid="total-damaged-vehicles" className="total">
            {totalDamagedVehicles}
          </span>{' '}
          Vehicle{totalDamagedVehicles === 1 ? '' : 's'} with Damage in Transit
        </div>
      )}
    </Alert>
  );
};

export default SessionSummary;
