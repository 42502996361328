import React from 'react';
import { PageTitle, Button, Alert } from '@gsa/afp-component-library';
import { useSelector } from 'react-redux';

export const Start = ({
  setScreen,
  setContinueSession,
  sessionComplete,
  setSessionComplete,
  sessionCompleteCount,
  isDesktop,
}) => {
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const deliveryData = marshallingData?.delivery || [];

  return (
    <div className="grid-col">
      <PageTitle
        title="Initiate vehicle delivery"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      {sessionComplete && (
        <Alert
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={true}
          onClose={() => setSessionComplete(false)}
        >
          <div>
            <strong>Delivery session completed.</strong>
          </div>
          <div>
            {sessionCompleteCount} vehicle
            {sessionCompleteCount === 1 ? ' was' : 's were'} delivered
          </div>
        </Alert>
      )}
      <p>Start vehicle session to add one or many vehicles to your delivery.</p>
      <Button
        data-testid="session-start-button"
        variant="primary"
        size={isDesktop ? 'medium' : 'large'}
        label={
          deliveryData.length > 0
            ? 'Continue delivery session'
            : 'Start delivery session'
        }
        onClick={() => {
          if (deliveryData.length > 0) {
            setContinueSession(true);
            setScreen('review');
          } else {
            setSessionComplete(false);
            setScreen('delivery-date');
          }
        }}
      />
    </div>
  );
};

export default Start;
