import React, { useState, useEffect } from 'react';
import { BarcodeScanner, BarcodeReader } from 'dynamsoft-javascript-barcode';
import { Button, Icon, Spinner } from '@gsa/afp-component-library';
import './Scanner.css';

BarcodeReader.engineResourcePath =
  'https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.11/dist/';
BarcodeReader.license =
  process?.env?.DYNAMSOFT_LICENSE ||
  'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxOTk0NzAwLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxOTk0NzAwIiwic3RhbmRieVNlcnZlclVSTCI6Imh0dHBzOi8vc2x0cy5keW5hbXNvZnQuY29tIiwiY2hlY2tDb2RlIjoxMjE1NDQwNTQ1fQ==';

export const Scanner = ({
  onCapture,
  onClose,
  isInPage = false,
  isEnabled = true,
}) => {
  const [scanner, setScanner] = useState(null);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);
  const [captureEnd, setCaptureEnd] = useState(false);

  const [error, setError] = useState(null);
  const elRef = React.createRef();
  let mounted = React.useRef(false);

  useEffect(() => {
    if (isCaptured === true) {
      window.initTimer = setTimeout(() => {
        setCaptureEnd(true);
        window.captureTimer = setTimeout(() => {
          setCaptureEnd(false);
          setIsCaptured(false);
        }, 2000);
      }, 50);
    }

    return () => {
      if (window.initTimer) {
        clearTimeout(window.initTimer);
      }
      if (window.captureTimer) {
        clearTimeout(window.captureTimer);
      }
    };
  }, [isCaptured]);

  useEffect(() => {
    window.pwaScanner = scanner;

    return () => {
      delete window.pwaScanner;
    };
  }, [scanner]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    try {
      BarcodeScanner.createInstance().then((scannerInstance) => {
        if (mounted.current) {
          setScanner(scannerInstance);
        }
      });
    } catch (ex) {
      let errMsg;
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error.';
      } else {
        errMsg = ex.message || ex;
      }
      console.error(errMsg);
      if (mounted.current) {
        setError(errMsg);
      }
    }
  }, []);

  const stopScrolling = () => {
    document.querySelector('body').style.height = '100%';
    document.querySelector('body').style.overflow = 'hidden';
  };

  const startScrolling = () => {
    document.querySelector('body').style.height = 'auto';
    document.querySelector('body').style.overflow = 'auto';
  };

  useEffect(() => {
    if (scanner && !error) {
      if (!scanner.isContextDestroyed()) {
        if (!scannerOpen) {
          scanner.setUIElement(elRef.current);
          scanner.getScanSettings().then((scanSettings) => {
            const newSettings = {
              ...scanSettings,
              whenToPlaySoundforSuccessfulRead: 'never',
              duplicateForgetTime: 2000,
            };
            scanner.updateScanSettings(newSettings);
          });
          scanner.onUniqueRead = (txt, result) => {
            if (typeof onCapture === 'function') {
              // Sometimes the captured VIN starts with an 'I', if so
              // and that makes the vin more than 17 chars, strip it before returning.
              const resultTxt =
                txt.indexOf('I') === 0 && txt.length > 17 ? txt.slice(1) : txt;
              onCapture(resultTxt, new Date().getTime());
              if (!isInPage) {
                startScrolling();
              }
            }
            if (mounted.current) {
              setIsCaptured(true);
            }
          };
          scanner.open();
          if (mounted.current) {
            setScannerOpen(true);
          }
          if (!isInPage) {
            stopScrolling();
          }
        }
      }
    }

    return () => {
      if (scanner && !isInPage && scannerOpen) {
        if (typeof scanner.close === 'function') {
          scanner.close();
        }
        setScannerOpen(false);
      }
    };
  }, [scanner, error, elRef, onCapture, isInPage, scannerOpen, isEnabled]);

  return (
    <div
      data-testid="scanner"
      className={`dynamsoft-scanner${isInPage ? ' in-page' : ''}`}
    >
      {!isInPage && (
        <div className="close-btn">
          <Button
            data-testid="close-button"
            variant="primary"
            size="small"
            label="Close"
            onClick={() => {
              if (typeof onClose === 'function') {
                onClose();
              }
              startScrolling();
            }}
          />
        </div>
      )}
      <div ref={elRef} className="component-barcode-scanner">
        <Spinner size="large" className="scanner-spinner" />
        <div className="dce-video-container" />
        <div className="dce-scanarea">
          {!isInPage && <div className="dce-scanlight" />}
        </div>
      </div>
      <div
        className={`capture-icon${isCaptured ? ' show' : ''}${
          captureEnd ? ' fade' : ''
        }`}
      >
        <Icon className="usa-icon--size-9" iconName="check_circle" />
      </div>
      <div className="helper-text">
        Please align the barcode or QR code in the frame.
      </div>
    </div>
  );
};

export default Scanner;
