import React from 'react';
import { Modal } from '@gsa/afp-component-library';
import { useResponsiveQuery } from 'atomic-layout';
import { SignatureCapture } from 'components';
import 'styled-components/macro';
import './SignatureModal.css';

export const SignatureModal = ({ show = false, title, onCancel, onSave }) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  if (!show) {
    return null;
  }

  return (
    <div
      className={`afp-modal-wrapper${isDesktop ? '' : ' mobile'}`}
      role="dialog"
      aria-labelledby="modal-heading"
      data-testid="signature-modal"
    >
      <div className="afp-modal-overlay">
        <Modal
          title={title}
          actions=""
          className="signature-modal"
          hideClose={false}
          onClose={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}
          variant="large"
        >
          <SignatureCapture
            onSave={(dataURL) => {
              if (typeof onSave === 'function') {
                onSave(dataURL);
              }
            }}
            onCancel={() => {
              if (typeof onCancel === 'function') {
                onCancel();
              }
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default SignatureModal;
