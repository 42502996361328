import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { useResponsiveQuery } from 'atomic-layout';
import { setLoadedVehicleData } from 'reducers/marshalling';
import { Start, LoadVehicle } from './screens';

export const VehicleLoad = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const [screen, setScreen] = useState('start');
  const [sessionComplete, setSessionComplete] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [vinScan, setVinScan] = useState(false);

  const setVehicleToLoad = (vehicle) => {
    setVehicle(vehicle);
    dispatch(
      setLoadedVehicleData({
        vehicle,
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screen]);

  useEffect(() => {
    if (vehicleLoadData?.vehicle) {
      setVehicle(vehicleLoadData?.vehicle);
      setScreen('load-vehicle');
    }
  }, [vehicleLoadData]);

  return (
    <div
      className={`grid-col margin-bottom-2${isDesktop ? ' slim-width' : ''}`}
    >
      <Breadcrumbs
        items={[{ path: '/home', text: 'Home' }, { text: 'Load Vehicle' }]}
      />
      {screen === 'start' && (
        <Start
          setScreen={setScreen}
          setVehicle={setVehicleToLoad}
          vehicle={vehicle}
          sessionComplete={sessionComplete}
          setSessionComplete={setSessionComplete}
          setVinScan={setVinScan}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'load-vehicle' && (
        <LoadVehicle
          setScreen={setScreen}
          vehicle={vehicle}
          setSessionComplete={setSessionComplete}
          vinScan={vinScan}
          isDesktop={isDesktop}
        />
      )}
    </div>
  );
};

export default VehicleLoad;
