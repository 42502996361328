import React, { useEffect, useCallback, useMemo, useState } from 'react';
import {
  StatusBadge,
  AFPTableRowAction,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { format } from 'date-fns';
import { useSync } from 'hooks';
import { useSelector } from 'react-redux';
import TableWrapper from './TableWrapper';
import RowSub from './RowSub';
import Filters from './Filters';

export const AppointmentListing = () => {
  const { getAppointments } = useSync();
  const data = useSelector((state) => state?.listings?.appointments || []);
  const [sort, setSort] = useState('createdByUser ASC');
  const [paginationState, setPaginationState] = useState({
    limit: 25,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };
  const tableRef = React.createRef();

  const renderRowSubComponent = useCallback(({ row: { original: data } }) => {
    return <RowSub rowData={data} />;
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Appt Date',
        accessor: 'appointmentStartDate',
        sortable: true,
        Cell: (cell) => {
          const { value } = cell;
          return value ? format(new Date(value), 'MM/dd/yyyy') : '';
        },
      },
      {
        Header: 'Appt Status',
        accessor: 'status',
        sortable: true,
        Cell: (cell) => {
          const { value } = cell;
          let variant = 'Warning-Gray';
          switch (value) {
            case 'PENDING':
              variant = 'Info-Gray';
              break;
            case 'SCHEDULED':
              variant = 'Ready-Gray';
              break;
            default:
              variant = 'Warning-Gray';
              break;
          }
          return (
            <StatusBadge variant={variant}>{value || 'Unknown'}</StatusBadge>
          );
        },
      },
      {
        Header: 'Old Plate',
        accessor: 'oldPlate',
        sortable: true,
      },
      {
        Header: 'New Plate',
        accessor: 'newPlate',
        sortable: true,
      },
      {
        Header: 'Available for Appt',
        accessor: 'availableDate',
        sortable: true,
      },
      {
        Header: 'Marshalling Site',
        accessor: 'vendorId',
        sortable: true,
      },
      {
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          const { cell } = props;
          const { row } = cell;
          if (!row) {
            return '-';
          }
          const { original: appointmentId } = row;
          const actions = [
            {
              icon: 'close',
              label: 'Blah',
            },
          ];
          return (
            <AFPTableRowAction
              actions={actions}
              onSelectAction={(operation) => {
                console.log(operation);
                console.log(appointmentId);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const appointmentTableProps = {
    tableProps: {
      expandable: true,
      fullWidth: true,
      testId: 'appointment-table',
      ref: tableRef,
      columns,
      data,
      renderRowSubComponent,
      onSort: setSort,
      defaultSort: sort,
    },
    paginationProps: {
      variant: 'advanced',
      itemsPerPageOptions: [10, 25, 50],
      itemsCount: data.length,
      itemsPerPage: paginationState.limit,
      currentPage: paginationState.currentPage,
      isReset: paginationState.isReset,
      onPageChange: handlePaginationChange,
    },
  };

  const FTF = useMemo(
    () => FilterTableFrame(null, null, Filters, TableWrapper),
    [],
  );

  useEffect(() => {
    getAppointments({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order: sort,
        filter: [],
      },
    });
  }, [getAppointments, paginationState, sort]);

  return (
    <div className="appointment-table">
      <FTF lowRightProps={appointmentTableProps} filterToggle />
    </div>
  );
};

export default AppointmentListing;
