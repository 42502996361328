import React, { useEffect, useCallback } from 'react';
import { DefaultLayout } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useSync } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import './Layout.css';
import { OnlineStatusBanner } from 'components';

export const Layout = ({ children }) => {
  let history = useHistory();
  const { isOnline } = useSync();
  const { currentUser, isLoggedIn, isLoading } = useCurrentUser();

  const onDomLoaded = useCallback(
    (ev) => {
      const HOME_APP_URL =
        window.AFP_CONFIG &&
        window.AFP_CONFIG.appURLs &&
        window.AFP_CONFIG.appURLs.home;
      const logoLink = document.querySelector(
        '#afpHeader .usa-navbar .afp-logo a',
      );
      if (logoLink) {
        logoLink.addEventListener('click', (ev) => {
          ev.preventDefault();
          const url = logoLink.href.replace(HOME_APP_URL, '');
          history.push(url);
        });
      }
      const navLinks = document.querySelectorAll('.usa-nav__primary a');
      if (navLinks) {
        navLinks.forEach((link) => {
          if (link && link?.href) {
            link.addEventListener('click', (ev) => {
              ev.preventDefault();
              const url = link.href.replace(HOME_APP_URL, '');
              history.push(url);
              document.querySelector('.usa-overlay').click();
            });
          }
        });
      }
      // Below could be needed for processing fixup of icons we inherit
      /*
      window.setTimeout(() => {
        const iconsToFix = document.querySelectorAll('svg[role=img].usa-icon');
        console.log(iconsToFix);
      }, 200);
      */
    },
    [history],
  );

  useEffect(() => {
    document.addEventListener('DOMContentLoaded', onDomLoaded);
    return () => {
      document.removeEventListener('DOMContentLoaded', onDomLoaded);
    };
  }, [onDomLoaded]);

  const menu = [
    {
      title: 'Home',
      linkUrl: '/home',
    },
    {
      title: 'Marshalling Management',
      childNavigationItems: [
        {
          title: 'Vehicles',
          linkUrl: '/vehicles',
        },
        {
          title: 'Plates',
          linkUrl: '/plates',
        },
        {
          title: 'Telematics',
          linkUrl: '/telematics',
        },
      ],
    },
    {
      title: 'Vehicle Marshalling',
      childNavigationItems: [
        {
          title: 'Vehicle Delivery',
          linkUrl: '/vehicle-delivery',
        },
        {
          title: 'Load Vehicle',
          linkUrl: '/load-vehicle',
        },
        {
          title: 'Plate Reconciliation',
          linkUrl: '/plate-reconciliation',
        },
        {
          title: 'Plate Destruction',
          linkUrl: '/plate-destruction',
        },
        {
          title: 'Telematics Installation',
          linkUrl: '/telematics-installation',
        },
        {
          title: 'Telematics PO',
          linkUrl: '/telematics-purchase-order-reconciliation',
        },
        {
          title: 'eVAF',
          linkUrl: '/evaf',
        },
        {
          title: 'Appointment Scheduling',
          linkUrl: '/appointment-scheduling',
        },
      ],
    },
  ];

  return (
    <>
      {!isLoading && <OnlineStatusBanner isOnline={isOnline} />}
      <DefaultLayout
        currentUser={isLoggedIn && currentUser}
        menu={isLoggedIn ? menu : []}
      >
        {children}
      </DefaultLayout>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
