import React, { useEffect, useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  PageTitle,
  Button,
  Alert,
  Label,
  Textbox,
} from '@gsa/afp-component-library';
import {
  IsCorrectField,
  PhotoCapture,
  SignatureModal,
  AreYouSureModal,
  LicensePlateField,
  VinField,
  LoadingButton,
} from 'components';
import { Composition } from 'atomic-layout';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import { setEvafData, clearEvafData } from 'reducers/marshalling';
import { useDispatch, useSelector } from 'react-redux';
import { isValidEmail, scrollToElement, scrollToTop } from 'utils';
import '../screens.css';
import { EMAIL_EVAF } from 'services/sync-gql';

export const NewVehicle = ({
  setScreen,
  eVafFormCompleted = false,
  prevFormValues,
  onSetFormValues,
  setEvafFormCompleted,
  isInsideTab = false,
  oldVehicleTabError = false,
  isSubmittedInsideExchange = false,
  setSubmitedInsideExchange,
  setTabError,
  isDesktop,
  onVerifySuccess,
  setCanLeaveNewTab,
  trigger,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const evafData = useSelector((state) => state?.marshalling?.evaf || {});
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    ...prevFormValues,
    ...(prevFormValues !== undefined && Object.keys(prevFormValues).length > 0
      ? prevFormValues
      : evafData?.new || {}),
  });
  const [vinError, setVinError] = useState(null);
  const [plateError, setPlateError] = useState(null);
  const [touchedFields, setTouchedFields] = useState({});
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [showMarshallerModal, setShowMarshallerModal] = useState(false);
  const [requiredFieldError, setRequiredFieldError] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(isSubmittedInsideExchange);
  const [showModal, setShowModal] = useState(false);
  const [callSubmitHandler, setCallSubmitHandler] = useState(false);
  const { currentUser } = useCurrentUser();

  const [emailEVAF] = useMutation(EMAIL_EVAF, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: async () => {
      return true;
    },
  });

  const validateImageFields = (photos) => {
    let isValid = true;
    if (photos !== null && photos !== undefined && photos.length > 0) {
      photos.forEach((element) => {
        if (element.type !== 'image/png' && element.type !== 'image/jpg') {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  const validateForm = useCallback(
    (formValues) => {
      const errors = {};
      if (plateError) {
        errors.newLicensePlate = plateError;
      }

      if (vinError) {
        errors.newVin = vinError;
      }

      if (formValues?.newMileage === '' || !formValues?.newMileage) {
        errors.newMileage = 'This is a required field';
      }

      if (formValues?.newMileage && isNaN(Number(formValues?.newMileage))) {
        errors.newMileage = 'Must be a numeric value';
      }

      if (!formValues?.email) {
        errors.email = 'This is a required field';
      } else if (!isValidEmail(formValues.email)) {
        errors.email = 'Please enter a valid email address';
      }

      if (formValues?.driverFirstName === '' || !formValues?.driverFirstName) {
        errors.driverFirstName = 'This is a required field';
      }

      if (formValues?.driverLastName === '' || !formValues?.driverLastName) {
        errors.driverLastName = 'This is a required field';
      }
      if (formValues?.driverSignature === '' || !formValues?.driverSignature) {
        errors.driverSignature = 'Driver signature is required';
      }

      const imageError = validateImageFields(formValues?.photoOfDamage?.photos);

      if (!imageError) {
        errors.photoOfDamage = 'Invalid Image type (Accepts png, jpg)';
      }
      return errors;
    },
    [plateError, vinError],
  );

  const fieldNames = {
    frontBumpGrill: 'Front Bumper / Grill',
    lFrontFenderQuarter: 'Left Front Fender/Quarter Panel',
    rFrontFenderQuarter: 'Right Front Fender/Quarter Panel',
    leftSide: 'Left Side',
    rightSide: 'Right Side',
    hoodPanel: 'Hood Panel',
    roofPanel: 'Roof Panel',
    lRearFenderQuarter: 'Left Rear Fender/Quarter Panel',
    rRearFenderQuarter: 'Right Rear Fender/Quarter Panel',
    lFront: 'Left Front',
    rFront: 'Right Front',
    lRear: 'Left Rear',
    rRear: 'Right Rear',
    spareTire: 'Spare Tire',
    tireInstallKit: 'Tire Inflator Kit',
    jackTools: 'Jack and Tools',
    seats: 'Seats',
    glass: 'Glass',
    sideWindows: 'Side Window(s)',
    flooring: 'Flooring',
    windshield: 'Windshield',
    mirrors: 'Mirrors',
  };

  useEffect(() => {
    if (trigger > 0) {
      setRequiredFieldError(false);
      setIsSubmitted(true);
      const errors = validateForm(formValues);
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        window.setTimeout(() => scrollToElement('.form-error'), 250);
        setRequiredFieldError(true);
      } else {
        setFormErrors({});
      }
    }
  }, [trigger, validateForm, formValues]);

  const emailEVAFSubmit = useCallback(
    async (formValues) => {
      const oldVehicleData = formValues?.old || {};
      const newVehicleData = formValues?.new || {};
      let oldVehiclePhotos = [];
      const oldVehicleDamageItems = {};
      Object.keys(fieldNames).forEach((field) => {
        oldVehicleDamageItems[field] = {
          fieldName: fieldNames[field],
          issues: oldVehicleData[field] !== 'OK',
          issueType:
            oldVehicleData[field] !== 'OK' ? oldVehicleData[field] : '',
        };
        if (
          oldVehicleData[field] !== 'OK' &&
          oldVehicleData[`${field}Photo`]?.photoData
        ) {
          oldVehiclePhotos = [
            ...oldVehiclePhotos,
            ...oldVehicleData[`${field}Photo`]?.photoData,
          ];
        }
      });
      const finalDataKey = evafData?.evafType === 'old-vehicle' ? 'old' : 'new';
      const finalData = formValues[finalDataKey];
      const driverName = `${finalData.driverFirstName} ${finalData.driverLastName}`;
      const driverSignature = finalData?.driverSignature;
      const marshallerName = `${currentUser.firstName} ${currentUser.lastName}`;
      const marshallerSignature = finalData?.marshallerSignature;
      const emailList = finalData?.email
        .split(',')
        .map((emailAddress) => emailAddress.trim());

      let submitData = {
        variables: {
          eVafEmailAndPdfInput: {
            newDamagePhotos: newVehicleData?.photoOfDamage?.photoData || [],
            newVehicleDamageComment: newVehicleData?.newComments,
            newLicensePlate: newVehicleData?.newLicensePlate,
            newVIN: newVehicleData?.newVin,
            newMileage: Number(newVehicleData?.newMileage),
            driver: driverName,
            signatureDriver: driverSignature,
            marshaller: marshallerName,
            signatureMarshaller: marshallerSignature,
            emailAddresses: emailList,
          },
        },
      };
      if (oldVehicleData && isInsideTab) {
        submitData = {
          ...submitData,
          variables: {
            ...submitData.variables,
            eVafEmailAndPdfInput: {
              ...submitData.variables.eVafEmailAndPdfInput,
              oldLicensePlate: oldVehicleData?.oldLicensePlate,
              oldVIN: oldVehicleData?.oldVin,
              oldMileage: Number(oldVehicleData?.oldMileage),
              numberOfPlates: Number(oldVehicleData?.noOfLicensePlates),
              missingPlate: oldVehicleData?.missingSidePlate,
              numberOfKeys: Number(oldVehicleData?.noOfKeys),
              creditCardReturn: oldVehicleData?.creditCardReturned,
              oldVehicleDamageComment: oldVehicleData?.oldComments,
              ...oldVehicleDamageItems,
              oldDamagePhotos: oldVehiclePhotos || [],
            },
          },
        };
      }
      const { data, errors } = await emailEVAF(submitData);
      if (data && data.emailEVAF) {
        if (onVerifySuccess) {
          onVerifySuccess();
          setIsloading(false);
        }
        setScreen('vehicle-selection');
      }
      if (errors) {
        setIsloading(false);
      }
    },
    [
      currentUser,
      emailEVAF,
      onVerifySuccess,
      setScreen,
      evafData.evafType,
      fieldNames,
      isInsideTab,
    ],
  );

  const submitHandler = useCallback(() => {
    setFormErrors({});
    setIsSubmitted(true);
    if (setSubmitedInsideExchange) {
      setSubmitedInsideExchange(true);
    }
    const errors = validateForm(formValues);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      window.setTimeout(() => scrollToElement('.form-error'), 250);
      setRequiredFieldError(true);
    } else {
      if (!formValues?.marshallerSignature) {
        setShowMarshallerModal(true);
      } else {
        const finalData = {
          ...evafData,
          new: {
            ...(evafData?.new || {}),
            ...formValues,
          },
        };
        dispatch(setEvafData(finalData));
        setRequiredFieldError(false);
        emailEVAFSubmit(finalData);
      }
    }
  }, [
    formValues,
    dispatch,
    evafData,
    validateForm,
    emailEVAFSubmit,
    setSubmitedInsideExchange,
  ]);

  useEffect(() => {
    const errors = validateForm(formValues);
    let canLeave = true;
    for (const [, error] of Object.entries(errors)) {
      if (
        error !== 'This is a required field' &&
        error !== 'Driver signature is required'
      ) {
        canLeave = false;
      }
    }
    if (Object.entries(errors).length === 0) {
      setRequiredFieldError(false);
    }
    setCanLeaveNewTab(canLeave);
    if (setTabError) {
      if (Object.keys(errors).length > 0) {
        setTabError(true);
      } else {
        setTabError(false);
      }
    }
    if (isSubmitted && !callSubmitHandler) {
      if (Object.entries(errors).length !== Object.entries(formErrors).length) {
        setFormErrors(errors || {});
        setRequiredFieldError(true);
      }
      return;
    }
    if (
      callSubmitHandler &&
      formValues.marshallerSignature &&
      !oldVehicleTabError
    ) {
      submitHandler();
      setCallSubmitHandler(false);
    }
  }, [
    formValues,
    formErrors,
    isSubmitted,
    touchedFields,
    setTabError,
    validateForm,
    setCanLeaveNewTab,
    callSubmitHandler,
    submitHandler,
    oldVehicleTabError,
  ]);

  const handleFieldChange = (fieldName, value) => {
    if (
      touchedFields !== undefined &&
      Object.keys(touchedFields).length === 0
    ) {
      return;
    }
    if (
      !['driverSignature', 'marshallerSignature'].includes(fieldName) &&
      formValues.driverSignature !== ''
    ) {
      const newFormValues = {
        ...formValues,
        driverSignature: '',
        [fieldName]: value,
      };
      setFormValues({
        ...newFormValues,
      });
      if (onSetFormValues) {
        onSetFormValues({
          ...newFormValues,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [fieldName]: value,
      });
      if (onSetFormValues) {
        onSetFormValues({
          ...formValues,
          [fieldName]: value,
        });
      }
    }
  };

  const setTouched = (field, value) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
    if (isSubmitted) {
      const errors = validateForm(formValues);
      const touchedFieldErrors = Object.entries(errors).reduce(
        (acc, [fld, err]) => {
          if (touchedFields[fld]) {
            acc[fld] = err;
          }

          return acc;
        },
        {},
      );

      setFormErrors(touchedFieldErrors || {});
    }
    if (setTabError) {
      const errors = validateForm(formValues);
      if (Object.keys(errors).length > 0) {
        setTabError(true);
      } else {
        setTabError(false);
      }
    }
  };

  return (
    <div className="grid-col">
      {requiredFieldError && (
        <div className="margin-top-3">
          <Alert
            data-testid="required-field-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
          >
            Please complete required fields
          </Alert>
        </div>
      )}

      {!isInsideTab && (
        <>
          <PageTitle
            title="eVAF"
            className={`${isDesktop ? 'margin-top-0' : ''}`}
          />
          <div className="vehicle-tab-heading padding-top-1">New Vehicle</div>
        </>
      )}
      <p className="border-bottom border-base-light">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <Composition
        areas={`
          ${eVafFormCompleted ? `AlertMessages` : ``}
          MainForm
          Comments
          Email
          DriverInfo
          MarshallerInfo
          MarshallerButton
        `}
        areasMd={`
          ${eVafFormCompleted ? `AlertMessages AlertMessages` : ``}
          MainForm MainForm
          Comments Comments
          Email Email
          DriverInfo DriverInfo
          MarshallerInfo MarshallerInfo
          MarshallerButton MarshallerButton 
        `}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({
          AlertMessages,
          MainForm,
          Comments,
          Email,
          DriverInfo,
          MarshallerInfo,
          MarshallerButton,
        }) => (
          <>
            <AlertMessages>
              {eVafFormCompleted && (
                <>
                  <Alert
                    className="margin-bottom-3"
                    type="success"
                    heading=""
                    noIcon={false}
                    validation={false}
                    focused={false}
                    showClose={true}
                    onClose={() => setEvafFormCompleted(false)}
                  >
                    The Electronic Vehicle Accountability form (eVAF) has been
                    signed and completed.
                  </Alert>
                </>
              )}
            </AlertMessages>
            <MainForm>
              <p className="tab-title margin-bottom-0 margin-top-0">
                New Vehicle details
              </p>
              <LicensePlateField
                id="newLicensePlate"
                initialValue={formValues?.newLicensePlate || ''}
                showErrors={formErrors?.newLicensePlate}
                required={true}
                label="New license plate"
                scanner={true}
                onChange={(plate) => {
                  handleFieldChange('newLicensePlate', plate);
                }}
                onValidate={(isValid, errorTxt) => {
                  if (!isValid) {
                    setPlateError(errorTxt);
                  } else {
                    setPlateError('');
                  }
                }}
                onFocus={() => setTouched('newLicensePlate', true)}
                className="mobile-textbox margin-bottom-3"
              />
              <VinField
                id="newVin"
                initialValue={formValues?.newVin || ''}
                showErrors={formErrors?.newVin}
                required={true}
                label="New VIN"
                scanner={true}
                onChange={(vin) => {
                  handleFieldChange('newVin', vin);
                }}
                onValidate={(isValid, errorTxt) => {
                  if (!isValid) {
                    setVinError(errorTxt);
                  } else {
                    setVinError('');
                  }
                }}
                onFocus={() => setTouched('newVin', true)}
                className="mobile-textbox margin-bottom-3"
              />
              <div
                className={
                  formErrors?.newMileage
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1" required>
                  <strong>Mileage</strong>
                </Label>
                {formErrors?.newMileage && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.newMileage}</strong>
                  </div>
                )}
                <div className="input-box">
                  <Textbox
                    type="text"
                    id="newMileage"
                    name="newMileage"
                    value={formValues?.newMileage || ''}
                    variant={formErrors?.newMileage ? 'error' : ''}
                    onChange={(e) => {
                      handleFieldChange('newMileage', e.target.value);
                    }}
                    onFocus={() => setTouched('newMileage', true)}
                    className="mobile-textbox margin-top-0"
                  />
                </div>
              </div>
              <div
                className={
                  formErrors?.photoOfDamage
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <IsCorrectField
                  error={formErrors['photoOfDamage'] || ''}
                  label="Photo of Damage"
                  fieldName="photoOfDamage"
                  isRequired={false}
                  onFocus={() => setTouched('photoOfDamage', true)}
                  onChange={(e) => {}}
                >
                  <div className="gray-text">
                    Take of photo of any vehicle damage. Max 10 photos
                  </div>
                  {formErrors?.photoOfDamage && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.photoOfDamage}</strong>
                    </div>
                  )}
                  <PhotoCapture
                    vin="photoOfDamage"
                    area="photoOfDamage"
                    code="inspect"
                    id="photoOfDamage-inspect-photo"
                    name="photoOfDamage-inspect-photo"
                    showLabel={false}
                    maxPhotoCount={10}
                    photosValue={formValues?.photoOfDamage?.photos}
                    photoDataValue={formValues?.photoOfDamage?.photoData}
                    onCapture={(photos, photoData) => {
                      handleFieldChange('photoOfDamage', {
                        photos,
                        photoData,
                      });
                    }}
                  />
                </IsCorrectField>
              </div>
            </MainForm>
            <Comments>
              <IsCorrectField
                error={formErrors['newComments'] || ''}
                label={<span>Comments:</span>}
                defaultValue=""
                currentValue={formValues?.newComments}
                fieldName="newComments"
                onFocus={() => setTouched('newvehicle', true)}
                onChange={(e) => {}}
                isRequired={false}
              >
                <Textbox
                  id="newComments"
                  name="newComments"
                  type="textarea"
                  data-testid="newComments"
                  size="medium"
                  value={formValues?.newComments || ''}
                  onFocus={() => setTouched('newComments', true)}
                  onChange={(e) => {
                    handleFieldChange('newComments', e.target.value);
                    const textLeft = 1000 - Number(e.target.value.length);
                    const charBox = document.getElementById('comments-chars');
                    const allChars = charBox.parentNode;
                    if (charBox) {
                      if (textLeft < 1000) {
                        charBox.innerHTML =
                          (textLeft >= 0 ? textLeft : 0) + '/';
                        if (textLeft < 0) {
                          if (allChars.className.indexOf('error') === -1) {
                            allChars.className += ' error';
                          }
                        } else {
                          allChars.className = allChars.className
                            .split(' ')
                            .filter((classItem) => classItem !== 'error')
                            .join(' ');
                        }
                      } else {
                        charBox.innerHTML = '';
                        allChars.className = allChars.className
                          .split(' ')
                          .filter((classItem) => classItem !== 'error')
                          .join(' ');
                      }
                    }
                  }}
                />
                <div className="textbox-note">
                  <span
                    id={`comments-chars`}
                    data-testid="comments-chars"
                  ></span>
                  1000 characters allowed
                </div>
              </IsCorrectField>
            </Comments>
            <Email>
              <div
                className={
                  formErrors?.email
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1" required>
                  <strong>Email</strong>
                </Label>
                {formErrors?.email && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.email}</strong>
                  </div>
                )}
                <div className="input-box">
                  <Textbox
                    type="text"
                    id="email"
                    name="email"
                    value={formValues?.email || ''}
                    variant={formErrors?.email ? 'error' : ''}
                    onChange={(e) => {
                      handleFieldChange('email', e.target.value);
                    }}
                    onFocus={() => setTouched('email', true)}
                    className="mobile-textbox margin-top-0"
                  />
                </div>
              </div>
            </Email>
            <DriverInfo>
              <div
                className={
                  formErrors?.driverFirstName
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1" required>
                  <strong>Driver's First Name</strong>
                </Label>
                {formErrors?.driverFirstName && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.driverFirstName}</strong>
                  </div>
                )}
                <div className="input-box">
                  <Textbox
                    type="text"
                    id="driverFirstName"
                    name="driverFirstName"
                    value={formValues?.driverFirstName || ''}
                    variant={formErrors?.driverFirstName ? 'error' : ''}
                    onChange={(e) => {
                      handleFieldChange('driverFirstName', e.target.value);
                    }}
                    onFocus={() => setTouched('driverFirstName', true)}
                    className="mobile-textbox margin-top-0"
                  />
                </div>
              </div>
              <div
                className={
                  formErrors?.driverLastName
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1" required>
                  <strong>Driver's Last Name</strong>
                </Label>
                {formErrors?.driverLastName && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.driverLastName}</strong>
                  </div>
                )}
                <div className="input-box">
                  <Textbox
                    type="text"
                    id="driverLastName"
                    name="driverLastName"
                    value={formValues?.driverLastName || ''}
                    variant={formErrors?.driverLastName ? 'error' : ''}
                    onChange={(e) => {
                      handleFieldChange('driverLastName', e.target.value);
                    }}
                    onFocus={() => setTouched('driverLastName', true)}
                    className="mobile-textbox margin-top-0"
                  />
                </div>
              </div>
              <div className={formErrors?.driverSignature ? 'form-error' : ''}>
                {formErrors?.driverSignature && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.driverSignature}</strong>
                  </div>
                )}
                <Button
                  className="icon-buttowidth-full"
                  data-testid="continue-button"
                  size={isDesktop ? 'medium' : 'large'}
                  label={
                    formValues?.driverSignature
                      ? 'Signature accepted'
                      : 'Driver Sign'
                  }
                  disabled={formValues?.driverSignature}
                  onClick={() => {
                    setTouched('driverSignature', true);
                    setFormErrors({});
                    setIsSubmitted(true);
                    if (setSubmitedInsideExchange) {
                      setSubmitedInsideExchange(true);
                    }
                    const errors = validateForm(formValues);
                    if (
                      Object.keys(errors).length > 1 &&
                      errors.driverSignature !== ''
                    ) {
                      setFormErrors(errors);
                      window.setTimeout(
                        () => scrollToElement('.form-error'),
                        250,
                      );
                      setRequiredFieldError(true);
                    } else {
                      setShowDriverModal(true);
                    }
                  }}
                />
              </div>
              <SignatureModal
                show={showDriverModal}
                title="Driver sign eVAF"
                onCancel={() => {
                  setShowDriverModal(false);
                }}
                onSave={(dataUrl) => {
                  handleFieldChange('driverSignature', dataUrl);
                  setShowDriverModal(false);
                }}
              />
            </DriverInfo>
            <MarshallerInfo>
              <div className="bold">Marshaller</div>
              <div>{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
            </MarshallerInfo>
            <MarshallerButton>
              <LoadingButton
                className="icon-buttowidth-full"
                data-testid="continue-button"
                size={isDesktop ? 'medium' : 'large'}
                label="Marshaller Sign & Submit"
                loading={isloading}
                onClick={() => {
                  setIsSubmitted(true);
                  if (setSubmitedInsideExchange) {
                    setSubmitedInsideExchange(true);
                  }
                  if (oldVehicleTabError) {
                    scrollToTop();
                    return;
                  }
                  if (
                    formValues.driverSignature === '' ||
                    formValues.driverSignature === undefined
                  ) {
                    const errors = validateForm(formValues);
                    setFormErrors(errors);
                    window.setTimeout(
                      () => scrollToElement('.form-error'),
                      250,
                    );
                    setRequiredFieldError(true);
                  } else {
                    setShowMarshallerModal(true);
                  }
                }}
              />
              <SignatureModal
                show={showMarshallerModal}
                title="Marshaller sign eVAF"
                onCancel={() => {
                  setShowMarshallerModal(false);
                }}
                onSave={(dataUrl) => {
                  setIsloading(true);
                  handleFieldChange('marshallerSignature', dataUrl);
                  setShowMarshallerModal(false);
                  setCallSubmitHandler(true);
                }}
              />
            </MarshallerButton>
          </>
        )}
      </Composition>
      <div className="padding-bottom-3 padding-top-5">
        <Button
          className="width-full"
          data-testid="cancel-button"
          variant="outline"
          size={isDesktop ? 'medium' : 'large'}
          label="Cancel"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <AreYouSureModal
        title="Are you sure you want to delete this eVAF?"
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, Delete eVAF"
        cancelLabel="Cancel"
        onConfirm={() => {
          dispatch(clearEvafData());
          setShowModal(false);
          history.push('/home');
          scrollToTop();
        }}
        onCancel={() => setShowModal(false)}
      >
        The data from this eVAF will not be saved.
      </AreYouSureModal>
    </div>
  );
};

export default NewVehicle;
