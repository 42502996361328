import React, { useState, useEffect } from 'react';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Label, Textbox, Button } from '@gsa/afp-component-library';
import { Scanner } from 'components';
import { VINValidation } from 'utils/VINValidation';
import './FormInputs.css';

export const VinField = ({
  id = '',
  initialValue = '',
  showErrors = false,
  required = false,
  label = 'VIN',
  errorMessage = null,
  scanner = true,
  onChange,
  onValidate,
  className,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(false);
  const [vin, setVin] = useState(initialValue);
  const [errorTxt, setErrorTxt] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [scanDetails, setScanDetails] = useState(null);
  const prevVin = usePrevious(vin);

  useEffect(() => {
    setVin(initialValue.toUpperCase());
  }, [initialValue]);

  useEffect(() => {
    let validNow = false;
    let errorNow = '';
    if (required && vin.trim() === '') {
      validNow = false;
      errorNow = 'This is a required field';
    } else if (errorMessage) {
      validNow = false;
      errorNow = errorMessage;
    } else {
      validNow = true;
      errorNow = '';
      const validateVin = VINValidation(vin);
      if (validateVin !== '') {
        validNow = false;
        errorNow = validateVin;
      }
    }
    if (typeof onChange === 'function' && prevVin !== vin.trim()) {
      onChange(vin, scanDetails);
    }
    if (typeof onValidate === 'function' && prevVin !== vin.trim()) {
      onValidate(validNow, errorNow);
    }
    setIsValid(validNow);
    setErrorTxt(errorNow);
  }, [vin, onChange, onValidate, required, scanDetails, prevVin, errorMessage]);

  return (
    <div className="form-input vin" data-testid="vin-field">
      <div className={!isValid && showErrors ? 'form-error' : ''}>
        <Label className="margin-top-1" required={required}>
          <strong>{label}</strong>
        </Label>
        {!isValid && showErrors && errorTxt && (
          <div className="form-error-msg" data-testid="form-error-msg">
            <strong>{errorTxt}</strong>
          </div>
        )}
        {scanner && <div className="gray-text">Scan or type</div>}
        <div>
          <Textbox
            data-testid="vin-input"
            type="text"
            id={id}
            name={id}
            value={vin}
            variant={!isValid && showErrors ? 'error' : ''}
            onChange={(e) => {
              setVin(e.target.value.toUpperCase());
            }}
            className={`vin-input${scanner ? '' : ' no-scan'}${
              className ? ` ${className}` : ''
            }`}
            {...rest}
          />
          {scanner && (
            <Button
              data-testid="scan-btn"
              variant="primary"
              size="medium"
              label=""
              leftIcon={{
                name: 'photo_camera',
                iconName: 'photo_camera',
              }}
              onClick={() => {
                setVin('');
                setScanDetails(null);
                setShowScanner(true);
              }}
              className="icon-button-round"
            />
          )}
        </div>
        {scanner && showScanner && (
          <Scanner
            onCapture={(captured, ts) => {
              setVin(captured.toUpperCase(), ts);
              setScanDetails({
                vin: captured,
                ts,
              });
              setShowScanner(false);
            }}
            onClose={() => {
              setShowScanner(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default VinField;
