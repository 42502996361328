import React, { useState, useEffect, useCallback } from 'react';
import { formatVehicleTitle } from 'utils';
import { useSelector } from 'react-redux';
import { Button, Label, Textbox } from '@gsa/afp-component-library';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { FsrModal } from 'components';
import { scrollToTop, VINValidation } from 'utils';
import './OrderSearchResults.css';

export const OrderSearchResults = ({
  orderVehicles = [],
  deliveredVehicles = [],
  setVehicle,
  setScreen,
  setScannerOpen,
  setScanFrom,
  scannerResult,
  scanFrom,
  setError,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const allVehicles = marshallingData?.vehicles || [];
  const deliveryData = marshallingData?.delivery || [];
  const [vinList, setVinList] = useState({});
  const [vinListRedo, setVinListRedo] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [allVehiclesAdded, setAllVehiclesAdded] = useState(false);
  const [nonDeliveredVehicles, setNonDeliveredVehicles] = useState([]);
  const [showFsrModal, setShowFsrModal] = useState(false);

  useEffect(() => {
    if (orderVehicles.length > 0 && !allVehiclesAdded) {
      const deliveredMap = deliveredVehicles.map((vehicle) => vehicle.id);
      const vehiclesNotDelivered = orderVehicles.filter(
        (vehicle) => !deliveredMap.includes(vehicle.id),
      );
      setNonDeliveredVehicles(vehiclesNotDelivered);
      if (vehiclesNotDelivered.length < 1) {
        setAllVehiclesAdded(true);
      } else if (deliveredVehicles.length >= orderVehicles.length) {
        setAllVehiclesAdded(true);
      }
    } else if (orderVehicles.length < 1 && !allVehiclesAdded) {
      setAllVehiclesAdded(true);
    }
  }, [deliveredVehicles, orderVehicles, allVehiclesAdded]);

  const setVinItem = useCallback(
    (index, vin) => {
      if (vinList[index] !== vin) {
        setVinList({
          ...vinList,
          [index]: vin,
        });
      }
    },
    [vinList],
  );

  const setVinRedoItem = (index, vin) => {
    setVinListRedo({
      ...vinListRedo,
      [index]: vin,
    });
  };

  useEffect(() => {
    if (scannerResult && scanFrom !== 'search') {
      setVinItem(scanFrom, scannerResult);
    }
  }, [scanFrom, scannerResult, setVinItem]);

  const handleVehicleSelect = (index) => {
    let errCount = 0;
    let formErrorsTmp = {};
    let newId = false;
    let vehicle = orderVehicles[index];
    const vinValue = vinList[index];
    const vinValueRedo = vinListRedo[index];

    if (vinValue) {
      const VINError = VINValidation(vinValue);
      if (VINError) {
        formErrorsTmp[`vin-${index}`] = VINError;
        errCount++;
      }
    } else if (!vinValue || vinValue.trim() === '') {
      formErrorsTmp[`vin-${index}`] = 'This is a required field';
      errCount++;
    }

    if (scanFrom !== index) {
      if (vinValueRedo) {
        const VINRedoError = VINValidation(vinValueRedo);
        if (VINRedoError) {
          formErrorsTmp[`vin-redo-${index}`] = VINRedoError;
          errCount++;
        }
      } else if (!vinValueRedo || vinValueRedo.trim() === '') {
        formErrorsTmp[`vin-redo-${index}`] = 'This is a required field';
        errCount++;
      }

      if (errCount === 0 && vinValue !== vinValueRedo) {
        formErrorsTmp[`vin-redo-${index}`] = 'VINs do not match';
        errCount++;
      }
    }

    if (deliveryData.length === 20) {
      setError(
        <>
          <div>
            <strong>
              Maximum number of vehicles per session has been reached.
            </strong>{' '}
            Only 20 vehicles are allowed per vehicle delivery session. To
            complete this session, select the complete delivery session button.
          </div>
        </>,
      );
      scrollToTop();
      return;
    }

    const vehicleDelivered = deliveryData.filter(
      (vehicle) => vehicle.vin === vinValue,
    );
    if (vehicleDelivered.length > 0) {
      setError(
        <>
          <div data-testid="alert-scan-order">
            This vehicle has already been scanned and added.
          </div>
          <div>
            <strong>{formatVehicleTitle(vehicleDelivered[0])}</strong>
          </div>
          <div>
            <strong>VIN: {vehicleDelivered[0].vin}</strong>
          </div>
        </>,
      );
      scrollToTop();
      return;
    }

    // For when the vehicle they're scanning in the order already has a VIN associated...
    if (errCount < 1 && vinValue) {
      const deliveredMap = deliveredVehicles.map((vehicle) => vehicle.id);
      const vinMap = allVehicles
        .filter(
          (vehicle) =>
            vehicle?.vin !== null && !deliveredMap.includes(vehicle?.id),
        )
        .map((vehicle) => vehicle?.vin);
      // If the VIN they've entered exists as any other non-delivered vehicle in the system, assume that vehicle record
      if (vinMap.includes(vinValue)) {
        const actualVehicle = allVehicles.filter(
          (vehicle) => vehicle?.vin === vinValue,
        );
        if (actualVehicle.length > 0) {
          vehicle = actualVehicle[0];
        }
      } else {
        // If the VIN scanned does not exist on any existing vehicle in the system,
        // duplicate another vehicle and use that vehicle data
        vehicle = orderVehicles[0]; // Just grabbing the first item from the order to clone it
        newId = true; // Indicate that we don't want to clone the vehicle ID
      }
    }

    if (errCount > 0) {
      setFormErrors(formErrorsTmp);
      return;
    } else {
      setFormErrors({});
      const vehicleData = {
        ...vehicle,
        vin: vinValue,
      };
      if (newId) {
        vehicleData.id = null;
      }
      setVehicle(vehicleData);
      setScreen('vehicle-delivery');
    }
  };

  const vehiclesToShow =
    nonDeliveredVehicles.length > 0 ? [nonDeliveredVehicles[0]] : [];

  return (
    <div className="order-search-results">
      {!allVehiclesAdded && (
        <div className="border-bottom border-base-light">
          <h2>Available vehicles in this order</h2>
          <p>Manually enter the VIN then select the vehicle.</p>
          {vehiclesToShow.map((vehicle, i) => {
            return (
              <div key={i} className="padding-bottom-2">
                <div>
                  <strong>{formatVehicleTitle(vehicle)}</strong>
                </div>
                <div>
                  <Composition
                    areas={`
                      Field ScanBtn
                      ReEnter ReEnter
                      SelectBtn SelectBtn
                    `}
                    gap={8}
                    templateCols="1fr 70px"
                  >
                    {({ Field, ScanBtn, SelectBtn, ReEnter }) => (
                      <>
                        <Field>
                          <div
                            className={
                              formErrors[`vin-${i}`] ? 'form-error' : ''
                            }
                          >
                            <Label className="margin-top-1" required>
                              <strong>VIN</strong>
                            </Label>
                            <div
                              style={{
                                color: '#71767A',
                              }}
                            >
                              Scan or type
                            </div>
                            {formErrors[`vin-${i}`] && (
                              <div className="form-error-msg">
                                <strong>{formErrors[`vin-${i}`]}</strong>
                              </div>
                            )}
                            <Textbox
                              type="text"
                              id={`vin-${i}`}
                              name={`vin-${i}`}
                              value={vinList[i] || ''}
                              variant={formErrors[`vin-${i}`] ? 'error' : ''}
                              onChange={(e) => {
                                setScanFrom('search');
                                setVinItem(i, e.target.value);
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                              }}
                              onDrop={(e) => {
                                e.preventDefault();
                              }}
                              className="mobile-textbox margin-top-0"
                            />
                          </div>
                        </Field>
                        <ScanBtn align="end">
                          <Button
                            variant="primary"
                            size="medium"
                            label=""
                            leftIcon={{
                              name: 'photo_camera',
                              iconName: 'photo_camera',
                            }}
                            onClick={() => {
                              setScannerOpen(true);
                              setScanFrom(i);
                            }}
                            className="icon-button"
                            style={{
                              height: '40px',
                              borderRadius: '.25rem',
                              position: 'static',
                              marginTop: '0',
                            }}
                          />
                        </ScanBtn>
                        <ReEnter>
                          {scanFrom !== i &&
                            vinList[i] &&
                            vinList[i].trim() !== '' && (
                              <div
                                className={
                                  formErrors[`vin-redo-${i}`]
                                    ? 'form-error'
                                    : ''
                                }
                              >
                                <Label className="margin-top-1" required>
                                  <strong>Reenter VIN</strong>
                                </Label>
                                {formErrors[`vin-redo-${i}`] && (
                                  <div className="form-error-msg">
                                    <strong>
                                      {formErrors[`vin-redo-${i}`]}
                                    </strong>
                                  </div>
                                )}
                                <Textbox
                                  type="text"
                                  id={`vin-redo-${i}`}
                                  name={`vin-redo-${i}`}
                                  value={vinListRedo[i] || ''}
                                  variant={
                                    formErrors[`vin-redo-${i}`] ? 'error' : ''
                                  }
                                  onChange={(e) => {
                                    setVinRedoItem(i, e.target.value);
                                  }}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                  }}
                                  onDrop={(e) => {
                                    e.preventDefault();
                                  }}
                                  className="mobile-textbox margin-top-0"
                                />
                              </div>
                            )}
                        </ReEnter>
                        <SelectBtn>
                          <div
                            className="padding-top-2"
                            style={{ width: '60%' }}
                          >
                            <Button
                              variant="outline"
                              size="medium"
                              label="Select this vehicle"
                              onClick={() => {
                                handleVehicleSelect(i);
                              }}
                            />
                          </div>
                        </SelectBtn>
                      </>
                    )}
                  </Composition>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {deliveredVehicles.length > 0 && (
        <div className="border-bottom border-base-light">
          <h2>
            Vehicles already delivered ({deliveredVehicles.length || 0}/
            {orderVehicles.length || 0})
          </h2>
          {deliveredVehicles.map((vehicle, i) => {
            return (
              <div key={i} className="padding-bottom-2">
                <div>
                  <strong>{formatVehicleTitle(vehicle)}</strong>
                </div>
                <div>VIN: {vehicle.vin}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className="border-bottom border-base-light padding-top-3 padding-bottom-3">
        <Button
          className="width-full"
          variant="outline"
          size={isDesktop ? 'medium' : 'large'}
          label="Vehicle not found?"
          onClick={() => {
            setShowFsrModal(true);
          }}
        />
      </div>
      <FsrModal
        show={showFsrModal}
        onClose={() => {
          setShowFsrModal(false);
        }}
      />
    </div>
  );
};

export default OrderSearchResults;
