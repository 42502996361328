import React, { useState, useEffect } from 'react';
import {
  PageTitle,
  Alert,
  RadioButton,
  Label,
  Button,
} from '@gsa/afp-component-library';
import { Composition } from 'atomic-layout';
import { useDispatch, useSelector } from 'react-redux';
import { addAcceptedVehicle } from 'reducers/marshalling';
import { formatVehicleTitle, scrollToElement } from 'utils';
import { DamageInTransit, CannotInspect } from 'components';

export const DeliverVehicle = ({
  setScreen,
  vehicle,
  setVehicleSuccess,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const deliveryData = marshallingData?.delivery || [];
  const [showAlert, setShowAlert] = useState(false);
  const [dit, setDit] = useState('no');
  const [damage, setDamage] = useState({});
  const [noDitEntered, setNoDitEntered] = useState(false);
  const [ditErrors, setDitErrors] = useState({});
  const [cannot, setCannot] = useState({});

  useEffect(() => {
    if (vehicle) {
      setShowAlert(true);
    }
  }, [vehicle]);

  const validateDit = (damage, isNotSubmission = false) => {
    setNoDitEntered(false);
    if (dit !== 'yes') {
      return true;
    }

    if (Object.keys(damage).length < 1 && isNotSubmission === false) {
      setNoDitEntered(true);
      window.setTimeout(() => scrollToElement('#no-dit-entered-alert'), 250);
      return false;
    }

    const errors = {};
    Object.keys(damage).forEach((damageArea) => {
      for (const [key, value] of Object.entries(damage[damageArea])) {
        const damageItem = value;
        if (!damageItem?.type) {
          // No damage type code selected
          if (!errors[damageArea]) {
            errors[damageArea] = {};
          }

          if (!errors[damageArea][key]) {
            errors[damageArea][key] = {};
          }

          errors[damageArea][key] = {
            ...errors[damageArea][key],
            type: true,
          };
        }

        if (damageItem?.details && damageItem?.details.length > 1000) {
          // Additional details is larger than the 1000 char limit
          if (!errors[damageArea]) {
            errors[damageArea] = {};
          }

          if (!errors[damageArea][key]) {
            errors[damageArea][key] = {};
          }

          errors[damageArea][key] = {
            ...errors[damageArea][key],
            details: true,
          };
        }
      }
    });

    if (Object.keys(errors).length > 0) {
      setDitErrors(errors);
      return false;
    } else {
      return true;
    }
  };

  const completeVehicle = () => {
    if (!deliveryData.map((vehicle) => vehicle.vin).includes(vehicle.vin)) {
      const isValid = validateDit(damage);
      if (isValid) {
        dispatch(
          addAcceptedVehicle({
            ...vehicle,
            dit,
            damage,
            cannot,
          }),
        );
        setDit('no');
        setVehicleSuccess(true);
        setShowAlert(false);
        setScreen('find-vehicle');
      }
    }
  };

  return (
    <div className="grid-col">
      <PageTitle
        title="Vehicle Delivery"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      {showAlert && (
        <Alert
          data-testid="vehicle-found-alert"
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={true}
          onClose={() => setShowAlert(false)}
        >
          Vehicle found! Please confirm vehicle details.
        </Alert>
      )}
      {vehicle && (
        <>
          <div className="border-bottom border-base-light padding-bottom-3 padding-top-3">
            <div>
              <strong>{formatVehicleTitle(vehicle)}</strong>
            </div>
            <div>
              <strong>VIN: {vehicle.vin}</strong>
            </div>
          </div>
          <div className="border-bottom border-base-light padding-bottom-3 padding-top-3">
            <div>
              Required fields are marked with an asterisk (
              <span className="afp-required-field">*</span>)
            </div>
          </div>
          <div className="border-bottom border-base-light padding-bottom-3 padding-top-2">
            <Label className="text-bold" required>
              Damage in transit
            </Label>
            <Composition
              areas={`
                No
                Yes
                CannotInspect
              `}
              areasMd={`No Yes CannotInspect`}
              gap={8}
              templateCols="1fr"
              templateColsMd="auto"
              templateRows="auto"
            >
              {({ No, Yes, CannotInspect }) => (
                <>
                  <No>
                    <RadioButton
                      id="dit-no"
                      name="damage-in-transit"
                      label="No"
                      checked={dit === 'no'}
                      onChange={() => {
                        setDit('no');
                      }}
                    />
                  </No>
                  <Yes>
                    <RadioButton
                      id="dit-yes"
                      name="damage-in-transit"
                      label="Yes"
                      checked={dit === 'yes'}
                      onChange={() => {
                        setDit('yes');
                      }}
                    />
                  </Yes>
                  <CannotInspect>
                    <RadioButton
                      id="dit-cannot"
                      name="damage-in-transit"
                      label="Cannot inspect"
                      checked={dit === 'cannot'}
                      onChange={() => {
                        setDit('cannot');
                      }}
                    />
                  </CannotInspect>
                </>
              )}
            </Composition>
          </div>
          {noDitEntered && (
            <div id="no-dit-entered-alert" className="padding-top-2">
              <Alert
                className="margin-top-0"
                type="error"
                heading=""
                noIcon={false}
                validation={false}
                focused={false}
                showClose={false}
              >
                Damage in Transit has been selected. You must identify the
                damage area and damage code below to continue.
              </Alert>
            </div>
          )}
          {dit === 'yes' && (
            <DamageInTransit
              vin={vehicle.vin}
              errors={ditErrors}
              validator={validateDit}
              onChange={(damage) => {
                setDamage(damage);
              }}
            />
          )}
          {dit === 'cannot' && (
            <CannotInspect
              vin={vehicle.vin}
              onChange={(cannot) => {
                setCannot(cannot);
              }}
            />
          )}
          <Composition
            areas={`
              Complete
              Cancel
            `}
            areasMd={`Cancel Complete`}
            gap={24}
            templateCols="1fr"
            templateColsMd="auto"
            marginVertical={24}
            paddingTop={8}
          >
            {({ Complete, Cancel }) => (
              <>
                <Complete>
                  <Button
                    className="width-full"
                    variant="primary"
                    size={isDesktop ? 'medium' : 'large'}
                    label="Add vehicle to session"
                    onClick={() => {
                      completeVehicle();
                    }}
                  />
                </Complete>
                <Cancel>
                  <Button
                    className="width-full"
                    variant="outline"
                    size={isDesktop ? 'medium' : 'large'}
                    label="Cancel delivering vehicle"
                    onClick={() => {
                      setScreen('find-vehicle');
                    }}
                  />
                </Cancel>
              </>
            )}
          </Composition>
        </>
      )}
    </div>
  );
};

export default DeliverVehicle;
