import React from 'react';
import { Accordion, Icon } from '@gsa/afp-component-library';
import { Composition } from 'atomic-layout';
import { formatVehicleTitle } from 'utils/formatting';
import './AccordionListing.css';

export const AccordionListing = ({ items = [], onToggle = null }) => {
  const formatTitle = (text, icon, iconColor) => {
    return (
      <span className="accordion-title">
        {icon && (
          <span className="icon-container">
            <Icon
              viewBox="0 0 24 24"
              iconName={icon}
              className={iconColor || ''}
            />
          </span>
        )}
        <span className="text-container">{text}</span>
      </span>
    );
  };

  const formatContent = (vehicles) => {
    const content = [];
    vehicles.forEach((vehicle, i) => {
      content.push(
        <div
          className="vehicle"
          //may be controled by delivery page css
          //should change to an AccordionListing.css
          key={i}
        >
          <Composition
            areas={`
              Title
              Vin
            `}
            gap={8}
            templateCols="1fr"
            templateColsMd="300px 1fr"
            templateRows="20px 20px auto"
            templateRowsMd="20px auto"
          >
            {({ Title, Vin }) => {
              return (
                <>
                  <Title>
                    <strong>{formatVehicleTitle(vehicle)}</strong>
                  </Title>
                  <Vin>
                    <strong>VIN: {vehicle.vin}</strong>
                  </Vin>
                </>
              );
            }}
          </Composition>
        </div>,
      );
    });

    return content;
  };

  // Extract 'items' and process specially for delivery listing details
  const tmpItems = [];
  items.forEach((item) => {
    tmpItems.push({
      ...item,
      title: formatTitle(
        item.title.text,
        item.title.icon,
        item.title.iconColor,
      ),
      content: formatContent(item.vehicles),
      expanded: false,
    });
  });

  return (
    <span data-testid="accordion-listing">
      <Accordion
        bordered={true}
        items={tmpItems}
        className="accordion-listing"
        multiselectable={true}
        handleToggleFilter={(data) => {
          if (typeof onToggle === 'function') {
            onToggle(data);
          }
        }}
      />
    </span>
  );
};

export default AccordionListing;
