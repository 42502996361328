import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import {
  PageTitle,
  Checkbox,
  Button,
  Alert,
  Label,
  Textbox,
  SelectDropdown,
} from '@gsa/afp-component-library';
import {
  IsCorrectField,
  PhotoCapture,
  SignatureModal,
  AreYouSureModal,
  VinField,
  LicensePlateField,
  LoadingButton,
} from 'components';
import { Composition } from 'atomic-layout';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setEvafData, clearEvafData } from 'reducers/marshalling';
import {
  evafDropDownOptions,
  numberOfKeys,
  numberOfLicensePlates,
  numberOfPlateSide,
} from 'constants/constants';
import { isValidEmail, scrollToElement, scrollToTop } from 'utils';
import '../screens.css';
import { EMAIL_EVAF } from 'services/sync-gql';

export const OldVehicle = ({
  setScreen,
  prevFormValues,
  onSetFormValues,
  eVafFormCompleted = false,
  setEvafFormCompleted,
  isInsideTab = false,
  setTabError,
  isDesktop,
  onVerifySuccess,
  setCanLeaveOldTab,
  trigger,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const evafData = useSelector((state) => state?.marshalling?.evaf || {});
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    frontBumpGrill: 'OK',
    lFrontFenderQuarter: 'OK',
    rFrontFenderQuarter: 'OK',
    leftSide: 'OK',
    rightSide: 'OK',
    hoodPanel: 'OK',
    roofPanel: 'OK',
    lRearFenderQuarter: 'OK',
    rRearFenderQuarter: 'OK',
    lRear: 'OK',
    rRear: 'OK',
    spareTire: 'OK',
    lFront: 'OK',
    jackTools: 'OK',
    rFront: 'OK',
    mirrors: 'OK',
    windshield: 'OK',
    flooring: 'OK',
    seats: 'OK',
    glass: 'OK',
    sideWindows: 'OK',
    tireInstallKit: 'OK',
    leftFronJack: 'OK',
    ...prevFormValues,
    ...(evafData?.old || {}),
  });
  const [vinError, setVinError] = useState(null);
  const [plateError, setPlateError] = useState(null);
  const [showDriverModal, setShowDriverModal] = useState(false);
  const [showMarshallerModal, setShowMarshallerModal] = useState(false);
  const [oldVehicleSuccess, setOldVehicleSuccess] = useState(false);
  const [requiredFieldError, setRequiredFieldError] = useState(false);
  const [showWarning, setShowWarning] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [callSubmitHandler, setCallSubmitHandler] = useState(false);
  const { currentUser } = useCurrentUser();

  const [emailEVAF] = useMutation(EMAIL_EVAF, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: async () => {
      return true;
    },
  });

  const validateForm = useCallback(
    (formValues) => {
      const errors = {};

      if (formValues?.noOfLicensePlates !== '0' && plateError) {
        errors.oldLicensePlate = plateError;
      }

      if (
        formValues?.noOfLicensePlates === '' ||
        !formValues?.noOfLicensePlates
      ) {
        errors.noOfLicensePlates = 'This is a required field';
      }

      if (
        formValues?.noOfLicensePlates === '1' &&
        (formValues?.missingSidePlate === '' || !formValues?.missingSidePlate)
      ) {
        errors.missingSidePlate = 'This is a required field';
      }

      if (vinError) {
        errors.oldVin = vinError;
      }

      if (formValues?.oldMileage === '' || !formValues?.oldMileage) {
        errors.oldMileage = 'This is a required field';
      }

      if (formValues?.oldMileage && isNaN(Number(formValues?.oldMileage))) {
        errors.oldMileage = 'Must be a numeric value';
      }

      if (formValues?.noOfKeys === '' || !formValues?.noOfKeys) {
        errors.noOfKeys = 'This is a required field';
      }

      if (!isInsideTab) {
        if (!formValues?.email) {
          errors.email = 'Email is required';
        } else if (!isValidEmail(formValues.email)) {
          errors.email = 'Please enter a valid email address';
        }

        if (
          formValues?.driverFirstName === '' ||
          !formValues?.driverFirstName
        ) {
          errors.driverFirstName = 'This is a required field';
        }

        if (formValues?.driverLastName === '' || !formValues?.driverLastName) {
          errors.driverLastName = 'This is a required field';
        }

        if (
          formValues?.driverSignature === '' ||
          !formValues?.driverSignature
        ) {
          errors.driverSignature = 'Driver signature is required';
        }
      }

      const oldVinError = vinError;
      if (
        oldVinError &&
        formValues?.oldVin !== undefined &&
        formValues?.oldVin !== ''
      ) {
        errors.oldVin = oldVinError;
      }

      var photoCaptureErrors = validatePhotoCapturefields(formValues);
      return { ...errors, ...photoCaptureErrors };
    },
    [isInsideTab, vinError, plateError],
  );

  const fieldNames = {
    frontBumpGrill: 'Front Bumper / Grill',
    lFrontFenderQuarter: 'Left Front Fender/Quarter Panel',
    rFrontFenderQuarter: 'Right Front Fender/Quarter Panel',
    leftSide: 'Left Side',
    rightSide: 'Right Side',
    hoodPanel: 'Hood Panel',
    roofPanel: 'Roof Panel',
    lRearFenderQuarter: 'Left Rear Fender/Quarter Panel',
    rRearFenderQuarter: 'Right Rear Fender/Quarter Panel',
    lFront: 'Left Front',
    rFront: 'Right Front',
    lRear: 'Left Rear',
    rRear: 'Right Rear',
    spareTire: 'Spare Tire',
    tireInstallKit: 'Tire Inflator Kit',
    jackTools: 'Jack and Tools',
    seats: 'Seats',
    glass: 'Glass',
    sideWindows: 'Side Window(s)',
    flooring: 'Flooring',
    windshield: 'Windshield',
    mirrors: 'Mirrors',
  };

  const handleFieldChange = (fieldName, value) => {
    if (
      touchedFields !== undefined &&
      Object.keys(touchedFields).length === 0
    ) {
      return;
    }
    if (
      !['driverSignature', 'marshallerSignature'].includes(fieldName) &&
      formValues.driverSignature !== ''
    ) {
      const newFormValues = {
        ...formValues,
        driverSignature: '',
        [fieldName]: value,
      };
      setFormValues({
        ...newFormValues,
      });
      if (onSetFormValues) {
        onSetFormValues({
          ...formValues,
        });
      }
    } else {
      setFormValues({
        ...formValues,
        [fieldName]: value,
      });
      if (onSetFormValues) {
        onSetFormValues({
          ...formValues,
          [fieldName]: value,
        });
      }
    }
  };

  const PhotoCaptureField = ({
    label,
    area,
    photoField,
    formValues,
    formErrors,
    onCapture,
  }) => {
    return (
      <div>
        <div className="exteriors">
          <Label className="margin-top-1 margin-bottom-3">
            <strong>{label}</strong>
          </Label>
          <SelectDropdown
            id={area}
            name={area}
            disabled={false}
            containerClassName="margin-top-0"
            defaultValue=""
            value={formValues?.[area] || ''}
            onFocus={() => setTouched('oldvehicle', true)}
            onChange={(e) => {
              handleFieldChange(area, e.target.value);
            }}
            options={evafDropDownOptions}
          />
        </div>
        {formValues?.[area] !== 'OK' && (
          <div
            className={
              formErrors?.[area]
                ? 'margin-bottom-3 form-error'
                : 'margin-bottom-3'
            }
          >
            <div className="margin-bottom-3">
              {formErrors?.[area] && (
                <div className="form-error-msg">
                  <strong>{formErrors?.[area]}</strong>
                </div>
              )}
              <PhotoCapture
                vin={area}
                area={area}
                code="inspect"
                id={`${area}-inspect-photo`}
                name={`${area}-inspect-photo`}
                showLabel={true}
                maxPhotoCount={3}
                photosValue={formValues?.[photoField]?.photos}
                photoDataValue={formValues?.[photoField]?.photoData}
                onCapture={(photos, photoData) => {
                  onCapture({ photos, photoData });
                  setTouched('oldvehicle', true);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (trigger > 0) {
      setRequiredFieldError(false);
      setIsSubmitted(true);
      const errors = validateForm(formValues);
      if (errors) {
        setOldVehicleSuccess(false);
      }
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        window.setTimeout(() => scrollToElement('.form-error'), 250);
        setRequiredFieldError(true);
      } else {
        setFormErrors({});
      }
    }
  }, [trigger, validateForm, formValues]);

  const setTouched = (field, value) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
    if (isSubmitted) {
      const errors = validateForm(formValues);
      const touchedFieldErrors = Object.entries(errors).reduce(
        (acc, [fld, err]) => {
          if (touchedFields[fld]) {
            acc[fld] = err;
          }

          return acc;
        },
        {},
      );

      setFormErrors(touchedFieldErrors || {});
    }
    if (setTabError && !callSubmitHandler) {
      const errors = validateForm(formValues);
      if (Object.keys(errors).length > 0) {
        setTabError(true);
      } else {
        setTabError(false);
      }
    }
  };

  const validatePhotoCapturefields = (formValues) => {
    const photoCaptureFields = [
      'frontBumpGrill',
      'lFrontFenderQuarter',
      'rFrontFenderQuarter',
      'leftSide',
      'rightSide',
      'hoodPanel',
      'roofPanel',
      'lRearFenderQuarter',
      'rRearFenderQuarter',
      'lRear',
      'rRear',
      'spareTire',
      'lFront',
      'jackTools',
      'rFront',
      'mirrors',
      'windshield',
      'flooring',
      'seats',
      'glass',
      'sideWindows',
      'tireInstallKit',
      'leftFronJack',
    ];
    var errors = {};
    photoCaptureFields.forEach((field) => {
      if (formValues[field] !== 'OK') {
        var photofield = field + 'Photo';
        if (formValues[photofield]?.photos.length === 0) {
          errors[field] = 'This is a required field';
        }
        if (formValues[photofield]?.photos.length > 0) {
          var photos = formValues[photofield]?.photos;
          photos.forEach((element) => {
            if (element.type !== 'image/png' && element.type !== 'image/jpg') {
              errors[field] = 'Invalid Image type (Accepts png, jpg)';
            }
          });
        }
      }
    });
    return errors;
  };

  const emailEVAFSubmit = useCallback(
    async (formValues) => {
      const oldVehicleData = formValues?.old || {};
      let oldVehiclePhotos = [];
      const oldVehicleDamageItems = {};
      Object.keys(fieldNames).forEach((field) => {
        oldVehicleDamageItems[field] = {
          fieldName: fieldNames[field],
          issues: oldVehicleData[field] !== 'OK',
          issueType:
            oldVehicleData[field] !== 'OK' ? oldVehicleData[field] : '',
        };
        if (
          oldVehicleData[field] !== 'OK' &&
          oldVehicleData[`${field}Photo`]?.photoData
        ) {
          oldVehiclePhotos = [
            ...oldVehiclePhotos,
            ...oldVehicleData[`${field}Photo`]?.photoData,
          ];
        }
      });

      const finalDataKey = evafData?.evafType === 'old-vehicle' ? 'old' : 'new';
      const finalData = formValues[finalDataKey];
      const driverName = `${finalData.driverFirstName} ${finalData.driverLastName}`;
      const driverSignature = finalData?.driverSignature;
      const marshallerName = `${currentUser.firstName} ${currentUser.lastName}`;
      const marshallerSignature = finalData?.marshallerSignature;
      const emailList = finalData?.email
        .split(',')
        .map((emailAddress) => emailAddress.trim());

      const submitData = {
        variables: {
          eVafEmailAndPdfInput: {
            oldLicensePlate: oldVehicleData?.oldLicensePlate,
            oldVIN: oldVehicleData?.oldVin,
            oldMileage: Number(oldVehicleData?.oldMileage),
            numberOfPlates: Number(oldVehicleData?.noOfLicensePlates),
            missingPlate: oldVehicleData?.missingSidePlate,
            numberOfKeys: Number(oldVehicleData?.noOfKeys),
            creditCardReturn: oldVehicleData?.creditCardReturned,
            oldVehicleDamageComment: oldVehicleData?.oldComments,
            ...oldVehicleDamageItems,
            oldDamagePhotos: oldVehiclePhotos || [],
            driver: driverName,
            signatureDriver: driverSignature,
            marshaller: marshallerName,
            signatureMarshaller: marshallerSignature,
            emailAddresses: emailList,
          },
        },
      };

      const { data } = await emailEVAF(submitData);
      if (data && data.emailEVAF) {
        if (onVerifySuccess) {
          onVerifySuccess();
          setIsloading(false);
        }
        setScreen('vehicle-selection');
      }
    },
    [
      currentUser,
      emailEVAF,
      onVerifySuccess,
      setScreen,
      evafData.evafType,
      fieldNames,
    ],
  );

  const submitHandler = useCallback(() => {
    setFormErrors({});
    setIsSubmitted(true);
    const errors = validateForm(formValues);
    if (errors) {
      setOldVehicleSuccess(false);
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      window.setTimeout(() => scrollToElement('.form-error'), 250);
      setRequiredFieldError(true);
    } else {
      if (isInsideTab) {
        setRequiredFieldError(false);
        setShowWarning(false);
        scrollToElement('.react-tabs');
        dispatch(
          setEvafData({
            ...evafData,
            old: {
              ...(evafData?.old || {}),
              ...formValues,
            },
          }),
        );
        setOldVehicleSuccess(true);
        setTabError(false);
      } else {
        if (!isInsideTab && !formValues?.marshallerSignature) {
          setShowMarshallerModal(true);
        } else {
          const finalData = {
            ...evafData,
            old: {
              ...(evafData?.old || {}),
              ...formValues,
            },
          };
          dispatch(setEvafData(finalData));
          setRequiredFieldError(false);
          setShowWarning(false);
          emailEVAFSubmit(finalData);
        }
      }
    }
  }, [
    formValues,
    dispatch,
    evafData,
    isInsideTab,
    setTabError,
    validateForm,
    emailEVAFSubmit,
  ]);

  useEffect(() => {
    const errors = validateForm(formValues);
    let canLeave = true;
    for (const [, error] of Object.entries(errors)) {
      if (error !== 'This is a required field') {
        canLeave = false;
      }
    }
    if (Object.entries(errors).length === 0) {
      setRequiredFieldError(false);
    }
    setCanLeaveOldTab(canLeave);
    if (isSubmitted && !callSubmitHandler) {
      if (Object.entries(errors).length !== Object.entries(formErrors).length) {
        setFormErrors(errors || {});
      }
      return;
    }
    if (setTabError && !callSubmitHandler) {
      if (Object.keys(errors).length > 0) {
        setTabError(true);
      } else {
        setTabError(false);
      }
    }
    if (callSubmitHandler && formValues.marshallerSignature) {
      submitHandler();
      setCallSubmitHandler(false);
    }
  }, [
    formValues,
    formErrors,
    isSubmitted,
    touchedFields,
    validateForm,
    setCanLeaveOldTab,
    callSubmitHandler,
    submitHandler,
    setCallSubmitHandler,
    setTabError,
  ]);

  return (
    <div className="grid-col">
      {oldVehicleSuccess && (
        <div className="margin-top-0 padding-bottom-3">
          <Alert
            data-testid="old-vehicle-success"
            type="success"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
            onClose={() => {
              setOldVehicleSuccess(false);
            }}
          >
            Data has been saved
          </Alert>
        </div>
      )}

      {requiredFieldError && (
        <div className="margin-top-3">
          <Alert
            data-testid="required-field-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
          >
            Please complete required fields
          </Alert>
        </div>
      )}

      {!isInsideTab && (
        <>
          <PageTitle
            title="eVAF"
            className={`${isDesktop ? 'margin-top-0' : ''}`}
          />
          <div className="vehicle-tab-heading padding-top-1">Old Vehicle</div>
        </>
      )}
      <p className="border-bottom border-base-light">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <Composition
        areas={`
          ${eVafFormCompleted ? `AlertMessages` : ``}
          MainForm
          ExteriorBodyCondition
          WheelCoversRimsandTireCondition
          InteriorAndGlassCondition
          Comments
          Email
          DriverInfo
          MarshallerInfo
          MarshallerButton
        `}
        areasMd={`
          ${eVafFormCompleted ? `AlertMessages AlertMessages` : ``}
          MainForm MainForm
          ExteriorBodyCondition ExteriorBodyCondition
          WheelCoversRimsandTireCondition WheelCoversRimsandTireCondition
          InteriorAndGlassCondition InteriorAndGlassCondition
          Comments Comments
          Email Email
          DriverInfo DriverInfo
          MarshallerInfo MarshallerInfo
          MarshallerButton MarshallerButton 
        `}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({
          AlertMessages,
          MainForm,
          ExteriorBodyCondition,
          WheelCoversRimsandTireCondition,
          InteriorAndGlassCondition,
          Comments,
          Email,
          DriverInfo,
          MarshallerInfo,
          MarshallerButton,
        }) => (
          <>
            <AlertMessages>
              {eVafFormCompleted && (
                <>
                  <Alert
                    className="margin-bottom-3"
                    type="success"
                    heading=""
                    noIcon={false}
                    validation={false}
                    focused={false}
                    showClose={true}
                    onClose={() => setEvafFormCompleted(false)}
                  >
                    The Electronic Vehicle Accountability form (eVAF) has been
                    signed and completed.
                  </Alert>
                </>
              )}
            </AlertMessages>
            <MainForm>
              <p className="tab-title margin-bottom-0 margin-top-0">
                Old Vehicle details
              </p>
              {formValues?.noOfLicensePlates !== '0' && (
                <LicensePlateField
                  id="oldLicensePlate"
                  initialValue={formValues?.oldLicensePlate || ''}
                  showErrors={formErrors?.oldLicensePlate}
                  required={true}
                  label="Old license plate"
                  scanner={true}
                  onChange={(plate) => {
                    handleFieldChange('oldLicensePlate', plate);
                  }}
                  onValidate={(isValid, errorTxt) => {
                    if (!isValid) {
                      setPlateError(errorTxt);
                    } else {
                      setPlateError('');
                    }
                  }}
                  onFocus={() => setTouched('oldLicensePlate', true)}
                  className="mobile-textbox margin-bottom-3"
                />
              )}
              <VinField
                id="oldVin"
                initialValue={formValues?.oldVin || ''}
                showErrors={formErrors?.oldVin}
                required={true}
                label="Old VIN"
                scanner={true}
                onChange={(vin) => {
                  handleFieldChange('oldVin', vin);
                }}
                onValidate={(isValid, errorTxt) => {
                  if (!isValid) {
                    setVinError(errorTxt);
                  } else {
                    setVinError('');
                  }
                }}
                onFocus={() => setTouched('oldVin', true)}
                className="mobile-textbox margin-bottom-3"
              />
              <div>
                <div
                  className={
                    formErrors?.noOfLicensePlates
                      ? 'margin-bottom-3 form-error'
                      : 'margin-bottom-3'
                  }
                >
                  <Label className="margin-top-1 margin-bottom-0" required>
                    <strong>Number of license plates</strong>
                  </Label>
                  {formErrors?.noOfLicensePlates && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.noOfLicensePlates}</strong>
                    </div>
                  )}
                  <SelectDropdown
                    id="noOfLicensePlates"
                    name="noOfLicensePlates"
                    disabled={false}
                    containerClassName="margin-top-0"
                    defaultValue=""
                    value={formValues?.noOfLicensePlates || ''}
                    onFocus={() => setTouched('noOfLicensePlates', true)}
                    onChange={(e) => {
                      handleFieldChange('noOfLicensePlates', e.target.value);
                      setTouched('noOfLicensePlates', true);
                    }}
                    options={numberOfLicensePlates}
                  />
                </div>
              </div>
              {(formValues?.noOfLicensePlates === '0' ||
                formValues?.noOfLicensePlates === '1') &&
                showWarning && (
                  <div>
                    <Alert
                      type="warning"
                      data-testid="deactivated-account-error"
                      focused
                    >
                      {formValues?.noOfLicensePlates === '0'
                        ? 'You selected 0 license plate. Please contact your FSR.'
                        : 'You only selected 1 license plate. Please contact your FSR if the second plate is missing.'}
                    </Alert>
                    {formValues?.noOfLicensePlates === '1' && (
                      <div
                        className={
                          formErrors?.missingSidePlate
                            ? 'margin-bottom-3 form-error'
                            : 'margin-bottom-3'
                        }
                      >
                        <Label
                          className="margin-top-1 margin-bottom-0"
                          required
                        >
                          <strong>Which plate from the set is missing</strong>
                        </Label>
                        {formErrors?.missingSidePlate && (
                          <div className="form-error-msg">
                            <strong>{formErrors?.missingSidePlate}</strong>
                          </div>
                        )}
                        <SelectDropdown
                          id="missingSidePlate"
                          name="missingSidePlate"
                          disabled={false}
                          containerClassName="margin-top-0"
                          defaultValue=""
                          value={formValues?.missingSidePlate || ''}
                          onFocus={() => setTouched('missingSidePlate', true)}
                          onChange={(e) => {
                            handleFieldChange(
                              'missingSidePlate',
                              e.target.value,
                            );
                          }}
                          options={numberOfPlateSide}
                        />
                      </div>
                    )}
                  </div>
                )}
              <div
                className={
                  formErrors?.oldMileage
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1" required>
                  <strong>Mileage</strong>
                </Label>
                {formErrors?.oldMileage && (
                  <div className="form-error-msg">
                    <strong>{formErrors?.oldMileage}</strong>
                  </div>
                )}
                <div className="input-box">
                  <Textbox
                    type="text"
                    id="oldMileage"
                    name="oldMileage"
                    value={formValues?.oldMileage || ''}
                    variant={formErrors?.oldMileage ? 'error' : ''}
                    onChange={(e) => {
                      handleFieldChange('oldMileage', e.target.value);
                    }}
                    className="mobile-textbox margin-top-0"
                  />
                </div>
              </div>
            </MainForm>
            <ExteriorBodyCondition>
              <p className="tab-title margin-bottom-0">
                Exterior Body Condition
              </p>
              {PhotoCaptureField({
                label: 'Front Bumper / Grill',
                area: 'frontBumpGrill',
                photoField: 'frontBumpGrillPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('frontBumpGrillPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
              {PhotoCaptureField({
                label: 'Left Front Fender/Quarter Panel',
                area: 'lFrontFenderQuarter',
                photoField: 'lFrontFenderQuarterPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('lFrontFenderQuarterPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
              {PhotoCaptureField({
                label: 'Right Front Fender/Quarter Panel',
                area: 'rFrontFenderQuarter',
                photoField: 'rFrontFenderQuarterPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('rFrontFenderQuarterPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
              {PhotoCaptureField({
                label: 'Left Side',
                area: 'leftSide',
                photoField: 'leftSidePhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('leftSidePhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Right Side',
                area: 'rightSide',
                photoField: 'rightSidePhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('rightSidePhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Hood Panel',
                area: 'hoodPanel',
                photoField: 'hoodPanelPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('hoodPanelPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Roof Panel',
                area: 'roofPanel',
                photoField: 'roofPanelPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('roofPanelPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Left Rear Fender/Quarter Panel',
                area: 'lRearFenderQuarter',
                photoField: 'lRearFenderQuarterPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('lRearFenderQuarterPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
              {PhotoCaptureField({
                label: 'Right Rear Fender/Quarter Panel',
                area: 'rRearFenderQuarter',
                photoField: 'rRearFenderQuarterPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('rRearFenderQuarterPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
            </ExteriorBodyCondition>
            <WheelCoversRimsandTireCondition>
              <p className="tab-title margin-bottom-0">
                Wheel Covers/Rims and Tire Condition
              </p>
              {PhotoCaptureField({
                label: 'Left Front',
                area: 'lFront',
                photoField: 'lFrontPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('lFrontPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Right Front',
                area: 'rFront',
                photoField: 'rFrontPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('rFrontPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Left Rear',
                area: 'lRear',
                photoField: 'lRearPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('lRearPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Right Rear',
                area: 'rRear',
                photoField: 'rRearPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('rRearPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Spare Tire',
                area: 'spareTire',
                photoField: 'spareTirePhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('spareTirePhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Tire Inflator Kit',
                area: 'tireInstallKit',
                photoField: 'tireInstallKitPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('tireInstallKitPhoto', {
                    photos,
                    photoData,
                  });
                },
              })}
              {PhotoCaptureField({
                label: 'Jack and Tools',
                area: 'jackTools',
                photoField: 'jackToolsPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('jackToolsPhoto', { photos, photoData });
                },
              })}
            </WheelCoversRimsandTireCondition>
            <InteriorAndGlassCondition>
              <p className="tab-title margin-bottom-0">
                Interior and Glass Condition
              </p>
              {PhotoCaptureField({
                label: 'Seats',
                area: 'seats',
                photoField: 'seatsPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('seatsPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Glass',
                area: 'glass',
                photoField: 'glassPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('glassPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Side Window(s)',
                area: 'sideWindows',
                photoField: 'sideWindowsPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('sideWindowsPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Flooring',
                area: 'flooring',
                photoField: 'flooringPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('flooringPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Windshield',
                area: 'windshield',
                photoField: 'windshieldPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('windshieldPhoto', { photos, photoData });
                },
              })}
              {PhotoCaptureField({
                label: 'Mirrors',
                area: 'mirrors',
                photoField: 'mirrorsPhoto',
                formValues: formValues,
                formErrors: formErrors,
                onCapture: ({ photos, photoData }) => {
                  handleFieldChange('mirrorsPhoto', { photos, photoData });
                },
              })}
            </InteriorAndGlassCondition>
            <Comments>
              <div className="border-top">
                <div
                  className={
                    formErrors?.noOfKeys
                      ? 'margin-bottom-3 form-error'
                      : 'margin-bottom-3'
                  }
                >
                  <Label className="margin-top-1 margin-bottom-3" required>
                    <strong>Number of keys</strong>
                  </Label>
                  {formErrors?.noOfKeys && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.noOfKeys}</strong>
                    </div>
                  )}
                  <SelectDropdown
                    id="noOfKeys"
                    name="noOfKeys"
                    disabled={false}
                    containerClassName="margin-top-0"
                    defaultValue=""
                    value={formValues?.noOfKeys || ''}
                    onFocus={() => setTouched('noOfKeys', true)}
                    onChange={(e) => {
                      handleFieldChange('noOfKeys', e.target.value);
                      setTouched('noOfKeys', true);
                    }}
                    options={numberOfKeys}
                  />
                </div>
              </div>
              <div className="margin-top-3 margin-bottom-2">
                <Checkbox
                  className="damage-select"
                  id="creditCardReturned"
                  name="creditCardReturned"
                  label="Credit card returned"
                  value={formValues?.creditCardReturned}
                  checked={formValues?.creditCardReturned || false}
                  onChange={(e) => {
                    handleFieldChange('creditCardReturned', e.target.checked);
                  }}
                />
              </div>
              <div className="margin-top-3 margin-bottom-2">
                <IsCorrectField
                  error={formErrors['oldComments'] || ''}
                  label={<span>Comments:</span>}
                  defaultValue=""
                  currentValue={formValues?.oldComments}
                  fieldName="oldComments"
                  onFocus={() => setTouched('oldComments', true)}
                  onChange={(e) => {}}
                  isRequired={false}
                >
                  <Textbox
                    id="oldComments"
                    name="oldComments"
                    type="textarea"
                    data-testid="oldComments"
                    size="medium"
                    value={formValues?.oldComments || ''}
                    onFocus={() => setTouched('oldComments', true)}
                    onChange={(e) => {
                      handleFieldChange('oldComments', e.target.value);
                      const textLeft = 1000 - Number(e.target.value.length);
                      const charBox = document.getElementById('comments-chars');
                      const allChars = charBox.parentNode;
                      if (charBox) {
                        if (textLeft < 1000) {
                          charBox.innerHTML =
                            (textLeft >= 0 ? textLeft : 0) + '/';
                          if (textLeft < 0) {
                            if (allChars.className.indexOf('error') === -1) {
                              allChars.className += ' error';
                            }
                          } else {
                            allChars.className = allChars.className
                              .split(' ')
                              .filter((classItem) => classItem !== 'error')
                              .join(' ');
                          }
                        } else {
                          charBox.innerHTML = '';
                          allChars.className = allChars.className
                            .split(' ')
                            .filter((classItem) => classItem !== 'error')
                            .join(' ');
                        }
                      }
                    }}
                  />
                  <div className="textbox-note">
                    <span
                      id={`comments-chars`}
                      data-testid="comments-chars"
                    ></span>
                    1000 characters allowed
                  </div>
                </IsCorrectField>
              </div>
            </Comments>
            <div style={{ background: '#A9AEB1', height: 1 }} />
            {!isInsideTab && (
              <>
                <Email>
                  <div
                    className={
                      formErrors?.email
                        ? 'margin-bottom-3 form-error'
                        : 'margin-bottom-3'
                    }
                  >
                    <Label className="margin-top-1" required>
                      <strong>Email</strong>
                    </Label>
                    {formErrors?.email && (
                      <div className="form-error-msg">
                        <strong>{formErrors?.email}</strong>
                      </div>
                    )}
                    <div className="input-box">
                      <Textbox
                        type="text"
                        id="email"
                        name="email"
                        value={formValues?.email || ''}
                        variant={formErrors?.email ? 'error' : ''}
                        onChange={(e) => {
                          handleFieldChange('email', e.target.value);
                        }}
                        onFocus={() => setTouched('email', true)}
                        className="mobile-textbox margin-top-0"
                      />
                    </div>
                  </div>
                </Email>
                <DriverInfo>
                  <div
                    className={
                      formErrors?.driverFirstName
                        ? 'margin-bottom-3 form-error'
                        : 'margin-bottom-3'
                    }
                  >
                    <Label className="margin-top-1" required>
                      <strong>Driver's First Name</strong>
                    </Label>
                    {formErrors?.driverFirstName && (
                      <div className="form-error-msg">
                        <strong>{formErrors?.driverFirstName}</strong>
                      </div>
                    )}
                    <div className="input-box">
                      <Textbox
                        type="text"
                        id="driverFirstName"
                        name="driverFirstName"
                        value={formValues?.driverFirstName || ''}
                        variant={formErrors?.driverFirstName ? 'error' : ''}
                        onChange={(e) => {
                          handleFieldChange('driverFirstName', e.target.value);
                        }}
                        onFocus={() => setTouched('driverFirstName', true)}
                        className="mobile-textbox margin-top-0"
                      />
                    </div>
                  </div>
                  <div
                    className={
                      formErrors?.driverLastName
                        ? 'margin-bottom-3 form-error'
                        : 'margin-bottom-3'
                    }
                  >
                    <Label className="margin-top-1" required>
                      <strong>Driver's Last Name</strong>
                    </Label>
                    {formErrors?.driverLastName && (
                      <div className="form-error-msg">
                        <strong>{formErrors?.driverLastName}</strong>
                      </div>
                    )}
                    <div className="input-box">
                      <Textbox
                        type="text"
                        id="driverLastName"
                        name="driverLastName"
                        value={formValues?.driverLastName || ''}
                        variant={formErrors?.driverLastName ? 'error' : ''}
                        onChange={(e) => {
                          handleFieldChange('driverLastName', e.target.value);
                        }}
                        onFocus={() => setTouched('driverLastName', true)}
                        className="mobile-textbox margin-top-0"
                      />
                    </div>
                  </div>
                  <div
                    className={formErrors?.driverSignature ? 'form-error' : ''}
                  >
                    {formErrors?.driverSignature && (
                      <div className="form-error-msg">
                        <strong>{formErrors?.driverSignature}</strong>
                      </div>
                    )}
                    <Button
                      className="icon-buttowidth-full"
                      data-testid="continue-button"
                      size={isDesktop ? 'medium' : 'large'}
                      label={
                        formValues?.driverSignature
                          ? 'Signature accepted'
                          : 'Driver Sign'
                      }
                      disabled={formValues?.driverSignature}
                      onClick={() => {
                        setTouched('driverSignature', true);
                        setFormErrors({});
                        setIsSubmitted(true);
                        const errors = validateForm(formValues);
                        if (
                          Object.keys(errors).length > 1 &&
                          errors.driverSignature !== ''
                        ) {
                          setFormErrors(errors);
                          window.setTimeout(
                            () => scrollToElement('.form-error'),
                            250,
                          );
                          setRequiredFieldError(true);
                        } else {
                          setShowDriverModal(true);
                        }
                      }}
                    />
                  </div>
                  <SignatureModal
                    show={showDriverModal}
                    title="Driver sign eVAF"
                    onCancel={() => {
                      setShowDriverModal(false);
                    }}
                    onSave={(dataUrl) => {
                      handleFieldChange('driverSignature', dataUrl);
                      setShowDriverModal(false);
                    }}
                  />
                </DriverInfo>
                <MarshallerInfo>
                  <div className="bold">Marshaller</div>
                  <div>{`${currentUser?.firstName} ${currentUser?.lastName}`}</div>
                </MarshallerInfo>
              </>
            )}
            <MarshallerButton>
              <LoadingButton
                className="icon-buttowidth-full"
                data-testid="continue-button"
                size={isDesktop ? 'medium' : 'large'}
                label={!isInsideTab ? 'Marshaller Sign & Submit' : 'Save'}
                loading={isloading}
                onClick={submitHandler}
              />
              <SignatureModal
                show={showMarshallerModal}
                title="Marshaller sign eVAF"
                onCancel={() => {
                  setShowMarshallerModal(false);
                }}
                onSave={(dataUrl) => {
                  setIsloading(true);
                  handleFieldChange('marshallerSignature', dataUrl);
                  setShowMarshallerModal(false);
                  setCallSubmitHandler(true);
                }}
              />
              <div className="padding-bottom-2 padding-top-2">
                <Button
                  className="width-full"
                  data-testid="cancel-button"
                  variant="outline"
                  size={isDesktop ? 'medium' : 'large'}
                  label="Cancel"
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
                <AreYouSureModal
                  title="Are you sure you want to delete this eVAF?"
                  show={showModal}
                  showConfirm={true}
                  showCancel={true}
                  confirmLabel="Yes, Delete eVAF"
                  cancelLabel="Cancel"
                  onConfirm={() => {
                    dispatch(clearEvafData());
                    setShowModal(false);
                    history.push('/home');
                    scrollToTop();
                  }}
                  onCancel={() => setShowModal(false)}
                >
                  The data from this eVAF will not be saved.
                </AreYouSureModal>
              </div>
            </MarshallerButton>
          </>
        )}
      </Composition>
    </div>
  );
};

export default OldVehicle;
