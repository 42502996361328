import React from 'react';
import { Button, Spinner } from '@gsa/afp-component-library';
import './FormInputs.css';

export const LoadingButton = ({
  label,
  variant = 'primary',
  disabled = false,
  loading = false,
  onClick,
  size,
}) => {
  const spinnerLabel = (
    <>
      <span className="original-label" data-testid="spinner-label">
        {label}
      </span>
      <Spinner size="small" />
    </>
  );

  return (
    <Button
      data-testid="loading-button"
      className={`loading-button${loading ? ' loading' : ''}`}
      label={loading ? spinnerLabel : label}
      disabled={disabled || loading}
      variant={variant}
      size={size}
      onClick={() => {
        if (typeof onClick === 'function' && !(disabled || loading)) {
          onClick();
        }
      }}
    />
  );
};

export default LoadingButton;
