import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import {
  FindVehicle,
  Telematics,
  TelematicsNotRequired,
  CompleteDelivery,
} from './screens';

export const TelematicsInstall = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const [screen, setScreen] = useState('find-vehicle');
  const [vehicle, setVehicle] = useState(null);
  const [errorCode, setErrorCode] = useState('');
  const [vehicleSuccess, setVehicleSuccess] = useState(false);
  const [showTelematicsInstallSuccess, setTelematicsInstallSuccess] =
    useState(false);

  // The screen changes weren't going to the top of the page, this handles that.
  useEffect(() => {
    window.scrollTo(0, 0);
    if (screen === 'find-vehicle') {
      setVehicleSuccess(false);
    }
  }, [screen]);

  const onInstallSuccess = () => {
    setScreen('find-vehicle');
    setTelematicsInstallSuccess(true);
  };

  const onInstallError = () => {};

  return (
    <div
      className={`grid-col margin-bottom-2${isDesktop ? ' slim-width' : ''}`}
    >
      <Breadcrumbs
        items={[
          { path: '/home', text: 'Home' },
          { text: 'Telematics Installation' },
        ]}
      />
      {screen === 'find-vehicle' && (
        <FindVehicle
          setScreen={setScreen}
          setVehicle={setVehicle}
          vehicle={vehicle}
          vehicleSuccess={vehicleSuccess}
          setVehicleSuccess={setVehicleSuccess}
          showTelematicsInstallSuccess={showTelematicsInstallSuccess}
          setErrorCode={setErrorCode}
          setshowTelematicsInstallSuccess={setTelematicsInstallSuccess}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'telematics-installation' && (
        <Telematics
          setScreen={setScreen}
          vehicle={vehicle}
          isDesktop={isDesktop}
          setVehicle={setVehicle}
          onInstallSuccess={onInstallSuccess}
          onInstallError={onInstallError}
        />
      )}
      {screen === 'not-required' && (
        <TelematicsNotRequired
          setScreen={setScreen}
          vehicle={vehicle}
          errorMessage={errorCode}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'complete-telematics-install' && (
        <CompleteDelivery
          setScreen={setScreen}
          vehicle={vehicle}
          messageCode={vehicle.status}
          isDesktop={isDesktop}
        />
      )}
    </div>
  );
};

export default TelematicsInstall;
