import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Composition } from 'atomic-layout';
import './SignatureCapture.css';

export const SignatureCapture = ({ onSave, onCancel }) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseMoving, setMouseMoving] = useState(false);
  const [pos, setPos] = useState({ x: null, y: null });
  const [canSave, setCanSave] = useState(false);
  const prevPos = usePrevious(pos);
  const canvasRef = useRef(null);

  const onMouseUp = () => {
    setMouseDown(false);
    setMouseMoving(false);
  };

  const onMouseDown = () => {
    setMouseDown(true);
  };

  const onMouseMove = useCallback(
    (ev) => {
      ev.preventDefault();
      if (mouseDown) {
        let x = ev.offsetX;
        let y = ev.offsetY;
        if (ev.type === 'touchmove') {
          const touchData = ev.targetTouches[0];
          const canvasData = canvasRef.current.getBoundingClientRect();
          x = touchData.clientX - canvasData.left;
          y = touchData.clientY - canvasData.top;
        }
        setPos({ x, y });
        setMouseMoving(true);
      }
    },
    [mouseDown],
  );

  const initialize = () => {
    const context = canvasRef.current.getContext('2d');
    canvasRef.current.width = canvasRef.current.offsetWidth;
    canvasRef.current.height = canvasRef.current.offsetHeight;
    if (context) {
      context.fillStyle = '#fff';
      context.strokeStyle = '#000';
      context.lineWidth = 2;
      context.lineCap = 'round';
      context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  };

  useEffect(() => {
    initialize();
    window.addEventListener('resize', initialize, false);

    return () => {
      window.removeEventListener('resize', initialize, false);
    };
  }, []);

  useEffect(() => {
    canvasRef.current.addEventListener('mousemove', onMouseMove, false);
    canvasRef.current.addEventListener('touchmove', onMouseMove, false);
    const latestCanvas = canvasRef.current;
    document.body.addEventListener('mouseup', onMouseUp, false);
    document.body.addEventListener('touchend', onMouseUp, false);
    document.body.addEventListener('mousedown', onMouseDown, false);
    document.body.addEventListener('touchstart', onMouseDown, false);

    return () => {
      latestCanvas.removeEventListener('mousemove', onMouseMove, false);
      latestCanvas.removeEventListener('touchmove', onMouseMove, false);
      document.body.removeEventListener('mouseup', onMouseUp, false);
      document.body.removeEventListener('touchend', onMouseUp, false);
      document.body.removeEventListener('mousedown', onMouseDown, false);
      document.body.removeEventListener('touchstart', onMouseDown, false);
    };
  }, [onMouseMove]);

  const draw = (prevPos, pos) => {
    const context = canvasRef.current.getContext('2d');
    context.quadraticCurveTo(prevPos.x, prevPos.y, pos.x, pos.y);
    context.stroke();
    context.beginPath();
    context.moveTo(pos.x, pos.y);
  };

  useEffect(() => {
    const context = canvasRef.current.getContext('2d');
    if (context) {
      if (!mouseDown) {
        context.stroke();
      } else {
        context.beginPath();
      }
    }
  }, [mouseDown, pos]);

  useEffect(() => {
    if (mouseMoving) {
      draw(prevPos, pos);
      if (!canSave) {
        setCanSave(true);
      }
    }
  }, [mouseMoving, pos, prevPos, canSave]);

  const signatureSave = () => {
    if (typeof onSave === 'function') {
      const dataURL = canvasRef.current.toDataURL('image/png');
      onSave(dataURL);
    }
  };

  const signatureClear = () => {
    const context = canvasRef.current.getContext('2d');
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setCanSave(false);
  };

  return (
    <div className="signature-capture" data-testid="signature-capture">
      <Composition
        areas={`
          Canvas Canvas Canvas
          CancelBtn ClearBtn SaveBtn
        `}
        gap={8}
      >
        {({ Canvas, CancelBtn, ClearBtn, SaveBtn }) => (
          <>
            <Canvas>
              <canvas ref={canvasRef} />
            </Canvas>
            <CancelBtn>
              <div className="centered">
                <span
                  data-testid="cancel-button"
                  className="cancel"
                  onClick={() => {
                    if (typeof onCancel === 'function') {
                      onCancel();
                    }
                  }}
                >
                  Cancel
                </span>
              </div>
            </CancelBtn>
            <ClearBtn>
              <Button
                data-testid="clear-btn"
                className="margin-right-0 full-width"
                label="Clear"
                variant="outline"
                size="medium"
                onClick={() => {
                  signatureClear();
                }}
              />
            </ClearBtn>
            <SaveBtn>
              <Button
                data-testid="save-btn"
                className="margin-right-0 full-width"
                label="Save signature"
                variant="primary"
                size="medium"
                onClick={() => {
                  if (canSave) {
                    signatureSave();
                  }
                }}
              />
            </SaveBtn>
          </>
        )}
      </Composition>
    </div>
  );
};

export default SignatureCapture;
