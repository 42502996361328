import { gql } from '@apollo/client';

export const GET_MOCKUP_VEHICLE = gql`
  query getVehicleMockUpData($id: String, $vin: String, $orderNumber: String) {
    getVehicleMockUpData(id: $id, vin: $vin, orderNumber: $orderNumber) {
      vin
      orderNumber
      orderId
      make
      model
      year
      color
      id
      vehicleClass
      acquisitionMileage
      vehicleTrim
      cylinders
      transmission
      fuelType
      fuelCapacity
      tireSize
      gvwr
      engineDisplacement
      vehicleOptions
      status
      telematicsRequired
      telematicsInstalled
      standardItems
    }
  }
`;

export const GET_PLATE = gql`
  query getPlate(
    $plate: String
    $tagClass: String
    $tag: String
    $status: String
  ) {
    getPlate(plate: $plate, tagClass: $tagClass, tag: $tag, status: $status) {
      class
      tag
      expirationDate
      status
    }
  }
`;

export const UPLOAD_TELEMETRY = gql`
  mutation uploadTelemetry($telemetryObject: TelemetryInput!) {
    uploadTelemetry(telemetryObject: $telemetryObject)
  }
`;

export const COMPLETE_DELIVERY_SESSION = gql`
  mutation completeDelivery($data: DeliveryInputs!) {
    completeDelivery(data: $data)
  }
`;

export const CREATE_DAMAGE_IN_TRANSIT = gql`
  mutation AddDamageInTransit($damageInTransit: DamageInTransitInput!) {
    addDamageInTransit(damageInTransit: $damageInTransit) {
      id
    }
  }
`;

export const COMPLETE_LOAD_SESSION = gql`
  mutation completeVehicleLoad($data: VehicleLoadInput!) {
    completeVehicleLoad(data: $data) {
      success
      errorCode
      errorMessage
    }
  }
`;

export const GEOTAB_INSTALL_STATUS = gql`
  query checkGeoTabInstallStatus(
    $logInstallParams: GeoTabLogInstallAPIRequestParams!
  ) {
    checkGeoTabInstallStatus(logInstallParams: $logInstallParams) {
      logInstallSuccess
      errorCode
      errorMessage
      resultDate
      requestDate
      lastServerCommunication
      simActive
      comments
      possibleIssues
    }
  }
`;

export const PLATE_RECONCILIATION_COMPLETE = gql`
  mutation reconcilePlates(
    $platesReconcileAndDestructInput: PlatesReconcileAndDestructInput!
  ) {
    reconcilePlates(
      platesReconcileAndDestructInput: $platesReconcileAndDestructInput
    ) {
      globalSuccessStatus
      globalErrorCode
      globalErrorMessage
      plateStatuses {
        plateNo
        expMonth
        expYear
        plateAOrB
        plateSuccessStatus
        plateErrorCode
        plateErrorMessage
      }
    }
  }
`;

export const PLATE_DESTROY_COMPLETE = gql`
  mutation destroyPlates(
    $platesReconcileAndDestructInput: PlatesReconcileAndDestructInput!
  ) {
    destroyPlates(
      platesReconcileAndDestructInput: $platesReconcileAndDestructInput
    ) {
      globalSuccessStatus
      globalErrorCode
      globalErrorMessage
      plateStatuses {
        plateNo
        expMonth
        expYear
        plateAOrB
        plateSuccessStatus
        plateErrorCode
        plateErrorMessage
      }
    }
  }
`;

export const GET_FSR_OPTIONS = gql`
  query GetFsrOptions($zoneId: String!) {
    getFsrOptions(zoneId: $zoneId) {
      locations {
        vendorId
        locationId
        name
        zoneId
        address1
        address2
        address3
        city
        stateId
        postalCode
        countryId
        contactPhoneCountryCode
        contactPhone
        contactPhoneExtension
      }
      fmc {
        id
        name
        address1
        address2
        address3
        city
        stateCode
        countryCode
      }
    }
  }
`;

export const GET_MARSHALLER_VENDOR = gql`
  query GetMarshallerVendor {
    getMarshallerVendor {
      success
      errorCode
      vendorId
      vendorName
    }
  }
`;

export const EMAIL_EVAF = gql`
  mutation EmailEVAF($eVafEmailAndPdfInput: eVAFEmailInput!) {
    emailEVAF(eVAFEmailAndPDFInput: $eVafEmailAndPdfInput) {
      eVAFStatus
      globalErrorCode
      globalErrorMessage
      globalSuccessStatus
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query GetAppointments(
    $offset: Int
    $limit: Int
    $order: OrderBy
    $filter: [Filter!]
  ) {
    getAppointments(
      offset: $offset
      limit: $limit
      order: $order
      filter: $filter
    ) {
      appointmentSchedulerId
      appointmentStartDate
      appointmentEndDate
      appointmentType
      vendorId
      oldAssetId
      newAssetId
      status
      notificationDate
      noOfReminders
      scheduledByName
      scheduledByEmailAddress
      scheduledByPhone
      driverName
      driverEmailAddress
      driverPhone
      tokenId
      comments
      createdAt
      createdByUser
      updatedAt
      updatedByUser
      deletedAt
      dmlFlag
      dataSource
    }
  }
`;
