import React, { useEffect, useState } from 'react';
import { Tabs } from 'components';
import { Alert } from '@gsa/afp-component-library';
import { PageTitle } from '@gsa/afp-component-library';
import OldVehicle from '../OldVehicle';
import NewVehicle from '../NewVehicle';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { setEvafData } from 'reducers/marshalling';
import '../screens.css';

export const ExchangeVehicle = ({ setScreen, isDesktop, onVerifySuccess }) => {
  const dispatch = useDispatch();
  const evafData = useSelector((state) => state?.marshalling?.evaf || {});
  const [selectedTab, setSelectedTab] = useState('oldVehicle');
  const [oldVehicleTabError, setOldVehicleTabError] = useState(false);
  const [newVehicleTabError, setNewVehicleTabError] = useState(false);
  const prevOldTabError = usePrevious(oldVehicleTabError);
  const prevNewTabError = usePrevious(newVehicleTabError);
  const [canLeaveOldTab, setCanLeaveOldTab] = useState(true);
  const [canLeaveNewTab, setCanLeaveNewTab] = useState(true);
  const [tabErrors, setTabErrors] = useState([]);
  const [oldFormValues, setOldFormValues] = useState({});
  const [newFormValues, setNewFormValues] = useState({});
  const [oldTrigger, setOldTrigger] = useState(0);
  const [newTrigger, setNewTrigger] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onOldFormValueUpdate = (formValues) => {
    setOldFormValues(formValues);
    setNewFormValues({
      ...newFormValues,
      driverSignature: '',
    });
  };
  const tabChange = (tabId) => {
    if (selectedTab !== tabId) {
      if (
        (selectedTab === 'oldVehicle' && canLeaveOldTab) ||
        (selectedTab === 'newVehicle' && canLeaveNewTab)
      ) {
        let tabErrorsNew = [...tabErrors];
        if (oldVehicleTabError && !tabErrorsNew.includes('Old Vehicle')) {
          tabErrorsNew.push('Old Vehicle');
        } else if (!oldVehicleTabError) {
          tabErrorsNew = tabErrorsNew.filter((err) => err !== 'Old Vehicle');
        }
        if (newVehicleTabError && !tabErrorsNew.includes('New Vehicle')) {
          tabErrorsNew.push('New Vehicle');
        } else if (!newVehicleTabError) {
          tabErrorsNew = tabErrorsNew.filter((err) => err !== 'New Vehicle');
        }
        if (tabId === 'newVehicle' && tabErrorsNew.includes('New Vehicle')) {
          tabErrorsNew = tabErrorsNew.filter((err) => err !== 'New Vehicle');
        }
        setTabErrors(tabErrorsNew);
        dispatch(
          setEvafData({
            ...evafData,
            old: {
              ...(evafData?.old || {}),
              ...oldFormValues,
            },
            new: {
              ...(evafData?.new || {}),
              ...newFormValues,
            },
          }),
        );
        setSelectedTab(tabId);
      } else {
        // trigger validations
        if (selectedTab === 'oldVehicle') {
          setOldTrigger(oldTrigger + 1);
        } else {
          setNewTrigger(newTrigger + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (
      prevOldTabError !== oldVehicleTabError ||
      prevNewTabError !== newVehicleTabError
    ) {
      let tabErrorsNew = [...tabErrors];
      if (!oldVehicleTabError && tabErrors.includes('Old Vehicle')) {
        tabErrorsNew = tabErrorsNew.filter((err) => err !== 'Old Vehicle');
      }
      if (!newVehicleTabError && tabErrors.includes('New Vehicle')) {
        tabErrorsNew = tabErrorsNew.filter((err) => err !== 'New Vehicle');
      }
      setTabErrors(tabErrorsNew);
    }
  }, [
    oldVehicleTabError,
    newVehicleTabError,
    tabErrors,
    prevOldTabError,
    prevNewTabError,
  ]);

  return (
    <div className="grid-col">
      <div className="evaf-title">
        <PageTitle title="eVAF" />
      </div>
      {tabErrors.length > 0 && (
        <div className="padding-bottom-3">
          <Alert
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            <div>Please complete all required fields.</div>
            {tabErrors.map((tab, i) => {
              return (
                <div key={i} className="margin-top-1">
                  Errors: {tab} Tab
                </div>
              );
            })}
          </Alert>
        </div>
      )}
      <Tabs
        selectedId={selectedTab}
        tabConfig={[
          {
            id: 'oldVehicle',
            heading: 'Old Vehicle',
            content: (
              <OldVehicle
                setScreen={setScreen}
                isDesktop={isDesktop}
                isInsideTab={true}
                setTabError={(val) => {
                  setOldVehicleTabError(val);
                }}
                prevFormValues={oldFormValues}
                onSetFormValues={onOldFormValueUpdate}
                setCanLeaveOldTab={setCanLeaveOldTab}
                trigger={oldTrigger}
              />
            ),
          },
          {
            id: 'newVehicle',
            heading: 'New Vehicle',
            content: (
              <NewVehicle
                setScreen={setScreen}
                isInsideTab={true}
                isDesktop={isDesktop}
                setTabError={(val) => {
                  setNewVehicleTabError(val);
                }}
                onVerifySuccess={onVerifySuccess}
                prevFormValues={newFormValues}
                oldVehicleTabError={oldVehicleTabError}
                onSetFormValues={setNewFormValues}
                setCanLeaveNewTab={setCanLeaveNewTab}
                isSubmittedInsideExchange={isSubmitted}
                setSubmitedInsideExchange={setIsSubmitted}
                trigger={newTrigger}
              />
            ),
          },
        ]}
        onTabChange={(tab) => {
          tabChange(tab);
        }}
      />
    </div>
  );
};

export default ExchangeVehicle;
