import React, { useState, useEffect } from 'react';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { Scanner, AreYouSureModal } from 'components';
import { Composition, Box } from 'atomic-layout';
import { Label, Textbox, Button, Alert } from '@gsa/afp-component-library';
import { useSync } from 'hooks';
import { setLoadedVehicleData } from 'reducers/marshalling';
import { PageTitle } from '@gsa/afp-component-library';
import { formatVehicleTitle } from 'utils';
import '../screens.css';

export const Telematics = ({
  setScreen,
  vehicle,
  setVehicle,
  onInstallSuccess,
  onInstallError,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { geoTabInstallStatus } = useSync();
  const [geoTabQuery, { data: geoTabData }] = geoTabInstallStatus;
  const { currentUser } = useCurrentUser();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [scannerOpen, setScannerOpen] = useState(false);
  const [telematicsSuccess, setTelematicsSuccess] = useState(
    vehicleLoadData?.telematicsSuccess || false,
  );
  const [geoTabError, setGeoTabError] = useState(null);
  const [geoTabResponse, setGeoTabResponse] = useState(null);
  const [geoTabDataValid, setGeoTabDataValid] = useState(false);
  const [geoTabRequested, setGeoTabRequested] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (geoTabRequested) {
      if (!telematicsSuccess && geoTabResponse && geoTabDataValid) {
        if (geoTabData?.checkGeoTabInstallStatus?.logInstallSuccess === true) {
          setTelematicsSuccess(true);
          setGeoTabError(null);
          setGeoTabRequested(false);
          setVehicle(vehicle);
          setFormValues({});
          onInstallSuccess();
          dispatch(
            setLoadedVehicleData({
              telematicsErrorCode: null,
              telematicsSN: '',
              currentMileage: '',
            }),
          );
        } else {
          setTelematicsSuccess(false);
          onInstallError();
          setGeoTabError(geoTabData?.checkGeoTabInstallStatus?.errorCode);
          dispatch(
            setLoadedVehicleData({
              telematicsErrorCode:
                geoTabData?.checkGeoTabInstallStatus?.errorCode,
            }),
          );
        }
      }
      if (geoTabResponse === null && geoTabData?.checkGeoTabInstallStatus) {
        setGeoTabDataValid(true);
        setGeoTabResponse(geoTabData.checkGeoTabInstallStatus);
      }
    }
  }, [
    geoTabData,
    telematicsSuccess,
    geoTabResponse,
    onInstallSuccess,
    onInstallError,
    geoTabDataValid,
    dispatch,
    geoTabRequested,
    setVehicle,
    vehicle,
  ]);

  useEffect(() => {
    if (vehicleLoadData.telematicsSN) {
      dispatch(
        setLoadedVehicleData({
          telematicsSuccess,
        }),
      );
    }
  }, [telematicsSuccess, vehicleLoadData.telematicsSN, dispatch]);

  const validateForm = (formValues, vehicle) => {
    const errors = {};
    if (vehicle?.telematicsRequired) {
      if (formValues?.telematicsSN === '' || !formValues?.telematicsSN) {
        errors.telematicsSN = 'This is a required field';
      }

      if (formValues?.currentMileage === '' || !formValues?.currentMileage) {
        errors.currentMileage = 'This is a required field';
      }

      if (
        formValues?.currentMileage &&
        isNaN(Number(formValues?.currentMileage))
      ) {
        errors.currentMileage = 'Must be a numeric value';
      }
    }
    return errors;
  };

  const doGeoTab = (vehicle, formValues, currentUser) => {
    setGeoTabResponse(null);
    setGeoTabDataValid(false);
    setGeoTabRequested(true);
    geoTabQuery({
      variables: {
        logInstallParams: {
          vin: vehicle.vin,
          telematicsDeviceSerialNumber: formValues.telematicsSN,
          installerCompany: `${currentUser.firstName} ${currentUser.lastName}`,
          installerName: `${currentUser.firstName} ${currentUser.lastName}`,
          odometer: Number(formValues.currentMileage),
          comments: 'PWA Submission',
        },
      },
    });
  };

  const submitHandler = () => {
    setFormErrors({});
    const errors = validateForm(formValues, vehicle);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      if (
        !telematicsSuccess &&
        formValues?.telematicsSN &&
        formValues?.currentMileage &&
        !geoTabRequested
      ) {
        doGeoTab(vehicle, formValues, currentUser);
      } else {
        dispatch(setLoadedVehicleData(formValues));
      }
    }
  };

  useEffect(() => {
    if (Object.keys(vehicleLoadData).length > 0) {
      setFormValues(vehicleLoadData);
    }
  }, [vehicleLoadData]);

  useEffect(() => {
    if (
      formValues.telematicsSN &&
      vehicleLoadData.telematicsSN &&
      vehicleLoadData.telematicsSN !== formValues.telematicsSN
    ) {
      setGeoTabDataValid(false);
      setTelematicsSuccess(false);
      onInstallError();
      dispatch(
        setLoadedVehicleData({
          telematicsSN: formValues.telematicsSN,
          telematicsErrorCode: geoTabError,
        }),
      );
    }
  }, [
    formValues.telematicsSN,
    vehicleLoadData.telematicsSN,
    geoTabError,
    dispatch,
    onInstallError,
  ]);

  const parseGeoTabError = (errorCode) => {
    let errorMessage = '';
    switch (errorCode) {
      case 'GEOTAB_DEVICE_NOT_COMMUNICATING':
        errorMessage =
          'The installed device is not actively communicating. Please ensure all lights are on and the device is securely installed/fastened. Initialization should complete when the vehicle is driven for 10-15 minutes.';
        break;
      case 'GEOTAB_INVALID_SERIAL_NUMBER':
        errorMessage = (
          <>
            The Serial Number is either invalid and/or does not exist, please
            report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      case 'GEOTAB_NO_GSA_ACCOUNT':
        errorMessage = (
          <>
            The Serial Number entered is not assigned to the GSA account, please
            report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      case 'GEOTAB_NO_RECENT_COMMUNICATION':
        errorMessage = (
          <>
            The installed device last communicated{' '}
            {geoTabResponse.lastServerCommunication}. Please ensure all lights
            on the device are solid, not blinking, and report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      default:
        errorMessage = (
          <>
            An unknown error occurred during telematics device check. If the
            issue persists, please report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
    }
    return errorMessage;
  };

  return (
    <div className="grid-col">
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            setFormValues({
              ...formValues,
              telematicsSN: result,
            });
          }}
          onClose={() => {
            setScannerOpen(false);
          }}
        />
      )}
      <Composition
        areas={`Title`}
        gap={8}
        templateCols="auto"
        templateRows="auto"
        alignItems="center"
      >
        {({ Title }) => (
          <>
            <Title>
              <PageTitle title="Telematics Installation" />
            </Title>
          </>
        )}
      </Composition>
      <div>
        <strong>{formatVehicleTitle(vehicle)}</strong>
      </div>
      <div>
        <strong>VIN: {vehicle.vin}</strong>
      </div>
      <div>
        <strong>RPN/Order Number: {vehicle.orderNumber}</strong>
      </div>
      <p className="border-bottom border-base-light padding-bottom-3">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <div className="padding-bottom-0">
        {geoTabError && (
          <Alert
            className="margin-bottom-3"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            {parseGeoTabError(geoTabError)}
          </Alert>
        )}
        {!vehicle?.telematicsRequired && (
          <p>This vehicle does not require telematics device installation.</p>
        )}
        {vehicle?.telematicsRequired && (
          <>
            <div
              className={
                formErrors?.telematicsSN
                  ? 'margin-bottom-3 form-error'
                  : 'margin-bottom-3'
              }
            >
              <Label className="margin-top-1" required>
                <strong>Scan device serial number</strong>
              </Label>
              <div className="gray-text">Scan or type</div>
              {formErrors?.telematicsSN && (
                <div className="form-error-msg">
                  <strong>{formErrors?.telematicsSN}</strong>
                </div>
              )}
              <Composition
                areas={`
                      SerialNumber ScanBtn
                    `}
                gap={8}
                templateCols="1fr 70px"
              >
                {({ SerialNumber, ScanBtn }) => (
                  <>
                    <SerialNumber>
                      <div className="input-box">
                        <Textbox
                          type="text"
                          id="telematicsSN"
                          name="telematicsSN"
                          value={formValues?.telematicsSN || ''}
                          variant={formErrors?.telematicsSN ? 'error' : ''}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              telematicsSN: e.target.value,
                            });
                          }}
                          className="mobile-textbox margin-top-0"
                        />
                      </div>
                    </SerialNumber>
                    <ScanBtn>
                      <Button
                        variant="primary"
                        size="medium"
                        label=""
                        leftIcon={{
                          name: 'photo_camera',
                          iconName: 'photo_camera',
                        }}
                        onClick={() => {
                          setGeoTabDataValid(false);
                          setTelematicsSuccess(false);
                          setFormValues({
                            ...formValues,
                            telematicsSN: '',
                          });
                          setScannerOpen(true);
                        }}
                        className="icon-button"
                        style={{
                          height: '40px',
                          borderRadius: '.25rem',
                          position: 'static',
                          marginTop: '0',
                        }}
                      />
                    </ScanBtn>
                  </>
                )}
              </Composition>
            </div>
            <div
              className={
                formErrors?.currentMileage
                  ? 'margin-bottom-3 form-error'
                  : 'margin-bottom-3'
              }
            >
              <Label className="margin-top-1" required>
                <strong>Current mileage</strong>
              </Label>
              <div className="gray-text">At time of installation</div>
              {formErrors?.currentMileage && (
                <div className="form-error-msg">
                  <strong>{formErrors?.currentMileage}</strong>
                </div>
              )}
              <div className="input-box">
                <Textbox
                  type="text"
                  id="currentMileage"
                  name="currentMileage"
                  value={formValues?.currentMileage || ''}
                  variant={formErrors?.currentMileage ? 'error' : ''}
                  onChange={(e) => {
                    setFormValues({
                      ...formValues,
                      currentMileage: e.target.value,
                    });
                  }}
                  className="mobile-textbox margin-top-0"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          data-testid="continue-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Install"
          onClick={submitHandler}
        />
      </Box>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          className="width-full"
          variant="outline"
          size={isDesktop ? 'medium' : 'large'}
          label="Cancel"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </Box>

      <AreYouSureModal
        title="Cancel Telematics installation."
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Cancel Telematics Installation"
        cancelLabel="Return to Telematics Installation"
        onConfirm={() => {
          setShowModal(false);
          setScreen('find-vehicle');
        }}
        onCancel={() => setShowModal(false)}
      >
        Are you sure you want to cancel Telematics installation?
      </AreYouSureModal>
    </div>
  );
};

export default Telematics;
