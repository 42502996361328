import React, { useEffect, useState } from 'react';
import { Button, Textbox, SelectBox, Alert } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { IsCorrectField, PhotoCapture } from 'components';
import { Composition, Box } from 'atomic-layout';
import { useDispatch, useSelector } from 'react-redux';
import {
  vehicleColors,
  cylinderCount,
  transmissionTypes,
  fuelTypes,
} from 'constants/vehicleDetailCodeMap';
import { setLoadedVehicleData } from 'reducers/marshalling';
import { scrollToElement } from 'utils';
import '../../../screens.css';

export const Vehicle = ({
  vehicle,
  setValid,
  setTouched,
  setTabPartial,
  showFormErrors = false,
  saves = 0,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const [tabSaved, setTabSaved] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(showFormErrors);
  const [fieldCorrectness, setFieldCorrectness] = useState({});
  const prevFormValues = usePrevious(formValues);
  const prevSaves = usePrevious(saves);

  useEffect(() => {
    setShowErrors(showFormErrors);
  }, [showFormErrors]);

  useEffect(() => {
    if (tabSaved) {
      scrollToElement('.react-tabs');
    }
  }, [tabSaved]);

  const allFields = React.useMemo(() => {
    return {
      acquisitionMileage: ['isNotEmpty', 'isNumber'],
      vehicleColor: ['isNotEmpty', 'isValidColor'],
      tireSize: ['isNotEmpty'],
      cylinders: ['isNotEmpty', 'isValidCylinderCount'],
      engineDisplacement: ['isNotEmpty', 'isNumber'],
      transmission: ['isNotEmpty', 'isValidTransmission'],
      oilLifeSensors: ['isNotEmpty'],
      fuelType: ['isNotEmpty', 'isValidFuelType'],
      fuelCapacity: ['isNotEmpty', 'isNumber'],
      gvwr: ['isNotEmpty', 'isNumber'],
      comments: ['isLessThanAThousandChars'],
      frontLeft: ['isValidImage'],
      frontRight: ['isValidImage'],
      rearLeft: ['isValidImage'],
      rearRight: ['isValidImage'],
      vehicleDataPlate: ['isValidImage'],
    };
  }, []);

  const fieldValidations = {
    isNotEmpty: (value) => {
      if (typeof value === 'string') {
        return value.trim() !== '' || 'This is a required field';
      }
      return 'This is a required field';
    },
    isValidColor: (value) =>
      vehicleColors.includes(value) || 'Invalid vehicle color',
    isValidCylinderCount: (value) =>
      Object.keys(cylinderCount).includes(value) || 'Invalid cylinder count',
    isValidTransmission: (value) =>
      Object.keys(transmissionTypes).includes(value) ||
      'Invalid transmission type',
    isValidFuelType: (value) =>
      Object.keys(fuelTypes).includes(value) || 'Invalid fuel type',
    isNumber: (value) => !isNaN(value) || 'Must be a number',
    isLessThanAThousandChars: (value) =>
      !value ||
      (value && value.length <= 1000) ||
      'Text limited to 1000 characters',
    isValidImage: (value) => {
      if (
        value != null &&
        Array.isArray(value.photos) &&
        Array.isArray(value.photoData)
      ) {
        return (
          (value.photos.length > 0 && value.photoData.length > 0) ||
          'This is required field'
        );
      }
      return 'This is a required field';
    },
  };

  const validateForm = (
    allFields,
    fieldCorrectness,
    fieldValidations,
    formValues,
    vehicle,
    vehicleLoadData,
  ) => {
    if (vehicle) {
      const errors = {};
      const newValues = {};
      Object.keys(allFields).forEach((field) => {
        const vehicleData =
          field === 'vehicleColor' ? vehicle?.color : vehicle[field];
        if (
          field !== 'oilLifeSensors' &&
          !(
            (fieldCorrectness[field] === true ||
              fieldCorrectness[field] === null) &&
            vehicleData
          )
        ) {
          const validations = [...allFields[field]].reverse() || [];
          validations.forEach((validation) => {
            const validationResult = fieldValidations[validation](
              formValues[field],
            );
            if (validationResult !== true) {
              errors[field] = validationResult;
            }
          });
        } else if (fieldCorrectness[field] !== null) {
          if (field === 'oilLifeSensors') {
            if (
              fieldCorrectness[field] !== null ||
              fieldCorrectness[field] !== undefined
            ) {
              newValues.oilLifeSensors = fieldCorrectness[field];
            } else {
              newValues.oilLifeSensors = vehicleLoadData?.oilLifeSensors;
            }
          } else {
            if (fieldCorrectness[field] === true) {
              newValues[field] = vehicleData;
            }
          }
        } else {
          if (
            fieldCorrectness[field] === null &&
            (vehicleData || field === 'oilLifeSensors')
          ) {
            errors[field] = 'This is a required field';
          }
        }
      });
      return {
        errors,
        newValues,
      };
    }
  };

  useEffect(() => {
    if (
      JSON.stringify(prevFormValues) !== JSON.stringify(formValues) &&
      vehicle
    ) {
      const { errors, newValues } = validateForm(
        allFields,
        fieldCorrectness,
        fieldValidations,
        formValues,
        vehicle,
      );
      if (Object.keys(newValues).length > 0) {
        setFormValues({
          ...formValues,
          ...newValues,
        });
      }
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        setTabPartial('vehicle', isPartial);
        setFormErrors(errors);
        setValid('vehicle', false);
      } else {
        setTabPartial('vehicle', false);
        setFormErrors({});
        setValid('vehicle', true);
      }
    }
  }, [
    setValid,
    allFields,
    fieldCorrectness,
    fieldValidations,
    formValues,
    vehicle,
    prevFormValues,
    setTabPartial,
    vehicleLoadData,
  ]);

  useEffect(() => {
    if (Object.keys(vehicleLoadData).length > 0) {
      setFormValues(vehicleLoadData);
    }
  }, [vehicleLoadData]);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      const fieldStatus = {};
      Object.keys(allFields).forEach((field) => {
        const radioYes = document.getElementById(`${field}-radio-yes`);
        const radioNo = document.getElementById(`${field}-radio-no`);
        if (radioYes && radioYes.checked === true) {
          fieldStatus[field] = true;
        } else if (radioNo && radioNo.checked === true) {
          fieldStatus[field] = false;
        } else {
          fieldStatus[field] = null;
        }
      });
      setFieldCorrectness(fieldStatus);
    }, 50);
    return () => window.clearTimeout(timer);
  }, [allFields]);

  const changeHandler = (field, value) => {
    setTabSaved(false);
    setTouched('vehicle', true);
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const submitHandler = () => {
    setShowErrors(false);
    setTabSaved(false);
    const { errors, newValues } = validateForm(
      allFields,
      fieldCorrectness,
      fieldValidations,
      formValues,
      vehicle,
    );
    if (Object.keys(newValues).length > 0) {
      setFormValues({
        ...formValues,
        ...newValues,
      });
    }
    let isPartial = false;
    if (Object.keys(errors).length > 0) {
      isPartial = true;
      for (const [, error] of Object.entries(errors)) {
        if (error !== 'This is a required field') {
          isPartial = false;
        }
      }
      setTabPartial('vehicle', isPartial);
      setFormErrors(errors);
      setShowErrors(true);
      return;
    }
    setTabPartial('vehicle', false);
    setTabSaved(true);
    dispatch(
      setLoadedVehicleData({
        ...formValues,
        ...newValues,
      }),
    );
  };

  const onCorrectnessChange = (fieldName, isCorrect) => {
    setTouched('vehicle', true);
    setTabSaved(false);
    if (fieldCorrectness[fieldName] === true && isCorrect === false) {
      setFormValues({
        ...formValues,
        [fieldName]: '',
      });
    }
    if (fieldName === 'oilLifeSensors' && isCorrect === false) {
      setFormValues({
        ...formValues,
        [fieldName]: isCorrect,
      });
    }
    setFieldCorrectness({
      ...fieldCorrectness,
      [fieldName]: isCorrect,
    });
  };

  const opts = {
    vehicleColor: vehicleColors
      .filter((color) => color !== vehicle?.color)
      .map((color) => {
        return {
          value: color,
          label: color,
        };
      }),
    cylinders: Object.keys(cylinderCount)
      .filter((item) => item !== vehicle?.cylinders)
      .map((item) => {
        return {
          value: item,
          label: cylinderCount[item],
        };
      }),
    transmission: Object.keys(transmissionTypes)
      .filter((item) => item !== vehicle?.transmission)
      .map((item) => {
        return {
          value: item,
          label: transmissionTypes[item],
        };
      }),
    fuelType: Object.keys(fuelTypes)
      .filter((item) => item !== vehicle?.fuelType)
      .map((item) => {
        return {
          value: item,
          label: fuelTypes[item],
        };
      }),
  };

  useEffect(() => {
    if (vehicle) {
      window.setTimeout(() => {
        const dropdownFields = [
          'vehicleColor',
          'cylinders',
          'transmission',
          'fuelType',
        ];
        dropdownFields.forEach((field) => {
          const vehicleData =
            field === 'vehicleColor' ? vehicle?.color : vehicle[field];
          if (
            formValues[field] &&
            vehicleData &&
            formValues[field] !== vehicleData
          ) {
            const element = document.getElementById(field);
            if (element) {
              const selectedIndex = opts[field]
                .map((item) => item.value)
                .indexOf(formValues[field]);
              if (selectedIndex > -1) {
                element.selectedIndex = selectedIndex + 1;
              }
            }
          }
        });
      }, 100);
    }
  }, [formValues, vehicle, opts]);

  useEffect(() => {
    if (prevSaves !== saves && prevSaves !== undefined) {
      setShowErrors(false);
      const { errors, newValues } = validateForm(
        allFields,
        fieldCorrectness,
        fieldValidations,
        formValues,
        vehicle,
      );
      if (Object.keys(newValues).length > 0) {
        setFormValues({
          ...formValues,
          ...newValues,
        });
      }
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        if (!isPartial) {
          setFormErrors(errors);
          setShowErrors(true);
          return;
        }
      }
      dispatch(
        setLoadedVehicleData({
          ...formValues,
          ...newValues,
        }),
      );
    }
  }, [
    saves,
    prevSaves,
    allFields,
    fieldCorrectness,
    fieldValidations,
    formValues,
    vehicle,
    dispatch,
  ]);

  if (!vehicleLoadData || !vehicle) {
    return null;
  }

  return (
    <div data-testid="vehicle-load-tabs-vehicle">
      {tabSaved && (
        <Alert
          className="margin-bottom-3 tab-save-success"
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          Tab successfully saved.
        </Alert>
      )}
      <p className="border-bottom border-base-light padding-bottom-3">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <p className="tab-title">Vehicle</p>
      <div className="padding-bottom-0">
        <Composition
          areas={`
            AcquisitionMileage
            VehicleColor
            TireSize
            Cylinders
            EngineDisplacement
            Transmission
            OilLifeSensors
            FuelType
            FuelCapacity
            Gvwr
            Comments
            VehiclePhotos
          `}
          gap={16}
        >
          {({
            AcquisitionMileage,
            VehicleColor,
            TireSize,
            Cylinders,
            EngineDisplacement,
            Transmission,
            OilLifeSensors,
            FuelType,
            FuelCapacity,
            Gvwr,
            Comments,
            VehiclePhotos,
          }) => (
            <>
              <AcquisitionMileage>
                <IsCorrectField
                  error={formErrors['acquisitionMileage'] || ''}
                  showErrors={showErrors}
                  label="Acquisition (ACQ) mileage:"
                  defaultValue={vehicle?.acquisitionMileage}
                  currentValue={formValues?.acquisitionMileage}
                  fieldName="acquisitionMileage"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <Textbox
                    type="text"
                    data-testid="acquisition-mileage"
                    id="acquisitionMileage"
                    name="acquisitionMileage"
                    value={formValues?.acquisitionMileage || ''}
                    variant={
                      formErrors?.acquisitionMileage && showErrors
                        ? 'error'
                        : ''
                    }
                    onFocus={() => setTouched('vehicle', true)}
                    onChange={(e) => {
                      changeHandler('acquisitionMileage', e.target.value);
                    }}
                    className="mobile-textbox"
                  />
                </IsCorrectField>
              </AcquisitionMileage>
              <VehicleColor>
                <IsCorrectField
                  error={formErrors['vehicleColor'] || ''}
                  showErrors={showErrors}
                  label="Vehicle color:"
                  defaultValue={vehicle?.color}
                  currentValue={formValues?.vehicleColor}
                  fieldName="vehicleColor"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <SelectBox
                    data-testid="vehicle-color"
                    id="vehicleColor"
                    name="vehicleColor"
                    variant={
                      formErrors?.vehicleColor && showErrors ? 'error' : ''
                    }
                    optionLabel="label"
                    optionValue="value"
                    defaultValue={formValues?.vehicleColor || ''}
                    onFocus={() => setTouched('vehicle', true)}
                    options={[
                      { value: '', label: '- Select -' },
                      ...opts.vehicleColor,
                    ]}
                    onChangeFunc={(e) => {
                      changeHandler('vehicleColor', e.target.value);
                    }}
                  />
                </IsCorrectField>
              </VehicleColor>
              <TireSize>
                <IsCorrectField
                  error={formErrors['tireSize'] || ''}
                  showErrors={showErrors}
                  label="Tire size:"
                  defaultValue={vehicle?.tireSize}
                  currentValue={formValues?.tireSize}
                  fieldName="tireSize"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <Textbox
                    type="text"
                    data-testid="tire-size"
                    id="tireSize"
                    name="tireSize"
                    value={formValues?.tireSize || ''}
                    variant={formErrors?.tireSize && showErrors ? 'error' : ''}
                    onFocus={() => setTouched('vehicle', true)}
                    onChange={(e) => {
                      changeHandler('tireSize', e.target.value);
                    }}
                    className="mobile-textbox"
                  />
                </IsCorrectField>
              </TireSize>
              <Cylinders>
                <IsCorrectField
                  error={formErrors['cylinders'] || ''}
                  showErrors={showErrors}
                  label="Cylinders:"
                  defaultValue={vehicle?.cylinders}
                  defaultValueLabel={cylinderCount[vehicle?.cylinders] || null}
                  currentValue={formValues?.cylinders}
                  fieldName="cylinders"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <SelectBox
                    data-testid="cylinders"
                    id="cylinders"
                    name="cylinders"
                    variant={formErrors?.cylinders && showErrors ? 'error' : ''}
                    optionLabel="label"
                    optionValue="value"
                    defaultValue={formValues?.cylinders || ''}
                    options={[
                      { value: '', label: '- Select -' },
                      ...opts.cylinders,
                    ]}
                    onChangeFunc={(e) => {
                      changeHandler('cylinders', e.target.value);
                    }}
                    onFocus={() => setTouched('vehicle', true)}
                  />
                </IsCorrectField>
              </Cylinders>
              <EngineDisplacement>
                <IsCorrectField
                  error={formErrors['engineDisplacement'] || ''}
                  showErrors={showErrors}
                  label="Engine displacement:"
                  defaultValue={vehicle?.engineDisplacement}
                  defaultValueLabel={`${vehicle?.engineDisplacement}L`}
                  currentValue={formValues?.engineDisplacement}
                  fieldName="engineDisplacement"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <Textbox
                    type="text"
                    data-testid="engine-displacement"
                    id="engineDisplacement"
                    name="engineDisplacement"
                    value={formValues?.engineDisplacement || ''}
                    variant={
                      formErrors?.engineDisplacement && showErrors
                        ? 'error'
                        : ''
                    }
                    onChange={(e) => {
                      changeHandler('engineDisplacement', e.target.value);
                    }}
                    onFocus={() => setTouched('vehicle', true)}
                    className="mobile-textbox"
                  />
                </IsCorrectField>
              </EngineDisplacement>
              <Transmission>
                <IsCorrectField
                  error={formErrors['transmission'] || ''}
                  showErrors={showErrors}
                  label="Transmission:"
                  defaultValue={vehicle?.transmission}
                  defaultValueLabel={
                    transmissionTypes[vehicle?.transmission] || null
                  }
                  currentValue={formValues?.transmission}
                  fieldName="transmission"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <SelectBox
                    data-testid="transmission"
                    id="transmission"
                    name="transmission"
                    variant={
                      formErrors?.transmission && showErrors ? 'error' : ''
                    }
                    optionLabel="label"
                    optionValue="value"
                    defaultValue={formValues?.transmission || ''}
                    options={[
                      { value: '', label: '- Select -' },
                      ...opts.transmission,
                    ]}
                    onChangeFunc={(e) => {
                      changeHandler('transmission', e.target.value);
                    }}
                    onFocus={() => setTouched('vehicle', true)}
                  />
                </IsCorrectField>
              </Transmission>
              <OilLifeSensors>
                <IsCorrectField
                  error={formErrors['oilLifeSensors'] || ''}
                  showErrors={showErrors}
                  label="Are there oil life sensors?"
                  defaultValue={vehicle?.oilLifeSensors}
                  currentValue={formValues?.oilLifeSensors}
                  fieldName="oilLifeSensors"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                  yesNoOnly={true}
                />
              </OilLifeSensors>
              <FuelType>
                <IsCorrectField
                  error={formErrors['fuelType'] || ''}
                  showErrors={showErrors}
                  label="Fuel type:"
                  defaultValue={vehicle?.fuelType}
                  defaultValueLabel={fuelTypes[vehicle?.fuelType] || null}
                  currentValue={formValues?.fuelType}
                  fieldName="fuelType"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <SelectBox
                    data-testid="fuel-type"
                    id="fuelType"
                    name="fuelType"
                    variant={formErrors?.fuelType && showErrors ? 'error' : ''}
                    optionLabel="label"
                    optionValue="value"
                    defaultValue={formValues?.fuelType || ''}
                    options={[
                      { value: '', label: '- Select -' },
                      ...opts.fuelType,
                    ]}
                    onChangeFunc={(e) => {
                      changeHandler('fuelType', e.target.value);
                    }}
                    onFocus={() => setTouched('vehicle', true)}
                  />
                </IsCorrectField>
              </FuelType>
              <FuelCapacity>
                <IsCorrectField
                  error={formErrors['fuelCapacity'] || ''}
                  showErrors={showErrors}
                  label="Fuel capacity:"
                  defaultValue={vehicle?.fuelCapacity}
                  defaultValueLabel={`${vehicle?.fuelCapacity} gallons`}
                  currentValue={formValues?.fuelCapacity}
                  fieldName="fuelCapacity"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <Textbox
                    type="text"
                    data-testid="fuel-capacity"
                    id="fuelCapacity"
                    name="fuelCapacity"
                    value={formValues?.fuelCapacity || ''}
                    variant={
                      formErrors?.fuelCapacity && showErrors ? 'error' : ''
                    }
                    onFocus={() => setTouched('vehicle', true)}
                    onChange={(e) => {
                      changeHandler('fuelCapacity', e.target.value);
                    }}
                    className="mobile-textbox"
                  />
                </IsCorrectField>
              </FuelCapacity>
              <Gvwr>
                <IsCorrectField
                  error={formErrors['gvwr'] || ''}
                  showErrors={showErrors}
                  label="GVWR:"
                  defaultValue={vehicle?.gvwr}
                  currentValue={formValues?.gvwr}
                  fieldName="gvwr"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                >
                  <Textbox
                    type="text"
                    data-testid="gvwr"
                    id="gvwr"
                    name="gvwr"
                    value={formValues?.gvwr || ''}
                    variant={formErrors?.gvwr && showErrors ? 'error' : ''}
                    onFocus={() => setTouched('vehicle', true)}
                    onChange={(e) => {
                      changeHandler('gvwr', e.target.value);
                    }}
                    className="mobile-textbox"
                  />
                </IsCorrectField>
              </Gvwr>
              <Comments>
                <IsCorrectField
                  error={formErrors['comments'] || ''}
                  showErrors={showErrors}
                  label={<span>Comments:</span>}
                  defaultValue=""
                  currentValue={formValues?.comments}
                  fieldName="comments"
                  onFocus={() => setTouched('vehicle', true)}
                  onChange={onCorrectnessChange}
                  isRequired={false}
                >
                  <div className="gray-text">Enter any notes or comments</div>
                  <Textbox
                    id="comments"
                    name="comments"
                    type="textarea"
                    data-testid="comments"
                    size="medium"
                    value={formValues?.comments || ''}
                    onFocus={() => setTouched('vehicle', true)}
                    onChange={(e) => {
                      changeHandler('comments', e.target.value);
                      const textLeft = 1000 - Number(e.target.value.length);
                      const charBox = document.getElementById('comments-chars');
                      const allChars = charBox.parentNode;
                      if (charBox) {
                        if (textLeft < 1000) {
                          charBox.innerHTML =
                            (textLeft >= 0 ? textLeft : 0) + '/';
                          if (textLeft < 0) {
                            if (allChars.className.indexOf('error') === -1) {
                              allChars.className += ' error';
                            }
                          } else {
                            allChars.className = allChars.className
                              .split(' ')
                              .filter((classItem) => classItem !== 'error')
                              .join(' ');
                          }
                        } else {
                          charBox.innerHTML = '';
                          allChars.className = allChars.className
                            .split(' ')
                            .filter((classItem) => classItem !== 'error')
                            .join(' ');
                        }
                      }
                    }}
                  />
                  <div className="textbox-note">
                    <span
                      id={`comments-chars`}
                      data-testid="comments-chars"
                    ></span>
                    1000 characters allowed
                  </div>
                </IsCorrectField>
              </Comments>
              <div className="vehicle-photo-container">
                <VehiclePhotos>
                  <p className="tab-title">Vehicle photos</p>
                  <div className="margin-btm">
                    Take or upload 5 photos of the vehicle. One from each corner
                    of the vehicle.
                  </div>
                  <div>
                    <Composition
                      areas={`
                      FrontLeft
                      FrontRight
                      RearLeft
                      RearRight
                      VehicleDataPlate
                    `}
                      gap={16}
                    >
                      {({
                        FrontLeft,
                        FrontRight,
                        RearLeft,
                        RearRight,
                        VehicleDataPlate,
                      }) => (
                        <>
                          <FrontLeft>
                            <IsCorrectField
                              error={formErrors['frontLeft'] || ''}
                              showErrors={showErrors}
                              label="Front Left"
                              fieldName="frontLeft"
                              onFocus={() => setTouched('vehicle', true)}
                              onChange={onCorrectnessChange}
                            >
                              <div>
                                <div className="gray-text">
                                  Take or upload photo
                                </div>
                                <PhotoCapture
                                  vin={vehicle.vin}
                                  area="FrontLeft"
                                  code="inspect"
                                  id="FrontLeft-inspect-photo"
                                  name="FrontLeft-inspect-photo"
                                  showLabel={false}
                                  maxPhotoCount={1}
                                  photosValue={formValues?.frontLeft?.photos}
                                  photoDataValue={
                                    formValues?.frontLeft?.photoData
                                  }
                                  onCapture={(photos, photoData) => {
                                    onCorrectnessChange(
                                      'frontLeft',
                                      photoData.length === 1
                                        ? {
                                            photos,
                                            photoData,
                                          }
                                        : null,
                                    );
                                    changeHandler(
                                      'frontLeft',
                                      photoData.length === 1
                                        ? {
                                            photos,
                                            photoData,
                                          }
                                        : null,
                                    );
                                  }}
                                />
                              </div>
                            </IsCorrectField>
                          </FrontLeft>
                          <FrontRight>
                            <IsCorrectField
                              error={formErrors['frontRight'] || ''}
                              showErrors={showErrors}
                              label="Front Right"
                              fieldName="frontRight"
                              onFocus={() => setTouched('vehicle', true)}
                              onChange={onCorrectnessChange}
                            >
                              <div>
                                <div className="gray-text">
                                  Take or upload photo
                                </div>
                                <PhotoCapture
                                  vin={vehicle.vin}
                                  area="FrontRight"
                                  code="inspect"
                                  id="FrontRight-inspect-photo"
                                  name="FrontRight-inspect-photo"
                                  showLabel={false}
                                  maxPhotoCount={1}
                                  photosValue={formValues?.frontRight?.photos}
                                  photoDataValue={
                                    formValues?.frontRight?.photoData
                                  }
                                  onCapture={(photos, photoData) => {
                                    onCorrectnessChange(
                                      'frontRight',
                                      photoData.length === 1
                                        ? {
                                            photos,
                                            photoData,
                                          }
                                        : null,
                                    );
                                    changeHandler(
                                      'frontRight',
                                      photoData.length === 1
                                        ? {
                                            photos,
                                            photoData,
                                          }
                                        : null,
                                    );
                                  }}
                                />
                              </div>
                            </IsCorrectField>
                          </FrontRight>
                          <RearLeft>
                            <IsCorrectField
                              error={formErrors['rearLeft'] || ''}
                              showErrors={showErrors}
                              label="Rear Left"
                              fieldName="rearLeft"
                              onFocus={() => setTouched('vehicle', true)}
                              onChange={onCorrectnessChange}
                            >
                              <div className="gray-text">
                                Take or upload photo
                              </div>
                              <PhotoCapture
                                vin={vehicle.vin}
                                area="RearLeft"
                                code="inspect"
                                id="RearLeft-inspect-photo"
                                name="RearLeft-inspect-photo"
                                showLabel={false}
                                maxPhotoCount={1}
                                photosValue={formValues?.rearLeft?.photos}
                                photoDataValue={formValues?.rearLeft?.photoData}
                                onCapture={(photos, photoData) => {
                                  onCorrectnessChange(
                                    'rearLeft',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                  changeHandler(
                                    'rearLeft',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                }}
                              />
                            </IsCorrectField>
                          </RearLeft>
                          <RearRight>
                            <IsCorrectField
                              error={formErrors['rearRight'] || ''}
                              showErrors={showErrors}
                              label="Rear Right"
                              fieldName="rearRight"
                              onFocus={() => setTouched('vehicle', true)}
                              onChange={onCorrectnessChange}
                            >
                              <div className="gray-text">
                                Take or upload photo
                              </div>
                              <PhotoCapture
                                vin={vehicle.vin}
                                area="RearRight"
                                code="inspect"
                                id="RearRight-inspect-photo"
                                name="RearRight-inspect-photo"
                                showLabel={false}
                                maxPhotoCount={1}
                                photosValue={formValues?.rearRight?.photos}
                                photoDataValue={
                                  formValues?.rearRight?.photoData
                                }
                                onCapture={(photos, photoData) => {
                                  onCorrectnessChange(
                                    'rearRight',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                  changeHandler(
                                    'rearRight',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                }}
                              />
                            </IsCorrectField>
                          </RearRight>
                          <VehicleDataPlate>
                            <IsCorrectField
                              error={formErrors['vehicleDataPlate'] || ''}
                              showErrors={showErrors}
                              label="Vehicle Data Plate"
                              fieldName="vehicleDataPlate"
                              onFocus={() => setTouched('vehicle', true)}
                              onChange={onCorrectnessChange}
                            >
                              <div className="gray-text">
                                Take or upload photo
                              </div>
                              <PhotoCapture
                                vin={vehicle.vin}
                                area="VehicleDataPlate"
                                code="inspect"
                                id="VehicleDataPlate-inspect-photo"
                                name="VehicleDataPlate-inspect-photo"
                                showLabel={false}
                                maxPhotoCount={1}
                                photosValue={
                                  formValues?.vehicleDataPlate?.photos
                                }
                                photoDataValue={
                                  formValues?.vehicleDataPlate?.photoData
                                }
                                onCapture={(photos, photoData) => {
                                  onCorrectnessChange(
                                    'vehicleDataPlate',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                  changeHandler(
                                    'vehicleDataPlate',
                                    photoData.length === 1
                                      ? {
                                          photos,
                                          photoData,
                                        }
                                      : null,
                                  );
                                }}
                              />
                            </IsCorrectField>
                          </VehicleDataPlate>
                        </>
                      )}
                    </Composition>
                  </div>
                </VehiclePhotos>
              </div>
            </>
          )}
        </Composition>
      </div>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          data-testid="save-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Save"
          onClick={submitHandler}
        />
      </Box>
    </div>
  );
};

export default Vehicle;
