import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import 'styled-components/macro';
import './DamageCodesModal.css';

export const DamageCodesModal = ({
  title,
  show = false,
  confirmLabel = '',
  cancelLabel = '',
  onConfirm,
  onCancel,
  children,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  if (!show) {
    return null;
  }
  const renderActions = () => {
    let areas = 'Confirm Cancel';

    return (
      <Composition
        areas={areas}
        gap={14}
        templateCols="auto"
        templateRows="auto"
        width="100%"
      >
        {({ Confirm, Cancel }) => (
          <>
            <Confirm>
              <Button
                data-testid="confirm-button"
                className="width-full"
                variant="primary"
                size={isDesktop ? 'medium' : 'large'}
                label={confirmLabel}
                onClick={() => {
                  if (typeof onConfirm === 'function') {
                    onConfirm();
                  }
                }}
                css={`
                  text-align: center !important;
                `}
              />
            </Confirm>
            <Cancel>
              <Button
                data-testid="cancel-button"
                className="width-full"
                variant="outline"
                size={isDesktop ? 'medium' : 'large'}
                label={cancelLabel}
                onClick={() => {
                  if (typeof onCancel === 'function') {
                    onCancel();
                  }
                }}
                css={`
                  text-align: center !important;
                `}
              />
            </Cancel>
          </>
        )}
      </Composition>
    );
  };

  return (
    <div
      className={`afp-modal-wrapper${isDesktop ? '' : ' mobile'}`}
      role="dialog"
      aria-labelledby="modal-heading"
      data-testid="damage-codes-modal"
    >
      <div className="afp-modal-overlay">
        <Modal
          title={title}
          actions={renderActions()}
          className="damage-codes-modal"
          hideClose={false}
          onClose={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}
          variant="large"
        >
          {children}
        </Modal>
      </div>
    </div>
  );
};

export default DamageCodesModal;
