import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import { useResponsiveQuery } from 'atomic-layout';
import 'styled-components/macro';
import './FsrModal.css';

export const FsrModal = ({ show = false, onClose }) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  if (!show) {
    return null;
  }

  return (
    <div
      data-testid="fsr-modal"
      className={`afp-modal-wrapper${isDesktop ? '' : ' mobile'}`}
      role="dialog"
      aria-labelledby="modal-heading"
    >
      <div className="afp-modal-overlay">
        <Modal
          title="Contact your FSR"
          actions={
            <Button
              data-testid="close-button"
              className="width-full"
              variant="primary"
              size={isDesktop ? 'medium' : 'large'}
              label="Close"
              onClick={() => {
                if (typeof onClose === 'function') {
                  onClose();
                }
              }}
              css={`
                text-align: center !important;
              `}
            />
          }
          className="fsr-modal"
          hideClose={false}
          onClose={() => {
            if (typeof onClose === 'function') {
              onClose();
            }
          }}
          variant="large"
        >
          <div>Contact your Fleet Service Representative (FSR).</div>
          <div className="margin-top-2">
            <div>
              <strong>Name:</strong> Joe Smith
            </div>
            <div>
              <strong>Phone:</strong> <a href="tel:5149693021">514-969-3021</a>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FsrModal;
