import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setFsrZone = createAsyncThunk('user/setFsrZone', async (data) => {
  return data;
});

export const setFsrLocation = createAsyncThunk(
  'user/setFsrLocation',
  async (data) => {
    return data;
  },
);

export const setUserLocations = createAsyncThunk(
  'user/setUserLocations',
  async (data) => {
    return data;
  },
);

export const setUserFmc = createAsyncThunk('user/setUserFmc', async (data) => {
  return data;
});

export const setUserVendorId = createAsyncThunk(
  'user/setUserVendorId',
  async (data) => {
    return data;
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(setFsrZone.fulfilled, (state, action) => {
        return {
          ...state,
          fsrZone: action.payload,
        };
      })
      .addCase(setFsrLocation.fulfilled, (state, action) => {
        return {
          ...state,
          fsrLocation: action.payload,
        };
      })
      .addCase(setUserLocations.fulfilled, (state, action) => {
        return {
          ...state,
          locations: action.payload,
        };
      })
      .addCase(setUserFmc.fulfilled, (state, action) => {
        return {
          ...state,
          fmc: action.payload,
        };
      })
      .addCase(setUserVendorId.fulfilled, (state, action) => {
        return {
          ...state,
          vendorId: action.payload,
        };
      });
  },
});

export default userSlice.reducer;
