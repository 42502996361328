export const vehicleColors = [
  'Airforce blue',
  'Black',
  'Blue',
  'Brown',
  'Forest service green',
  'Gold',
  'Gray',
  'Green',
  'Orange',
  'Pink',
  'Red',
  'School bus yellow',
  'Silver',
  'Tan',
  'White',
  'Yellow',
];

export const cylinderCount = {
  0: 'No Cylinders',
  2: '2 Cylinders',
  4: '4 Cylinders',
  5: '5 Cylinders',
  6: '6 Cylinders',
  8: '8 Cylinders',
  10: '10 Cylinders',
  12: '12 Cylinders',
};

export const transmissionTypes = {
  T2: 'Automatic',
  T3: '3 Speed Manual',
  T4: '4 Speed Manual',
  T5: '5 Speed Manual',
};

export const fuelTypes = {
  10: 'Gasoline',
  20: 'Diesel',
  21: 'Biodiesel B20',
  24: 'CNG Diesel',
  31: 'Methanol/Unleaded Gasoline (M85) Flexible Fuel',
  41: 'Ethanol/Unleaded gasoline (E85) Flexible Fuel',
  50: 'Compressed Natural Gas-Dedicated',
  54: 'CNG/Unleaded Gasoline dual',
  55: 'CNG Conversion to Unleaded Gas',
  60: 'Dedicated Propane',
  64: 'Propane/Unleaded Gasoline Dual',
  70: 'Electric',
  71: 'Plug-In Hybrid Electric/Gas',
  72: 'Plug-In Hybrid Electric/Diesel',
  73: 'Plug-In Hybrid Electric/E85',
  74: 'Gasoline Hybrid Electric',
  75: 'Diesel Hybrid Electric',
  76: 'CNG Hybrid Electric',
  99: 'Non-Fueled Equipment',
};

export const vehicleClasses = {
  10: { tagClass: 'G10', title: 'Compact Sedan' },
  12: { tagClass: 'G12', title: 'Compact Sedan' },
  11: { tagClass: 'G11', title: 'Midsize Sedan' },
  13: { tagClass: 'G13', title: 'SubCompact Sedan' },
  14: { tagClass: 'G14', title: 'Large Sedan' },
  20: { tagClass: 'G15', title: 'Subcompact Station Wagon' },
  21: { tagClass: 'G21', title: 'Compact Station Wagon' },
  22: { tagClass: 'G21', title: 'Midsize Station Wagon' },
  23: { tagClass: 'G21', title: 'Large Size Station Wagon' },
  31: { tagClass: 'G31', title: 'Ambulance' },
  32: { tagClass: 'G32', title: 'Bus' },
  41: { tagClass: 'G41', title: 'Light Truck, under 6,000 GVWR, 4x2' },
  42: { tagClass: 'G42', title: 'Light Truck, 6,000 to 8,499 GVWR, 4x2' },
  43: { tagClass: 'G43', title: 'Light Truck, 8,500 to 12,499 GVWR, 4x2' },
  61: { tagClass: 'G61', title: 'Light Truck, under 6,000 GVWR, 4x4' },
  62: { tagClass: 'G62', title: 'Light Truck, 6,000 to 8,499 GVWR, 4x4' },
  63: { tagClass: 'G63', title: 'Light Truck, 8,500 to 12,499 GVWR, 4x4' },
  71: { tagClass: 'G71', title: 'Medium Truck, Cab & Chassis' },
  72: { tagClass: 'G71', title: 'Medium Truck, Cab with Stake Body' },
  73: { tagClass: 'G71', title: 'Medium Truck, Stake Dump' },
  74: { tagClass: 'G71', title: 'Medium Truck, Cab with Van Body' },
  75: { tagClass: 'G71', title: 'Medium Truck, Cab with Refrigerator Van' },
  76: {
    tagClass: 'G71',
    title: 'Medium Truck, Cab with Utility-Maintenance Body',
  },
  77: { tagClass: 'G71', title: 'Medium Truck, Multistop Van, FC' },
  78: { tagClass: 'G71', title: 'Medium Truck, Cab with Dump Body' },
  79: { tagClass: 'G71', title: 'Medium Truck, Tractor with Cab' },
  80: { tagClass: 'G81', title: 'Heavy Truck, 4x2, Using Gasoline' },
  81: { tagClass: 'G81', title: 'Heavy Truck, 4x4, Using Gasoline' },
  82: {
    tagClass: 'G82',
    title: 'Heavy Truck, Greater than 21,000; Diesel Fuel',
  },
  85: { tagClass: 'G82', title: 'Heavy Truck, 4x2, Using Diesel Fuel' },
  86: { tagClass: 'G82', title: 'Heavy Truck, 4x4, Using Diesel Fuel' },
  87: { tagClass: 'G82', title: 'Heavy Truck, 4x6, Using Diesel Fuel' },
  88: { tagClass: 'G82', title: 'Heavy Truck, Other' },
  90: { tagClass: 'G90', title: 'Special Purpose' },
  91: { tagClass: 'G91', title: 'Special Purpose, Non-Powered' },
  92: { tagClass: 'G91', title: 'Semi Trailer' },
};

export const standardItemsMap = {
  MF: 'FLOOR MATS',
  STA: 'SPARE TIRE ASSEMBLY',
  RH2: 'OEM TRAILER HITCH PROVISIONS',
  RM: 'EXTRA WIDE MIRRORS',
  LRR: 'LADDER RACK - REMOVABLE',
  LU01: 'BASIC SEDAN LE UP-FIT',
  SCC: 'INDIVIDUAL HIGH BACK SEATS',
  DAOH: 'AIR HORNS, DUAL',
  DCAM: 'DRIVING CAMERA',
  VV8: 'VIDEO VIEWING-DVD W/ 8 15',
  WCL1: 'MAXON WHEELCHAIR LIFT PACKAGE',
};
