import React, { useEffect, useState } from 'react';
import { Composition } from 'atomic-layout';
import { Textbox, Label } from '@gsa/afp-component-library';
import './CannotInspect.css';
import PhotoCapture from 'components/PhotoCapture';

export const CannotInspect = ({ vin, onChange }) => {
  const [cannot, setCannot] = useState({});
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(cannot);
    }
  }, [cannot, onChange]);

  return (
    <div className="cannot_inspect">
      <Composition
        areas={`
        Photos
        AdditionalDetails
      `}
        gap={24}
        templateRows="auto"
        templateCols="1fr"
      >
        {({ Photos, AdditionalDetails }) => (
          <>
            <Photos>
              <Label className="text-bold">Bill of Landing documentation</Label>
              <p>
                Marshaller or Transporter must write "Subject to Inspection
                (STI)" on the Bill of Landing.
              </p>
              <Label className="text-bold">Vehicle photos</Label>
              <p className="damage-code-info">
                Take photos and/or add details of the vehicle you cannot
                inspect.
              </p>
              <PhotoCapture
                vin={vin}
                area="cannot"
                code="inspect"
                id="cannot-inspect-photo"
                name="cannot-inspect-photo"
                showLabel={false}
                onCapture={(photos, photoData) => {
                  setCannot({ ...cannot, photos, photoData });
                }}
              />
            </Photos>
            <AdditionalDetails>
              <Label className="text-bold">Additional details</Label>
              <Textbox
                type="textarea"
                size="medium"
                onChange={(e) => {
                  setCannot({
                    ...cannot,
                    details: e.target.value || '',
                  });
                  const textLeft = 1000 - Number(e.target.value.length);
                  const charBox = document.getElementById(
                    'cannot-inspect-char',
                  );
                  const allChars = charBox.parentNode;
                  if (charBox) {
                    if (textLeft < 1000) {
                      charBox.innerHTML = (textLeft >= 0 ? textLeft : 0) + '/';
                      if (textLeft < 0) {
                        if (allChars.className.indexOf('error') === -1) {
                          allChars.className += ' error';
                        }
                      } else {
                        allChars.className = allChars.className
                          .split(' ')
                          .filter((classItem) => classItem !== 'error')
                          .join(' ');
                      }
                    } else {
                      charBox.innerHTML = '';
                      allChars.className = allChars.className
                        .split(' ')
                        .filter((classItem) => classItem !== 'error')
                        .join(' ');
                    }
                  }
                }}
              />
              <div className="textbox-note">
                <span id="cannot-inspect-char"></span>1000 characters allowed
              </div>
            </AdditionalDetails>
          </>
        )}
      </Composition>
    </div>
  );
};

export default CannotInspect;
