import React, { useState, useEffect } from 'react';
import { usePrevious, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { Scanner, IsCorrectField, CountdownTextboxField } from 'components';
import { Composition, Box } from 'atomic-layout';
import { Label, Textbox, Button, Alert } from '@gsa/afp-component-library';
import { useSync } from 'hooks';
import { setLoadedVehicleData } from 'reducers/marshalling';
import { scrollToElement } from 'utils';
import '../../../screens.css';

export const Telematics = ({
  vehicle,
  showFormErrors = false,
  setValid,
  setTouched,
  setTabPartial,
  setTelematicsSuccess,
  setTelematicsScan,
  telematicsSuccess = false,
  saves = 0,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { geoTabInstallStatus } = useSync();
  const [geoTabQuery, { data: geoTabData }] = geoTabInstallStatus;
  const { currentUser } = useCurrentUser();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const [tabSaved, setTabSaved] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(showFormErrors);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [geoTabSuccess, setGeoTabSuccess] = useState(telematicsSuccess);
  const [geoTabError, setGeoTabError] = useState(
    formValues?.installTelematics === true
      ? vehicleLoadData?.telematicsErrorCode || null
      : null,
  );
  const [geoTabResponse, setGeoTabResponse] = useState(null);
  const [geoTabDataValid, setGeoTabDataValid] = useState(false);
  const [geoTabRequested, setGeoTabRequested] = useState(false);
  const [switchedToNo, setSwitchedToNo] = useState(false);
  const prevFormValues = usePrevious(formValues);
  const prevSaves = usePrevious(saves);
  const prevGeoTab = usePrevious(geoTabSuccess);

  useEffect(() => {
    if (tabSaved) {
      scrollToElement('.react-tabs');
    }
  }, [tabSaved]);

  useEffect(() => {
    if (geoTabRequested) {
      if (!geoTabSuccess && geoTabResponse && geoTabDataValid) {
        if (geoTabData?.checkGeoTabInstallStatus?.logInstallSuccess === true) {
          setGeoTabSuccess(true);
          setTelematicsSuccess(true);
          setGeoTabError(null);
          dispatch(
            setLoadedVehicleData({
              telematicsErrorCode: null,
            }),
          );
          setGeoTabRequested(false);
        } else {
          setGeoTabSuccess(false);
          setTelematicsSuccess(false);
          setGeoTabError(geoTabData?.checkGeoTabInstallStatus?.errorCode);
          dispatch(
            setLoadedVehicleData({
              telematicsErrorCode:
                geoTabData?.checkGeoTabInstallStatus?.errorCode,
            }),
          );
        }
      }
      if (geoTabResponse === null && geoTabData?.checkGeoTabInstallStatus) {
        setGeoTabDataValid(true);
        setGeoTabResponse(geoTabData.checkGeoTabInstallStatus);
      }
    }
  }, [
    geoTabData,
    geoTabSuccess,
    geoTabResponse,
    setTelematicsSuccess,
    geoTabDataValid,
    dispatch,
    geoTabRequested,
  ]);

  useEffect(() => {
    if (geoTabError) {
      if (!switchedToNo && formValues?.installTelematics === true) {
        setSwitchedToNo(true);
      } else if (switchedToNo) {
        setFormValues({
          ...formValues,
          installTelematics: false,
        });
        dispatch(
          setLoadedVehicleData({
            installTelematics: false,
          }),
        );
        setSwitchedToNo(false);
      }
    }
  }, [geoTabError, formValues, switchedToNo, dispatch]);

  useEffect(() => {
    if (geoTabSuccess && !prevGeoTab) {
      submitHandler();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [geoTabSuccess, prevGeoTab]);

  useEffect(() => {
    setShowErrors(showFormErrors);
  }, [showFormErrors]);

  const validateForm = (formValues, vehicle) => {
    const errors = {};
    if (vehicle?.telematicsRequired) {
      if (typeof formValues?.installTelematics !== 'boolean') {
        errors.installTelematics = 'This is a required field';
      }

      if (formValues?.installTelematics === true) {
        if (formValues?.telematicsSN === '' || !formValues?.telematicsSN) {
          errors.telematicsSN = 'This is a required field';
        }

        if (formValues?.telematicsSN?.length > 12) {
          errors.telematicsSN = 'Cannot exceed 12 characters';
        }

        if (formValues?.currentMileage === '' || !formValues?.currentMileage) {
          errors.currentMileage = 'This is a required field';
        }

        if (
          formValues?.currentMileage &&
          isNaN(Number(formValues?.currentMileage))
        ) {
          errors.currentMileage = 'Must be a numeric value';
        }
      }

      if (
        formValues?.telematicsDetails &&
        formValues?.telematicsDetails.length > 0
      ) {
        if (formValues?.telematicsDetails.length > 1000) {
          errors.telematicsDetails = true;
        }
      }
    }
    return errors;
  };

  const changeHandler = (field, value) => {
    setTabSaved(false);
    setGeoTabSuccess(false);
    setGeoTabRequested(false);
    setGeoTabDataValid(false);
    setTouched('telematics', true);
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const doGeoTab = (vehicle, formValues, currentUser) => {
    setGeoTabResponse(null);
    setGeoTabDataValid(false);
    setGeoTabRequested(true);
    geoTabQuery({
      variables: {
        logInstallParams: {
          vin: vehicle.vin,
          telematicsDeviceSerialNumber: formValues.telematicsSN,
          installerCompany: `${currentUser.firstName} ${currentUser.lastName}`,
          installerName: `${currentUser.firstName} ${currentUser.lastName}`,
          odometer: Number(formValues.currentMileage),
          comments: 'PWA Submission',
        },
      },
    });
  };

  const submitHandler = () => {
    setShowErrors(false);
    setTabSaved(false);
    const errors = validateForm(formValues, vehicle);
    let isPartial = false;
    if (Object.keys(errors).length > 0) {
      isPartial = true;
      for (const [, error] of Object.entries(errors)) {
        if (error !== 'This is a required field') {
          isPartial = false;
        }
      }
      setTabPartial('telematics', isPartial);
      setFormErrors(errors);
      setTouched('telematics', true);
      setValid('telematics', false);
      setShowErrors(true);
    } else {
      if (
        !geoTabSuccess &&
        formValues?.installTelematics === true &&
        formValues?.telematicsSN &&
        formValues?.currentMileage &&
        !geoTabRequested
      ) {
        doGeoTab(vehicle, formValues, currentUser);
      } else {
        setTabPartial('telematics', false);
        setTouched('telematics', false);
        setValid('telematics', true);
        setTabSaved(true);
        dispatch(setLoadedVehicleData(formValues));
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(prevFormValues) !== JSON.stringify(formValues)) {
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        setTabPartial('telematics', isPartial);
        setFormErrors(errors);
        setValid('telematics', false);
      } else {
        setTabPartial('telematics', false);
        setFormErrors({});
        setValid('telematics', true);
      }
    }
  }, [prevFormValues, formValues, setValid, setTabPartial, vehicle]);

  useEffect(() => {
    if (Object.keys(vehicleLoadData).length > 0) {
      setFormValues(vehicleLoadData);
    }
  }, [vehicleLoadData]);

  useEffect(() => {
    if (prevSaves !== saves && prevSaves !== undefined) {
      setShowErrors(false);
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        if (!isPartial) {
          setFormErrors(errors);
          setShowErrors(true);
          return;
        }
      } else {
        setValid('telematics', true);
      }
      dispatch(setLoadedVehicleData(formValues));
    }
  }, [saves, prevSaves, formValues, vehicle, dispatch]);

  useEffect(() => {
    if (
      formValues.telematicsSN &&
      vehicleLoadData.telematicsSN &&
      vehicleLoadData.telematicsSN !== formValues.telematicsSN
    ) {
      setGeoTabDataValid(false);
      setGeoTabSuccess(false);
      setTelematicsSuccess(false);
      dispatch(
        setLoadedVehicleData({
          telematicsSN: formValues.telematicsSN,
          telematicsErrorCode: geoTabError,
        }),
      );
    }
  }, [formValues.telematicsSN, vehicleLoadData.telematicsSN, geoTabError]);

  const parseGeoTabError = (errorCode) => {
    let errorMessage = '';
    switch (errorCode) {
      case 'GEOTAB_DEVICE_NOT_COMMUNICATING':
        errorMessage =
          'The installed device is not actively communicating. Please ensure all lights are on and the device is securely installed/fastened. Initialization should complete when the vehicle is driven for 10-15 minutes.';
        break;
      case 'GEOTAB_INVALID_SERIAL_NUMBER':
        errorMessage = (
          <>
            The Serial Number is either invalid and/or does not exist, please
            report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      case 'GEOTAB_NO_GSA_ACCOUNT':
        errorMessage = (
          <>
            The Serial Number entered is not assigned to the GSA account, please
            report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      case 'GEOTAB_NO_RECENT_COMMUNICATION':
        errorMessage = (
          <>
            The installed device last communicated{' '}
            {geoTabResponse.lastServerCommunication}. Please ensure all lights
            on the device are solid, not blinking, and report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
      default:
        errorMessage = (
          <>
            An unknown error occurred during telematics device check. If the
            issue persists, please report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
    }
    return errorMessage;
  };

  return (
    <div className="grid-col">
      {tabSaved && (
        <Alert
          className="margin-bottom-3 tab-save-success"
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          Tab successfully saved.
        </Alert>
      )}
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            setFormValues({
              ...formValues,
              telematicsSN: result,
            });
            setTelematicsScan(true);
          }}
          onClose={() => {
            setScannerOpen(false);
          }}
        />
      )}
      <p className="border-bottom border-base-light padding-bottom-3">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <div className="padding-bottom-0">
        <p className="tab-title">Install telematics device</p>
        {geoTabError && (
          <Alert
            className="margin-bottom-3"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            {parseGeoTabError(geoTabError)}
          </Alert>
        )}
        {geoTabSuccess && formValues?.installTelematics && (
          <Alert
            className="margin-bottom-3"
            type="success"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            The telematics device was successfully connected.
          </Alert>
        )}
        {!vehicle?.telematicsRequired && (
          <p>This vehicle does not require telematics device installation.</p>
        )}
        {vehicle?.telematicsRequired && (
          <>
            <IsCorrectField
              error={formErrors?.installTelematics || ''}
              showErrors={showErrors}
              label="This vehicle requires telematics installation. Install the telematics device at this time?"
              defaultValue={vehicle?.installTelematics}
              currentValue={formValues?.installTelematics}
              fieldName="installTelematics"
              onFocus={() => setTouched('telematics', true)}
              onChange={(field, value) => {
                if (geoTabError && value === true && !switchedToNo) {
                  setTabSaved(false);
                  setGeoTabError(null);
                  setFormValues({
                    ...formValues,
                    installTelematics: true,
                  });
                } else {
                  changeHandler(field, value);
                }
              }}
              yesNoOnly={true}
            />
            {formValues?.installTelematics === true && (
              <>
                <div
                  className={
                    formErrors?.telematicsSN && showErrors
                      ? 'margin-bottom-3 form-error'
                      : 'margin-bottom-3'
                  }
                >
                  <Label className="margin-top-1" required>
                    <strong>Scan device serial number</strong>
                  </Label>
                  <div className="gray-text">Scan or type</div>
                  {formErrors?.telematicsSN && showErrors && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.telematicsSN}</strong>
                    </div>
                  )}
                  <Composition
                    areas={`
                      SerialNumber ScanBtn
                    `}
                    gap={8}
                    templateCols="1fr 70px"
                  >
                    {({ SerialNumber, ScanBtn }) => (
                      <>
                        <SerialNumber>
                          <div className="input-box">
                            <Textbox
                              type="text"
                              id="telematicsSN"
                              name="telematicsSN"
                              value={formValues?.telematicsSN || ''}
                              variant={
                                formErrors?.telematicsSN && showErrors
                                  ? 'error'
                                  : ''
                              }
                              onFocus={() => setTouched('telematics', true)}
                              onChange={(e) => {
                                setTabSaved(false);
                                setFormValues({
                                  ...formValues,
                                  telematicsSN: e.target.value,
                                });
                                setTelematicsScan(false);
                              }}
                              className="mobile-textbox margin-top-0"
                            />
                          </div>
                        </SerialNumber>
                        <ScanBtn>
                          <Button
                            variant="primary"
                            size="medium"
                            label=""
                            leftIcon={{
                              name: 'photo_camera',
                              iconName: 'photo_camera',
                            }}
                            onClick={() => {
                              setGeoTabDataValid(false);
                              setGeoTabSuccess(false);
                              setTelematicsSuccess(false);
                              setTouched('telematics', true);
                              setFormValues({
                                ...formValues,
                                telematicsSN: '',
                              });
                              setScannerOpen(true);
                            }}
                            className="icon-button"
                            style={{
                              height: '40px',
                              borderRadius: '.25rem',
                              position: 'static',
                              marginTop: '0',
                            }}
                          />
                        </ScanBtn>
                      </>
                    )}
                  </Composition>
                </div>
                <div
                  className={
                    formErrors?.currentMileage && showErrors
                      ? 'margin-bottom-3 form-error'
                      : 'margin-bottom-3'
                  }
                >
                  <Label className="margin-top-1" required>
                    <strong>Current mileage</strong>
                  </Label>
                  <div className="gray-text">At time of installation</div>
                  {formErrors?.currentMileage && showErrors && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.currentMileage}</strong>
                    </div>
                  )}
                  <div className="input-box">
                    <Textbox
                      type="text"
                      id="currentMileage"
                      name="currentMileage"
                      value={formValues?.currentMileage || ''}
                      variant={
                        formErrors?.currentMileage && showErrors ? 'error' : ''
                      }
                      onFocus={() => setTouched('telematics', true)}
                      onChange={(e) => {
                        setTabSaved(false);
                        setFormValues({
                          ...formValues,
                          currentMileage: e.target.value,
                        });
                      }}
                      className="mobile-textbox margin-top-0"
                    />
                  </div>
                </div>
                <CountdownTextboxField
                  id="telematicsDetails"
                  name="telematicsDetails"
                  defaultValue={formValues?.telematicsDetails || ''}
                  isError={
                    (formErrors?.telematicsDetails && showErrors) ||
                    (formValues?.telematicsDetails &&
                      formValues?.telematicsDetails.length > 1000)
                  }
                  onFocus={() => setTouched('telematics', true)}
                  onChange={(e) => {
                    setTabSaved(false);
                    setFormValues({
                      ...formValues,
                      telematicsDetails: e.target.value,
                    });
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          data-testid="continue-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Save"
          onClick={submitHandler}
        />
      </Box>
    </div>
  );
};

export default Telematics;
