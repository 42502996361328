import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

export const Ability = ({ subject, operation, defaultDisplay, children }) => {
  const ability = useAppAbility();

  if (!subject || !operation || !ability?.can(operation, subject)) {
    return defaultDisplay || null;
  }

  return <>{children}</>;
};

export default Ability;
