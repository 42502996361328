import React from 'react';
import { Box } from 'atomic-layout';
import { NavLink } from 'react-router-dom';
import 'styled-components/macro';
import { IndividualIcon } from '@gsa/afp-component-library';
import { Ability } from 'components';

export const ActionCard = ({
  title,
  path,
  icon,
  iconType,
  viewBox,
  subject,
  operation,
}) => {
  return (
    <Ability
      subject={subject || 'VehicleMarshalling'}
      operation={operation || 'view'}
    >
      <Box
        flexWrap="wrap"
        margin={8}
        flex={true}
        width="calc(50% - 16px);"
        minWidth={150}
        minHeight={150}
        widthMd={150}
        heightMd={150}
        padding={8}
        data-testid="action-card"
        css={`
          border: 1px solid #dfe1e2;
          cursor: pointer;
          background: transparent;
          display: inline-block;
          vertical-align: top;

          & > a {
            color: #000;
          }

          &:hover {
            border: 1px solid #162e51;
            background: #0050d8;

            & svg {
              fill: #fff;
            }

            & > a {
              color: #fff;

              & span {
                color: #fff;
              }
            }
          }
        `}
      >
        <NavLink
          to={path}
          css={`
            text-decoration: none;
            padding-top: 20px;
            display: block;
            min-height: 130px;
          `}
        >
          <div
            css={`
              text-align: center;
              width: 100%;
            `}
          >
            {iconType === 'internal' ? (
              <>{icon}</>
            ) : (
              <IndividualIcon
                className="usa-icon--size-4"
                iconName={icon}
                type={iconType || ''}
                viewBox={`${viewBox ? viewBox : '0 0 24 24'}`}
              />
            )}

            <div
              css={`
                line-height: 1.1rem;
                margin-top: 1rem;
              `}
            >
              {title}
            </div>
          </div>
        </NavLink>
      </Box>
    </Ability>
  );
};

export default ActionCard;
