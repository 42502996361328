import React, { useState, useEffect, useCallback } from 'react';
import {
  Label,
  Button,
  SelectDropdown,
  Alert,
} from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { useSync } from 'hooks';
import { LicensePlateField } from 'components';
import { Composition, Box } from 'atomic-layout';
import { isBefore } from 'date-fns';
import { setLoadedVehicleData, setMarshallingData } from 'reducers/marshalling';
import { scrollToElement } from 'utils';
import '../../../screens.css';

const VEHICLE_CLASS_G10 = 'G10';
const VEHICLE_CLASS_G12 = 'G12';

export const Plates = ({
  vehicle,
  showFormErrors = false,
  setValid,
  setTouched,
  setTabPartial,
  setTagScan,
  saves = 0,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { getPlateData } = useSync();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const [tabSaved, setTabSaved] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(showFormErrors);
  const [hasPlateError, setHasPlateError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const plateLookupData = useSelector(
    (state) => state?.marshalling?.plateLookup || [],
  );
  const plateLookupTime = useSelector(
    (state) => state?.marshalling?.plateLookupTs || 0,
  );
  const prevLookupTime = usePrevious(plateLookupTime);
  const prevFormValues = usePrevious(formValues);
  const prevSaves = usePrevious(saves);

  useEffect(() => {
    setShowErrors(showFormErrors);
  }, [showFormErrors]);

  useEffect(() => {
    if (tabSaved) {
      scrollToElement('.react-tabs');
    }
  }, [tabSaved]);

  const plateLookup = useCallback(
    async ({ plate, tagClass, tag, status }) => {
      dispatch(
        setMarshallingData({
          ...marshallingData,
          plateLookup: [],
        }),
      );
      getPlateData({
        variables: {
          plate,
          tagClass,
          tag,
          status,
        },
      });
    },
    [dispatch, getPlateData, marshallingData],
  );

  useEffect(() => {
    if (
      formValues?.plateA &&
      formValues?.plateB &&
      formValues?.plateA === formValues?.plateB &&
      !isSearching
    ) {
      setIsSearching(true);
      plateLookup({
        plate: formValues?.plateA,
      });
    }
  }, [plateLookup, formValues.plateA, formValues.plateB, isSearching]);

  const validateForm = (formValues, vehicle) => {
    const errors = {};
    if (!formValues?.plateA || formValues?.plateA.trim() === '') {
      errors.plateA = 'This is a required field';
    }

    if (!formValues?.plateB || formValues?.plateB.trim() === '') {
      errors.plateB = 'This is a required field';
    }

    const currentPlate = plateLookupData.length > 0 ? plateLookupData[0] : null;
    if (formValues?.plateA && formValues?.plateB) {
      if (
        formValues?.plateA.toUpperCase() !== formValues?.plateB.toUpperCase()
      ) {
        errors.plateA = errors.plateB = 'Plates do not match';
      } else {
        if (!currentPlate) {
          errors.plateA = errors.plateB = 'Plate not found';
        } else {
          if (currentPlate?.status === 'MISS') {
            errors.plateA = errors.plateB =
              'This license plate is reported as missing/stolen';
          }

          if (currentPlate?.status === 'ATTD') {
            errors.plateA = errors.plateB =
              'This license plate is associated with another vehicle';
          }

          if (vehicle?.vehicleClass !== currentPlate?.class) {
            if (
              vehicle?.vehicleClass === VEHICLE_CLASS_G12 ||
              vehicle?.vehicleClass === VEHICLE_CLASS_G10
            ) {
              if (
                currentPlate?.class !== VEHICLE_CLASS_G10 &&
                currentPlate?.class !== VEHICLE_CLASS_G12
              ) {
                errors.plateA = errors.plateB =
                  'Plate class does not match vehicle class';
              }
            } else {
              errors.plateA = errors.plateB =
                'Plate class does not match vehicle class';
            }
          }
        }
      }
    }

    if (formValues?.plateA && vehicle?.vehicleClass === 'G91') {
      if (!currentPlate) {
        errors.plateA = 'Plate not found';
      } else {
        if (['missing', 'stolen'].includes(currentPlate?.status)) {
          errors.plateA = 'This license plate is reported as missing/stolen';
        }

        if (currentPlate?.status === 'attached') {
          errors.plateA =
            'This license plate is associated with another vehicle';
        }

        if (vehicle?.vehicleClass !== currentPlate?.class) {
          errors.plateA = 'Plate class does not match vehicle class';
        }
      }
    }

    if (!formValues?.month || formValues?.month === '') {
      errors.month = 'This is a required field';
    }

    if (!formValues?.year || formValues?.year === '') {
      errors.year = 'This is a required field';
    }

    if (formValues?.month && formValues?.year) {
      const expirationDate = new Date(formValues?.year, formValues?.month, 1);
      if (!isBefore(new Date(), expirationDate)) {
        errors.expiration = 'This plate is expired';
      }
    }

    return errors;
  };

  const submitHandler = () => {
    setShowErrors(false);
    setTabSaved(false);
    const errors = validateForm(formValues, vehicle);
    let isPartial = false;
    if (Object.keys(errors).length > 0) {
      isPartial = true;
      for (const [, error] of Object.entries(errors)) {
        if (error !== 'This is a required field') {
          isPartial = false;
        }
      }
      setTabPartial('plates', isPartial);
      setFormErrors(errors);
      setTouched('plates', true);
      setValid('plates', false);
      setShowErrors(true);
    } else {
      setTabPartial('plates', false);
      setTouched('plates', false);
      setValid('plates', true);
      setTabSaved(true);
      dispatch(setLoadedVehicleData(formValues));
    }
  };

  const monthArray = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const monthOptions = [
    { label: '- Select -', value: '' },
    ...monthArray.map((month) => {
      return { label: month, value: parseInt(month) };
    }),
  ];

  const getYearArray = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const yearArray = [];
    for (let i = 6; i <= 10; i++) {
      yearArray.push(currentYear + i);
    }
    return yearArray;
  };

  const yearOptions = [
    { label: '- Select -', value: '' },
    ...getYearArray().map((year) => {
      return { label: year, value: year };
    }),
  ];

  useEffect(() => {
    if (
      JSON.stringify(prevFormValues) !== JSON.stringify(formValues) ||
      plateLookupTime > prevLookupTime
    ) {
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        setTabPartial('plates', isPartial);
        setFormErrors(errors);
        setValid('plates', false);
      } else {
        setTabPartial('plates', false);
        setFormErrors({});
        setValid('plates', true);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    prevFormValues,
    formValues,
    setValid,
    setTabPartial,
    vehicle,
    plateLookupTime,
    prevLookupTime,
  ]);

  useEffect(() => {
    if (Object.keys(vehicleLoadData).length > 0) {
      setFormValues(vehicleLoadData);
    }
  }, [vehicleLoadData]);

  useEffect(() => {
    if (prevSaves !== saves && prevSaves !== undefined) {
      setShowErrors(false);
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        if (!isPartial) {
          setFormErrors(errors);
          setShowErrors(true);
          return;
        }
      }
      dispatch(setLoadedVehicleData(formValues));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [saves, prevSaves, formValues, vehicle, dispatch]);

  const setScannedExp = (scanData, plate) => {
    let triggerBadPlate = false;
    const newFormValues = {
      ...formValues,
      [plate]: `${scanData.plateClass}${scanData.tag}`,
    };
    if (scanData?.expMonth) {
      newFormValues.month = scanData?.expMonth;
    }
    const availableYears = yearOptions.map((year) => year.value);
    if (
      scanData?.expYear &&
      availableYears.includes(Number(scanData.expYear))
    ) {
      newFormValues.year = scanData?.expYear;
    } else {
      triggerBadPlate = true;
    }
    if (triggerBadPlate) {
      setHasPlateError(true);
      setFormValues({
        plateA: '',
        plateB: '',
        month: '',
        year: '',
      });
    } else {
      setFormValues(newFormValues);
    }
  };

  return (
    <div className="grid-col">
      {tabSaved && (
        <Alert
          className="margin-bottom-3 tab-save-success"
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          Tab successfully saved.
        </Alert>
      )}
      {hasPlateError && (
        <Alert
          className="margin-bottom-3"
          type="error"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          Plate cannot be attached.
        </Alert>
      )}
      <p className="border-bottom border-base-light padding-bottom-3">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      <p className="tab-title margin-bottom-0">License Plate</p>
      <div className="margin-bottom-3">
        <strong>Plate class:</strong>{' '}
        {vehicle?.vehicleClass === VEHICLE_CLASS_G10 ||
        vehicle?.vehicleClass === VEHICLE_CLASS_G12
          ? `${VEHICLE_CLASS_G10} or ${VEHICLE_CLASS_G12}`
          : vehicle?.vehicleClass}
      </div>
      <LicensePlateField
        id="plateA"
        initialValue={formValues?.plateA || ''}
        showErrors={showErrors}
        errorMessage={formErrors?.plateA}
        required={true}
        label="Scan license plate 1"
        scanner={true}
        onChange={(plate, scanDetails) => {
          if (scanDetails) {
            setScannedExp(scanDetails, 'plateA');
            setTagScan(true);
          } else {
            setFormValues({
              ...formValues,
              plateA: plate,
            });
            setTagScan(false);
          }
          setTabSaved(false);
          setIsSearching(false);
        }}
        onFocus={() => setTouched('plates', true)}
        className="mobile-textbox margin-bottom-3"
      />
      {vehicle?.vehicleClass !== 'G91' && (
        <LicensePlateField
          id="plateB"
          initialValue={formValues?.plateB || ''}
          showErrors={showErrors}
          errorMessage={formErrors?.plateB}
          required={true}
          label="Scan license plate 2"
          scanner={true}
          onChange={(plate, scanDetails) => {
            if (scanDetails) {
              setScannedExp(scanDetails, 'plateB');
              setTagScan(true);
            } else {
              setFormValues({
                ...formValues,
                plateB: plate,
              });
              setTagScan(false);
            }
            setTabSaved(false);
            setIsSearching(false);
          }}
          onFocus={() => setTouched('plates', true)}
          className="mobile-textbox margin-bottom-0"
        />
      )}
      <div className="padding-bottom-3">
        <div
          className={
            (formErrors?.expiration || formErrors?.month || formErrors?.year) &&
            showErrors
              ? 'form-error'
              : ''
          }
        >
          <Label className="text-bold" required>
            License plates expiration date
          </Label>
          {formErrors.expiration && showErrors && (
            <div
              className="form-error-msg"
              style={{ color: '#B50909', padding: 0, margin: 0 }}
            >
              <strong>{formErrors.expiration}</strong>
            </div>
          )}
          <Composition
            areas={`
              ErrorA ErrorB
              Month Year
            `}
            gap={8}
            templateCols="auto"
            height={'auto'}
          >
            {({ ErrorA, ErrorB, Month, Year }) => (
              <>
                <ErrorA>
                  {formErrors?.month && showErrors && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.month}</strong>
                    </div>
                  )}
                </ErrorA>
                <Month>
                  <div style={{ color: '#71767A' }}>Month</div>
                  <SelectDropdown
                    id="expirationMonth"
                    name="expirationMonth"
                    disabled={false}
                    containerClassName="margin-top-0"
                    defaultValue=""
                    value={formValues?.month || ''}
                    onFocus={() => setTouched('plates', true)}
                    onChange={(e) => {
                      setTabSaved(false);
                      setFormValues({
                        ...formValues,
                        month: e.target.value,
                      });
                    }}
                    options={monthOptions}
                  />
                </Month>
                <ErrorB>
                  {formErrors?.year && showErrors && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.year}</strong>
                    </div>
                  )}
                </ErrorB>
                <Year>
                  <div style={{ color: '#71767A' }}>Year</div>
                  <SelectDropdown
                    id="expirationYear"
                    name="expirationYear"
                    disabled={false}
                    containerClassName="margin-top-0"
                    defaultValue=""
                    value={formValues?.year || ''}
                    onFocus={() => setTouched('plates', true)}
                    onChange={(e) => {
                      setTabSaved(false);
                      setFormValues({
                        ...formValues,
                        year: e.target.value,
                      });
                    }}
                    options={yearOptions}
                  />
                </Year>
              </>
            )}
          </Composition>
        </div>
      </div>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          data-testid="continue-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Save"
          onClick={submitHandler}
        />
      </Box>
    </div>
  );
};

export default Plates;
