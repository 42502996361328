import React from 'react';
import './AppointmentListing.css';

export const RowSub = ({ rowData }) => {
  return (
    <div className="row-sub grid-container padding-top-2 padding-bottom-2">
      <div>
        <div className="grid-col inline-block inline-half">
          <div className="text-bold padding-bottom-1 field-border">
            APPOINTMENT DETAILS
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Appt Time</div>
            <div className="text-right inline-block inline-40">
              3:15PM EST ({rowData.appointmentId})
            </div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Appt Type</div>
            <div className="text-right inline-block inline-40">
              Vehicle Exchange
            </div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Appt Missed</div>
            <div className="text-right inline-block inline-40">2</div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">
              Total reminder emails sent
            </div>
            <div className="text-right inline-block inline-40">1</div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">
              Date of last reminder email
            </div>
            <div className="text-right inline-block inline-40">08/17/2023</div>
          </div>
        </div>
        <div className="grid-col inline-block inline-half">
          <div className="text-bold padding-bottom-1 field-border">CONTACT</div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Driver Name</div>
            <div className="text-right inline-block inline-40">Max Miller</div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Driver Phone</div>
            <div className="text-right inline-block inline-40">
              589-465-4256
            </div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-40">Agency</div>
            <div className="text-right inline-block inline-60">
              Department of Homeland Security
            </div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">Customer POC</div>
            <div className="text-right inline-block inline-40">Ellie Smith</div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-40">FMC</div>
            <div className="text-right inline-block inline-60">
              Montgomery Fleet Office
            </div>
          </div>
          <div className="padding-top-1 padding-bottom-1 field-border">
            <div className="text-bold inline-block inline-60">FSR</div>
            <div className="text-right inline-block inline-40">
              Mary Roberts
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RowSub;
