import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import {
  Start,
  DeliveryDate,
  FindVehicle,
  DeliverVehicle,
  Review,
} from './screens';

export const VehicleDelivery = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const [screen, setScreen] = useState('start');
  const [vehicle, setVehicle] = useState(null);
  const [vehicleSuccess, setVehicleSuccess] = useState(false);
  const [continueSession, setContinueSession] = useState(false);
  const [sessionComplete, setSessionComplete] = useState(false);
  const [sessionCompleteCount, setSessionCompleteCount] = useState(0);

  // The screen changes weren't going to the top of the page, this handles that.
  useEffect(() => {
    window.scrollTo(0, 0);
    if (screen === 'start') {
      setVehicleSuccess(false);
    }
  }, [screen]);

  return (
    <div
      className={`grid-col margin-bottom-2${isDesktop ? ' slim-width' : ''}`}
    >
      <Breadcrumbs
        items={[{ path: '/home', text: 'Home' }, { text: 'Vehicle Delivery' }]}
      />
      {screen === 'start' && (
        <Start
          setScreen={setScreen}
          setContinueSession={setContinueSession}
          sessionComplete={sessionComplete}
          setSessionComplete={setSessionComplete}
          sessionCompleteCount={sessionCompleteCount}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'delivery-date' && (
        <DeliveryDate setScreen={setScreen} isDesktop={isDesktop} />
      )}
      {screen === 'find-vehicle' && (
        <FindVehicle
          setScreen={setScreen}
          setVehicle={setVehicle}
          vehicle={vehicle}
          vehicleSuccess={vehicleSuccess}
          setVehicleSuccess={setVehicleSuccess}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'vehicle-delivery' && (
        <DeliverVehicle
          setScreen={setScreen}
          vehicle={vehicle}
          setVehicleSuccess={setVehicleSuccess}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'review' && (
        <Review
          setScreen={setScreen}
          continueSession={continueSession}
          setContinueSession={setContinueSession}
          setSessionComplete={setSessionComplete}
          setSessionCompleteCount={setSessionCompleteCount}
          setVehicleSuccess={setVehicleSuccess}
          isDesktop={isDesktop}
        />
      )}
    </div>
  );
};

export default VehicleDelivery;
