import React, { useEffect, useState } from 'react';
import { Label, RadioButton } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Composition } from 'atomic-layout';

export const IsCorrectField = ({
  error,
  showErrors = false,
  label,
  defaultValue,
  defaultValueLabel,
  currentValue,
  fieldName,
  isRequired = true,
  onChange,
  onFocus,
  yesNoOnly = false,
  children,
}) => {
  const [isCorrect, setIsCorrect] = useState(null);
  const [init, setInit] = useState(false);
  const prevCorrect = usePrevious(isCorrect);

  useEffect(() => {
    if (
      (currentValue === defaultValue && currentValue) ||
      (currentValue === true && !children)
    ) {
      setIsCorrect(true);
    } else if (currentValue !== defaultValue && currentValue && defaultValue) {
      setIsCorrect(false);
    } else if (
      currentValue !== null &&
      currentValue !== undefined &&
      currentValue !== defaultValue &&
      yesNoOnly
    ) {
      setIsCorrect(currentValue);
    } else if (
      yesNoOnly &&
      defaultValue !== null &&
      defaultValue !== undefined &&
      (currentValue === null || currentValue === undefined)
    ) {
      setIsCorrect(defaultValue);
    }
  }, [currentValue, defaultValue, yesNoOnly, children]);

  useEffect(() => {
    if (
      typeof onChange === 'function' &&
      (isCorrect !== prevCorrect || init === false)
    ) {
      onChange(fieldName, isCorrect);
      setInit(true);
    }
  }, [isCorrect, onChange, fieldName, prevCorrect, init]);

  const shouldShowField = !(
    ((isCorrect === true || isCorrect === null) && defaultValue) ||
    !children
  );
  const shouldShowRadio = defaultValue || !children;

  if (!fieldName) {
    return null;
  }

  return (
    <div
      data-testid={`${fieldName}-form-field`}
      className={
        error && showErrors && isCorrect === null
          ? 'margin-bottom-3 form-error'
          : 'margin-bottom-3'
      }
    >
      <Composition
        areas={`
          RadioLabel RadioLabel
          Radio1 Radio2
          FieldLabel FieldLabel
          Field Field
        `}
        areasMd={`
          RadioLabel RadioLabel RadioLabel
          Radio1 Radio2 .
          FieldLabel FieldLabel FieldLabel
          Field Field Field
        `}
        templateCols="auto"
        templateColsMd="150px 150px auto"
      >
        {({ RadioLabel, Radio1, Radio2, FieldLabel, Field }) => (
          <>
            <RadioLabel>
              {shouldShowRadio && (
                <>
                  <Label className="margin-top-0">
                    <span
                      className="text-bold"
                      data-testid={`${fieldName}-radio-label`}
                    >
                      {label}{' '}
                      {isRequired && (
                        <span
                          data-testid={`${fieldName}-required-field`}
                          className="afp-required-field"
                        >
                          *
                        </span>
                      )}
                    </span>
                    <span className="margin-left-1">
                      {defaultValueLabel || defaultValue || ''}
                    </span>
                  </Label>
                  {error && showErrors && isCorrect === null && (
                    <div
                      className="form-error-msg"
                      data-testid={`${fieldName}-radio-error`}
                    >
                      <strong>{error}</strong>
                    </div>
                  )}
                </>
              )}
            </RadioLabel>
            <Radio1>
              {shouldShowRadio && (
                <RadioButton
                  data-testid={`${fieldName}-radio-yes`}
                  id={`${fieldName}-radio-yes`}
                  name={`${fieldName}-radio`}
                  label="Yes"
                  checked={isCorrect === true}
                  onFocus={(e) => {
                    if (typeof onFocus === 'function') {
                      onFocus(e);
                    }
                  }}
                  onChange={() => {
                    setIsCorrect(true);
                  }}
                />
              )}
            </Radio1>
            <Radio2>
              {shouldShowRadio && (
                <RadioButton
                  data-testid={`${fieldName}-radio-no`}
                  id={`${fieldName}-radio-no`}
                  name={`${fieldName}-radio`}
                  label="No"
                  checked={isCorrect === false}
                  onFocus={(e) => {
                    if (typeof onFocus === 'function') {
                      onFocus(e);
                    }
                  }}
                  onChange={() => {
                    setIsCorrect(false);
                  }}
                />
              )}
            </Radio2>
            <FieldLabel>
              {shouldShowField && (
                <Label className={defaultValue ? '' : 'margin-top-0'}>
                  <span
                    className="text-bold"
                    data-testid={`${fieldName}-field-label`}
                  >
                    {label}{' '}
                    {isRequired && (
                      <span className="afp-required-field">*</span>
                    )}
                  </span>
                </Label>
              )}
            </FieldLabel>
            <Field>
              {shouldShowField && (
                <>
                  {error && showErrors && (
                    <div
                      className="form-error-msg"
                      data-testid={`${fieldName}-field-error`}
                    >
                      <strong>{error}</strong>
                    </div>
                  )}
                  {children}
                </>
              )}
            </Field>
          </>
        )}
      </Composition>
    </div>
  );
};

export default IsCorrectField;
