import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import './OnlineStatusBanner.css';

export const OnlineStatusBanner = ({ isOnline = false }) => {
  return (
    <div data-testid="online-status-banner" className="online-status-banner">
      <StatusBadge variant={isOnline ? 'Ready-White' : 'Warning-White'}>
        {isOnline ? 'Online' : 'Offline'}
      </StatusBadge>
    </div>
  );
};

export default OnlineStatusBanner;
