import { combineReducers } from 'redux';
import user from './user';
import marshalling from './marshalling';
import listings from './listings';

const combinedReducer = combineReducers({
  user,
  marshalling,
  listings,
});

const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};

export default rootReducer;
