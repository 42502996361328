import React, { useState, useEffect, useCallback } from 'react';
import { PageTitle, Button, Alert } from '@gsa/afp-component-library';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useSync } from 'hooks';
import { Scanner, VinField } from 'components';
import { setMarshallingData } from 'reducers/marshalling';
import { formatVehicleTitle, VINValidation } from 'utils';
import '../screens.css';

export const Start = ({
  setScreen,
  setVehicle,
  vehicle,
  sessionComplete,
  setSessionComplete,
  setVinScan,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { getVehicleData } = useSync();
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [search, setSearch] = useState('');
  const [isScanned, setIsScanned] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLookupData = useSelector(
    (state) => state?.marshalling?.lookup || [],
  );
  const vehicleLookupTime = useSelector(
    (state) => state?.marshalling?.lookupTs || 0,
  );
  const prevLookupTime = usePrevious(vehicleLookupTime);
  const loadData = marshallingData?.loaded || [];
  const plateLookupData = useSelector(
    (state) => state?.marshalling?.plateLookup || [],
  );

  const vehicleLookup = useCallback(
    async ({ vin, orderNumber, id }) => {
      dispatch(
        setMarshallingData({
          ...marshallingData,
          lookup: [],
        }),
      );
      getVehicleData({
        variables: {
          vin,
          orderNumber,
          id,
        },
      });
    },
    [dispatch, getVehicleData, marshallingData],
  );

  useEffect(() => {
    if (
      search.trim() !== '' &&
      isSearching &&
      prevLookupTime < vehicleLookupTime
    ) {
      if (
        vehicleLookupData.length > 0 &&
        search.trim() === vehicleLookupData[0]?.vin
      ) {
        setIsSearching(false);
        setVehicle(vehicleLookupData[0]);
        setSessionComplete(false);
        setIsScanned(false);
        setScreen('load-vehicle');
      } else {
        setIsSearching(false);
        if (isScanned) {
          setError(
            <>
              <div>
                <strong>VIN not recognized.</strong>
              </div>
              <div>
                The scanned VIN could not be found in our system. Please
                manually enter the VIN or order number to see available
                vehicles.
              </div>
            </>,
          );
          setIsScanned(false);
          return;
        } else {
          setFormErrors({
            ...formErrors,
            search: 'VIN not found',
          });
          return;
        }
      }
    }
  }, [
    search,
    isScanned,
    formErrors,
    prevLookupTime,
    vehicleLookupTime,
    isSearching,
    setScreen,
    setSessionComplete,
    setVehicle,
    vehicleLookupData,
  ]);

  const submitHandler = useCallback(() => {
    setShowErrors(false);
    if (search.trim() === '') {
      setFormErrors({
        ...formErrors,
        search: 'VIN is required',
      });
      setShowErrors(true);
      return;
    }

    const VINError = VINValidation(search);
    if (VINError) {
      setFormErrors({
        ...formErrors,
        search: VINError,
      });
      setShowErrors(true);
      return;
    }

    const vehicleLoaded = loadData.filter((vehicle) => vehicle.vin === search);
    if (vehicleLoaded.length > 0) {
      setError(
        <>
          <div data-testid="alert-scan-find-vehicle">
            This vehicle has already been scanned and loaded.
          </div>
          <div>
            <strong>{formatVehicleTitle(vehicleLoaded[0])}</strong>
          </div>
          <div>
            <strong>VIN: {vehicleLoaded[0].vin}</strong>
          </div>
          <div>
            <strong>RPN/Order Number: {vehicleLoaded[0].orderNumber}</strong>
          </div>
        </>,
      );
      return;
    }

    setIsSearching(true);
    vehicleLookup({
      vin: search,
    });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [vehicleLookup, search, formErrors, loadData]);

  useEffect(() => {
    if (search) {
      if (isScanned && !error && !formErrors?.search && !isSearching) {
        submitHandler();
      }
    }
  }, [isScanned, search, submitHandler, error, formErrors.search, isSearching]);

  if (!setScreen || !setVehicle) {
    return null;
  }

  return (
    <div data-testid="vehicle-load-start-screen" className="grid-col">
      <PageTitle title="Load vehicle" />
      {error && (
        <Alert
          type="error"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          {error}
        </Alert>
      )}
      {sessionComplete && (
        <Alert
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={true}
          onClose={() => setSessionComplete(false)}
        >
          <div>
            <strong>Vehicle was loaded:</strong>
          </div>
          <div>{formatVehicleTitle(vehicle)}</div>
          <div>VIN: {vehicle.vin}</div>
          <div>
            Plate: {vehicle.vehicleClass}-{plateLookupData[0]?.tag}
          </div>
        </Alert>
      )}
      <p>Please scan or enter the VIN to load a vehicle.</p>
      <div className="padding-bottom-2 padding-top-2">
        <Button
          data-testid="scanner-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Scan VIN"
          leftIcon={{
            name: 'photo_camera',
            iconName: 'photo_camera',
          }}
          onClick={() => {
            setFormErrors({
              ...formErrors,
              search: '',
            });
            setScannerOpen(true);
            setSearch('');
          }}
        />
      </div>
      <div>
        <VinField
          id="search"
          initialValue={search}
          showErrors={showErrors}
          required={true}
          label="Search by VIN"
          scanner={false}
          onChange={(vin) => {
            setVinScan(false);
            setSearch(vin);
          }}
          onValidate={(isValid, errorTxt) => {
            if (!isValid) {
              setFormErrors({
                ...formErrors,
                search: errorTxt,
              });
            }
          }}
          className="mobile-textbox icon-input"
        />
        <Button
          className="icon-button"
          data-testid="search-button"
          leftIcon={{
            name: 'search',
            iconName: 'search',
          }}
          onClick={submitHandler}
        />
      </div>
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            setIsScanned(true);
            setSearch(result);
            setVinScan(true);
          }}
          onClose={() => {
            setScannerOpen(false);
            setIsScanned(false);
          }}
        />
      )}
    </div>
  );
};

export default Start;
