//import validateVINFormat from  '@gsa/afp-shared-ui-utils'
//VINFormat is added as a copy here until able to utilize in build

export const VINFormat = (vin) => {
  return /^(?=.[A-HJ-NPR-Z0-9])(?!(.[IO])(.*[Q]))([A-HJ-NPR-Z0-9]{17})$/.test(
    vin,
  );
};

export const checkBitMatch = (fieldValue) => {
  const val = new Array(17);

  for (let i = 0; i < val.length; i++) {
    switch (fieldValue[i]) {
      case 'A':
        val[i] = 1;
        break;
      case 'B':
        val[i] = 2;
        break;
      case 'C':
        val[i] = 3;
        break;
      case 'D':
        val[i] = 4;
        break;
      case 'E':
        val[i] = 5;
        break;
      case 'F':
        val[i] = 6;
        break;
      case 'G':
        val[i] = 7;
        break;
      case 'H':
        val[i] = 8;
        break;
      case 'J':
        val[i] = 1;
        break;
      case 'K':
        val[i] = 2;
        break;
      case 'L':
        val[i] = 3;
        break;
      case 'M':
        val[i] = 4;
        break;
      case 'N':
        val[i] = 5;
        break;
      case 'P':
        val[i] = 7;
        break;
      case 'R':
        val[i] = 9;
        break;
      case 'S':
        val[i] = 2;
        break;
      case 'T':
        val[i] = 3;
        break;
      case 'V':
        val[i] = 5;
        break;
      case 'W':
        val[i] = 6;
        break;
      case 'X':
        val[i] = 7;
        break;
      case 'Y':
        val[i] = 8;
        break;
      case 'Z':
        val[i] = 9;
        break;
      default:
        val[i] = parseInt(fieldValue[i]);
        break;
    }
  }

  let sum = 0;
  const weight = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
  for (let i = 0; i < val.length; i++) {
    sum += val[i] * weight[i];
  }
  const remainder = sum % 11;
  if (remainder === 10) {
    return 'Invalid VIN';
  }
  if (remainder === parseInt(fieldValue[8])) {
    return '';
  } else {
    return 'Invalid VIN';
  }
};

export const VINValidation = (fieldValue) => {
  const validatedUtil = VINFormat(fieldValue);

  if (fieldValue?.trim().length !== 17) {
    return 'VIN should be 17 characters long';
  } else if (fieldValue.match(/([a-z])\1/i)) {
    return 'Invalid VIN';
  } else if (fieldValue) {
    for (let i = 0; i < fieldValue?.length; i++) {
      if (fieldValue[i]?.match(/[a-zA-Z0-9]/) === null) {
        return 'Special characters are not allowed';
      } else if (fieldValue[i]?.match(/[ I | O | Q]/i)) {
        return 'Invalid VIN';
      } else if (i === 9) {
        if (fieldValue[i]?.match(/[ Z | U | 0]/i)) {
          return 'Invalid VIN';
        }
      } else if (i > 12) {
        if (fieldValue[i]?.match(/[A-Za-z]/)) {
          return 'Invalid VIN';
        }
      }
    }
    return checkBitMatch(fieldValue);
  } else if (validatedUtil === false) {
    return 'Invalid VIN';
  } else {
    return '';
  }
};

export function isAlphaNumeric(input = '', len = 8) {
  const pattern = new RegExp(`^[a-zA-Z0-9]{${len}}$`);
  return pattern.test(input);
}
