import React, { useEffect, useState, useCallback } from 'react';
import {
  PageTitle,
  Button,
  Checkbox,
  Alert,
  RadioButton,
} from '@gsa/afp-component-library';
import {
  Breadcrumbs,
  Scanner,
  LicensePlateField,
  PlateListing,
  AreYouSureModal,
  PlateReconcileAckModal as PlateDistructionModal,
} from 'components';
import { Composition, useResponsiveQuery, Box } from 'atomic-layout';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSync } from 'hooks';
import {
  updateDestructionSession,
  clearDestructionSession,
} from 'reducers/marshalling';
import { plateValidation } from 'utils';
import './PlateDestruction.css';

const SCAN_MAXIMUM_ALLOWED = 80;
const SPECIAL_TAG_CLASS_G91 = 'G91';

export const PlateDestruction = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const history = useHistory();
  const dispatch = useDispatch();
  const plateSessionData = useSelector(
    (state) => state?.marshalling?.plateDestruction || {},
  );
  const userData = useSelector((state) => state?.user || {});
  const vendorId = userData?.vendorId || '0';
  const [scanResult, setScanResult] = useState(null);
  const [lastScan, setLastScan] = useState(null);
  const [error, setError] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [showPlateDistructionModal, setShowPlateDistructionModal] =
    useState(false);
  const [issuePlates, setIssuePlates] = useState([]);
  const [isMissingPlate, setIsMissingPlate] = useState(false);
  const [showPlateDeleteModal, setShowPlateDeleteModal] = useState(false);
  const [plateToDelete, setPlateToDelete] = useState(null);
  const [showMissingPlateModal, setShowMissingPlateModal] = useState(false);
  const [showCancelSessionModal, setShowCancelSessionModal] = useState(false);
  const [maximumScanned, setMaximumScanned] = useState(false);
  const [nearMax, setNearMax] = useState(false);
  const [sessionSuccess, setSessionSuccess] = useState(false);
  const [invalidSession, setInvalidSession] = useState(false);
  const [validatePlateAddedManually, setValidatePlateAddedManually] = useState({
    error: '',
    status: false,
  });
  const [manualEntryPlateNumber, setManualEntryPlateNumber] = useState({
    value: '',
    hasA: true,
    hasB: true,
  });
  const [manualLicensePlateFormError, setManualLicensePlateFormError] =
    useState({
      error: '',
      validated: false,
    });
  const [licensePlateAddedManually, setLicensePlateAddedManually] =
    useState(false);
  const [scanOrManual, setScanOrManual] = useState('scan');
  const { completePlateDestroy } = useSync();
  const prevPlate = usePrevious(scanResult);
  const prevLastScan = usePrevious(lastScan);

  const plateValidationHandler = (fieldValue) => {
    if (!fieldValue) return 'This is required field';
    if (fieldValue.length !== 8) return 'Invalid plate number';
    return plateValidation(fieldValue);
  };

  const formatPlate = () => {
    return (
      manualEntryPlateNumber.value.slice(0, 3) +
      '-' +
      manualEntryPlateNumber.value.slice(3)
    );
  };

  const isPendingPlate = () => {
    const currentPlate = plateSessionData?.currentPlate || null;
    const tagClass = manualEntryPlateNumber.value.slice(0, 3);
    const lastData = currentPlate ? plateSessionData?.plates[currentPlate] : {};
    const platesScanned = Object.keys(lastData);
    const aOrBPlate = platesScanned[0] || null;
    const lastTagClass =
      lastData && lastData[aOrBPlate] ? lastData[aOrBPlate]?.tagClass : null;

    if (
      plateSessionData?.requiredTotal !== null &&
      platesScanned.length !== plateSessionData?.requiredTotal &&
      tagClass !== SPECIAL_TAG_CLASS_G91 &&
      currentPlate !== null &&
      lastTagClass !== null &&
      (lastTagClass !== tagClass ||
        currentPlate !== manualEntryPlateNumber.value)
    ) {
      return true;
    }

    return false;
  };

  const hasPlateAlreadyScanned = () => {
    const plateInSession =
      plateSessionData?.plates?.[manualEntryPlateNumber.value];
    const tagClass = manualEntryPlateNumber.value.slice(0, 3);

    if (isPendingPlate()) {
      setValidatePlateAddedManually({
        ...validatePlateAddedManually,
        error:
          'These plates do not match. Please re-enter second plate or select missing second plate.',
        status: true,
      });
      return true;
    } else if (
      plateInSession &&
      (tagClass === SPECIAL_TAG_CLASS_G91 ||
        (plateInSession?.A && plateInSession?.B))
    ) {
      setValidatePlateAddedManually({
        ...validatePlateAddedManually,
        error: (
          <>
            This plate has already been scanned.{' '}
            <strong>
              {manualEntryPlateNumber.value.slice(0, 3)}-
              {manualEntryPlateNumber.value.slice(3)}
            </strong>
          </>
        ),
        status: true,
      });
      return true;
    } else {
      setValidatePlateAddedManually({
        ...validatePlateAddedManually,
        error: ``,
        status: false,
      });
      return false;
    }
  };

  const manuallyAddSecondPlate = () => {
    const plateInSession =
      plateSessionData?.plates?.[manualEntryPlateNumber.value];
    const tagClass = manualEntryPlateNumber.value.slice(0, 3);
    const plateNumber = manualEntryPlateNumber.value;
    const isAorB = plateInSession?.A ? 'A' : 'B';
    const plate = plateInSession[isAorB].plate;
    const expMonth = plateInSession[isAorB].expMonth;
    const expYear = plateInSession[isAorB].expYear;

    const getPlateSideInfos = (plateAOrB) => {
      return { plate, plateAOrB, tagClass, expMonth, expYear };
    };

    dispatch(
      updateDestructionSession({
        plates: {
          ...plateSessionData?.plates,
          [plateNumber]: {
            A: getPlateSideInfos('A'),
            B: getPlateSideInfos('B'),
          },
        },
        requiredTotal: null,
        currentPlate: null,
      }),
    );
  };

  const manuallyAddG91Plate = () => {
    const plateNumber = manualEntryPlateNumber.value;
    const plate = manualEntryPlateNumber.value.slice(3);
    dispatch(
      updateDestructionSession({
        plates: {
          ...plateSessionData?.plates,
          [plateNumber]: {
            A: {
              plate,
              plateAOrB: 'A',
              tagClass: SPECIAL_TAG_CLASS_G91,
            },
          },
        },
        requiredTotal: null,
        currentPlate: null,
      }),
    );
  };

  const handleManualAddLicensePlate = () => {
    const plateFormValidationErr = plateValidationHandler(
      manualEntryPlateNumber.value,
    );

    if (plateFormValidationErr) {
      setManualLicensePlateFormError({
        ...manualLicensePlateFormError,
        error: plateFormValidationErr,
      });
      setShowErrors(true);
    } else if (hasPlateAlreadyScanned()) {
      setManualLicensePlateFormError({
        ...manualLicensePlateFormError,
        error: '',
        validated: false,
      });
      setShowErrors(true);
      return;
    } else if (
      manualEntryPlateNumber.value.slice(0, 3) === SPECIAL_TAG_CLASS_G91
    ) {
      manuallyAddG91Plate();
      setManualLicensePlateFormError({
        ...manualLicensePlateFormError,
        error: '',
        validated: false,
      });
      setManualEntryPlateNumber({
        ...licensePlateAddedManually,
        value: '',
        hasA: true,
        hasB: true,
      });
      setValidatePlateAddedManually({
        ...validatePlateAddedManually,
        error: '',
        status: false,
      });
    } else {
      const plateInSession =
        plateSessionData?.plates?.[manualEntryPlateNumber.value];

      if (plateInSession) {
        manuallyAddSecondPlate();
        setError(null);
        setManualLicensePlateFormError({
          ...manualLicensePlateFormError,
          error: '',
          validated: false,
        });
        setManualEntryPlateNumber({
          ...licensePlateAddedManually,
          value: '',
          hasA: true,
          hasB: true,
        });
        setValidatePlateAddedManually({
          ...validatePlateAddedManually,
          error: '',
          status: false,
        });
      } else {
        setManualLicensePlateFormError({
          ...manualLicensePlateFormError,
          error: '',
          validated: true,
        });
        setLicensePlateAddedManually(true);
      }
    }
  };

  const parsePlate = (plateTag) => {
    const plateAOrB = plateTag.charAt(0);
    const expMonth = plateTag.slice(1, 3);
    const expYear = plateTag.slice(3, 7);
    const tagClass = plateTag.slice(7, 10);
    const plate = plateTag.slice(10);
    return {
      full: `${tagClass}${plate}`,
      plate,
      plateAOrB,
      expMonth,
      expYear,
      tagClass,
    };
  };

  const validateSession = () => {
    let isValid = true;
    const plates = plateSessionData?.plates;
    if (plates) {
      for (const [, plateData] of Object.entries(plates)) {
        const hasA = plateData?.A;
        const hasB = plateData?.B;
        const details = hasA ? plateData.A : plateData.B;
        if (details?.tagClass !== SPECIAL_TAG_CLASS_G91 && (!hasA || !hasB)) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const handleMissingPlate = () => {
    setError(null);
    const currentPlate = plateSessionData?.currentPlate;
    const missingPlate = plateSessionData?.plates[currentPlate]?.A ? 'B' : 'A';
    dispatch(
      updateDestructionSession({
        plates: {
          ...(plateSessionData?.plates || {}),
          [currentPlate]: {
            ...(plateSessionData?.plates &&
            plateSessionData?.plates[currentPlate]
              ? plateSessionData?.plates[currentPlate]
              : {}),
            [missingPlate]: {
              plateAOrB: missingPlate,
              missing: true,
            },
          },
        },
        requiredTotal: null,
        currentPlate: null,
      }),
    );
  };

  const getTotalPlatesScanned = (plateSessionData) => {
    let totalPlatesScanned = 0;
    if (plateSessionData && plateSessionData?.plates) {
      for (const [, plateData] of Object.entries(plateSessionData?.plates)) {
        if (plateData?.A && plateData?.A?.missing !== true) {
          totalPlatesScanned += 1;
        }
        if (plateData?.B && plateData?.B?.missing !== true) {
          totalPlatesScanned += 1;
        }
      }
    }
    return totalPlatesScanned;
  };

  const handlePlate = useCallback((plateTag, plateSessionData, dispatch) => {
    setError(null);
    let error = false;
    const justPlate = plateTag.slice(7);
    if (
      !plateTag ||
      plateTag.length !== 15 ||
      plateValidation(justPlate) !== ''
    ) {
      error = (
        <>
          Invalid plate barcode. <code>{plateTag}</code>
        </>
      );
    }

    const currentPlate = plateSessionData?.currentPlate || null;
    const { full, plate, plateAOrB, expMonth, expYear, tagClass } =
      parsePlate(plateTag);

    if (!['A', 'B'].includes(plateAOrB)) {
      error = (
        <>
          Invalid plate barcode. <code>{plateTag}</code>
        </>
      );
    }

    if (
      plateSessionData?.plates &&
      plateSessionData?.plates[full] &&
      plateSessionData?.plates[full][plateAOrB]
    ) {
      const plateErrorData = plateSessionData?.plates[full][plateAOrB];
      error = (
        <>
          This plate has already been scanned.{' '}
          <strong>
            {plateAOrB} {plateErrorData?.tagClass}-{plate}.
          </strong>
        </>
      );
    }

    const lastData = currentPlate ? plateSessionData?.plates[currentPlate] : {};
    const platesScanned = Object.keys(lastData);
    const aOrBPlate = platesScanned[0] || null;
    const lastTagClass =
      lastData && lastData[aOrBPlate] ? lastData[aOrBPlate]?.tagClass : null;

    if (
      plateSessionData?.requiredTotal !== null &&
      platesScanned.length !== plateSessionData?.requiredTotal &&
      tagClass !== SPECIAL_TAG_CLASS_G91 &&
      currentPlate !== null &&
      lastTagClass !== null &&
      (lastTagClass !== tagClass || currentPlate !== full)
    ) {
      error =
        'These plates do not match. Please re-scan second plate or select missing second plate.';
    }

    const totalScanned = getTotalPlatesScanned(plateSessionData);
    if (
      tagClass !== SPECIAL_TAG_CLASS_G91 &&
      totalScanned === SCAN_MAXIMUM_ALLOWED - 1 &&
      !error &&
      platesScanned.length === 0
    ) {
      setNearMax(true);
      return;
    }

    if (!error) {
      let requiredTotal = tagClass === SPECIAL_TAG_CLASS_G91 ? 1 : 2;
      let currentPlateItem = full;

      dispatch(
        updateDestructionSession({
          plates: {
            ...(plateSessionData?.plates || {}),
            [full]: {
              ...(plateSessionData?.plates && plateSessionData?.plates[full]
                ? plateSessionData?.plates[full]
                : {}),
              [plateAOrB]: {
                plate,
                plateAOrB,
                expMonth,
                expYear,
                tagClass,
              },
            },
          },
          requiredTotal,
          currentPlate: currentPlateItem,
        }),
      );
      return true;
    } else {
      setError(error);
      return false;
    }
  }, []);

  const deletePlate = (plate) => {
    const newPlates = {};
    for (const [tag, data] of Object.entries(plateSessionData?.plates)) {
      if (tag !== plate) {
        newPlates[tag] = data;
      }
    }
    dispatch(
      updateDestructionSession({
        ...plateSessionData,
        plates: newPlates,
        requiredTotal: null,
        currentPlate: null,
      }),
    );
    setMaximumScanned(false);
    setError(false);
    setManualLicensePlateFormError({
      ...manualLicensePlateFormError,
      error: '',
      validated: false,
    });
    setManualEntryPlateNumber({
      ...licensePlateAddedManually,
      value: '',
      hasA: true,
      hasB: true,
    });
    setValidatePlateAddedManually({
      ...validatePlateAddedManually,
      error: '',
      status: false,
    });
  };

  const processSuccess = () => {
    setMaximumScanned(false);
    setSessionSuccess(true);
    setSubmissionError(false);
    dispatch(clearDestructionSession());
    setShowPlateDistructionModal(false);
    setError(false);
    setManualLicensePlateFormError({
      ...manualLicensePlateFormError,
      error: '',
      validated: false,
    });
  };

  const submitDestroyPlates = async () => {
    const isValid = validateSession();
    setError(null);
    setInvalidSession(false);
    if (isValid) {
      const plateData = [];

      for (const [, data] of Object.entries(plateSessionData?.plates)) {
        for (const [, plateSideValues] of Object.entries(data)) {
          if (!plateSideValues?.missing) {
            plateData.push(plateSideValues);
          }
        }
      }

      const completeData = await completePlateDestroy({
        variables: {
          platesReconcileAndDestructInput: {
            vendorId: vendorId,
            isOnline: true,
            plates: plateData,
          },
        },
      });

      const destroyResponse = completeData?.data?.destroyPlates;
      if (destroyResponse?.globalSuccessStatus === true) {
        if (destroyResponse?.plateStatuses?.length < 1) {
          processSuccess();
        } else {
          const issuePlatesData = destroyResponse?.plateStatuses;
          if (issuePlatesData?.length > 0) {
            setIssuePlates(issuePlatesData);
            setShowPlateDistructionModal(true);
          }
        }
      } else {
        setSubmissionError(true);
      }
    } else {
      setInvalidSession(true);
    }
  };

  const showSinglePlateDeleteModal = (plateToDelete) => {
    setPlateToDelete(plateToDelete);
    setShowPlateDeleteModal(true);
  };

  useEffect(() => {
    if (scanResult && (scanResult !== prevPlate || lastScan > prevLastScan)) {
      setError(null);
      handlePlate(scanResult, plateSessionData, dispatch);
    }
  }, [
    scanResult,
    plateSessionData,
    prevPlate,
    lastScan,
    prevLastScan,
    dispatch,
    handlePlate,
  ]);

  useEffect(() => {
    const totalScanned = getTotalPlatesScanned(plateSessionData);
    if (totalScanned >= SCAN_MAXIMUM_ALLOWED) {
      setMaximumScanned(true);
    } else {
      setMaximumScanned(false);
    }
  }, [plateSessionData, maximumScanned]);

  useEffect(() => {
    return () => {
      if (window.pwaScanner && !window.pwaScanner.isContextDestroyed()) {
        window.pwaScanner.destroyContext();
      }
    };
  }, []);

  const currentPlate =
    plateSessionData && plateSessionData?.plates
      ? plateSessionData?.plates[plateSessionData?.currentPlate]
      : null;

  const hasA = currentPlate && currentPlate?.A;
  const hasB = currentPlate && currentPlate?.B;
  const currentPlateData = currentPlate
    ? hasA
      ? currentPlate.A
      : currentPlate.B
    : null;
  const needOtherPlate =
    currentPlate && (!hasA || !hasB) && plateSessionData?.requiredTotal > 1;
  const hasPlates =
    plateSessionData?.plates &&
    Object.keys(plateSessionData?.plates).length > 0;

  const handleManualAddSession = () => {
    const tagClass = manualEntryPlateNumber.value.slice(0, 3);
    const plate = manualEntryPlateNumber.value.slice(3);
    const currentPlate = manualEntryPlateNumber.value;

    const getPlateSideInfos = (plateAOrB) => {
      return manualEntryPlateNumber['has' + plateAOrB] === true
        ? { plate, plateAOrB, tagClass, tagScan: false }
        : { plateAOrB, missing: true, tagScan: false };
    };

    dispatch(
      updateDestructionSession({
        plates: {
          ...plateSessionData?.plates,
          [currentPlate]: {
            A: getPlateSideInfos('A'),
            B: getPlateSideInfos('B'),
          },
        },
        requiredTotal: null,
        currentPlate: null,
      }),
    );
    setScanOrManual('manual');
    setLicensePlateAddedManually(false);
    setManualEntryPlateNumber({
      ...manualEntryPlateNumber,
      value: '',
      hasA: true,
      hasB: true,
    });
    setManualLicensePlateFormError({
      ...manualLicensePlateFormError,
      error: '',
      validated: false,
    });
    setShowErrors(false);
  };

  const manualEntryMissingPlateForm = (plateSide) => {
    return (
      <div className="plate-tile margin-top-1">
        <Composition
          areas={`
              PlateSide PlateNumber Exp Trash
              Missing Missing Missing Trash
            `}
          gap={8}
          templateCols="28px 108px 76px auto"
          templateRows="28px"
          padding={16}
        >
          {({ PlateSide, PlateNumber, Missing }) => {
            const reversePlateSide = plateSide === 'A' ? 'B' : 'A';
            return (
              <>
                <PlateSide>
                  <span className="mono">{plateSide}</span>
                </PlateSide>
                <PlateNumber className="mono">{formatPlate()}</PlateNumber>
                {manualEntryPlateNumber.value.slice(0, 3) !==
                  SPECIAL_TAG_CLASS_G91 && (
                  <Missing>
                    <Checkbox
                      className={`Missing ${plateSide} plate`}
                      data-testid={`Missing ${plateSide} plate`}
                      id={`Missing ${plateSide} plate`}
                      name={`Missing ${plateSide} plate`}
                      label={`Missing ${reversePlateSide} plate`}
                      value="missing-plate"
                      checked={
                        manualEntryPlateNumber['has' + reversePlateSide]
                          ? ''
                          : 'checked'
                      }
                      onChange={(e) => {
                        setManualEntryPlateNumber({
                          ...manualEntryPlateNumber,
                          ['has' + reversePlateSide]:
                            !manualEntryPlateNumber['has' + reversePlateSide],
                        });
                      }}
                    />
                  </Missing>
                )}
              </>
            );
          }}
        </Composition>
      </div>
    );
  };

  return (
    <div
      data-testid="plate-destruction"
      className={`grid-col margin-bottom-2 plate-destruction${
        isDesktop ? ' slim-width' : ''
      }`}
    >
      <Breadcrumbs
        items={[
          { path: '/home', text: 'Home' },
          { text: 'Pending Destruction' },
        ]}
      />
      <div>
        <PageTitle
          title="Plate Pending Destruction"
          className="title-smaller"
        />
      </div>
      {sessionSuccess && (
        <div className="margin-top-0 padding-bottom-3">
          <Alert
            data-testid="plate-destruction-session-success"
            type="success"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
            onClose={() => {
              setSessionSuccess(false);
            }}
          >
            All plates were successfully submitted for Pending Destruction.
          </Alert>
        </div>
      )}
      {submissionError && (
        <div className="margin-top-3">
          <Alert
            data-testid="submission-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
          >
            <div>
              <strong>System Error:</strong> please try again.
            </div>
            <div>
              If you continue to experience technical difficulties with this
              page, please contact the GSA Fleet Technical Support team at
            </div>
            <div>
              <a href="mailto:fleet.helpdesk@gsa.gov">fleet.helpdesk@gsa.gov</a>{' '}
              or
            </div>
            <div>
              <a href="tel:866-472-6711">866-472-6711</a> from 8:00 a.m. - 7:00
              p.m. ET, Monday-Friday.
            </div>
          </Alert>
        </div>
      )}
      {!licensePlateAddedManually && (
        <div className="margin-top-0 padding-bottom-3">
          <Composition areas={`Scan Manual`}>
            {({ Scan, Manual }) => (
              <>
                <Scan>
                  <RadioButton
                    data-testid="scanOrManual-scan"
                    id="scanOrManual-scan"
                    name="scanOrManual-scan"
                    label="Scan plate"
                    checked={scanOrManual === 'scan'}
                    onChange={() => {
                      setShowErrors(false);
                      if (submissionError) {
                        setSubmissionError(false);
                      }
                      if (sessionSuccess) {
                        setSessionSuccess(false);
                      }
                      setScanOrManual('scan');
                      setManualLicensePlateFormError({
                        ...manualLicensePlateFormError,
                        error: '',
                        validated: false,
                      });
                      setValidatePlateAddedManually({
                        ...validatePlateAddedManually,
                        error: ``,
                        status: false,
                      });
                    }}
                  />
                </Scan>
                <Manual>
                  <RadioButton
                    data-testid="manual-entry-btn"
                    id="scanOrManual-manual"
                    name="scanOrManual-manual"
                    label="Manual Entry"
                    checked={scanOrManual === 'manual'}
                    onChange={() => {
                      setShowErrors(false);
                      if (submissionError) {
                        setSubmissionError(false);
                      }
                      if (sessionSuccess) {
                        setSessionSuccess(false);
                      }
                      setScanOrManual('manual');
                    }}
                  />
                </Manual>
              </>
            )}
          </Composition>
        </div>
      )}
      {!licensePlateAddedManually &&
        scanOrManual === 'scan' &&
        !maximumScanned &&
        !nearMax && (
          <div className="margin-top-5">
            <Scanner
              isInPage={true}
              onCapture={(result, ts) => {
                setLastScan(ts);
                setScanResult(result);
                setIsMissingPlate(false);
                setSessionSuccess(false);
              }}
            />
          </div>
        )}
      {!licensePlateAddedManually &&
        scanOrManual === 'manual' &&
        !maximumScanned &&
        !nearMax && (
          <>
            <div className="margin-top-5">
              <Box as="header" gap={16}>
                <div className="manual-entry-details">
                  <LicensePlateField
                    id="plate"
                    initialValue={manualEntryPlateNumber.value || ''}
                    showErrors={showErrors}
                    required={true}
                    label="Plate Number"
                    scanner={false}
                    onChange={(plate) => {
                      if (submissionError) {
                        setSubmissionError(false);
                      }
                      if (sessionSuccess) {
                        setSessionSuccess(false);
                      }
                      setManualEntryPlateNumber({
                        ...manualEntryPlateNumber,
                        value: plate.toUpperCase(),
                      });
                      if (!showErrors && plate.trim() !== '') {
                        setShowErrors(true);
                      }
                    }}
                    onValidate={(isValid, errorTxt) => {
                      if (!isValid) {
                        setManualLicensePlateFormError({
                          error: errorTxt,
                          validated: false,
                        });
                      } else {
                        setManualLicensePlateFormError({
                          error: '',
                          validated: true,
                        });
                      }
                    }}
                    data-testid="license-plate-input"
                    className="mobile-textbox margin-top-1"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <div className="padding-top-3 padding-bottom-3">
                    <Button
                      data-testid="add-license-plate-btn"
                      className="width-full"
                      variant="outline"
                      size={isDesktop ? 'medium' : 'large'}
                      label="Add License plate"
                      onClick={() => {
                        handleManualAddLicensePlate();
                      }}
                    />
                  </div>
                </div>
              </Box>
            </div>
            {!(error && error.includes('Please re-scan second plate')) &&
              validatePlateAddedManually.status && (
                <Alert
                  type="error"
                  heading=""
                  noIcon={false}
                  validation={false}
                  focused={false}
                  showClose={false}
                >
                  {validatePlateAddedManually.error}
                </Alert>
              )}
          </>
        )}

      {licensePlateAddedManually && (
        <div>
          <div className="padding-bottom-2">
            <Button
              data-testid="back-btn"
              leftIcon={{
                name: 'arrow_back',
                iconName: 'arrow_back',
              }}
              className="width-full margin-0 padding-left-0 padding-right-0"
              variant="outline"
              size={isDesktop ? 'medium' : 'large'}
              label="Back"
              onClick={() => {
                setLicensePlateAddedManually(false);
                setManualLicensePlateFormError({
                  ...manualLicensePlateFormError,
                  error: '',
                  validated: false,
                });
              }}
            />
          </div>

          {manualEntryPlateNumber.value.slice(0, 3) ===
          SPECIAL_TAG_CLASS_G91 ? (
            manualEntryMissingPlateForm('A')
          ) : (
            <>
              {manualEntryPlateNumber.hasA && manualEntryMissingPlateForm('A')}
              {manualEntryPlateNumber.hasB && manualEntryMissingPlateForm('B')}
            </>
          )}

          <div className="padding-bottom-3 padding-top-3">
            <Button
              data-testid="add-to-session"
              className="width-full"
              variant="primary"
              size={isDesktop ? 'medium' : 'large'}
              label="Add to session"
              onClick={handleManualAddSession}
            />
          </div>
        </div>
      )}
      {(maximumScanned || nearMax) && (
        <div className="margin-top-3">
          <Alert
            data-testid="plate-maximum-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            You have entered the plate maximum for this session. Please submit
            plates for destruction to continue.
          </Alert>
        </div>
      )}
      {invalidSession && (
        <div className="margin-top-3">
          <Alert
            data-testid="session-invalid-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            Please complete the session before reconciling.
          </Alert>
        </div>
      )}
      <div className="margin-top-3">
        Scan both A & B plates. A maximum of {SCAN_MAXIMUM_ALLOWED} plates can
        be scanned per session.
      </div>
      {error && (
        <div className="margin-top-3">
          <Alert
            data-testid="plate-scan-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            {error}
          </Alert>
        </div>
      )}
      {!error && hasPlates && (
        <div className="margin-top-3">
          <Button
            className="width-full margin-0"
            data-testid="destruction-button"
            variant="primary"
            size={isDesktop ? 'medium' : 'large'}
            label="Submit for pending destruction"
            onClick={() => {
              submitDestroyPlates();
            }}
          />
        </div>
      )}
      {currentPlate && needOtherPlate && (
        <div className="plate-tile margin-top-3">
          <Composition
            areas={`
              PlateAB Tag Exp Trash
              Missing Missing Missing Trash
            `}
            gap={8}
            templateCols="28px 108px 76px auto"
            templateRows="28px"
            padding={16}
          >
            {({ PlateAB, Tag, Exp, Trash, Missing }) => {
              return (
                <>
                  <PlateAB>
                    <span className="mono">
                      {hasA && !hasB && <>A</>}
                      {hasB && !hasA && <>B</>}
                      {hasA && hasB && <>A/B</>}
                    </span>
                  </PlateAB>
                  <Tag>
                    <span className="mono">
                      {currentPlateData?.tagClass}-{currentPlateData?.plate}
                    </span>
                  </Tag>
                  <Exp>
                    <span className="mono">
                      {currentPlateData?.expMonth}/{currentPlateData?.expYear}
                    </span>
                  </Exp>
                  <Trash>
                    <div className="text-right">
                      <Button
                        className="width-full margin-0 trash-btn"
                        data-testid="delete-single-plate-btn"
                        variant="outline"
                        size={isDesktop ? 'medium' : 'large'}
                        label=""
                        leftIcon={{
                          name: 'delete',
                        }}
                        onClick={() => {
                          setPlateToDelete(plateSessionData?.currentPlate);
                          setShowPlateDeleteModal(true);
                        }}
                      />
                    </div>
                  </Trash>
                  <Missing>
                    <Checkbox
                      className="missing-plate"
                      data-testid="missing-plate"
                      id="missing-plate"
                      name="missing-plate"
                      label="Missing second plate"
                      value="missing-plate"
                      checked={isMissingPlate ? 'checked' : ''}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setShowMissingPlateModal(true);
                        }
                      }}
                    />
                  </Missing>
                </>
              );
            }}
          </Composition>
        </div>
      )}
      <div className="margin-top-3">
        <PlateListing
          plates={plateSessionData?.plates}
          onDelete={(plate) => {
            deletePlate(plate);
          }}
          setShowSinglePlateDelete={showSinglePlateDeleteModal}
        />
      </div>
      <div className="margin-top-3">
        <Button
          className="width-full margin-0 padding-left-0 padding-right-0"
          data-testid="session-cancel-button"
          variant="outline"
          size={isDesktop ? 'medium' : 'large'}
          label="Cancel"
          onClick={() => {
            setShowCancelSessionModal(true);
          }}
        />
      </div>
      <AreYouSureModal
        title="Missing second license plate?"
        show={showMissingPlateModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Missing second plate"
        cancelLabel="Cancel"
        variant="blue-btn"
        onConfirm={() => {
          setIsMissingPlate(true);
          setShowMissingPlateModal(false);
          handleMissingPlate();
        }}
        onCancel={() => setShowMissingPlateModal(false)}
      >
        Please confirm that there is no second license plate to complete this
        set. Contact your FSR if you are missing a license plate.
      </AreYouSureModal>
      <AreYouSureModal
        title="Are you sure you want to remove this license plate?"
        show={showPlateDeleteModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, remove"
        cancelLabel="Cancel"
        onConfirm={() => {
          deletePlate(plateToDelete);
          setPlateToDelete(null);
          setShowPlateDeleteModal(false);
        }}
        onCancel={() => setShowPlateDeleteModal(false)}
      />
      <AreYouSureModal
        title="Are you sure you want to end this session?"
        show={showCancelSessionModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, end session"
        cancelLabel="Cancel"
        onConfirm={() => {
          dispatch(clearDestructionSession());
          setShowCancelSessionModal(false);
          history.push('/home');
        }}
        onCancel={() => setShowCancelSessionModal(false)}
      >
        All scanned plates will be removed and will not be submitted for
        destruction.
      </AreYouSureModal>
      <PlateDistructionModal
        show={showPlateDistructionModal}
        issuePlates={issuePlates}
        onConfirm={() => {
          processSuccess();
        }}
        modalTitle="submitted"
      />
    </div>
  );
};

export default PlateDestruction;
