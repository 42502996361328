export const damageCodeMap = [
  {
    slug: 'left_driver_side',
    name: 'Left / Driver Side',
    codes: [
      { code: '10', text: 'Door-Left Front' },
      { code: '11', text: 'Door-Left Rear' },
      { code: '14', text: 'Fender-Left Front' },
      { code: '15', text: 'Quarter Panel/Pick-Up Box-Left' },
      { code: '30', text: 'Mirror-Outside Left' },
      { code: '35', text: 'Rocker Panel/Outer Sill - Left' },
      { code: '38', text: 'Running Board/Step - Left' },
      { code: '70', text: 'Center Post Left' },
    ],
  },
  {
    slug: 'trunk_cargo',
    name: 'Trunk / Cargo',
    codes: [
      { code: '01', text: 'Antenna/Antenna Base' },
      { code: '18', text: 'Front Floor Mats' },
      { code: '19', text: 'Rear Floor Mats' },
      { code: '23', text: 'Accessory Bag/Box' },
      { code: '40', text: 'Spare Tire/Wheel' },
      { code: '52', text: 'Deck Lid/Tailgate/Hatchback' },
      { code: '55', text: 'Cargo Area-Other' },
      { code: '57', text: 'Wheel Covers/Caps/Rings' },
    ],
  },
  {
    slug: 'right_passenger_side',
    name: 'Right / Passenger Side',
    codes: [
      { code: '09', text: 'Door-Right Cargo' },
      { code: '12', text: 'Door-Right Front' },
      { code: '13', text: 'Door-Right Rear' },
      { code: '16', text: 'Fender-Right Front' },
      { code: '17', text: 'Quarter Panel/Pick-Up Box - Right' },
      { code: '31', text: 'Mirror-Outside Right' },
      { code: '36', text: 'Rocker Panel/Outer Sill - Right' },
      { code: '39', text: 'Running Board/Step - Right' },
      { code: '69', text: 'Center Post, Right' },
      { code: '76', text: 'Right Rear Tire' },
      { code: '77', text: 'Right Rear Wheel/Rim' },
      { code: '78', text: 'Right Front Tire' },
      { code: '79', text: 'Right Front Wheel/Rim' },
    ],
  },
  {
    slug: 'front_end',
    name: 'Front End',
    codes: [
      { code: '03', text: 'Bumper/Cover/Ext-Front' },
      { code: '05', text: 'Bumper Guard/Strip-Front' },
      { code: '20', text: 'Glass Windshield' },
      { code: '22', text: 'Grille' },
      { code: '24', text: 'Headlight/Cover/Turn Signal' },
      { code: '25', text: 'Lamps-Fog/Driving/Spot Light' },
      { code: '27', text: 'Hood' },
      { code: '42', text: 'Splash Panel/Spoiler - Front' },
      { code: '59', text: 'Wipers, all' },
      { code: '80', text: 'Cowl' },
    ],
  },
  {
    slug: 'under_hood',
    name: 'Under Hood',
    codes: [
      { code: '02', text: 'Battery/Box' },
      { code: '99', text: 'Engine Compartment-Other' },
    ],
  },
  {
    slug: 'roof',
    name: 'Roof',
    codes: [
      { code: '01', text: 'Antenna/Antenna Base' },
      { code: '37', text: 'Roof' },
      { code: '53', text: 'Sunroof/T-Top' },
      { code: '56', text: 'Vinyl/Convertible Top/Tonneau Cover' },
      { code: '64', text: 'Spoiler/Deflector-Rear' },
      { code: '65', text: 'Luggage Rack (Strips)/Drip Rail' },
      { code: '71', text: 'Corner Post' },
    ],
  },
  {
    slug: 'interior',
    name: 'Interior',
    codes: [
      { code: '68', text: 'Carpet-Front' },
      { code: '85', text: 'Communication/GPS Unit' },
      { code: '93', text: 'Steering Wheel/Airbag' },
      { code: '94', text: 'Seat-Front Left' },
      { code: '95', text: 'Seat-Front Right' },
      { code: '96', text: 'Seat-Rear' },
      { code: '97', text: 'Carpet-Rear' },
      { code: '98', text: 'Interior-Other' },
    ],
  },
  {
    slug: 'undercarriage',
    name: 'Undercarriage',
    codes: [
      { code: '44', text: 'Gas Tank' },
      { code: '54', text: 'Undercarriage-Other' },
      { code: '81', text: 'Gas/Cap Cover' },
      { code: '90', text: 'Frame' },
      { code: '91', text: 'Exhaust System' },
    ],
  },
];

export const damageTypes = [
  { code: '01', text: 'Bent' },
  { code: '02', text: 'Inoperable' },
  { code: '03', text: 'Cut' },
  { code: '04', text: 'Dented ‐ Paint or Chrome damaged' },
  { code: '05', text: 'Chipped ‐ Does not apply to glass or panel edge' },
  { code: '06', text: 'Cracked ‐ Does not apply to glass' },
  { code: '07', text: 'Gouged' },
  { code: '08', text: 'Missing' },
  { code: '09', text: 'Scuffed' },
  { code: '10', text: 'Stained or Soiled ‐ Interior' },
  { code: '11', text: 'Punctured' },
  { code: '12', text: 'Scratched ‐ Does not apply to glass' },
  { code: '13', text: 'Torn' },
  { code: '14', text: 'Dented Paint/Chrome not damaged' },
  { code: '18', text: 'Molding/Emblem/Weather‐Strip Damaged' },
  { code: '19', text: 'Molding/Emblem/Weather‐strip Loose' },
  { code: '20', text: 'Glass Cracked' },
  { code: '21', text: 'Glass Broken' },
  { code: '22', text: 'Glass Chipped' },
  { code: '23', text: 'Glass Scratched' },
];
