import React from 'react';
import { IndividualIcon } from '@gsa/afp-component-library';
import 'styled-components/macro';
import { ReactComponent as EVafLogo } from './evaf.svg';
import { ReactComponent as TelematicsLogo } from './telematics.svg';

export const EVafIcon = () => {
  return (
    <div
      css={`
        color: #000;
        display: inline-block;
      `}
    >
      <span
        css={`
          position: relative;
          display: inline-block;
          width: 32px;
          height: 32px;
          overflow: hidden;
          margin-right: 4px;
          margin-bottom: 5px;
        `}
      >
        <EVafLogo />
      </span>
    </div>
  );
};

export const TelematicsIcon = () => {
  return (
    <div
      css={`
        color: #000;
        display: inline-block;
      `}
    >
      <span
        css={`
          position: relative;
          display: inline-block;
          width: 32px;
          height: 32px;
          overflow: hidden;
          margin-right: 4px;
          margin-bottom: 5px;
        `}
      >
        <TelematicsLogo />
      </span>
    </div>
  );
};

export const PlateDestructionIcon = () => {
  return (
    <div
      css={`
        color: #000;
        display: inline-block;
      `}
    >
      <span
        css={`
          position: relative;
          display: inline-block;
          width: 16px;
          overflow: hidden;
          transform: rotate(-15deg);
          margin-right: 4px;
        `}
      >
        <IndividualIcon
          className="usa-icon--size-4"
          iconName="license-plate-tag"
          type="custom"
          viewBox="0 0 24 24"
        />
      </span>
      <span
        css={`
          position: relative;
          display: inline-block;
          width: 16px;
          overflow: hidden;
          transform: rotate(15deg);
        `}
      >
        <IndividualIcon
          className="usa-icon--size-4"
          iconName="license-plate-tag"
          type="custom"
          viewBox="0 0 24 24"
          css={`
            margin-left: -15px;
          `}
        />
      </span>
    </div>
  );
};
