export const evafDropDownOptions = [
  { label: 'OK', value: 'OK' },
  { label: 'Chipped', value: 'Chipped' },
  { label: 'Damaged', value: 'Damaged' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Rusted', value: 'Rusted' },
  { label: 'Scratched', value: 'Scratched' },
];

export const numberOfLicensePlates = [
  { label: 'Select', value: '' },
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

export const numberOfPlateSide = [
  { label: 'Select', value: '' },
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
];

export const numberOfKeys = [
  { label: 'Select', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11+', value: '11+' },
];
