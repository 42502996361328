import React from 'react';
import './Tabs.css';

export const Tabs = ({ tabConfig = [], selectedId = null, onTabChange }) => {
  return (
    <div className="react-tabs" data-tabs="true">
      <ul className="react-tabs__tab-list" role="tablist">
        {tabConfig.map((tab, i) => {
          const tabId = tab?.id || `react-tabs-${i}`;
          const isSelectedTab =
            selectedId === tabId || (i === 0 && selectedId === null);
          const tabClass =
            'react-tabs__tab' +
            (isSelectedTab ? ' react-tabs__tab--selected' : '');
          return (
            <li
              key={i}
              className={tabClass}
              role="tab"
              id={tab?.id || `react-tabs-${i}`}
              aria-selected={isSelectedTab}
              aria-disabled="false"
              aria-controls={tab?.id ? `${tab?.id}-content` : `react-tabs-${i}`}
              tabIndex="0"
              onClick={() => {
                if (typeof onTabChange === 'function') {
                  onTabChange(tab?.id || `react-tabs-${i}`);
                }
              }}
            >
              {tab?.heading || `Tab-${i}`}
            </li>
          );
        })}
      </ul>
      {tabConfig.map((tab, i) => {
        const tabId = tab?.id || `react-tabs-${i}`;
        const isSelectedTab =
          selectedId === tabId || (i === 0 && selectedId === null);
        const tabClass =
          'react-tabs__tab-panel' +
          (isSelectedTab ? ' react-tabs__tab-panel--selected' : '');
        return (
          <div
            key={i}
            className={tabClass}
            role="tabpanel"
            id={tab?.id ? `${tab?.id}-content` : `react-tabs-${i}`}
            aria-labelledby={tab?.id || `react-tabs-${i}`}
          >
            {isSelectedTab && <>{tab?.content || ''}</>}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
