import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';

const encryptor = encryptTransform({
  secretKey:
    process.env.REACT_APP_ENCRYPTION_SECRET || 'h%0MRN)[tLy}8UWgkYKNZxhBN<W^_z',
  onError: function (e) {
    console.log('Encryption Error: ' + e.message);
  },
});

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: null,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  //devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: [thunk],
});

export const persistor = persistStore(store);
