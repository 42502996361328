import React, { useState, useEffect } from 'react';
import {
  PageTitle,
  Label,
  RadioButton,
  Textbox,
  Button,
  Tooltip,
} from '@gsa/afp-component-library';
import { Composition } from 'atomic-layout';
import { format, parse, isValid, isBefore, subDays } from 'date-fns';
import { useDispatch } from 'react-redux';
import 'styled-components/macro';
import { setDeliverySessionData } from 'reducers/marshalling';
import '../screens.css';

export const DeliveryDate = ({ setScreen, isDesktop }) => {
  const dispatch = useDispatch();
  const [deliveryTime, setDeliveryTime] = useState('during-business-hours');
  const [dateError, setDateError] = useState(null);
  const [dateChosenError, setDateChosenError] = useState(false);

  useEffect(() => {
    const dateField = document.getElementById('deliveryDate');
    if (dateField) {
      dateField.value = format(new Date(), 'yyyy-MM-dd');
    }
  }, []);

  const isDeliveryDateWarning = (deliveryDate) => {
    const deliveryDateParsed = parse(deliveryDate, 'yyyy-MM-dd', new Date());
    if (isValid(deliveryDateParsed)) {
      const sevenDaysAgo = subDays(new Date(), 7);
      if (isBefore(deliveryDateParsed, sevenDaysAgo)) {
        const inputField = document.querySelector('input#deliveryDate');
        if (inputField && document.activeElement?.id !== 'deliveryDate') {
          inputField.focus();
        }
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const submitHandler = () => {
    const deliveryDateField = document.getElementById('deliveryDate');
    const deliveryDate = deliveryDateField
      ? deliveryDateField.value
      : format(new Date(), 'yyyy-MM-dd');

    // Validate delivery date
    const deliveryDateParsed = parse(deliveryDate, 'yyyy-MM-dd', new Date());
    if (!isValid(deliveryDateParsed)) {
      setDateError('This is a required field');
      return;
    }

    if (isBefore(new Date(), deliveryDateParsed)) {
      setDateError('Invalid Delivery Date');
      return;
    }

    dispatch(
      setDeliverySessionData({
        deliveryDate,
        deliveryTime,
      }),
    );
    setScreen('find-vehicle');
  };

  return (
    <div className="grid-col">
      <PageTitle
        title="Delivery date"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <div className="border-bottom border-base-light padding-bottom-3">
        <Composition
          areas={`
            RequiredText
            DeliveryDateLabel
            DeliveryDateField
            DeliveryTimeLabel
            DuringHours
            AfterHours
            ContinueButton
            CancelButton
          `}
          areasMd={`
            RequiredText RequiredText RequiredText
            DeliveryDateLabel DeliveryDateLabel .
            DeliveryDateField DeliveryDateField .
            DeliveryTimeLabel DeliveryTimeLabel .
            DuringHours AfterHours .
            CancelButton ContinueButton .
          `}
          gap={16}
          templateCols="auto"
          templateColsMd="200px 200px auto"
          templateRows="auto"
        >
          {({
            RequiredText,
            DeliveryDateLabel,
            DeliveryDateField,
            DeliveryTimeLabel,
            DuringHours,
            AfterHours,
            ContinueButton,
            CancelButton,
          }) => (
            <>
              <RequiredText>
                <p className="border-bottom border-base-light margin-top-0 margin-bottom-0">
                  Required fields are marked with an asterisk (
                  <span className="afp-required-field">*</span>
                  ).
                </p>
              </RequiredText>
              <DeliveryDateLabel>
                <Label className="text-bold margin-top-0" required>
                  Delivery date
                </Label>
              </DeliveryDateLabel>
              <DeliveryDateField>
                <div className={dateError ? 'form-error' : ''}>
                  {dateError && (
                    <div className="form-error-msg">
                      <strong>{dateError}</strong>
                    </div>
                  )}
                  <div
                    className={
                      dateChosenError
                        ? 'delivery-date-msg show'
                        : 'delivery-date-msg hide'
                    }
                  >
                    <Tooltip
                      label={
                        <div>
                          <div>
                            <strong>Check delivery date</strong>
                          </div>
                          <div>You selected 7 or more days</div>
                          <div>before today for the delivery date.</div>
                        </div>
                      }
                      position="top"
                    >
                      <Textbox
                        type="date"
                        max={format(new Date(), 'yyyy-MM-dd')}
                        id="deliveryDate"
                        name="deliveryDate"
                        variant={dateError ? 'error' : ''}
                        className="mobile-textbox margin-top-0"
                        onChange={(e) => {
                          const validDate = isDeliveryDateWarning(
                            e.target.value,
                          );
                          if (!validDate) {
                            setDateChosenError(true);
                          } else {
                            setDateChosenError(false);
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </DeliveryDateField>
              <DeliveryTimeLabel>
                <Label className="text-bold margin-top-0" required>
                  Delivery time
                </Label>
              </DeliveryTimeLabel>
              <DuringHours>
                <span
                  css={`
                    & label {
                      margin-top: 0;
                    }
                  `}
                >
                  <RadioButton
                    id="during-business-hours"
                    name="delivery-time"
                    label="During business hours"
                    checked={deliveryTime === 'during-business-hours'}
                    onChange={() => {
                      setDeliveryTime('during-business-hours');
                    }}
                  />
                </span>
              </DuringHours>
              <AfterHours>
                <span
                  css={`
                    & label {
                      margin-top: 0;
                    }
                  `}
                >
                  <RadioButton
                    id="after-hours"
                    name="delivery-time"
                    label="After hours"
                    checked={deliveryTime === 'after-hours'}
                    onChange={() => {
                      setDeliveryTime('after-hours');
                    }}
                  />
                </span>
              </AfterHours>
              <ContinueButton>
                <Button
                  data-testid="continue-button"
                  className="width-full"
                  variant="primary"
                  size={isDesktop ? 'medium' : 'large'}
                  label="Continue"
                  onClick={submitHandler}
                />
              </ContinueButton>
              <CancelButton>
                <Button
                  className="width-full"
                  variant="outline"
                  size={isDesktop ? 'medium' : 'large'}
                  label="Cancel"
                  onClick={() => {
                    setScreen('start');
                  }}
                />
              </CancelButton>
            </>
          )}
        </Composition>
      </div>
    </div>
  );
};

export default DeliveryDate;
