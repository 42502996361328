import React, { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser, deleteCookies } from '@gsa/afp-shared-ui-utils';

export const LogoutPage = () => {
  const history = useHistory();
  const { logout } = useCurrentUser();
  const isOnline = navigator && navigator.onLine;
  useLayoutEffect(() => {
    if (isOnline) {
      logout();
      history.replace('/');
    } else {
      deleteCookies(/^CognitoIdentityServiceProvider/);
      history.replace('/');
    }
  });

  return isOnline ? (
    <div>
      <h6>Logging out..</h6>
    </div>
  ) : (
    <div>
      <h6>Redirecting to login page to perform offline activities...</h6>
    </div>
  );
};

export default LogoutPage;
