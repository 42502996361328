import React from 'react';
import { Composition } from 'atomic-layout';
import { PageTitle, Button, Icon } from '@gsa/afp-component-library';
import { formatVehicleTitle } from 'utils';
import '../screens.css';

export const CompleteDelivery = ({
  setScreen,
  vehicle,
  messageCode,
  isDesktop,
}) => {
  const parseMessage = (messageCode) => {
    let deliveryMessage = '';
    switch (messageCode) {
      case 'EXPECTEDDELIVERY':
        deliveryMessage = (
          <>
            This vehicle has not been delivered into the system. Please perform
            Vehicle Delivery before loading the vehicle.{' '}
            <span>
              {' '}
              <a href="/vehicle-delivery" className="link-container">
                Go to Vehicle Delivery{' '}
                <Icon className="usa-icon--size-3" iconName="arrow_forward" />
              </a>
            </span>
          </>
        );
        break;
      case 'DELIVERED':
        deliveryMessage = (
          <>
            This vehicle has not been loaded into the system. Please perform the
            Loading of this Vehicle before installing telematics.{' '}
            <span>
              {' '}
              <a href="/load-vehicle" className="link-container">
                Go to Load Vehicle{' '}
                <Icon className="usa-icon--size-3" iconName="arrow_forward" />
              </a>
            </span>
          </>
        );
        break;
      default:
        deliveryMessage = (
          <>
            An unknown error occurred during telematics device check. If the
            issue persists, please report this to{' '}
            <a href="mailto:fleetsolutions@gsa.gov">fleetsolutions@gsa.gov</a>.
          </>
        );
        break;
    }
    return deliveryMessage;
  };

  return (
    <div className="grid-col">
      <PageTitle
        title="Telematics Installation"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      {vehicle && (
        <>
          <div>
            <strong>{formatVehicleTitle(vehicle)}</strong>
          </div>
          <div>
            <strong>VIN: {vehicle.vin}</strong>
          </div>
          <div>
            <strong>RPN/Order Number: {vehicle.orderNumber}</strong>
          </div>
        </>
      )}
      <Composition
        areas={`DeliveryMessage`}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({ DeliveryMessage }) => (
          <>
            <DeliveryMessage>
              <p className="tab-title margin-bottom-0">
                {messageCode === 'EXPECTEDDELIVERY'
                  ? 'Complete Vehicle Delivery'
                  : 'Complete Load Vehicle'}
              </p>
              <p className="margin-top-0">{parseMessage(messageCode)}</p>
            </DeliveryMessage>
          </>
        )}
      </Composition>
      <div className="padding-bottom-3 padding-top-5">
        <Button
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Return to scan"
          leftIcon={{
            name: 'photo_camera',
            iconName: 'photo_camera',
          }}
          onClick={() => {
            setScreen('find-vehicle');
          }}
        />
      </div>
    </div>
  );
};

export default CompleteDelivery;
