import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Button } from '@gsa/afp-component-library';
import './sign-in.css';

export const SignInPage = () => {
  const { currentUser, loginUserGSA, loginUserExternal } = useCurrentUser();
  const history = useHistory();

  /**
   * to login for External Users using Okta ( Vendors, Customer and Public Users)
   * @param {*}
   */
  const handleLoginExternal = async (e) => {
    e.preventDefault();
    await loginUserExternal();
  };

  /**
   * to login for GSA Employees using Secure Auth
   * @param {*} e
   */
  const handleLoginGSA = async (e) => {
    e.preventDefault();
    await loginUserGSA();
  };

  React.useEffect(() => {
    if (!currentUser) return;
    history.push('/home');
  }, [history, currentUser]);

  return (
    <div className="grid-row margin-x-neg-205 margin-bottom-7 flex-justify-center">
      <div className="grid-col-11 mobile-lg:grid-col-9 tablet:grid-col-7 desktop:grid-col-5 padding-x-205 margin-bottom-5">
        <div className="bg-white padding-y-5 padding-x-5 margin-top-2 border border-base-light">
          <h1 className="margin-bottom-1 margin-top-neg-2">Log in</h1>
          <div className="grid-row flex-column">
            <p>
              If you are a GSA Fleet customer or federal agency customer, please
              log in below.
            </p>
            <div className="grid-col">
              <Button
                label="Federal agency customer login"
                variant="primary"
                className="margin-top-2 margin-bottom-5"
                id="external-okta-login"
                onClick={handleLoginExternal}
                data-testid="handle-external-login-existing-user"
              />
            </div>

            <div className="divider text-uppercase text-bold">Or</div>

            <p>If you are a GSA Fleet employee, please log in below.</p>
            <div className="grid-col">
              <Button
                label="GSA Fleet employee login"
                className="usa-button--fluid  margin-top-1"
                data-testid="gsa-email-login-testid"
                type="button"
                variant="primary"
                onClick={handleLoginGSA}
              />
            </div>

            <div className="margin-top-4 divider text-uppercase text-bold">
              Or
            </div>

            <p>
              If you want to perform offline activities, please click below.
            </p>
            <div className="grid-col">
              <Button
                label="Offline activity"
                className="usa-button--fluid  margin-top-1"
                data-testid="gsa-email-login-testid"
                type="button"
                variant="primary"
                onClick={() => history.push('/accept-vehicle')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
