import { Alert, Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const ALERT_TYPES = ['success', 'error', 'info', 'warning'];

export const BannerMessage = ({
  type,
  message,
  onClose,
  showClose,
  ...props
}) => {
  React.useEffect(() => {
    if (message?.length) window.scrollTo(0, 0);
  }, [message]);

  if (!ALERT_TYPES.includes(type) || !message) return null;

  return (
    <Alert
      slim
      actions={
        <div data-testid="close-wrapper">
          {showClose ? (
            <div className="position-absolute top-0 right-0">
              <Button
                data-testid="banner-message-close"
                aria-label="close"
                variant="unstyled"
                style={{ color: 'black' }}
                className="margin-x-1"
                onClick={onClose}
                leftIcon={{
                  name: 'close',
                  className: 'usa-icon--size-4',
                }}
              />
            </div>
          ) : null}
        </div>
      }
      type={type}
      {...props}
    >
      {/* Using ReactHtmlParser as it is safer than using the native dangerouslySetInnerHTML */}
      {ReactHtmlParser(message)}
    </Alert>
  );
};

BannerMessage.defaultProps = {
  type: null,
  message: null,
  showClose: true,
  onClose: () => {
    // logic here...
  },
};

BannerMessage.propTypes = {
  type: PropTypes.oneOf(ALERT_TYPES),
  message: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default BannerMessage;
