import React from 'react';
import { Label, Textbox } from '@gsa/afp-component-library';

export const CountdownTextboxField = ({
  isError = false,
  label = 'Additional details',
  error = 'Text limited to 1000 characters',
  charsAllowed = 1000,
  id,
  name,
  defaultValue = '',
  onChange,
  onFocus,
}) => {
  return (
    <div
      data-testid="countdown-textbox"
      className={isError ? 'form-error' : ''}
    >
      <Label>{label}</Label>
      {isError && (
        <div data-testid="form-error-msg" className="form-error-msg">
          <strong>{error}</strong>
        </div>
      )}
      <Textbox
        id={id}
        name={name}
        type="textarea"
        size="medium"
        value={defaultValue}
        onFocus={(e) => {
          if (typeof onFocus === 'function') {
            onFocus(e);
          }
        }}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
          const textLeft = charsAllowed - Number(e.target.value.length);
          const charBox = document.getElementById(`${id}-chars`);
          const allChars = charBox.parentNode;
          if (charBox) {
            if (textLeft < charsAllowed) {
              charBox.innerHTML = (textLeft >= 0 ? textLeft : 0) + '/';
              if (textLeft < 0) {
                if (allChars.className.indexOf('error') === -1) {
                  allChars.className += ' error';
                }
              } else {
                allChars.className = allChars.className
                  .split(' ')
                  .filter((classItem) => classItem !== 'error')
                  .join(' ');
              }
            } else {
              charBox.innerHTML = '';
              allChars.className = allChars.className
                .split(' ')
                .filter((classItem) => classItem !== 'error')
                .join(' ');
            }
          }
        }}
      />
      <div className="textbox-note">
        <span id={`${id}-chars`}></span>
        {charsAllowed} characters allowed
      </div>
    </div>
  );
};

export default CountdownTextboxField;
