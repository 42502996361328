import React, { useState } from 'react';
import { Breadcrumbs } from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import { useSelector } from 'react-redux';
import {
  ExchangeVehicle,
  NewVehicle,
  OldVehicle,
  VehicleTypeSelection,
} from './screens';
import { useDispatch } from 'react-redux';
import { clearEvafData } from 'reducers/marshalling';

export const EvafPage = () => {
  const dispatch = useDispatch();
  const evafData = useSelector((state) => state?.marshalling?.evaf || {});
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const [screen, setScreen] = useState(
    evafData?.evafType ? evafData.evafType : 'vehicle-selection',
  );
  const [eVafSignSuccess, seteVafSignSuccess] = useState(false);

  const onVerifySuccess = () => {
    dispatch(clearEvafData());
    setScreen('vehicle-selection');
    seteVafSignSuccess(true);
  };

  const onVerifyError = () => {};

  return (
    <div
      className={`grid-col margin-bottom-2${isDesktop ? ' slim-width' : ''}`}
    >
      <Breadcrumbs
        items={[{ path: '/home', text: 'Home' }, { text: 'eVAF' }]}
      />
      {screen === 'vehicle-selection' && (
        <VehicleTypeSelection
          setScreen={setScreen}
          eVafFormCompleted={eVafSignSuccess}
          setEvafFormCompleted={seteVafSignSuccess}
          isDesktop={isDesktop}
        />
      )}
      {screen === 'exchange-vehicle' && (
        <ExchangeVehicle
          setScreen={setScreen}
          isDesktop={isDesktop}
          onVerifySuccess={onVerifySuccess}
          onInstallError={onVerifyError}
        />
      )}
      {screen === 'old-vehicle' && (
        <OldVehicle
          setScreen={setScreen}
          eVafFormCompleted={eVafSignSuccess}
          setEvafFormCompleted={seteVafSignSuccess}
          isDesktop={isDesktop}
          onVerifySuccess={onVerifySuccess}
          setCanLeaveOldTab={() => {}}
        />
      )}
      {screen === 'new-vehicle' && (
        <NewVehicle
          setScreen={setScreen}
          eVafFormCompleted={eVafSignSuccess}
          setEvafFormCompleted={seteVafSignSuccess}
          isDesktop={isDesktop}
          onVerifySuccess={onVerifySuccess}
          setCanLeaveNewTab={() => {}}
        />
      )}
    </div>
  );
};

export default EvafPage;
