import React from 'react';
import { useSync } from 'hooks';
import { useSelector } from 'react-redux';
import { ActionCard, FsrSelector } from 'components';
import { EVafIcon, TelematicsIcon, PlateDestructionIcon } from './icons';

const actions = [
  {
    title: 'Vehicle Delivery',
    content: 'Accept new vehicles at arrival',
    icon: 'check_circle',
    path: '/vehicle-delivery',
    offline: true,
    subject: 'VehicleArrival',
    operation: 'view',
  },
  {
    title: 'Load Vehicle',
    content: 'Inspect delivered vehicles for loading',
    icon: 'fleet-car',
    iconType: 'custom',
    path: '/load-vehicle',
    offline: true,
    subject: 'LoadVehicle',
    operation: 'view',
  },
  {
    title: 'Plate Reconciliation',
    content: 'Scan plates to track arrival',
    icon: 'license-plate-tag',
    iconType: 'custom',
    path: '/plate-reconciliation',
    offline: false,
  },
  {
    title: 'Plate Pending Destruction',
    content: 'Scan plates to mark as pending destruction',
    icon: <PlateDestructionIcon />,
    iconType: 'internal',
    path: '/plate-destruction',
    offline: false,
  },
  {
    title: 'Telematics Installation',
    content: 'Scan plates to install telematics',
    icon: <TelematicsIcon />,
    iconType: 'internal',
    path: '/telematics-installation',
    offline: false,
  },
  {
    title: 'Telematics Purchase Order Reconciliation',
    content: 'Scan plates to install telematics',
    icon: <TelematicsIcon />,
    iconType: 'internal',
    path: '/telematics-purchase-order-reconciliation',
    offline: false,
  },
  {
    title: 'eVAF',
    content: 'Electronic Vehicle Accountability Form',
    icon: <EVafIcon />,
    iconType: 'internal',
    path: '/evaf',
    offline: false,
  },
  {
    title: 'Appointment Scheduling',
    content: 'Schedule Vehicle Appointments with Customers',
    icon: 'calendar',
    viewBox: '0 0 14 16',
    iconType: 'custom',
    path: '/appointment-scheduling',
    offline: false,
  },
];
/*
const manageActions = [
  {
    title: 'Vehicles',
    content: 'Vehicle inventory management',
    icon: 'vehicle_icon',
    iconType: 'custom',
    path: '/vehicles',
    offline: true,
  },
  {
    title: 'Plates',
    content: 'License plate inventory management',
    icon: 'plate_icon',
    iconType: 'custom',
    path: '/plates',
    offline: true,
  },
  {
    title: 'Telematics',
    content: 'Telematics device management',
    icon: 'add',
    path: '/telematics',
    offline: false,
  },
];
*/

export const HomePage = () => {
  const { isOnline, isFsr } = useSync();
  const userState = useSelector((state) => state?.user || {});
  const currentZone = userState?.fsrZone || null;
  const currentLocation = userState?.fsrLocation || null;
  const finalActions = actions.filter(
    (action) => (!isOnline && action.offline) || isOnline,
  );
  //const finalManageActions = manageActions.filter(action => (!isOnline && action.offline) || isOnline);

  return (
    <div className="margin-top-4">
      {isFsr && <FsrSelector />}
      {((isFsr && currentZone && currentLocation) || !isFsr) && (
        <>
          {finalActions.map((action, i) => {
            return (
              <ActionCard
                key={i}
                title={action.title}
                path={action.path}
                icon={action.icon}
                iconType={action?.iconType || ''}
                viewBox={action?.viewBox || null}
                subject={action?.subject || null}
                operation={action?.operation || null}
              />
            );
          })}
        </>
      )}
      {/*
      <h3 className="text-uppercase">Vehicle Marshalling</h3>
      <LinkCard
        cards={finalActions}
      />
      <h3 className="text-uppercase">Marshalling Management</h3>
      <LinkCard
        cards={finalManageActions}
      />
      */}
      {/*
      <SignatureModal
        show={showModal}
        title="Driver sign eVAF"
        onCancel={() => {
          setShowModal(false);
        }}
        onSave={dataUrl => {
          setSignature(dataUrl);
          setShowModal(false);
        }}
      />
      */}
    </div>
  );
};

export default HomePage;
