import React, { useEffect, useState } from 'react';
import { SelectBox, Label } from '@gsa/afp-component-library';
import { useSelector, useDispatch } from 'react-redux';
import {
  setFsrZone,
  setFsrLocation,
  setUserLocations,
  setUserVendorId,
} from 'reducers/user';
import { useSync } from 'hooks';

export const FsrSelector = () => {
  const dispatch = useDispatch();
  const { getFsrOptions } = useSync();
  const userState = useSelector((state) => state?.user || {});
  const currentZone = userState?.fsrZone || null;
  const currentLocation = userState?.fsrLocation || null;
  const [zoneLoaded, setZoneLoaded] = useState(currentZone);
  const availableLocations = userState?.locations || [];

  const zoneList = [
    { id: 1, name: 'Zone 1' },
    { id: 2, name: 'Zone 2' },
    { id: 3, name: 'Zone 3' },
    { id: 4, name: 'Zone 4' },
  ];

  const handleZoneChange = (e) => {
    dispatch(setFsrZone(e.target.options[e.target.selectedIndex].value));
    dispatch(setFsrLocation(''));
    dispatch(setUserVendorId(null));
  };

  const handleLocationChange = (e) => {
    const fsrLocation = e.target.options[e.target.selectedIndex].value;
    dispatch(setFsrLocation(fsrLocation));
    const curLocation = availableLocations.filter(
      (loc) => loc.locationId === Number(fsrLocation),
    );
    const vendorId = curLocation[0]?.vendorId || null;
    dispatch(setUserVendorId(vendorId));
  };

  useEffect(() => {
    if (currentZone !== zoneLoaded && currentZone) {
      setZoneLoaded(currentZone);
      dispatch(setUserLocations([]));
      getFsrOptions({
        variables: {
          zoneId: currentZone,
        },
      });
    }
  }, [currentZone, zoneLoaded, getFsrOptions, dispatch]);

  const selectedLocation = currentLocation
    ? availableLocations.find((loc) => {
        return loc.locationId === Number(currentLocation);
      })
    : null;

  return (
    <div data-testid="fsr-selector" className="fsr-selector margin-bottom-2">
      <p>Filter by your Zone/Marshalling Location then select a tile.</p>
      <Label className="margin-top-2" required>
        <strong>Zone</strong>
      </Label>
      <SelectBox
        data-testid="zone-selection"
        id="zoneSelection"
        name="zoneSelection"
        optionLabel="name"
        optionValue="id"
        value={currentZone || ''}
        options={[{ id: '', name: '- Select -' }, ...zoneList]}
        onChangeFunc={handleZoneChange}
      />
      <Label className="margin-top-2" required>
        <strong>Marshalling Location</strong>
      </Label>
      <SelectBox
        data-testid="location-selection"
        id="locationSelection"
        name="locationSelection"
        optionLabel="name"
        optionValue="locationId"
        value={currentLocation || ''}
        options={[
          { locationId: '', name: '- Select -' },
          ...availableLocations,
        ]}
        onChangeFunc={handleLocationChange}
      />
      {selectedLocation && (
        <div className="margin-top-1" data-testid="fsr-selected-location">
          Selected: {selectedLocation.name}
        </div>
      )}
    </div>
  );
};

export default FsrSelector;
