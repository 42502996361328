import React, { useEffect, useState, useCallback } from 'react';
import { PageTitle, Button, Checkbox, Alert } from '@gsa/afp-component-library';
import {
  Breadcrumbs,
  Scanner,
  PlateListing,
  AreYouSureModal,
  PlateReconcileAckModal,
} from 'components';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updatePlateSession, clearPlateSession } from 'reducers/marshalling';
import { useSync } from 'hooks';
import './PlateReconciliation.css';

export const PlateReconciliation = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const scanMaximum = 80;
  const history = useHistory();
  const dispatch = useDispatch();
  const { completePlateReconciliation } = useSync();
  const plateSessionData = useSelector(
    (state) => state?.marshalling?.plateSession || {},
  );
  const userData = useSelector((state) => state?.user || {});
  const vendorId = userData?.vendorId || '0';
  const [scanResult, setScanResult] = useState(null);
  const [lastScan, setLastScan] = useState(null);
  const [error, setError] = useState(null);
  const [submissionError, setSubmissionError] = useState(false);
  const [isMissingPlate, setIsMissingPlate] = useState(false);
  const [showPlateDeleteModal, setShowPlateDeleteModal] = useState(false);
  const [plateToDelete, setPlateToDelete] = useState(null);
  const [showMissingPlateModal, setShowMissingPlateModal] = useState(false);
  const [showCancelSessionModal, setShowCancelSessionModal] = useState(false);
  const [maximumScanned, setMaximumScanned] = useState(false);
  const [nearMax, setNearMax] = useState(false);
  const [sessionSuccess, setSessionSuccess] = useState(false);
  const [invalidSession, setInvalidSession] = useState(false);
  const [issuePlates, setIssuePlates] = useState([]);
  const [showPlateReconcileAckModal, setShowPlateReconcileAckModal] =
    useState(false);
  const prevPlate = usePrevious(scanResult);
  const prevLastScan = usePrevious(lastScan);

  const parsePlate = (plateTag) => {
    const plateAOrB = plateTag.charAt(0);
    const expMonth = plateTag.slice(1, 3);
    const expYear = plateTag.slice(3, 7);
    const tagClass = plateTag.slice(7, 10);
    const plate = plateTag.slice(10);
    return {
      full: `${tagClass}${plate}`,
      plate,
      plateAOrB,
      expMonth,
      expYear,
      tagClass,
    };
  };

  const validateSession = () => {
    let isValid = true;
    const plates = plateSessionData?.plates;
    if (plates) {
      for (const [, plateData] of Object.entries(plates)) {
        const hasA = plateData?.A;
        const hasB = plateData?.B;
        const details = hasA ? plateData.A : plateData.B;
        if (details?.tagClass !== 'G91' && (!hasA || !hasB)) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  const handleMissingPlate = () => {
    setError(null);
    const currentPlate = plateSessionData?.currentPlate;
    const missingPlate = plateSessionData?.plates[currentPlate]?.A ? 'B' : 'A';
    dispatch(
      updatePlateSession({
        plates: {
          ...(plateSessionData?.plates || {}),
          [currentPlate]: {
            ...(plateSessionData?.plates &&
            plateSessionData?.plates[currentPlate]
              ? plateSessionData?.plates[currentPlate]
              : {}),
            [missingPlate]: {
              plateAOrB: missingPlate,
              missing: true,
            },
          },
        },
        requiredTotal: null,
        currentPlate: null,
      }),
    );
  };

  const getTotalPlatesScanned = (plateSessionData) => {
    let totalPlatesScanned = 0;
    if (plateSessionData && plateSessionData?.plates) {
      for (const [, plateData] of Object.entries(plateSessionData?.plates)) {
        if (plateData?.A && plateData?.A?.missing !== true) {
          totalPlatesScanned += 1;
        }
        if (plateData?.B && plateData?.B?.missing !== true) {
          totalPlatesScanned += 1;
        }
      }
    }
    return totalPlatesScanned;
  };

  const handlePlate = useCallback((plateTag, plateSessionData, dispatch) => {
    setError(null);
    let error = false;
    if (!plateTag || plateTag.length !== 15) {
      error = (
        <>
          Invalid plate barcode. <code>{plateTag}</code>
        </>
      );
    }

    const currentPlate = plateSessionData?.currentPlate || null;
    const { full, plate, plateAOrB, expMonth, expYear, tagClass } =
      parsePlate(plateTag);

    if (!['A', 'B'].includes(plateAOrB)) {
      error = (
        <>
          Invalid plate barcode. <code>{plateTag}</code>
        </>
      );
    }

    if (
      plateSessionData?.plates &&
      plateSessionData?.plates[full] &&
      plateSessionData?.plates[full][plateAOrB]
    ) {
      const plateErrorData = plateSessionData?.plates[full][plateAOrB];
      error = (
        <>
          This plate has already been scanned.{' '}
          <strong>
            {plateAOrB} {plateErrorData?.tagClass}-{plate}.
          </strong>
        </>
      );
    }

    const lastData = currentPlate ? plateSessionData?.plates[currentPlate] : {};
    const platesScanned = Object.keys(lastData);
    const aOrBPlate = platesScanned[0] || null;
    const lastTagClass =
      lastData && lastData[aOrBPlate] ? lastData[aOrBPlate]?.tagClass : null;

    if (
      plateSessionData?.requiredTotal !== null &&
      platesScanned.length !== plateSessionData?.requiredTotal &&
      tagClass !== 'G91' &&
      currentPlate !== null &&
      lastTagClass !== null &&
      (lastTagClass !== tagClass || currentPlate !== full)
    ) {
      error =
        'These plates do not match. Please re-scan second plate or select missing second plate.';
    }

    const totalScanned = getTotalPlatesScanned(plateSessionData);
    if (
      tagClass !== 'G91' &&
      totalScanned === scanMaximum - 1 &&
      !error &&
      platesScanned.length === 0
    ) {
      setNearMax(true);
      return;
    }

    if (!error) {
      let requiredTotal = tagClass === 'G91' ? 1 : 2;
      let currentPlateItem = full;

      dispatch(
        updatePlateSession({
          plates: {
            ...(plateSessionData?.plates || {}),
            [full]: {
              ...(plateSessionData?.plates && plateSessionData?.plates[full]
                ? plateSessionData?.plates[full]
                : {}),
              [plateAOrB]: {
                plate,
                plateAOrB,
                expMonth,
                expYear,
                tagClass,
              },
            },
          },
          requiredTotal,
          currentPlate: currentPlateItem,
        }),
      );
      return true;
    } else {
      setError(error);
      return false;
    }
  }, []);

  const deletePlate = (plate) => {
    const newPlates = {};
    for (const [tag, data] of Object.entries(plateSessionData?.plates)) {
      if (tag !== plate) {
        newPlates[tag] = data;
      }
    }
    dispatch(
      updatePlateSession({
        ...plateSessionData,
        plates: newPlates,
        requiredTotal: null,
        currentPlate: null,
      }),
    );
    setMaximumScanned(false);
    setError(false);
  };

  const submitReconciledPlates = async () => {
    const isValid = validateSession();
    setError(null);
    setInvalidSession(false);
    if (isValid) {
      const plateData = [];
      for (const [, data] of Object.entries(plateSessionData?.plates)) {
        for (const [aOrB, plateValues] of Object.entries(data)) {
          const otherPlate = aOrB === 'A' ? data?.B : data?.A;
          const dataToAdd = {
            ...(plateValues?.missing === true ? otherPlate : {}),
            ...plateValues,
          };
          plateData.push(dataToAdd);
        }
      }

      const completeData = await completePlateReconciliation({
        variables: {
          platesReconcileAndDestructInput: {
            vendorId: vendorId,
            isOnline: true,
            plates: plateData,
          },
        },
      });

      const reconcileResponse = completeData?.data?.reconcilePlates;
      if (reconcileResponse?.globalSuccessStatus === true) {
        if (
          reconcileResponse?.plateStatuses &&
          reconcileResponse?.plateStatuses.length < 1
        ) {
          processSuccess();
        } else {
          const issuePlatesData =
            reconcileResponse?.plateStatuses.filter(
              (plate) => plate.plateSuccessStatus === false,
            ) || [];
          if (issuePlatesData.length > 0) {
            setIssuePlates(issuePlatesData);
            setShowPlateReconcileAckModal(true);
          } else {
            processSuccess();
          }
        }
      } else {
        setSubmissionError(true);
      }
    } else {
      setInvalidSession(true);
    }
  };

  const processSuccess = () => {
    setMaximumScanned(false);
    setSessionSuccess(true);
    dispatch(clearPlateSession());
  };

  const showSinglePlateDeleteModal = (plateToDelete) => {
    setPlateToDelete(plateToDelete);
    setShowPlateDeleteModal(true);
  };

  useEffect(() => {
    if (scanResult && (scanResult !== prevPlate || lastScan > prevLastScan)) {
      setError(null);
      handlePlate(scanResult, plateSessionData, dispatch);
    }
  }, [
    scanResult,
    plateSessionData,
    prevPlate,
    lastScan,
    prevLastScan,
    dispatch,
    handlePlate,
  ]);

  useEffect(() => {
    const totalScanned = getTotalPlatesScanned(plateSessionData);
    if (totalScanned >= scanMaximum) {
      setMaximumScanned(true);
    } else {
      setMaximumScanned(false);
    }
  }, [plateSessionData, maximumScanned, scanMaximum]);

  useEffect(() => {
    return () => {
      if (window.pwaScanner && !window.pwaScanner.isContextDestroyed()) {
        window.pwaScanner.destroyContext();
      }
    };
  }, []);

  const currentPlate =
    plateSessionData && plateSessionData?.plates
      ? plateSessionData?.plates[plateSessionData?.currentPlate]
      : null;
  const hasA = currentPlate && currentPlate?.A;
  const hasB = currentPlate && currentPlate?.B;
  const currentPlateData = currentPlate
    ? hasA
      ? currentPlate.A
      : currentPlate.B
    : null;
  const needOtherPlate =
    currentPlate && (!hasA || !hasB) && plateSessionData?.requiredTotal > 1;
  const hasPlates =
    plateSessionData?.plates &&
    Object.keys(plateSessionData?.plates).length > 0;

  return (
    <div
      data-testid="plate-reconciliation"
      className={`grid-col margin-bottom-2 plate-reconciliation${
        isDesktop ? ' slim-width' : ''
      }`}
    >
      <Breadcrumbs
        items={[
          { path: '/home', text: 'Home' },
          { text: 'Plate Reconciliation' },
        ]}
      />
      <div>
        <PageTitle title="Plate Reconciliation" />
      </div>
      {sessionSuccess && (
        <div className="margin-top-0 padding-bottom-3">
          <Alert
            data-testid="plate-session-success"
            type="success"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
            onClose={() => {
              setSessionSuccess(false);
            }}
          >
            All plates were successfully reconciled.
          </Alert>
        </div>
      )}
      {!maximumScanned && !nearMax && !submissionError && (
        <div className="margin-top-5">
          <Scanner
            isInPage={true}
            onCapture={(result, ts) => {
              setLastScan(ts);
              setScanResult(result);
              setIsMissingPlate(false);
              setSessionSuccess(false);
            }}
          />
        </div>
      )}
      {(maximumScanned || nearMax) && (
        <div className="margin-top-3">
          <Alert
            data-testid="plate-maximum-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            You have entered the plate maximum for this session. Please
            reconcile plates to continue.
          </Alert>
        </div>
      )}
      {invalidSession && (
        <div className="margin-top-3">
          <Alert
            data-testid="session-invalid-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            Please complete the session before reconciling.
          </Alert>
        </div>
      )}
      {submissionError && (
        <div className="margin-top-3">
          <Alert
            data-testid="submission-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
          >
            <div>
              <strong>System Error:</strong> please try again.
            </div>
            <div>
              If you continue to experience technical difficulties with this
              page, please contact the GSA Fleet Technical Support team at
            </div>
            <div>
              <a href="mailto:fleet.helpdesk@gsa.gov">fleet.helpdesk@gsa.gov</a>{' '}
              or
            </div>
            <div>
              <a href="tel:866-472-6711">866-472-6711</a> from 8:00 a.m. - 7:00
              p.m. ET, Monday-Friday.
            </div>
          </Alert>
        </div>
      )}
      <div className="margin-top-3">
        Scan both A & B plates. A maximum of {scanMaximum} plates can be scanned
        per session.
      </div>
      {error && (
        <div className="margin-top-3">
          <Alert
            data-testid="plate-scan-error"
            type="error"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
          >
            {error}
          </Alert>
        </div>
      )}
      {!error && hasPlates && (
        <div className="margin-top-3">
          <Button
            className="width-full margin-0"
            data-testid="reconcile-button"
            variant="primary"
            size={isDesktop ? 'medium' : 'large'}
            label="Reconcile plates"
            onClick={() => {
              submitReconciledPlates();
            }}
          />
        </div>
      )}
      {currentPlate && needOtherPlate && (
        <div className="plate-tile margin-top-3">
          <Composition
            areas={`
              PlateAB Tag Exp Trash
              Missing Missing Missing Trash
            `}
            gap={8}
            templateCols="28px 108px 76px auto"
            templateRows="28px"
            padding={16}
          >
            {({ PlateAB, Tag, Exp, Trash, Missing }) => {
              return (
                <>
                  <PlateAB>
                    <span className="mono">
                      {hasA && !hasB && <>A</>}
                      {hasB && !hasA && <>B</>}
                      {hasA && hasB && <>A/B</>}
                    </span>
                  </PlateAB>
                  <Tag>
                    <span className="mono">
                      {currentPlateData?.tagClass}-{currentPlateData?.plate}
                    </span>
                  </Tag>
                  <Exp>
                    <span className="mono">
                      {currentPlateData?.expMonth}/{currentPlateData?.expYear}
                    </span>
                  </Exp>
                  <Trash>
                    <div className="text-right">
                      <Button
                        className="width-full margin-0 trash-btn"
                        data-testid="delete-single-plate-btn"
                        variant="outline"
                        size={isDesktop ? 'medium' : 'large'}
                        label=""
                        leftIcon={{
                          name: 'delete',
                        }}
                        onClick={() => {
                          setPlateToDelete(plateSessionData?.currentPlate);
                          setShowPlateDeleteModal(true);
                        }}
                      />
                    </div>
                  </Trash>
                  <Missing>
                    <Checkbox
                      className="missing-plate"
                      data-testid="missing-plate"
                      id="missing-plate"
                      name="missing-plate"
                      label="Missing second plate"
                      value="missing-plate"
                      checked={isMissingPlate ? 'checked' : ''}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setShowMissingPlateModal(true);
                        }
                      }}
                    />
                  </Missing>
                </>
              );
            }}
          </Composition>
        </div>
      )}
      <div className="margin-top-3">
        <PlateListing
          plates={plateSessionData?.plates}
          onDelete={(plate) => {
            deletePlate(plate);
          }}
          setShowSinglePlateDelete={showSinglePlateDeleteModal}
        />
      </div>
      <div className="margin-top-3">
        <Button
          className="width-full margin-0 padding-left-0 padding-right-0"
          data-testid="session-cancel-button"
          variant="outline"
          size={isDesktop ? 'medium' : 'large'}
          label="Cancel"
          onClick={() => {
            setShowCancelSessionModal(true);
          }}
        />
      </div>
      <AreYouSureModal
        title="Missing second license plate?"
        show={showMissingPlateModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Missing second plate"
        cancelLabel="Cancel"
        variant="blue-btn"
        onConfirm={() => {
          setIsMissingPlate(false);
          setShowMissingPlateModal(false);
          handleMissingPlate();
        }}
        onCancel={() => setShowMissingPlateModal(false)}
      >
        Please confirm that there is no second license plate to complete this
        set. Contact your FSR if you are missing a license plate.
      </AreYouSureModal>
      <AreYouSureModal
        title="Are you sure you want to remove this license plate?"
        show={showPlateDeleteModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, remove"
        cancelLabel="Cancel"
        onConfirm={() => {
          deletePlate(plateToDelete);
          setPlateToDelete(null);
          setShowPlateDeleteModal(false);
        }}
        onCancel={() => setShowPlateDeleteModal(false)}
      />
      <AreYouSureModal
        title="Are you sure you want to end this session?"
        show={showCancelSessionModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, end session"
        cancelLabel="Cancel"
        onConfirm={() => {
          dispatch(clearPlateSession());
          setShowCancelSessionModal(false);
          history.push('/home');
        }}
        onCancel={() => setShowCancelSessionModal(false)}
      >
        All scanned plates will be removed without being reconciled.
      </AreYouSureModal>
      <PlateReconcileAckModal
        show={showPlateReconcileAckModal}
        issuePlates={issuePlates}
        onConfirm={() => {
          processSuccess();
          setShowPlateReconcileAckModal(false);
        }}
        modalTitle="reconcile"
      />
    </div>
  );
};

export default PlateReconciliation;
