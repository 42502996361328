import React, { useState } from 'react';
import {
  PageTitle,
  Button,
  Label,
  Textbox,
  Alert,
} from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import { Scanner } from 'components';
const INVALID_DATA = '123';

export const PurchaseOrder = ({ isDesktop }) => {
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState('');
  const [error, setError] = useState('');
  const [showTelematicsPoSuccess, setShowTelematicsPoSuccess] = useState(false);
  const [purchaseOrderScannerOpen, setPurchaseOrderScannerOpen] =
    useState(false);
  const history = useHistory();

  const handleFieldChange = (fieldName, value) => {
    if (showTelematicsPoSuccess) setShowTelematicsPoSuccess(false);
    setFormValues({
      ...formValues,
      [fieldName]: value,
      value: value,
    });
  };
  const formValidation = () => {
    setShowTelematicsPoSuccess(false);
    if (!formValues?.purchaseOrder?.length) {
      setFormErrors('PO number is invalid');
      return false;
    }

    let isValid = /^[a-zA-Z0-9]*$/.test(formValues?.purchaseOrder);
    if (!isValid || formValues?.purchaseOrder?.length > 22) {
      setFormErrors('PO number is invalid');
      return false;
    }

    if (formValues.purchaseOrder === INVALID_DATA) {
      setError(
        <div>
          Purchase Order Number was not found
          <br></br>
          <strong>PO: {formValues.purchaseOrder}</strong>
        </div>,
      );
      return false;
    }

    setFormErrors('');
    return true;
  };
  const handleSubmit = () => {
    if (!formValidation()) {
      return;
    }
    setError('');
    setFormErrors('');
    setShowTelematicsPoSuccess(true);
    setFormValues({
      ...formValues,
      purchaseOrder: '',
    });
  };

  return (
    <div className="grid-col">
      {error && (
        <Alert
          type="error"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          {error}
        </Alert>
      )}
      {showTelematicsPoSuccess && (
        <>
          <Alert
            className="margin-bottom-3"
            type="success"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={true}
            onClose={() => setShowTelematicsPoSuccess(false)}
          >
            <div>
              The Telematics PO was successfully added.
              <br></br>
              <strong>Purchase Order: {formValues?.value}</strong>
            </div>
          </Alert>
        </>
      )}

      <PageTitle
        title="Telematics Purchase Order Reconciliation"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <Composition
        areas={`ExplainText`}
        areasMd={`ExplainText ExplainText`}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({ ExplainText }) => (
          <>
            <ExplainText>
              <p className="margin-top-0">
                Please scan or enter the Purchase Order (PO) number.
              </p>
            </ExplainText>
            <div
              className={
                formErrors !== ''
                  ? 'margin-bottom-3 form-error'
                  : 'margin-bottom-3'
              }
            >
              <Label className="margin-top-1" required>
                <strong>Purchase Order number</strong>
              </Label>
              <div className="gray-text">Scan or type</div>
              {formErrors !== '' && (
                <div className="form-error-msg">
                  <strong>{formErrors}</strong>
                </div>
              )}
              <Composition
                areas={`
                PurchaseOrder ScanBtn
                SubmitButton SubmitButton
                CancelButton CancelButton`}
                gap={16}
                templateCols="auto"
                templateRows="auto"
              >
                {({ PurchaseOrder, ScanBtn, CancelButton, SubmitButton }) => (
                  <>
                    <PurchaseOrder>
                      <div className="input-box">
                        <Textbox
                          type="text"
                          id="purchaseOrder"
                          name="purchaseOrder"
                          value={formValues?.purchaseOrder || ''}
                          variant={formErrors?.purchaseOrder ? 'error' : ''}
                          onChange={(e) => {
                            handleFieldChange('purchaseOrder', e.target.value);
                          }}
                          className="mobile-textbox margin-top-0"
                        />
                      </div>
                    </PurchaseOrder>
                    <ScanBtn>
                      <Button
                        variant="primary"
                        size="medium"
                        label=""
                        leftIcon={{
                          name: 'photo_camera',
                          iconName: 'photo_camera',
                        }}
                        onClick={() => {
                          handleFieldChange('purchaseOrder', '');
                          setPurchaseOrderScannerOpen(true);
                        }}
                        className="icon-button"
                        style={{
                          height: '40px',
                          borderRadius: '.25rem',
                          position: 'static',
                          marginTop: '0',
                        }}
                      />
                    </ScanBtn>

                    <SubmitButton>
                      <Button
                        className="width-full"
                        variant="primary"
                        size={isDesktop ? 'medium' : 'large'}
                        label="Submit"
                        onClick={handleSubmit}
                      />
                    </SubmitButton>
                    <CancelButton>
                      <Button
                        className="width-full"
                        variant="outline"
                        size={isDesktop ? 'medium' : 'large'}
                        label="Cancel"
                        onClick={() => {
                          history.push('/home');
                        }}
                      />
                    </CancelButton>
                  </>
                )}
              </Composition>
            </div>
          </>
        )}
      </Composition>
      {purchaseOrderScannerOpen && (
        <Scanner
          onCapture={(result) => {
            setPurchaseOrderScannerOpen(false);
            handleFieldChange('purchaseOrder', result);
          }}
          onClose={() => {
            setPurchaseOrderScannerOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default PurchaseOrder;
