import React, { useState, useEffect, useCallback } from 'react';
import { PageTitle, Button, Alert } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Composition } from 'atomic-layout';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  removeLoadedVehicleData,
  setMarshallingData,
} from 'reducers/marshalling';
import { Scanner, AreYouSureModal, VinField } from 'components';
import { formatVehicleTitle } from 'utils';
import { useSync } from 'hooks';
import '../screens.css';

export const FindVehicle = ({
  setScreen,
  setVehicle,
  vehicle,
  setVehicleSuccess,
  setErrorCode,
  showTelematicsInstallSuccess = false,
  setshowTelematicsInstallSuccess,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getVehicleData } = useSync();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLookupData = useSelector(
    (state) => state?.marshalling?.lookup || [],
  );
  const vehicleLookupTime = useSelector(
    (state) => state?.marshalling?.lookupTs || 0,
  );
  const prevLookupTime = usePrevious(vehicleLookupTime);
  const [search, setSearch] = useState('');
  const [isSearchValid, setIsSearchValid] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [isScanned, setIsScanned] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (
      search.trim() !== '' &&
      isSearching &&
      prevLookupTime < vehicleLookupTime
    ) {
      if (search.trim() === vehicleLookupData[0]?.vin) {
        if (vehicleLookupData.length > 0) {
          setVehicle(vehicleLookupData[0]);
          setIsScanned(false);
          setIsSearching(false);
          if (
            vehicleLookupData[0].status === 'EXPECTEDDELIVERY' ||
            vehicleLookupData[0].status === 'DELIVERED'
          ) {
            setScreen('complete-telematics-install');
          } else if (
            vehicleLookupData[0].telematicsRequired &&
            vehicleLookupData[0].telematicsInstalled
          ) {
            setErrorCode('TELEMATICS_ALREADY_INSTALLED');
            setScreen('not-required');
          } else if (!vehicleLookupData[0].telematicsRequired) {
            setErrorCode('TELEMATICS_NOT_REQUIRED');
            setScreen('not-required');
          } else if (
            vehicleLookupData[0].telematicsRequired &&
            !vehicleLookupData[0].telematicsInstalled
          ) {
            setScreen('telematics-installation');
          }
        } else {
          if (isScanned) {
            setError(
              <>
                <div>
                  <strong>VIN not recognized.</strong>
                </div>
                <div>
                  The scanned VIN could not be found in our system. Please
                  manually enter the VIN or order number to see available
                  vehicles.
                </div>
              </>,
            );
            setIsScanned(false);
            return;
          } else {
            setFormErrors({
              ...formErrors,
              search: 'VIN not found',
            });
            return;
          }
        }
      }
    }
  }, [
    vehicleLookupData,
    vehicle,
    isScanned,
    formErrors,
    setScreen,
    setVehicle,
    setErrorCode,
    search,
    isSearching,
    prevLookupTime,
    vehicleLookupTime,
  ]);

  const vehicleLookup = async ({ vin, orderNumber, id }) => {
    dispatch(
      setMarshallingData({
        ...marshallingData,
        lookup: [],
      }),
    );
    getVehicleData({
      variables: {
        vin,
        orderNumber,
        id,
      },
    });
  };

  const removePreviousDataForLoadedVehicle = () => {
    dispatch(removeLoadedVehicleData());
  };

  const submitHandler = useCallback(() => {
    removePreviousDataForLoadedVehicle();
    setError(null);
    setIsScanned(false);
    setVehicleSuccess(false);
    setshowTelematicsInstallSuccess(false);
    setShowErrors(false);
    if (!isSearchValid) {
      setShowErrors(true);
      return;
    }
    setIsSearching(true);
    vehicleLookup({
      vin: search,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formErrors, search, setVehicleSuccess, isSearchValid]);

  useEffect(() => {
    if (search && isSearchValid) {
      if (isScanned && !error) {
        submitHandler();
      }
    }
  }, [isScanned, search, submitHandler, error, isSearchValid]);

  return (
    <div className="grid-col">
      <PageTitle
        title="Telematics Installation"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <Composition
        areas={`
          ${error || showTelematicsInstallSuccess ? `AlertMessages` : ``}
          ExplainText
          SearchForm
          CancelButton
        `}
        areasMd={`
          ${
            error || showTelematicsInstallSuccess
              ? `AlertMessages AlertMessages`
              : ``
          }
          ExplainText ExplainText
          SearchForm SearchForm
          CancelButton CancelButton
        `}
        gap={16}
        templateCols="auto"
        templateRows="auto"
      >
        {({ AlertMessages, ExplainText, SearchForm, CancelButton }) => (
          <>
            <AlertMessages>
              {error && (
                <Alert
                  data-testid="find-vehicle-error"
                  type="error"
                  heading=""
                  noIcon={false}
                  validation={false}
                  focused={false}
                  showClose={false}
                >
                  {error}
                </Alert>
              )}
              {showTelematicsInstallSuccess && (
                <>
                  <Alert
                    className="margin-bottom-3"
                    type="success"
                    heading=""
                    noIcon={false}
                    validation={false}
                    focused={false}
                    showClose={true}
                    onClose={() => setshowTelematicsInstallSuccess(false)}
                  >
                    The telematics device was successfully connected.
                    <div>
                      <strong>{formatVehicleTitle(vehicle)}</strong>
                    </div>
                    <div>
                      <strong>VIN: {vehicle.vin}</strong>
                    </div>
                    <div>
                      <strong>RPN/Order Number: {vehicle.orderNumber}</strong>
                    </div>
                  </Alert>
                </>
              )}
            </AlertMessages>
            <ExplainText>
              <p className="margin-top-0">
                Scan or enter the VIN or license plate to search for the vehicle
                that requires a telematics device install.
              </p>
            </ExplainText>
            <SearchForm>
              <div>
                <Button
                  className="width-full"
                  variant="primary"
                  size={isDesktop ? 'medium' : 'large'}
                  label="Scan VIN"
                  leftIcon={{
                    name: 'photo_camera',
                    iconName: 'photo_camera',
                  }}
                  onClick={() => {
                    setSearch('');
                    setScannerOpen(true);
                  }}
                />
                <div className="border-bottom border-base-light padding-bottom-3 padding-top-3">
                  <VinField
                    id="search"
                    initialValue={search}
                    showErrors={showErrors}
                    required={true}
                    label="Search by VIN"
                    scanner={false}
                    onChange={(vin) => {
                      setSearch(vin);
                    }}
                    onValidate={(isValid, errorTxt) => {
                      setIsSearchValid(isValid);
                      if (!isValid) {
                        setFormErrors({
                          ...formErrors,
                          search: errorTxt,
                        });
                      }
                    }}
                    className="mobile-textbox icon-input"
                  />
                  <Button
                    className="icon-button"
                    data-testid="search-button"
                    leftIcon={{
                      name: 'search',
                      iconName: 'search',
                    }}
                    onClick={submitHandler}
                  />
                </div>
              </div>
            </SearchForm>
            <CancelButton>
              <Button
                className="width-full"
                variant="outline"
                size={isDesktop ? 'medium' : 'large'}
                label="Cancel"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </CancelButton>
          </>
        )}
      </Composition>
      <AreYouSureModal
        title="Cancel Telematics installation."
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Cancel Telematics Installation"
        cancelLabel="Return to Telematics Installation"
        onConfirm={() => {
          setShowModal(false);
          history.push('/home');
        }}
        onCancel={() => setShowModal(false)}
      >
        Are you sure you want to cancel Telematics installation?
      </AreYouSureModal>
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            setIsScanned(true);
            setSearch(result);
          }}
          onClose={() => {
            setScannerOpen(false);
            setIsScanned(false);
          }}
        />
      )}
    </div>
  );
};

export default FindVehicle;
