import React from 'react';
import {
  Scheduler,
  //AgendaView,
  //TimelineView,
  DayView,
  WeekView,
  MonthView,
} from '@progress/kendo-react-scheduler';
import './theme.scss';

export const AppointmentScheduler = () => {
  const data = [
    {
      id: 0,
      title: 'Vehicle Exchange Appointment',
      start: new Date('2023-09-27T22:30:00.000Z'),
      end: new Date('2023-09-27T22:45:00.000Z'),
    },
    {
      id: 1,
      title: 'Vehicle Exchange Appointment New',
      start: new Date('2023-09-28T22:30:00.000Z'),
      end: new Date('2023-09-28T22:45:00.000Z'),
    },
    {
      id: 2,
      title: 'Vehicle Exchange Appointment Newest',
      start: new Date('2023-09-13T22:30:00.000Z'),
      end: new Date('2023-09-13T22:45:00.000Z'),
    },
  ];

  const breakStart = '20:00';
  const breakEnd = '20:30';

  const breakData = {
    id: 999,
    typeId: 1,
    title: 'Afternoon Break',
    start: new Date(`2019-09-13T${breakStart}:00.000Z`),
    end: new Date(`2019-09-13T${breakEnd}:00.000Z`),
    recurrenceRule: 'FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR',
    recurrenceExceptions: [],
  };

  const resources = [
    {
      name: 'Types',
      data: [
        {
          //text: "Meeting Room 101",
          value: 1,
          color: '#73b3e7',
        },
      ],
      field: 'typeId',
      valueField: 'value',
      //textField: "text",
      colorField: 'color',
    },
  ];

  return (
    <Scheduler
      data={[...data, breakData]}
      defaultDate={new Date()}
      defaultView="week"
      resources={resources}
    >
      {/*<AgendaView />*/}
      {/*<TimelineView />*/}
      <DayView slotDuration={15} slotDivisions={1} />
      <WeekView slotDuration={15} slotDivisions={1} />
      <MonthView />
    </Scheduler>
  );
};

export default AppointmentScheduler;
