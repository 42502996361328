import React, { useState, useEffect } from 'react';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useDispatch, useSelector } from 'react-redux';
import { slugify } from 'utils';
import { IsCorrectField, YesNoCannotField, Scanner } from 'components';
import { standardItemsMap } from '../../../../../../constants/vehicleDetailCodeMap';
import { Composition, Box } from 'atomic-layout';
import {
  Alert,
  Label,
  Textbox,
  Button,
  SelectBox,
} from '@gsa/afp-component-library';
import { setLoadedVehicleData } from 'reducers/marshalling';
import { scrollToElement } from 'utils';
import '../../../screens.css';

export const Equipment = ({
  vehicle,
  showFormErrors = false,
  setValid,
  setTouched,
  setTabPartial,
  saves = 0,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const vehicleLoadData = marshallingData?.load || {};
  const vehicleOptions = vehicle?.vehicleOptions || [];
  const vehicleStandardItems = vehicle?.standardItems
    ? vehicle?.standardItems.filter((item) =>
        Object.keys(standardItemsMap).includes(item),
      )
    : [];
  const [tabSaved, setTabSaved] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [showErrors, setShowErrors] = useState(showFormErrors);
  const [scannerOpen, setScannerOpen] = useState(false);
  const [scanFrom, setScanFrom] = useState(null);
  const prevFormValues = usePrevious(formValues);
  const prevSaves = usePrevious(saves);

  useEffect(() => {
    setShowErrors(showFormErrors);
  }, [showFormErrors]);

  useEffect(() => {
    if (tabSaved) {
      scrollToElement('.react-tabs');
    }
  }, [tabSaved]);

  const validateForm = (formValues, vehicle) => {
    const errors = {};

    if (vehicle?.vehicleOptions && vehicle?.vehicleOptions.length > 0) {
      vehicle.vehicleOptions.forEach((opt) => {
        const optionKey = `option-${slugify(opt)}`;
        if (
          !formValues[optionKey] ||
          !['yes', 'no', 'cannot'].includes(formValues[optionKey])
        ) {
          errors[optionKey] = 'This is a required field';
        }
      });
    }

    if (vehicle?.standardItems && vehicle?.standardItems.length > 0) {
      vehicle.standardItems.forEach((opt) => {
        if (Object.keys(standardItemsMap).includes(opt)) {
          const optionKey = `stdItem-${opt}`;
          if (
            formValues[optionKey] !== true &&
            formValues[optionKey] !== false
          ) {
            errors[optionKey] = 'This is a required field';
          }
        }
      });
    }

    if (!formValues?.numKeys || formValues?.numKeys === '') {
      errors.numKeys = 'This is a required field';
    }

    if (typeof formValues?.isKeyBarcode !== 'boolean') {
      errors.isKeyBarcode = 'This is a required field';
    }

    if (formValues?.isKeyBarcode === true) {
      if (!formValues?.keyBarcode1 || formValues?.keyBarcode1.trim() === '') {
        errors.keyBarcode1 = 'This is a required field';
      }

      if (
        formValues?.keyBarcode1 &&
        formValues?.keyBarcode1.trim().length > 15
      ) {
        errors.keyBarcode1 = 'Must be less than 15 characters';
      }

      if (
        formValues?.keyBarcode2 &&
        formValues?.keyBarcode2.trim().length > 15
      ) {
        errors.keyBarcode2 = 'Must be less than 15 characters';
      }
    }

    if (formValues?.numKeys === '10+') {
      if (
        !formValues?.actualNumKeys ||
        formValues?.actualNumKeys.trim() === ''
      ) {
        errors.actualNumKeys = 'This is a required field';
      }
      const parsedNumKeys = formValues?.actualNumKeys
        ? parseInt(formValues?.actualNumKeys.trim())
        : '';
      if (
        !formValues?.actualNumKeys ||
        (!isNaN(parsedNumKeys) && parsedNumKeys < 11) ||
        parsedNumKeys.toString().length < 2
      ) {
        errors.actualNumKeys = 'Must be greater than 10';
      }
      if (
        formValues?.actualNumKeys &&
        isNaN(Number(formValues?.actualNumKeys))
      ) {
        errors.actualNumKeys = 'Must be a number';
      }
    }

    return errors;
  };

  const changeHandler = (field, value) => {
    setTouched('equipment', true);
    setTabSaved(false);
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  const submitHandler = () => {
    setShowErrors(false);
    setTabSaved(false);
    const errors = validateForm(formValues, vehicle);
    let isPartial = false;
    if (Object.keys(errors).length > 0) {
      isPartial = true;
      for (const [, error] of Object.entries(errors)) {
        if (error !== 'This is a required field') {
          isPartial = false;
        }
      }
      setTabPartial('equipment', isPartial);
      setFormErrors(errors);
      setTouched('equipment', true);
      setValid('equipment', false);
      setShowErrors(true);
    } else {
      setTabPartial('equipment', false);
      setTouched('equipment', false);
      setValid('equipment', true);
      setTabSaved(true);
      dispatch(setLoadedVehicleData(formValues));
    }
  };

  useEffect(() => {
    if (JSON.stringify(prevFormValues) !== JSON.stringify(formValues)) {
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        setTabPartial('equipment', isPartial);
        setFormErrors(errors);
        setValid('equipment', false);
      } else {
        setTabPartial('equipment', false);
        setFormErrors({});
        setValid('equipment', true);
      }
    }
  }, [prevFormValues, formValues, setValid, setTabPartial, vehicle]);

  useEffect(() => {
    if (Object.keys(vehicleLoadData).length > 0) {
      const vehicleLoadObj = { ...vehicleLoadData };
      if (typeof vehicleLoadData?.isKeyBarcode !== 'boolean') {
        vehicleLoadObj.isKeyBarcode = false;
      }
      setFormValues(vehicleLoadObj);
    }
  }, [vehicleLoadData]);

  useEffect(() => {
    if (prevSaves !== saves && prevSaves !== undefined) {
      setShowErrors(false);
      const errors = validateForm(formValues, vehicle);
      let isPartial = false;
      if (Object.keys(errors).length > 0) {
        isPartial = true;
        for (const [, error] of Object.entries(errors)) {
          if (error !== 'This is a required field') {
            isPartial = false;
          }
        }
        if (!isPartial) {
          setFormErrors(errors);
          setShowErrors(true);
          return;
        }
      }
      dispatch(setLoadedVehicleData(formValues));
    }
  }, [saves, prevSaves, formValues, vehicle, dispatch]);

  return (
    <div className="grid-col">
      {tabSaved && (
        <Alert
          className="margin-bottom-3 tab-save-success"
          type="success"
          heading=""
          noIcon={false}
          validation={false}
          focused={false}
          showClose={false}
        >
          Tab successfully saved.
        </Alert>
      )}
      {scannerOpen && (
        <Scanner
          onCapture={(result) => {
            setScannerOpen(false);
            setFormValues({
              ...formValues,
              [scanFrom]: result,
            });
          }}
          onClose={() => {
            setScannerOpen(false);
          }}
        />
      )}
      <p className="border-bottom border-base-light padding-bottom-3">
        Required fields are marked with an asterisk (
        <span className="afp-required-field">*</span>).
      </p>
      {vehicleStandardItems.length > 0 && (
        <div className="border-bottom border-base-light padding-bottom-3">
          <p className="tab-title">Standard Equipment</p>
          {vehicleStandardItems.map((item, i) => {
            const itemKey = `stdItem-${item}`;
            return (
              <IsCorrectField
                key={i}
                error={formErrors[itemKey] || ''}
                showErrors={showErrors}
                label={standardItemsMap[item]}
                defaultValue={null}
                currentValue={formValues[itemKey]}
                fieldName={itemKey}
                onFocus={() => setTouched('equipment', true)}
                onChange={changeHandler}
                yesNoOnly={true}
              />
            );
          })}
        </div>
      )}
      {vehicleOptions.length > 0 && (
        <div className="border-bottom border-base-light padding-bottom-3">
          <p className="tab-title">Options</p>
          {vehicleOptions.map((option, i) => {
            const optionKey = `option-${slugify(option)}`;
            return (
              <div
                key={i}
                className={
                  formErrors[optionKey] && showErrors
                    ? 'margin-bottom-3 form-error'
                    : 'margin-bottom-3'
                }
              >
                <Label className="margin-top-1">
                  <strong>Option:</strong>
                  <span className="afp-required-field">*</span> {option}
                </Label>
                {formErrors[optionKey] && showErrors && (
                  <div className="form-error-msg">
                    <strong>{formErrors[optionKey]}</strong>
                  </div>
                )}
                <YesNoCannotField
                  fieldName={optionKey}
                  defaultValue={formValues[optionKey] || null}
                  onFocus={() => setTouched('equipment', true)}
                  onChange={(val) => changeHandler(optionKey, val)}
                />
              </div>
            );
          })}
        </div>
      )}
      <div className="padding-bottom-3">
        <p className="tab-title">Vehicle Keys</p>
        <div className={formErrors?.numKeys && showErrors ? 'form-error' : ''}>
          <Label className="margin-top-1" required>
            <strong>Number of keys</strong>
          </Label>
          {formErrors?.numKeys && showErrors && (
            <div className="form-error-msg">
              <strong>{formErrors.numKeys}</strong>
            </div>
          )}
          <SelectBox
            data-testid="num-keys"
            id="numKeys"
            name="numKeys"
            variant={formErrors?.numKeys && showErrors ? 'error' : ''}
            optionLabel="label"
            optionValue="value"
            defaultValue={formValues?.numKeys || ''}
            value={formValues?.numKeys || ''}
            onFocus={() => setTouched('equipment', true)}
            options={[
              { value: '', label: '- Select -' },
              { value: '0', label: '0' },
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' },
              { value: '4', label: '4' },
              { value: '5', label: '5' },
              { value: '6', label: '6' },
              { value: '7', label: '7' },
              { value: '8', label: '8' },
              { value: '9', label: '9' },
              { value: '10', label: '10' },
              { value: '10+', label: '10+' },
            ]}
            onChangeFunc={(e) => {
              changeHandler('numKeys', e.target.value);
            }}
          />
        </div>
        {formValues?.numKeys === '10+' && (
          <div
            className={
              formErrors?.actualNumKeys && showErrors
                ? 'margin-top-3 form-error'
                : 'margin-top-3'
            }
          >
            <Label className="margin-top-1" required>
              <strong>How many keys?</strong>
            </Label>
            {formErrors?.actualNumKeys && showErrors && (
              <div className="form-error-msg">
                <strong>{formErrors?.actualNumKeys}</strong>
              </div>
            )}
            <div className="input-box">
              <Textbox
                type="text"
                id="actualNumKeys"
                name="actualNumKeys"
                value={formValues?.actualNumKeys || ''}
                variant={formErrors?.actualNumKeys && showErrors ? 'error' : ''}
                onFocus={() => setTouched('equipment', true)}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value.trim());
                  setTabSaved(false);
                  setFormValues({
                    ...formValues,
                    actualNumKeys:
                      typeof parsed === 'number' && !isNaN(parsed)
                        ? parsed.toString()
                        : e.target.value,
                  });
                }}
                className="mobile-textbox margin-top-0"
              />
            </div>
          </div>
        )}
        <div className="margin-top-3">
          <IsCorrectField
            error={formErrors['isKeyBarcode'] || ''}
            showErrors={showErrors}
            label="Do these key(s) have a barcode to enter?"
            currentValue={formValues?.isKeyBarcode}
            fieldName="isKeyBarcode"
            onFocus={() => setTouched('equipment', true)}
            onChange={changeHandler}
            yesNoOnly={true}
          />
          {formValues?.isKeyBarcode &&
            !['', '0'].includes(formValues?.numKeys) && (
              <div>
                <p>
                  You are required to scan/enter one barcode regardless of
                  number of keys.
                </p>
                <div
                  className={
                    formErrors?.keyBarcode1 && showErrors ? 'form-error' : ''
                  }
                >
                  <Label className="margin-top-1" required>
                    <strong>Enter key 1</strong>
                  </Label>
                  <div className="gray-text">Scan or type</div>
                  {formErrors?.keyBarcode1 && showErrors && (
                    <div className="form-error-msg">
                      <strong>{formErrors?.keyBarcode1}</strong>
                    </div>
                  )}
                  <Composition
                    areas={`
                    KeyBarcode ScanBtn
                  `}
                    gap={8}
                    templateCols="1fr 70px"
                  >
                    {({ KeyBarcode, ScanBtn }) => (
                      <>
                        <KeyBarcode>
                          <div className="input-box">
                            <Textbox
                              type="text"
                              id="keyBarcode1"
                              name="keyBarcode1"
                              value={formValues?.keyBarcode1 || ''}
                              variant={
                                formErrors?.keyBarcode1 && showErrors
                                  ? 'error'
                                  : ''
                              }
                              onFocus={() => setTouched('equipment', true)}
                              onChange={(e) => {
                                setFormValues({
                                  ...formValues,
                                  keyBarcode1: e.target.value,
                                });
                              }}
                              className="mobile-textbox margin-top-0"
                            />
                          </div>
                        </KeyBarcode>
                        <ScanBtn>
                          <Button
                            variant="primary"
                            size="medium"
                            label=""
                            leftIcon={{
                              name: 'photo_camera',
                              iconName: 'photo_camera',
                            }}
                            onClick={() => {
                              setTouched('equipment', true);
                              setScanFrom('keyBardcode1');
                              setScannerOpen(true);
                            }}
                            className="icon-button"
                            style={{
                              height: '40px',
                              borderRadius: '.25rem',
                              position: 'static',
                              marginTop: '0',
                            }}
                          />
                        </ScanBtn>
                      </>
                    )}
                  </Composition>
                </div>
                {!['', '0', '1'].includes(formValues?.numKeys) && (
                  <div
                    className={
                      formErrors?.keyBarcode2 && showErrors
                        ? 'margin-top-2 form-error'
                        : 'margin-top-2'
                    }
                  >
                    <Label className="margin-top-1">
                      <strong>Enter key 2</strong>
                    </Label>
                    <div className="gray-text">Scan or type</div>
                    {formErrors?.keyBarcode2 && showErrors && (
                      <div className="form-error-msg">
                        <strong>{formErrors?.keyBarcode2}</strong>
                      </div>
                    )}
                    <Composition
                      areas={`
                      KeyBarcode ScanBtn
                    `}
                      gap={8}
                      templateCols="1fr 70px"
                    >
                      {({ KeyBarcode, ScanBtn }) => (
                        <>
                          <KeyBarcode>
                            <div className="input-box">
                              <Textbox
                                type="text"
                                id="keyBarcode2"
                                name="keyBarcode2"
                                value={formValues?.keyBarcode2 || ''}
                                variant={
                                  formErrors?.keyBarcode2 && showErrors
                                    ? 'error'
                                    : ''
                                }
                                onFocus={() => setTouched('equipment', true)}
                                onChange={(e) => {
                                  setFormValues({
                                    ...formValues,
                                    keyBarcode2: e.target.value,
                                  });
                                }}
                                className="mobile-textbox margin-top-0"
                              />
                            </div>
                          </KeyBarcode>
                          <ScanBtn>
                            <Button
                              variant="primary"
                              size="medium"
                              label=""
                              leftIcon={{
                                name: 'photo_camera',
                                iconName: 'photo_camera',
                              }}
                              onClick={() => {
                                setTouched('equipment', true);
                                setScanFrom('keyBardcode2');
                                setScannerOpen(true);
                              }}
                              className="icon-button"
                              style={{
                                height: '40px',
                                borderRadius: '.25rem',
                                position: 'static',
                                marginTop: '0',
                              }}
                            />
                          </ScanBtn>
                        </>
                      )}
                    </Composition>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      <Box marginBottom={24} paddingTop={8} width={isDesktop ? '50%' : '100%'}>
        <Button
          data-testid="continue-button"
          className="width-full"
          variant="primary"
          size={isDesktop ? 'medium' : 'large'}
          label="Save"
          onClick={submitHandler}
        />
      </Box>
    </div>
  );
};

export default Equipment;
