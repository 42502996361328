import { vehicleClasses } from 'constants/vehicleDetailCodeMap';

export const plateValidation = (plate) => {
  if (plate.length !== 8) {
    return 'Plate must be 8 characters';
  }

  // Check for non-alphanumeric chars
  if (plate.match(/[^a-zA-Z0-9]+/gi) !== null) {
    return 'Special characters are not allowed';
  }

  const tagClass = plate.slice(0, 3);
  const validClasses = [];
  for (const [, value] of Object.entries(vehicleClasses)) {
    if (!validClasses.includes(value.tagClass)) {
      validClasses.push(value.tagClass);
    }
  }
  if (!validClasses.includes(tagClass)) {
    return 'Tag class is invalid';
  }

  return '';
};

export default plateValidation;
