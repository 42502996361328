import React, { useState } from 'react';
import { Accordion, Icon, Button, Alert } from '@gsa/afp-component-library';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import { formatVehicleTitle } from 'utils/formatting';
import { AreYouSureModal } from 'components';
import { damageCodeMap, damageTypes } from 'constants/damageCodeMap';
import './DeliveryListing.css';

export const DeliveryListing = ({
  items = [],
  onToggle = null,
  showRemoval = false,
  onRemoval,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const [showModal, setShowModal] = useState(false);
  const [removeVehicle, setRemoveVehicle] = useState(null);

  const formatTitle = (text, icon, iconColor) => {
    return (
      <span className="accordion-title">
        {icon && (
          <span className="icon-container">
            <Icon
              viewBox="0 0 24 24"
              iconName={icon}
              className={iconColor || ''}
            />
          </span>
        )}
        <span className="text-container">{text}</span>
      </span>
    );
  };

  const damageList = (damage) => {
    const listing = [];
    for (const [damageArea, areaDamage] of Object.entries(damage)) {
      const areaCodes = damageCodeMap.find(
        (area) => area.slug === damageArea,
      ).codes;
      for (const [damageCode, damageData] of Object.entries(areaDamage)) {
        const damageInfo = areaCodes.find(
          (areaCode) => areaCode.code === damageCode,
        ).text;
        const problemText = damageTypes.find(
          (type) => type.code === damageData.type,
        )?.text;
        listing.push({
          location: `${damageInfo} (${damageCode})`,
          problems: [`${damageData.type} - ${problemText}`],
        });
      }
    }

    return (
      <>
        {listing.map((item, i) => {
          return (
            <div key={i} className="damage">
              <div>
                <strong>{item.location}</strong>
              </div>
              <ul>
                {item.problems.map((problem, x) => {
                  return <li key={x}>{problem}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </>
    );
  };

  const formatContent = (vehicles) => {
    const content = [];
    if (
      Object.keys(vehicles[0].damage).length > 0 ||
      vehicles[0].dit === 'cannot'
    ) {
      content.push(
        <div key="alert" className="grid-row padding-top-2">
          <Alert
            type="warning"
            heading=""
            noIcon={false}
            validation={false}
            focused={false}
            showClose={false}
            className="width-full"
          >
            Add this Damage in Transit to the Bill of Lading (BOL)
          </Alert>
        </div>,
      );
    }
    vehicles.forEach((vehicle, i) => {
      const hasDamage = Object.keys(vehicle?.damage).length > 0;
      content.push(
        <div className={`vehicle${isDesktop ? ' large' : ''}`} key={i}>
          <Composition
            areas={`
              Title
              Vin
              ${hasDamage ? `Damage` : `.`}
              ${vehicle.dit === 'cannot' ? `CannotInspect` : `.`}
            `}
            areasMd={`
              Title ${hasDamage ? `Damage` : `Title`}
              Vin ${hasDamage ? `Damage` : `Vin`}
              ${vehicle.dit === 'cannot' ? `CannotInspect` : `. .`}
            `}
            gap={8}
            templateCols="1fr"
            templateColsMd="300px 1fr"
            templateRows="20px 20px auto"
            templateRowsMd="20px auto"
          >
            {({ Title, Vin, Damage, CannotInspect }) => {
              return (
                <>
                  <Title>
                    <strong>{formatVehicleTitle(vehicle)}</strong>
                  </Title>
                  <Vin>
                    <strong>VIN: {vehicle.vin}</strong>
                  </Vin>
                  <Damage
                    marginTop={
                      vehicle.damage && Object.keys(vehicle.damage).length
                        ? 24
                        : 0
                    }
                    marginTopMd={0}
                  >
                    {vehicle.damage &&
                      Object.keys(vehicle.damage).length > 0 && (
                        <>{damageList(vehicle.damage)}</>
                      )}
                  </Damage>
                  {vehicle.dit === 'cannot' && (
                    <CannotInspect>
                      <strong>Cannot inspect</strong>
                      <ul>
                        <li>
                          Marshaller or Transporter must write "Subject to
                          Inspection (STI)" on the Bill of Lading.
                        </li>
                      </ul>
                    </CannotInspect>
                  )}
                </>
              );
            }}
          </Composition>
          {showRemoval && (
            <div style={{ width: '70%' }}>
              <Button
                className="margin-top-1"
                variant="outline"
                size="medium"
                label="Remove this vehicle"
                onClick={() => {
                  if (typeof onRemoval === 'function') {
                    setRemoveVehicle(vehicle);
                    setShowModal(true);
                  }
                }}
              />
            </div>
          )}
        </div>,
      );
    });

    return content;
  };

  // Extract 'items' and process specially for delivery listing details
  const tmpItems = [];
  items.forEach((item) => {
    tmpItems.push({
      ...item,
      title: formatTitle(
        item.title.text,
        item.title.icon,
        item.title.iconColor,
      ),
      content: formatContent(item.vehicles),
      expanded: true,
    });
  });

  return (
    <div data-testid="delivery-listing" className="delivery-listing-wrapper">
      <Accordion
        bordered={true}
        items={tmpItems}
        className="delivery-listing"
        multiselectable={true}
        handleToggleFilter={(data) => {
          if (typeof onToggle === 'function') {
            onToggle(data);
          }
        }}
      />
      <AreYouSureModal
        title="Remove this vehicle"
        show={showModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, remove vehicle"
        cancelLabel="Cancel"
        onConfirm={() => {
          onRemoval(removeVehicle);
          setRemoveVehicle(null);
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
      >
        Are you sure you want to remove this vehicle from Vehicle Delivery?
      </AreYouSureModal>
    </div>
  );
};

export default DeliveryListing;
