import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';

export const Filters = () => {
  const initialFilterStructure = [
    {
      title: 'License Plate',
      key: 'plate',
      id: 'filter-license-plate',
      type: 'text',
      permanent: false,
      placeholder: '    All plates',
      operator: '$startsWith',
    },
    {
      title: 'FSR',
      key: 'fsr',
      id: 'filter-fsr',
      type: 'text',
      permanent: false,
      placeholder: '    All FSRs',
      operator: '$startsWith',
    },
    {
      title: 'VIN',
      key: 'vin',
      id: 'filter-vin',
      type: 'text',
      permanent: false,
      placeholder: '    All VINs',
      operator: '$startsWith',
    },
    {
      title: 'Appointment Date',
      key: 'appointmentDate',
      id: 'filter-appointment-date',
      type: 'select',
      permanent: false,
      placeholder: '    All Appointment Dates',
      operator: '$exact',
      options: [
        { value: '', label: 'All Appointment Dates', defaultValue: true },
      ],
      value: '',
    },
    {
      title: 'Appointment Status',
      key: 'appointmentStatus',
      id: 'filter-appointment-status',
      type: 'select',
      permanent: false,
      placeholder: '    All Appointment Status',
      operator: '$exact',
      options: [
        { value: '', label: 'All Appointment Status', defaultValue: true },
      ],
      value: '',
    },
    {
      title: 'Business days available for appointment',
      key: 'businessDaysAvailableForAppointment',
      id: 'filter-business-days-available-for-appointment',
      type: 'select',
      permanent: false,
      placeholder: '    All Available Dates',
      operator: '$exact',
      options: [
        { value: '', label: 'All Available Dates', defaultValue: true },
      ],
      value: '',
    },
    {
      title: 'Appointment Type',
      key: 'appointmentType',
      id: 'filter-appointment-type',
      type: 'select',
      permanent: false,
      placeholder: '    All Appointment Types',
      operator: '$exact',
      options: [
        { value: '', label: 'All Appointment Types', defaultValue: true },
      ],
      value: '',
    },
    {
      title: 'Agency',
      key: 'agency',
      id: 'filter-agency',
      type: 'select',
      permanent: false,
      placeholder: '    All Agencies',
      operator: '$exact',
      options: [{ value: '', label: 'All Agencies', defaultValue: true }],
      value: '',
    },
    {
      title: 'Marshalling Site',
      key: 'marshallingSite',
      id: 'filter-marshalling-site',
      type: 'select',
      permanent: false,
      placeholder: '    All Marshalling Site',
      operator: '$exact',
      options: [
        { value: '', label: 'All Marshalling Sites', defaultValue: true },
      ],
      value: '',
    },
  ];
  return (
    <div data-testid="appointment-filters">
      <FilterPanel.FilterPanel
        filterStructure={initialFilterStructure}
        setQueryFiltersState={(filters) => {
          console.log(filters);
        }}
        clearButtonLabel="Reset All"
        showClearIcon
      />
    </div>
  );
};

export default Filters;
