import React, { useEffect, useState } from 'react';
import { Button, Icon } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Composition, Box } from 'atomic-layout';
import './PhotoCapture.css';

export const PhotoCapture = ({
  vin,
  area,
  code,
  id,
  name,
  onCapture,
  maxPhotoCount = 3,
  showLabel = true,
  photosValue = null,
  photoDataValue = null,
}) => {
  const [photos, setPhotos] = useState(photosValue || []);
  const [photoData, setPhotoData] = useState(photoDataValue || []);
  const [photoCount, setPhotoCount] = useState(1);
  const [deleteItem, setDeleteItem] = useState(null);
  const prevLength = usePrevious(photoData.length);
  const [firstTimeLoad, setFirstTimeload] = useState(true);

  useEffect(() => {
    if (
      typeof onCapture === 'function' &&
      photos.length === photoData.length &&
      prevLength !== photoData.length
    ) {
      onCapture(photos, photoData);
      setPhotoCount(photos.length + 1);
    }
    if (
      firstTimeLoad &&
      photosValue &&
      photosValue.length > 0 &&
      photoDataValue &&
      photoDataValue.length > 0
    ) {
      setPhotos(photosValue);
      setPhotoData(photoDataValue);
      setPhotoCount(photos.length);
      setFirstTimeload(false);
    }
  }, [
    photos,
    photoData,
    prevLength,
    onCapture,
    photosValue,
    photoDataValue,
    firstTimeLoad,
  ]);

  if (!vin || !area || !code || !id || !name) {
    return null;
  }

  const truncateName = (name, i) => {
    const extSplit = name.split('.');
    const ext = extSplit[extSplit.length - 1];
    const fileName = `${vin}_${area}_${code}_${i}.`;
    return (
      <span className="file-name">
        <span>{fileName}</span>
        <span>{ext}</span>
      </span>
    );
  };

  const handleDelete = (index) => {
    setPhotos([...photos.filter((photo, i) => i !== index)]);
    setPhotoData([...photoData.filter((photo, i) => i !== index)]);
    setPhotoCount(photoCount - 1);
  };

  const fileReader = new FileReader();
  fileReader.onloadend = () => {
    const imgSrc = fileReader.result;
    setPhotoData([...photoData, imgSrc]);
  };

  const photoNodes = [];
  for (let i = 0; i < photoCount + 1; i++) {
    photoNodes.push(
      <input
        key={i}
        id={`${id}-${i}`}
        name={`${name}-${i}`}
        type="file"
        className="file-upload-input"
        accept="image/*;capture=camera"
        onChange={() => {
          const file = document.getElementById(`${id}-${i}`).files[0];
          fileReader.readAsDataURL(file);
          if (file) {
            setPhotos([
              ...photos,
              {
                modified: file?.lastModifiedDate || null,
                name: file?.name || null,
                size: file?.size || null,
                type: file?.type || null,
              },
            ]);
            setPhotoCount(photoCount + 1);
          }
        }}
      />,
    );
  }

  return (
    <div data-testid="photo-capture" className="photo-capture">
      {photoNodes}
      {photos.map((photo, i) => {
        const photoName = truncateName(photo.name, i);
        return (
          <div className="photo-item" key={i}>
            <Composition
              areas={`
                Photo Name Delete
              `}
              gap={8}
              templateCols="64px auto 40px"
              templateRows="46px"
              alignItems="center"
            >
              {({ Photo, Name, Delete }) => (
                <>
                  <Photo>
                    <img
                      className="damage-photo"
                      src={photoData[i]}
                      alt={`Damage Preview - ${i}`}
                      style={{ verticalAlign: 'bottom' }}
                    />
                  </Photo>
                  <Name>{photoName}</Name>
                  <Delete>
                    {deleteItem === i && (
                      <div
                        data-testid="photo-deleter"
                        className="photo-deleter"
                      >
                        <div>
                          <Composition
                            areas={`
                              Message Message
                              DeleteBtn CancelBtn
                            `}
                            gap={8}
                            templateCols="50% 50%"
                            templateRows="auto"
                          >
                            {({ Message, DeleteBtn, CancelBtn }) => (
                              <>
                                <Message>
                                  <div>
                                    <strong>
                                      Are you sure you want to delete?
                                    </strong>
                                  </div>
                                </Message>
                                <DeleteBtn>
                                  <Button
                                    data-testid={`photo-delete-button-${i}`}
                                    className="margin-right-0"
                                    label="Delete"
                                    variant="primary"
                                    size="small"
                                    leftIcon={{
                                      name: 'delete',
                                      iconName: 'delete',
                                    }}
                                    onClick={() => {
                                      const fileInput = document.getElementById(
                                        `${name}-${i}`,
                                      );
                                      if (fileInput) {
                                        fileInput.value = null;
                                      }
                                      handleDelete(i);
                                      setDeleteItem(null);
                                    }}
                                  />
                                </DeleteBtn>
                                <CancelBtn>
                                  <Button
                                    data-testid={`photo-delete-cancel-button-${i}`}
                                    className="margin-right-0"
                                    label="Cancel"
                                    variant="outline"
                                    size="small"
                                    onClick={() => setDeleteItem(null)}
                                  />
                                </CancelBtn>
                              </>
                            )}
                          </Composition>
                        </div>
                      </div>
                    )}
                    <div
                      data-testid={`delete-button-${i}`}
                      className="delete-button"
                      onClick={() => {
                        setDeleteItem(i);
                      }}
                    >
                      <Icon iconName="delete" />
                    </div>
                  </Delete>
                </>
              )}
            </Composition>
          </div>
        );
      })}
      {showLabel && (
        <div>
          <p className="damage-code">
            Photo of damage <span className="afp-required-field">*</span>
          </p>
          <p className="damage-code info">Max {maxPhotoCount} photos</p>
        </div>
      )}
      {photos.length < maxPhotoCount && (
        <Box marginTop={photoCount > 1 ? 24 : 0}>
          <Button
            data-testid="capture-button"
            className="width-full"
            variant="outline"
            size="medium"
            label={photoCount > 1 ? 'Take additional photo' : 'Take photo'}
            leftIcon={{
              name: 'photo_camera',
              iconName: 'photo_camera',
            }}
            onClick={() => {
              if (photos.length < maxPhotoCount) {
                document.getElementById(`${id}-${photos.length}`).click();
              }
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default PhotoCapture;
