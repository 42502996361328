import React, { useState, useEffect } from 'react';
import {
  PageTitle,
  Icon,
  Button,
  Alert,
  Tooltip,
} from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { NavLink } from 'react-router-dom';
import { formatVehicleTitle } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { useSync } from 'hooks';
import { ucfirst } from 'utils';
import {
  removeLoadedVehicleData,
  setLoadedVehicleData,
} from 'reducers/marshalling';
import { Tabs, AreYouSureModal } from 'components';
import { Equipment, Plates, Telematics, Vehicle } from './tabs';
import { Composition } from 'atomic-layout';
import '../../screens/screens.css';

export const LoadVehicle = ({
  setScreen,
  vehicle,
  setSessionComplete,
  vinScan,
  isDesktop,
}) => {
  const dispatch = useDispatch();
  const { completeVehicleLoad } = useSync();
  const vehicleLoadData = useSelector(
    (state) => state?.marshalling?.load || {},
  );
  const userData = useSelector((state) => state?.user || {});
  const vendorId = userData?.vendorId || '0';
  const [notDelivered, setNotDelivered] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [selectedTab, setSelectedTab] = useState('plates');
  const [helpTooltipOpen, setHelpTooltipOpen] = useState(false);
  const [ableToComplete, setAbleToComplete] = useState(false);
  const [completeRequested, setCompleteRequested] = useState(false);
  const [telematicsSuccess, setTelematicsSuccess] = useState(
    vehicleLoadData?.telematicsSuccess || false,
  );
  const [telematicsScan, setTelematicsScan] = useState(false);
  const [tagScan, setTagScan] = useState(false);
  const prevTab = usePrevious(selectedTab);
  const [tabError, setTabError] = useState([]);
  const [tabValid, setTabValid] = useState({
    plates: true,
    vehicle: true,
    equipment: true,
    telematics: true,
  });
  const [tabTouched, setTabTouched] = useState({
    plates: false,
    vehicle: false,
    equipment: false,
    telematics: false,
  });
  const [tabEverTouched, setTabEverTouched] = useState({
    plates: false,
    vehicle: false,
    equipment: false,
    telematics: false,
  });
  const prevEverTouched = usePrevious(tabEverTouched);
  const [tabPartial, setTabPartial] = useState({
    plates: false,
    vehicle: false,
    equipment: false,
    telematics: false,
  });
  const [showErrors, setShowErrors] = useState({
    plates: false,
    vehicle: false,
    equipment: false,
    telematics: false,
  });
  const [saves, setSaves] = useState({
    plates: 0,
    vehicle: 0,
    equipment: 0,
    telematics: 0,
  });
  const prevTabValid = usePrevious(tabValid);

  useEffect(() => {
    if (vehicleLoadData.telematicsSN) {
      dispatch(
        setLoadedVehicleData({
          telematicsSuccess,
        }),
      );
    }
  }, [telematicsSuccess, vehicleLoadData.telematicsSN, dispatch]);

  const setPartial = (tab, isPartial) => {
    setTabPartial({
      ...tabPartial,
      [tab]: isPartial,
    });
  };

  const setValid = (tab, isValid) => {
    setTabValid({
      ...tabValid,
      [tab]: isValid,
    });
  };

  const setTouched = (tab, isTouched) => {
    if (isTouched) {
      setTabEverTouched({
        ...tabEverTouched,
        [tab]: true,
      });
    }
    setTabTouched({
      ...tabTouched,
      [tab]: isTouched,
    });
  };

  if (!vehicle) {
    setScreen('start');
  }

  const tabChange = (tabId) => {
    const isTabValid = tabValid[selectedTab];
    const isTabTouched = tabTouched[selectedTab];
    const isTabPartial = tabPartial[selectedTab];
    if (isTabTouched && !isTabValid && !isTabPartial) {
      setShowErrors({
        ...showErrors,
        [selectedTab]: true,
      });
    } else {
      if ((isTabValid && isTabTouched) || isTabPartial) {
        setSaves({
          ...saves,
          [selectedTab]: saves[selectedTab] + 1,
        });
        setTabTouched({
          ...tabTouched,
          [selectedTab]: false,
        });
        setShowErrors({
          ...showErrors,
          [selectedTab]: false,
        });
      }
      window.setTimeout(() => {
        setSelectedTab(tabId);
      }, 50);
    }
  };

  useEffect(() => {
    if (vehicle?.status === 'EXPECTEDDELIVERY') {
      setNotDelivered(true);
    }
  }, [vehicle]);

  const handleCancel = () => {
    dispatch(removeLoadedVehicleData());
  };

  useEffect(() => {
    if (
      JSON.stringify(prevTabValid) !== JSON.stringify(tabValid) ||
      JSON.stringify(prevEverTouched) !== JSON.stringify(tabEverTouched) ||
      prevTab !== selectedTab
    ) {
      let tabsWithErrors = [...tabError];
      for (const [tab, isValid] of Object.entries(tabValid)) {
        if (
          !isValid &&
          tabEverTouched[tab] &&
          !tabsWithErrors.includes(tab) &&
          tab !== selectedTab
        ) {
          tabsWithErrors.push(tab);
        } else if (isValid && tabsWithErrors.includes(tab)) {
          const newTabsWithErrors = tabsWithErrors.filter(
            (errorTab) => tab !== errorTab,
          );
          tabsWithErrors = newTabsWithErrors;
        }
      }
      if (tabsWithErrors.length > 0) {
        setAbleToComplete(false);
      } else {
        setAbleToComplete(true);
      }
      setTabError(tabsWithErrors);
    }
  }, [
    tabValid,
    prevTabValid,
    tabEverTouched,
    prevEverTouched,
    selectedTab,
    prevTab,
    tabError,
  ]);

  const handleClickAway = (ev) => {
    const helpTooltip = document.querySelector('.help-tooltip');
    if (helpTooltip && !helpTooltip.contains(ev.target)) {
      setHelpTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickAway);
    return () => {
      document.removeEventListener('click', handleClickAway);
    };
  });

  const loadSave = (tabs, initialTab, resolve, current = 0) => {
    const item = tabs[current];
    setTimeout(() => {
      setSelectedTab(tabs[current]);
      setSaves({
        ...saves,
        [item]: saves[item] + 1,
      });
      setTabEverTouched({
        ...tabEverTouched,
        [item]: true,
      });
      if (current < tabs.length - 1) {
        loadSave(tabs, initialTab, resolve, (current += 1));
      } else {
        setSelectedTab(initialTab);
        resolve(true);
      }
    });
  };

  useEffect(() => {
    if (completeRequested && ableToComplete) {
      setCompleteRequested(false);
      const stdItems = Object.keys(vehicleLoadData)
        .filter((item) => item.indexOf('stdItem-') === 0)
        .map((item) => {
          return {
            name: item.replace('stdItem-', ''),
            value: vehicleLoadData[item],
          };
        });
      const options = Object.keys(vehicleLoadData)
        .filter((item) => item.indexOf('option-') === 0)
        .map((item) => {
          return {
            name: item.replace('option-', ''),
            value: vehicleLoadData[item],
          };
        });
      completeVehicleLoad({
        variables: {
          data: {
            vehicleId: vehicle.id,
            isKeyBarcode: vehicleLoadData?.isKeyBarcode === true,
            options: options,
            standardItems: stdItems,
            numKeys: vehicleLoadData?.numKeys,
            plateA: vehicleLoadData?.plateA,
            plateB: vehicleLoadData?.plateB,
            month: vehicleLoadData?.month.toString(),
            year: vehicleLoadData?.year,
            oilLifeSensors: vehicleLoadData?.oilLifeSensors,
            comments: vehicleLoadData?.comments,
            vehicleColor: vehicleLoadData?.vehicleColor,
            tireSize: vehicleLoadData?.tireSize,
            cylinders: vehicleLoadData?.cylinders,
            engineDisplacement: vehicleLoadData?.engineDisplacement,
            transmission: vehicleLoadData?.transmission,
            fuelType: vehicleLoadData?.fuelType,
            fuelCapacity: vehicleLoadData?.fuelCapacity,
            gvwr: vehicleLoadData?.gvwr,
            acquisitionMileage: vehicleLoadData?.acquisitionMileage,
            keyBarcode1: vehicleLoadData?.keyBarcode1,
            keyBarcode2: vehicleLoadData?.keyBarcode2,
            actualNumKeys: vehicleLoadData?.actualNumKeys,
            installTelematics: vehicleLoadData?.installTelematics,
            telematicsSN: vehicleLoadData?.telematicsSN,
            currentMileage: vehicleLoadData?.currentMileage,
            vendorId: vendorId,
            orderNumber: vehicle?.orderNumber,
            orderId: vehicle?.orderId,
            vinScan: vinScan,
            telematicsScan: telematicsScan,
            tagScan: tagScan,
          },
        },
      }).then(({ data }) => {
        if (data?.completeVehicleLoad?.success === true) {
          setSessionComplete(true);
          dispatch(removeLoadedVehicleData());
          setTelematicsSuccess(false);
          setScreen('start');
        }
      });
    } else if (completeRequested && !ableToComplete) {
      setShowErrors({
        plates: true,
        vehicle: true,
        equipment: true,
        telematics: true,
      });
      setCompleteRequested(false);
    }
  }, [
    ableToComplete,
    completeRequested,
    completeVehicleLoad,
    vehicle,
    vendorId,
    vehicleLoadData,
    setSessionComplete,
    setScreen,
    dispatch,
    tagScan,
    telematicsScan,
    vinScan,
  ]);

  const completeVehicleLoading = async () => {
    if (
      !telematicsSuccess &&
      vehicle?.telematicsRequired &&
      vehicleLoadData?.installTelematics === true
    ) {
      setSaves({
        ...saves,
        telematics: (saves.telematics += 1),
      });
      setSelectedTab('telematics');
      return;
    }
    const initialTab = selectedTab;
    setSaves({
      ...saves,
      [initialTab]: (saves[initialTab] += 1),
    });
    setTimeout(async () => {
      await new Promise((resolve) => {
        loadSave(Object.keys(saves), initialTab, resolve, 0);
      });
      setCompleteRequested(true);
    });
  };

  return (
    <div className="grid-col">
      <Composition
        areas={`Title Help`}
        gap={8}
        templateCols="auto"
        templateRows="auto"
        alignItems="center"
      >
        {({ Title, Help }) => (
          <>
            <Title>
              <PageTitle title="Load vehicle" />
            </Title>
            <Help>
              <div
                className={`help-message text-right${
                  helpTooltipOpen ? ' show' : ' hide'
                }`}
              >
                <Tooltip
                  className="help-tooltip"
                  label={
                    <div className="text-left">
                      <div>
                        <strong>Need Help?</strong>
                      </div>
                      <p
                        style={{
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        <span>
                          Please contact your GSA Fleet Representative.
                        </span>
                      </p>
                    </div>
                  }
                  position="bottom"
                >
                  <span
                    className="usa-link text-no-underline cursor-pointer"
                    onClick={() => {
                      setHelpTooltipOpen(true);
                    }}
                  >
                    Help
                  </span>
                </Tooltip>
              </div>
            </Help>
          </>
        )}
      </Composition>
      {vehicle && (
        <div className="border-bottom border-base-light padding-bottom-3 padding-top-0">
          {tabError.length > 0 && (
            <div className="padding-bottom-3">
              <Alert
                type="error"
                heading=""
                noIcon={false}
                validation={false}
                focused={false}
                showClose={false}
              >
                <div>
                  Please complete all required fields before loading vehicle.
                </div>
                {tabError.map((tab, i) => {
                  return (
                    <div key={i} className="margin-top-1">
                      Errors: {ucfirst(tab)} Tab
                    </div>
                  );
                })}
              </Alert>
            </div>
          )}
          <div>
            <strong>{formatVehicleTitle(vehicle)}</strong>
          </div>
          <div>
            <strong>VIN: {vehicle.vin}</strong>
          </div>
          <div>
            <strong>RPN/Order Number: {vehicle.orderNumber}</strong>
          </div>
          {!notDelivered && (
            <>
              <Composition
                areas={`CancelButton LoadButton`}
                gap={20}
                templateCols="0.5fr 0.5fr"
                templateRows="auto"
                marginVertical={24}
              >
                {({ CancelButton, LoadButton }) => (
                  <>
                    <CancelButton>
                      <Button
                        className="width-full margin-0 padding-left-0 padding-right-0"
                        variant="outline"
                        size="medium"
                        label="Cancel"
                        onClick={() => {
                          setIsCancel(true);
                        }}
                      />
                    </CancelButton>
                    <LoadButton>
                      <Button
                        className="width-full margin-0 padding-left-0 padding-right-0"
                        variant="primary"
                        size="medium"
                        label="Load Vehicle"
                        onClick={() => {
                          completeVehicleLoading();
                        }}
                      />
                    </LoadButton>
                  </>
                )}
              </Composition>
              <Tabs
                selectedId={selectedTab}
                tabConfig={[
                  {
                    id: 'plates',
                    heading: 'Plates',
                    content: (
                      <Plates
                        vehicle={vehicle}
                        showFormErrors={showErrors['plates']}
                        setValid={setValid}
                        setTouched={setTouched}
                        setTabPartial={setPartial}
                        saves={saves?.plates || 0}
                        setTagScan={setTagScan}
                        isDesktop={isDesktop}
                      />
                    ),
                  },
                  {
                    id: 'vehicle',
                    heading: 'Vehicle',
                    content: (
                      <Vehicle
                        vehicle={vehicle}
                        showFormErrors={showErrors['vehicle']}
                        setValid={setValid}
                        setTouched={setTouched}
                        setTabPartial={setPartial}
                        saves={saves?.vehicle || 0}
                        isDesktop={isDesktop}
                      />
                    ),
                  },
                  {
                    id: 'equipment',
                    heading: 'Equipment',
                    content: (
                      <Equipment
                        vehicle={vehicle}
                        showFormErrors={showErrors['equipment']}
                        setValid={setValid}
                        setTouched={setTouched}
                        setTabPartial={setPartial}
                        saves={saves?.equipment || 0}
                        isDesktop={isDesktop}
                      />
                    ),
                  },
                  {
                    id: 'telematics',
                    heading: 'Telematics',
                    content: (
                      <Telematics
                        vehicle={vehicle}
                        showFormErrors={showErrors['telematics']}
                        setValid={setValid}
                        setTouched={setTouched}
                        setTabPartial={setPartial}
                        saves={saves?.telematics || 0}
                        setTelematicsSuccess={setTelematicsSuccess}
                        telematicsSuccess={telematicsSuccess}
                        setTelematicsScan={setTelematicsScan}
                        isDesktop={isDesktop}
                      />
                    ),
                  },
                ]}
                onTabChange={(tab) => {
                  tabChange(tab);
                }}
              />
            </>
          )}
        </div>
      )}
      {notDelivered && (
        <>
          <div className="border-bottom border-base-light padding-bottom-3 padding-top-3">
            <div>
              <strong>Complete Vehicle Delivery</strong>
            </div>
            <div>
              This vehicle has not been delivered into the system. Please
              perform Vehicle Delivery before loading the vehicle.
            </div>
            <div>
              <NavLink to="/vehicle-delivery">Go to Vehicle Delivery</NavLink>
              <Icon
                style={{
                  verticalAlign: 'middle',
                  color: '#005ea2',
                }}
                className="usa-icon--size-2"
                iconName="arrow_forward"
              />
            </div>
          </div>
          <div className="padding-bottom-3 padding-top-5">
            <Button
              className="width-full"
              variant="primary"
              size={isDesktop ? 'medium' : 'large'}
              label="Return to scan"
              leftIcon={{
                name: 'photo_camera',
                iconName: 'photo_camera',
              }}
              onClick={() => {
                setScreen('start');
              }}
            />
          </div>
        </>
      )}
      <AreYouSureModal
        title="Vehicle load progress will be deleted."
        show={isCancel}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Cancel loading this vehicle"
        cancelLabel="Return to vehicle loading session"
        onConfirm={() => {
          handleCancel();
          setIsCancel(false);
          setScreen('start');
        }}
        onCancel={() => setIsCancel(false)}
      >
        Are you sure you want to cancel this vehicle loading session? All
        information collected during this session will be deleted.
      </AreYouSureModal>
    </div>
  );
};

export default LoadVehicle;
