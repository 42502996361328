import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setMarshallingData = createAsyncThunk(
  'marshalling/setMarshallingData',
  async (data) => {
    return data;
  },
);

export const setPlateData = createAsyncThunk(
  'marshalling/setPlateData',
  async (data) => {
    return data;
  },
);

export const addAcceptedVehicle = createAsyncThunk(
  'marshalling/addAcceptedVehicle',
  async (data) => {
    return data;
  },
);

export const removeAcceptedVehicle = createAsyncThunk(
  'marshalling/removeAcceptedVehicle',
  async (data) => {
    return data;
  },
);

export const addVehicleToRemovedList = createAsyncThunk(
  'marshalling/addVehicleToRemovedList',
  async (data) => {
    return data;
  },
);

export const removeAllVehicles = createAsyncThunk(
  'marshalling/removeAllVehicles',
  async (data) => {
    return data;
  },
);

export const undoAllVehiclesRemoval = createAsyncThunk(
  'marshalling/undoAllVehiclesRemoval',
  async (data) => {
    return data;
  },
);

export const setDeliverySessionData = createAsyncThunk(
  'marshalling/setDeliverySessionData',
  async (data) => {
    return data;
  },
);

export const setLoadedVehicleData = createAsyncThunk(
  'marshalling/setLoadedVehicleData',
  async (data) => {
    return data;
  },
);

export const removeLoadedVehicleData = createAsyncThunk(
  'marshalling/removeLoadedVehicleData',
  async (data) => {
    return data;
  },
);

export const updatePlateSession = createAsyncThunk(
  'marshalling/updatePlateSession',
  async (data) => {
    return data;
  },
);

export const clearPlateSession = createAsyncThunk(
  'marshalling/clearPlateSession',
  async (data) => {
    return data;
  },
);

export const updateDestructionSession = createAsyncThunk(
  'marshalling/updateDestructionSession',
  async (data) => {
    return data;
  },
);

export const clearDestructionSession = createAsyncThunk(
  'marshalling/clearDestructionSession',
  async (data) => {
    return data;
  },
);

export const setEvafData = createAsyncThunk(
  'marshalling/setEvafData',
  async (data) => {
    return data;
  },
);

export const clearEvafData = createAsyncThunk(
  'marshalling/clearEvafData',
  async (data) => {
    return data;
  },
);

const marshallingSlice = createSlice({
  name: 'marshalling',
  initialState: {},
  extraReducers: (builder) => {
    builder
      .addCase(setMarshallingData.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(setPlateData.fulfilled, (state, action) => {
        return {
          ...state,
          plates: [...(state.plates || []), action.payload],
        };
      })
      .addCase(addAcceptedVehicle.fulfilled, (state, action) => {
        return {
          ...state,
          delivery: [...(state.delivery || []), action.payload],
        };
      })
      .addCase(setDeliverySessionData.fulfilled, (state, action) => {
        return {
          ...state,
          session: {
            ...(state.session || {}),
            ...action.payload,
          },
        };
      })
      .addCase(removeAcceptedVehicle.fulfilled, (state, action) => {
        const vehicleId = action.payload;
        const removedVehicle = [
          ...state.delivery.filter((vehicle) => vehicle.id === vehicleId),
        ];
        const newDelivery = [
          ...state.delivery.filter((vehicle) => vehicle.id !== vehicleId),
        ];
        const listOfWhatIsLeft = newDelivery.filter(
          (item) => item.id !== vehicleId,
        );
        var newRemoved =
          listOfWhatIsLeft.length < 1
            ? []
            : [...(state.removedVehicle ?? []), ...removedVehicle];
        return {
          ...state,
          delivery: newDelivery,
          removedVehicle: newRemoved,
        };
      })
      .addCase(undoAllVehiclesRemoval.fulfilled, (state) => {
        const newDelivery = [...state.delivery, ...state.removedVehicle];
        var uniqueDelivery = newDelivery.reduce((acc, current) => {
          if (!acc.find((item) => item.vin === current.vin)) {
            acc.push(current);
          }
          return acc;
        }, []);
        return {
          ...state,
          delivery: uniqueDelivery,
          removedVehicle: [],
        };
      })
      .addCase(removeAllVehicles.fulfilled, (state) => {
        return {
          ...state,
          delivery: [],
          removedVehicle: [],
        };
      })
      .addCase(setLoadedVehicleData.fulfilled, (state, action) => {
        return {
          ...state,
          load: {
            ...state.load,
            ...action.payload,
          },
        };
      })
      .addCase(removeLoadedVehicleData.fulfilled, (state) => {
        return {
          ...state,
          load: {},
        };
      })
      .addCase(updatePlateSession.fulfilled, (state, action) => {
        return {
          ...state,
          plateSession: {
            ...(state.plateSession || {}),
            ...action.payload,
          },
        };
      })
      .addCase(clearPlateSession.fulfilled, (state) => {
        return {
          ...state,
          plateSession: {},
        };
      })
      .addCase(updateDestructionSession.fulfilled, (state, action) => {
        return {
          ...state,
          plateDestruction: {
            ...(state.plateDestruction || {}),
            ...action.payload,
          },
        };
      })
      .addCase(clearDestructionSession.fulfilled, (state) => {
        return {
          ...state,
          plateDestruction: {},
        };
      })
      .addCase(setEvafData.fulfilled, (state, action) => {
        return {
          ...state,
          evaf: {
            ...(state.evaf || {}),
            ...action.payload,
          },
        };
      })
      .addCase(clearEvafData.fulfilled, (state, action) => {
        return {
          ...state,
          evaf: {},
        };
      });
  },
});

export default marshallingSlice.reducer;
