import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '@gsa/afp-component-library/dist/css/index.css';
import {
  AppProvider,
  NotFound,
  PrivateRoute,
  PublicRoute,
  Unauthorized,
} from '@gsa/afp-shared-ui-utils';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import dataStore from './services/data-source';
import { store, persistor } from './store';
import SyncProvider from './context/sync-provider';
import { ServiceWorkerWrapper, Layout } from 'components';
import {
  HomePage,
  LogoutPage,
  PlateDestruction,
  PlateReconciliation,
  SignInPage,
  TelematicsInstall,
  TelematicsPurchaseOrderReconciliation,
  VehicleDelivery,
  VehicleLoad,
  EvafPage,
  AppointmentScheduling,
} from 'pages';

function App() {
  return (
    <ApolloProvider client={dataStore}>
      <AppProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SyncProvider>
              <ServiceWorkerWrapper>
                <Router>
                  <Layout>
                    <Switch>
                      <PublicRoute
                        exact
                        path="/"
                        component={SignInPage}
                        title="Sign In"
                      />
                      <PublicRoute
                        exact
                        path="/logout"
                        component={LogoutPage}
                        title="Logout"
                      />
                      <PublicRoute
                        exact
                        path="/unauthorized"
                        component={Unauthorized}
                        title="Access Denied"
                      />
                      <PrivateRoute
                        exact
                        path="/home"
                        component={HomePage}
                        title="Home"
                      />
                      <PrivateRoute
                        operation="view"
                        subject="VehicleArrival"
                        exact
                        path="/vehicle-delivery"
                        component={VehicleDelivery}
                        title="Vehicle Delivery"
                      />
                      <PrivateRoute
                        operation="view"
                        subject="LoadVehicle"
                        exact
                        path="/load-vehicle"
                        component={VehicleLoad}
                        title="Load Vehicle"
                      />
                      <PrivateRoute
                        exact
                        path="/plate-reconciliation"
                        component={PlateReconciliation}
                        title="Plate Reconciliation"
                      />
                      <PrivateRoute
                        exact
                        path="/plate-destruction"
                        component={PlateDestruction}
                        title="Plate Destruction"
                      />
                      <PrivateRoute
                        exact
                        path="/telematics-installation"
                        component={TelematicsInstall}
                        title="Telematics Installation"
                      />
                      <PrivateRoute
                        exact
                        path="/telematics-purchase-order-reconciliation"
                        component={TelematicsPurchaseOrderReconciliation}
                        title="Telematics Purchase Order Reconciliation"
                      />
                      <PrivateRoute
                        exact
                        path="/evaf"
                        component={EvafPage}
                        title="Evaf"
                      />
                      <PrivateRoute
                        exact
                        path="/appointment-scheduling"
                        component={AppointmentScheduling}
                        title="Appointment Scheduling"
                      />
                      <PublicRoute
                        path="*"
                        component={NotFound}
                        title="Sorry for the Detour"
                      />
                    </Switch>
                  </Layout>
                </Router>
              </ServiceWorkerWrapper>
            </SyncProvider>
          </PersistGate>
        </ReduxProvider>
      </AppProvider>
    </ApolloProvider>
  );
}

export default App;
