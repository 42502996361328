import React, { useEffect } from 'react';
import * as serviceWorker from '../../serviceWorkerRegistration';
import { UpdateModal } from 'components';

export const ServiceWorkerWrapper = ({ children }) => {
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState();

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPWA = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setShowReload(false);
      window.location.reload();
    }
  };

  return (
    <>
      <UpdateModal showReload={showReload} reload={reloadPWA} />
      {children}
    </>
  );
};

export default ServiceWorkerWrapper;
