import { useLazyQuery, useMutation } from '@apollo/client';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setMarshallingData } from '../reducers/marshalling';
import {
  setUserLocations,
  setUserFmc,
  setUserVendorId,
} from '../reducers/user';
import { setAppointmentListing } from '../reducers/listings';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  UPLOAD_TELEMETRY,
  COMPLETE_LOAD_SESSION,
  GEOTAB_INSTALL_STATUS,
  PLATE_RECONCILIATION_COMPLETE,
  PLATE_DESTROY_COMPLETE,
  GET_MOCKUP_VEHICLE,
  GET_PLATE,
  GET_FSR_OPTIONS,
  GET_MARSHALLER_VENDOR,
  GET_APPOINTMENTS,
} from '../services/sync-gql';

export const SyncContext = createContext();

/**
 * NOTE: If this gets too big as we go, we should create a leaner setup with a folder
 * structure and individual handlers for each method.
 */

const SyncProvider = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const marshallingData = useSelector((state) => state?.marshalling || {});
  const [isFsr, setIsFsr] = useState(false);
  const [isOnline, setIsOnline] = useState(false);

  const [getMarshallerVendor] = useLazyQuery(GET_MARSHALLER_VENDOR, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log(error);
      dispatch(setUserVendorId(null));
    },
    onCompleted: async ({
      getMarshallerVendor: { success, errorCode, vendorId },
    }) => {
      if (success) {
        dispatch(setUserVendorId(vendorId));
      } else {
        if (errorCode) {
          console.log(errorCode);
        }
        dispatch(setUserVendorId(null));
      }
    },
  });

  useEffect(() => {
    const userRoles = currentUser
      ? currentUser?.roles.map((role) => role.id)
      : [];
    if (userRoles.includes('1')) {
      setIsFsr(true);
    } else {
      getMarshallerVendor();
    }
  }, [currentUser, getMarshallerVendor, dispatch]);

  useEffect(() => {
    if (navigator && navigator.onLine) {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }

    window.addEventListener('offline', function (e) {
      setIsOnline(false);
    });

    window.addEventListener('online', function (e) {
      setIsOnline(true);
    });
  }, []);

  const [getVehicleData] = useLazyQuery(GET_MOCKUP_VEHICLE, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ getVehicleMockUpData }) => {
      dispatch(
        setMarshallingData({
          ...marshallingData,
          lookup: getVehicleMockUpData,
          lookupTs: new Date().getTime(),
        }),
      );
    },
  });

  const [getPlateData] = useLazyQuery(GET_PLATE, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ getPlate }) => {
      dispatch(
        setMarshallingData({
          ...marshallingData,
          plateLookup: getPlate,
          plateLookupTs: new Date().getTime(),
        }),
      );
    },
  });

  const geoTabInstallStatus = useLazyQuery(GEOTAB_INSTALL_STATUS, {
    fetchPolicy: 'no-cache',
  });

  const [uploadTelemetry] = useMutation(UPLOAD_TELEMETRY, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: () => {
      return true;
    },
  });

  const [completeVehicleLoad] = useMutation(COMPLETE_LOAD_SESSION, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ completeVehicleLoad }) => {
      return completeVehicleLoad;
    },
  });

  const [completePlateReconciliation] = useMutation(
    PLATE_RECONCILIATION_COMPLETE,
    {
      onError: (error) => {
        console.log(error);
      },
      onCompleted: ({ data: reconcilePlates }) => {
        return reconcilePlates;
      },
    },
  );

  const [completePlateDestroy] = useMutation(PLATE_DESTROY_COMPLETE, {
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ data: destroyPlates }) => {
      return destroyPlates;
    },
  });

  const [getFsrOptions] = useLazyQuery(GET_FSR_OPTIONS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log(error);
      dispatch(setUserLocations([]));
      dispatch(setUserFmc(null));
    },
    onCompleted: async ({ getFsrOptions: { locations, fmc } }) => {
      dispatch(setUserLocations(locations));
      dispatch(setUserFmc(fmc));
    },
  });

  const [getAppointments] = useLazyQuery(GET_APPOINTMENTS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.log(error);
    },
    onCompleted: ({ getAppointments }) => {
      dispatch(setAppointmentListing(getAppointments));
    },
  });

  return (
    <SyncContext.Provider
      value={{
        isOnline,
        isFsr,
        uploadTelemetry,
        completeVehicleLoad,
        geoTabInstallStatus,
        completePlateReconciliation,
        completePlateDestroy,
        getVehicleData,
        getPlateData,
        getFsrOptions,
        getAppointments,
      }}
    >
      {children}
    </SyncContext.Provider>
  );
};

export default SyncProvider;
