import React, { useState, useEffect } from 'react';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { RadioButton } from '@gsa/afp-component-library';
import { Composition } from 'atomic-layout';

export const YesNoCannotField = ({
  fieldName = '',
  onFocus,
  onChange,
  defaultValue,
}) => {
  const [radioVal, setRadioVal] = useState(null);
  const prevRadioVal = usePrevious(radioVal);

  useEffect(() => {
    if (typeof onChange === 'function' && prevRadioVal !== radioVal) {
      onChange(radioVal);
    }
  }, [radioVal, prevRadioVal, onChange]);

  useEffect(() => {
    if (defaultValue && prevRadioVal !== defaultValue) {
      setRadioVal(defaultValue);
    }
  }, [defaultValue, prevRadioVal]);

  return (
    <div className="yes-no-cannot-field" data-testid="yes-no-cannot-field">
      <Composition
        areas={`
          Yes
          No
          Cannot
        `}
        areasMd={`Yes No Cannot`}
        templateColsMd="150px 150px 150px"
      >
        {({ Yes, No, Cannot }) => (
          <>
            <Yes>
              <RadioButton
                data-testid={`${fieldName}-radio-yes`}
                id={`${fieldName}-radio-yes`}
                name={`${fieldName}-radio`}
                label="Yes"
                checked={radioVal === 'yes'}
                onFocus={(e) => {
                  if (typeof onFocus === 'function') {
                    onFocus(e);
                  }
                }}
                onChange={() => {
                  setRadioVal('yes');
                }}
              />
            </Yes>
            <No>
              <RadioButton
                data-testid={`${fieldName}-radio-no`}
                id={`${fieldName}-radio-no`}
                name={`${fieldName}-radio`}
                label="No"
                checked={radioVal === 'no'}
                onFocus={(e) => {
                  if (typeof onFocus === 'function') {
                    onFocus(e);
                  }
                }}
                onChange={() => {
                  setRadioVal('no');
                }}
              />
            </No>
            <Cannot>
              <RadioButton
                data-testid={`${fieldName}-radio-cannot`}
                id={`${fieldName}-radio-cannot`}
                name={`${fieldName}-radio`}
                label="Cannot Inspect"
                checked={radioVal === 'cannot'}
                onFocus={(e) => {
                  if (typeof onFocus === 'function') {
                    onFocus(e);
                  }
                }}
                onChange={() => {
                  setRadioVal('cannot');
                }}
              />
            </Cannot>
          </>
        )}
      </Composition>
    </div>
  );
};

export default YesNoCannotField;
