import React, { useState, useEffect } from 'react';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { scrollToElement } from 'utils';
import './ManagedAccordion.css';

export const ManagedAccordion = ({
  items = [],
  openItems = [],
  onOpen,
  onClose,
}) => {
  const [accordionItems, setAccordionItems] = useState(items);
  const [reRender, setReRender] = useState(0);
  const prevItems = usePrevious(JSON.stringify(openItems));

  useEffect(() => {
    if (items && items.length > 0) {
      const newItems = items.map((item) => {
        return {
          ...item,
          expanded: openItems.includes(item.id),
        };
      });
      setAccordionItems(newItems);
    }
  }, [openItems, items, setAccordionItems]);

  useEffect(() => {
    if (accordionItems && accordionItems.length > 0) {
      if (JSON.stringify(openItems) !== prevItems) {
        setReRender(reRender + 1);
      }
    }
  }, [openItems, accordionItems, reRender, prevItems]);

  if (!items || items.length < 1) {
    return null;
  }

  return (
    <>
      <div
        className="usa-accordion usa-accordion--bordered managed-accordion"
        data-testid="accordion"
        aria-multiselectable="false"
      >
        {items.map((item, i) => {
          const hidden = !openItems.includes(item.id);
          return (
            <span
              key={i}
              className={`accordion-container container-${item.id}`}
            >
              <h2
                className="usa-accordion__heading"
                data-testid={`accordion-heading-${item.id}`}
                onClick={() => {
                  if (
                    openItems.includes(item.id) &&
                    typeof onClose === 'function'
                  ) {
                    onClose(item.id);
                  } else if (
                    !openItems.includes(item.id) &&
                    typeof onOpen === 'function'
                  ) {
                    onOpen(item.id);
                    window.setTimeout(() => {
                      scrollToElement(`.container-${item.id}`);
                    }, 250);
                  }
                }}
              >
                <button
                  type="button"
                  className="usa-accordion__button bg-gray-3"
                  aria-expanded={!hidden}
                  aria-controls={item.id}
                  data-testid={`accordionButton_${item.id}`}
                >
                  <span className="accordion-title">
                    <span className="text-container">{item.title}</span>
                  </span>
                </button>
              </h2>
              <div
                id={item.id}
                data-testid={`accordionItem_${item.id}`}
                className="usa-accordion__content usa-prose margin-top-1em margin-bottom-1em padding-bottom-1 padding-top-1"
                hidden={hidden}
              >
                <div className="area-content">{item.content}</div>
              </div>
            </span>
          );
        })}
      </div>
    </>
  );
};

export default ManagedAccordion;
