export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const scrollToElement = (element) => {
  let el = element;
  if (typeof element === 'string') {
    el = document.querySelector(element);
  }

  if (el) {
    el.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      smooth: true,
    });
  }
};

export const slugify = (text) => {
  return text.replace(/[\s_]/g, '-').toLowerCase();
};

export function isValidEmail(email) {
  const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailValidationRegex.test(email);
}

export * from './cookies';
export * from './formatting';
export * from './VINValidation';
export * from './plateValidation';
