import React, { useState, useEffect } from 'react';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { Label, Textbox, Button } from '@gsa/afp-component-library';
import { Scanner } from 'components';
import { plateValidation } from 'utils/plateValidation';
import './FormInputs.css';

export const LicensePlateField = ({
  id = '',
  initialValue = '',
  showErrors = false,
  required = false,
  label = 'Plate Number',
  errorMessage = null,
  scanner = true,
  onChange,
  onValidate,
  className,
  ...rest
}) => {
  const [isValid, setIsValid] = useState(false);
  const [plate, setPlate] = useState(initialValue);
  const [errorTxt, setErrorTxt] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [scanDetails, setScanDetails] = useState(null);
  const prevPlate = usePrevious(plate);

  useEffect(() => {
    setPlate(initialValue.toUpperCase());
  }, [initialValue]);

  const parseScannedPlate = (scanResult, ts) => {
    if (scanResult.length !== 15) {
      return scanResult;
    }
    const aOrB = scanResult.charAt(0);
    const expMonth = scanResult.slice(1, 3);
    const expYear = scanResult.slice(3, 7);
    const plateClass = scanResult.slice(7, 10);
    const tag = scanResult.slice(10, 15);

    setScanDetails({
      aOrB,
      expMonth,
      expYear,
      plateClass,
      tag,
      ts,
    });

    return `${plateClass}${tag}`;
  };

  useEffect(() => {
    let validNow = false;
    let errorNow = '';
    if (required && plate.trim() === '') {
      validNow = false;
      errorNow = 'This is a required field';
    } else if (errorMessage) {
      validNow = false;
      errorNow = errorMessage;
    } else {
      validNow = true;
      errorNow = '';
      const validatePlate = plateValidation(plate);
      if (validatePlate !== '') {
        validNow = false;
        errorNow = validatePlate;
      }
    }
    if (typeof onChange === 'function' && prevPlate !== plate.trim()) {
      onChange(plate, scanDetails);
    }
    if (typeof onValidate === 'function' && prevPlate !== plate.trim()) {
      onValidate(validNow, errorNow);
    }
    setIsValid(validNow);
    setErrorTxt(errorNow);
  }, [
    plate,
    onChange,
    onValidate,
    required,
    scanDetails,
    prevPlate,
    errorMessage,
  ]);

  return (
    <div
      className="form-input license-plate"
      data-testid="license-plate-form-input"
    >
      <div className={!isValid && showErrors ? 'form-error' : ''}>
        <Label className="margin-top-1" required={required}>
          <strong>{label}</strong>
        </Label>
        {!isValid && showErrors && errorTxt && (
          <div className="form-error-msg" data-testid="form-error-msg">
            <strong>{errorTxt}</strong>
          </div>
        )}
        {scanner && <div className="gray-text">Scan or type</div>}
        <div>
          <Textbox
            data-testid="license-plate-input"
            type="text"
            id={id}
            name={id}
            value={plate}
            variant={!isValid && showErrors ? 'error' : ''}
            onChange={(e) => {
              setScanDetails(null);
              setPlate(e.target.value.toUpperCase());
            }}
            className={`plate-input${scanner ? '' : ' no-scan'}${
              className ? ` ${className}` : ''
            }`}
            {...rest}
          />
          {scanner && (
            <Button
              data-testid="scan-btn"
              variant="primary"
              size="medium"
              label=""
              leftIcon={{
                name: 'photo_camera',
                iconName: 'photo_camera',
              }}
              onClick={() => {
                setPlate('');
                setScanDetails(null);
                setShowScanner(true);
              }}
              className="icon-button-round"
            />
          )}
        </div>
        {scanner && showScanner && (
          <Scanner
            onCapture={(captured, ts) => {
              setPlate(parseScannedPlate(captured, ts).toUpperCase());
              setShowScanner(false);
            }}
            onClose={() => {
              setShowScanner(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default LicensePlateField;
