import React, { useState } from 'react';
import {
  Breadcrumbs,
  AppointmentScheduler,
  AppointmentListing,
} from 'components';
import { useResponsiveQuery } from 'atomic-layout';
import { PageTitle, Button } from '@gsa/afp-component-library';

export const AppointmentScheduling = () => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  const [option, setOption] = useState('calendar');

  return (
    <div className={`grid-col margin-bottom-2`}>
      <Breadcrumbs
        items={[
          { path: '/home', text: 'Home' },
          { text: 'Appointment Scheduling' },
        ]}
      />
      <PageTitle
        title="Appointment Scheduling"
        className={`${isDesktop ? 'margin-top-0' : ''}`}
      />
      <div className="margin-bottom-3">
        <Button
          label={option === 'calendar' ? 'View Listing' : 'View Calendar'}
          variant="primary"
          size="small"
          onClick={() => {
            if (option === 'calendar') {
              setOption('listing');
            } else {
              setOption('calendar');
            }
          }}
        />
      </div>
      {option === 'calendar' && <AppointmentScheduler />}
      {option === 'listing' && <AppointmentListing />}
    </div>
  );
};

export default AppointmentScheduling;
