import React, { useState } from 'react';
import { Accordion, CounterTag, Button } from '@gsa/afp-component-library';
import { AreYouSureModal } from 'components';
import { Composition } from 'atomic-layout';
import './PlateListing.css';

export const PlateListing = ({
  plates,
  onDelete,
  setShowSinglePlateDelete,
  isResponseList = false,
  defaultOpen = false,
}) => {
  const [showPlateDeleteModal, setShowPlateDeleteModal] = useState(false);
  const [plateToDelete, setPlateToDelete] = useState(null);

  if (!plates) {
    return null;
  }

  let plateData = plates;
  if (isResponseList) {
    const tmpPlates = {};
    plates.forEach((plate) => {
      if (!tmpPlates[plate?.plateNo]) {
        tmpPlates[plate?.plateNo] = {};
      }
      const plateNum = plate?.plateNo.slice(3);
      const plateClass = plate?.plateNo.slice(0, 3);
      tmpPlates[plate?.plateNo] = {
        ...tmpPlates[plate?.plateNo],
        [plate?.plateAOrB]: {
          plate: plateNum,
          plateAOrB: plate?.plateAOrB,
          expMonth: plate?.expMonth,
          expYear: plate?.expYear,
          tagClass: plateClass,
        },
      };
    });
    plateData = tmpPlates;
  }

  let plateCount = 0;
  for (const [, value] of Object.entries(plateData)) {
    if (value?.A && value?.A?.missing !== true) {
      plateCount += 1;
    }
    if (value?.B && value?.B?.missing !== true) {
      plateCount += 1;
    }
  }

  const formatContent = (plates) => {
    const content = [];
    if (plates) {
      Object.keys(plates).forEach((plate, i) => {
        const plateData = plates[plate];
        const hasA = plateData?.A || false;
        const hasB = plateData?.B || false;
        const currentPlateData =
          hasA && plateData?.A?.missing !== true ? plateData.A : plateData.B;
        const missingOtherPlate =
          (hasA && plateData.A?.missing === true) ||
          (hasB && plateData.B?.missing === true);
        const platesCaptured = [];
        if (hasA && plateData.A?.missing !== true) {
          platesCaptured.push('A');
        }
        if (hasB && plateData.B?.missing !== true) {
          platesCaptured.push('B');
        }
        let areas = `
          PlateAB Tag Exp Trash
          Missing Missing Missing Trash
        `;
        if (isResponseList) {
          areas = `
            PlateAB Tag Exp Exp
          `;
        }
        content.push(
          <div className="plate-tile" data-testid={`plate-tile-${i}`} key={i}>
            <Composition
              areas={areas}
              gapCol={8}
              gapRow={missingOtherPlate ? 8 : 0}
              templateCols="28px 108px 76px auto"
              templateRows={`${missingOtherPlate ? '28px' : '40px'} ${
                missingOtherPlate ? '28px' : '0px'
              }`}
              padding={16}
              alignItems="center"
            >
              {({ PlateAB, Tag, Exp, Trash, Missing }) => {
                return (
                  <>
                    <PlateAB>{platesCaptured.join('/')}</PlateAB>
                    <Tag>
                      {currentPlateData.tagClass}-{currentPlateData.plate}
                    </Tag>
                    {currentPlateData.expMonth && (
                      <Exp>
                        {currentPlateData.expMonth}/{currentPlateData.expYear}
                      </Exp>
                    )}

                    <Trash alignContent="flex-start">
                      <div
                        className="text-right"
                        style={{
                          height: missingOtherPlate ? '56px' : 'auto',
                        }}
                      >
                        <Button
                          className="width-full margin-0 trash-btn"
                          data-testid="delete-plate-btn"
                          variant="outline"
                          size="large"
                          label=""
                          leftIcon={{
                            name: 'delete',
                          }}
                          onClick={() => {
                            if (
                              currentPlateData.tagClass !== 'G91' &&
                              (!hasA || !hasB)
                            ) {
                              setShowSinglePlateDelete(plate);
                            } else {
                              setPlateToDelete(plate);
                              setShowPlateDeleteModal(true);
                            }
                          }}
                        />
                      </div>
                    </Trash>
                    <Missing>
                      {missingOtherPlate && (
                        <ul
                          className="missing-plate"
                          data-testid="missing-plate"
                        >
                          <li>Missing second plate</li>
                        </ul>
                      )}
                    </Missing>
                  </>
                );
              }}
            </Composition>
          </div>,
        );
      });
    }

    return content;
  };

  const generateTitle = () => {
    return (
      <>
        <CounterTag count={plateCount} />{' '}
        {isResponseList ? 'Plates with errors' : 'Plates added in this session'}
      </>
    );
  };

  return (
    <span
      className={`plate-listing${plateCount < 1 ? ' empty' : ''}`}
      data-testid="plate-listing"
    >
      <Accordion
        bordered={true}
        items={[
          {
            title: generateTitle(),
            content: formatContent(plateData),
            expanded: defaultOpen,
          },
        ]}
        className="plate-listing-accordion"
        multiselectable={false}
      />
      <AreYouSureModal
        title="Are you sure you want to remove this plate set?"
        show={showPlateDeleteModal}
        showConfirm={true}
        showCancel={true}
        confirmLabel="Yes, remove both"
        cancelLabel="Cancel"
        onConfirm={() => {
          if (typeof onDelete === 'function') {
            onDelete(plateToDelete);
          }
          setPlateToDelete(null);
          setShowPlateDeleteModal(false);
        }}
        onCancel={() => setShowPlateDeleteModal(false)}
      >
        Both A & B plates will be removed.
      </AreYouSureModal>
    </span>
  );
};

export default PlateListing;
