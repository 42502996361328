import React from 'react';
import { Modal, Button } from '@gsa/afp-component-library';
import { Composition, useResponsiveQuery } from 'atomic-layout';
import 'styled-components/macro';
import './AreYouSureModal.css';

export const AreYouSureModal = ({
  title,
  show = false,
  showConfirm = false,
  showCancel = false,
  confirmLabel = '',
  cancelLabel = '',
  onConfirm,
  onCancel,
  variant,
  children,
}) => {
  const isDesktop = useResponsiveQuery({ from: 'md' });
  if (!show) {
    return null;
  }
  let numAreas = 0;
  const renderActions = () => {
    let areas;
    if (!showConfirm && !showCancel) {
      return null;
    } else if (showConfirm && !showCancel) {
      areas = `Confirm`;
      numAreas = 1;
    } else if (!showConfirm && showCancel) {
      areas = `Cancel`;
      numAreas = 1;
    } else {
      areas = `
        Confirm
        Cancel
      `;
      numAreas = 2;
    }

    return (
      <Composition
        areas={areas}
        gap={28}
        templateCols="1fr"
        templateRows="auto"
        width="100%"
      >
        {({ Confirm, Cancel }) => (
          <>
            <Confirm>
              <Button
                data-testid="confirm-button"
                className="width-full"
                variant="primary"
                size={isDesktop ? 'medium' : 'large'}
                label={confirmLabel}
                onClick={() => {
                  if (typeof onConfirm === 'function') {
                    onConfirm();
                  }
                }}
                css={`
                  text-align: center !important;
                `}
              />
            </Confirm>
            <Cancel>
              <div className="centered">
                <span
                  data-testid="cancel-button"
                  className="cancel"
                  onClick={() => {
                    if (typeof onCancel === 'function') {
                      onCancel();
                    }
                  }}
                >
                  {cancelLabel}
                </span>
              </div>
            </Cancel>
          </>
        )}
      </Composition>
    );
  };

  return (
    <div
      className={`afp-modal-wrapper${variant ? ' ' + variant : ''}${
        isDesktop ? '' : ' mobile'
      }`}
      role="dialog"
      aria-labelledby="modal-heading"
      data-testid="are-you-sure-modal"
    >
      <div className="afp-modal-overlay">
        <Modal
          title={title}
          actions={renderActions()}
          className={`are-you-sure-modal areas-${numAreas}`}
          hideClose={false}
          onClose={() => {
            if (typeof onCancel === 'function') {
              onCancel();
            }
          }}
          variant="large"
        >
          {children}
        </Modal>
      </div>
    </div>
  );
};

export default AreYouSureModal;
