import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setAppointmentListing = createAsyncThunk(
  'listings/setAppointmentListing',
  async (data) => {
    return data;
  },
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(setAppointmentListing.fulfilled, (state, action) => {
      return {
        ...state,
        appointments: action.payload,
      };
    });
  },
});

export default listingsSlice.reducer;
